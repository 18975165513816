// api.ts
import type { NetworkPayload } from "../../../composites";
import type { SavvyMoneyViewUrl } from "./types";

const API = {
  fetchSavvyRedirectUrl: (view: SavvyMoneyViewUrl): NetworkPayload => {
    return {
      url: `signed_urls/${view}/savvymoney`,
      options: {
        method: "GET",
      },
    };
  },
};

export default API;
