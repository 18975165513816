import { catcat } from "cerulean";

import styles from "./BackButton.module.scss";

type Props = {
  text?: string;
  className?: string;
  onClick: () => void;
};

const BackButton = ({ text = "Back", className, onClick }: Props) => (
  <div className={catcat([styles.backButton, className])}>
    <button
      className="nds-typography nds-button nds-button--secondary resetButton"
      onClick={onClick}
    >
      <span className="narmi-icon-chevron-left" />
      {text}
    </button>
  </div>
);

export default BackButton;
