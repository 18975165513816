/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

type EndToEndTestState = {
  isReady: boolean;
  featureOverrides: Partial<API.Features>;
};

const initialState: EndToEndTestState = {
  isReady: false,
  featureOverrides: {},
};

const name = "endToEndTest";

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setReady: (state, action: PayloadAction<boolean>) => {
      state.isReady = action.payload;
    },
    setFeatures: (state, action: PayloadAction<Partial<API.Features>>) => {
      state.featureOverrides = action.payload;
    },
  },
});

export const { actions } = slice;

export default {
  [name]: slice.reducer,
};
