// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--dacRl{margin-bottom:var(--space-l)}.title--RLL36{margin-right:\"auto\"}.iframeContainer--UbU65{display:flex;justify-content:center}", "",{"version":3,"sources":["webpack://./components/dashboard/SavvyMoneyWidget/SavvyMoneyWidget.module.scss"],"names":[],"mappings":"AAAA,kBACE,4BAAA,CAGF,cACE,mBAAA,CAGF,wBACE,YAAA,CACA,sBAAA","sourcesContent":[".container {\n  margin-bottom: var(--space-l);\n}\n\n.title {\n  margin-right: \"auto\";\n}\n\n.iframeContainer {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container--dacRl",
	"title": "title--RLL36",
	"iframeContainer": "iframeContainer--UbU65"
};
export default ___CSS_LOADER_EXPORT___;
