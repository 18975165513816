/* eslint-disable camelcase */

import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
  Outlet,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useLocalization } from "@fluent/react";

import { Tabs } from "cerulean";
import { featureEquals } from "byzantine/src/Feature";
import type Account from "byzantine/src/Account";
import type Membership from "byzantine/src/Membership";
import { type Features, type FeatureValue } from "byzantine/src/Feature";
import { SudoProvider, SudoRoutes } from "../SudoContext";
import RequestOTP from "../SudoContext/RequestOTP";
import SubmitOTP from "../SudoContext/SubmitOTP";

import CardManagement from "../card_settings/CardManagement";
import { useUserFeatures } from "../contexts/UserFeaturesContext";
import BankCardList from "./cards/BankCardList";
import UserSettingsProfilePage from "./profile_settings/UserSettingsProfilePage";
import ExternalAccountManager from "./linked_accounts/ExternalAccountManager";
import Recipients from "./recipients/Recipients";
import SecuritySettingsContainer from "./security/SecuritySettingsContainer";
import { InstitutionSettingsContextProvider } from "../contexts/InstitutionSettingsContext";
import ReplaceACardContainer from "./cards/ReplaceACardContainer/ReplaceACardContainer";
import SettingsBanner from "./SettingsBanner";
import ScrollToTopOnRouteChange from "../ScrollToTopOnRouteChange";
import styles from "./SettingsNavBar.module.scss";

interface UserFeaturesContextType {
  ach?: boolean;
  alerts?: boolean;
  bill_pay?: string;
  allow_external_account_linking_without_transferable_accounts?: boolean;
  cards?: boolean;
  p2p: string[];
  wires: boolean;
  olb_enable_replace_a_card?: boolean;
}

interface SettingsProps {
  externalAccounts: Account[];
  accounts: Account[];
  uatMicrodepAmntOne: string;
  uatMicrodepAmntTwo: string;
  memberships: Membership[];
  devices: string;
  features: UserFeaturesContextType;
}

enum SettingsTabToRoute {
  "Profile" = "/profile",
  "Linked accounts" = "/linked_accounts",
  "Recipients" = "/recipients",
  "Security" = "/security",
  "Cards" = "/cards",
}
export type SettingsTab = keyof typeof SettingsTabToRoute;

const convertRouteToRegex = (route: string) => {
  if (route === "/cards") {
    return /^\/cards(\/([0-9a-z-]+)(\/travel_notice)?)?\/?$/;
  }
  return new RegExp(`^${route}/?$`);
};

export const findRouteIndexOfPathname = (
  pathname: string,
  settings: SettingsTab[],
) =>
  settings.findIndex((setting) =>
    convertRouteToRegex(SettingsTabToRoute[setting]).test(
      pathname.toLowerCase(),
    ),
  );

const SettingsTabs = () => {
  const { l10n } = useLocalization();
  const features = useUserFeatures() as UserFeaturesContextType;
  const settings: SettingsTab[] = [
    "Profile" as SettingsTab,
    ...(features.ach ||
    features.allow_external_account_linking_without_transferable_accounts
      ? ["Linked accounts" as SettingsTab]
      : []),
    ...(featureEquals(
      features as unknown as Features,
      "p2p",
      "m2m" as FeatureValue,
    ) ||
    features.bill_pay ||
    features.wires
      ? ["Recipients" as SettingsTab]
      : []),
    "Security",
    ...(features.cards ? ["Cards" as SettingsTab] : []),
  ];

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname) {
      const index = findRouteIndexOfPathname(location.pathname, settings);
      if (index !== -1) {
        setSelectedTabIndex(index);
      }
    }
  }, []);

  const onTabChange = (index: number) => {
    setSelectedTabIndex(index);
    navigate(SettingsTabToRoute[settings[index]]);
  };

  return (
    <div className={styles.container}>
      <SettingsBanner />
      <div className="margin--top--l">
        <Tabs selectedIndex={selectedTabIndex} onTabChange={onTabChange}>
          <Tabs.List>
            {settings.map((setting) => {
              const tabName = l10n.getString(
                {
                  Profile: "tab-settings-profile",
                  "Linked accounts": "tab-settings-linked-accounts",
                  Recipients: "tab-settings-recipients",
                  Security: "tab-settings-security",
                  Cards: "tab-settings-cards",
                }[setting],
              );
              return <Tabs.Tab key={setting} label={tabName} tabId={setting} />;
            })}
          </Tabs.List>
        </Tabs>
        <Outlet />
      </div>
    </div>
  );
};

const SettingsRouter = ({
  externalAccounts,
  uatMicrodepAmntOne,
  uatMicrodepAmntTwo,
  accounts,
  devices,
  features,
  memberships,
}: SettingsProps) => (
  <div className="settings-nav-bar-desktop">
    <Router basename="settings">
      <SudoProvider>
        <ScrollToTopOnRouteChange />
        <Routes>
          <Route path={`${SudoRoutes.RequestOTP}`} element={<RequestOTP />} />
          <Route path={`${SudoRoutes.SubmitOTP}`} element={<SubmitOTP />} />
          {features.cards && (
            <Route
              path="cards/replace-a-card/:cardId/*"
              element={<ReplaceACardContainer />}
            />
          )}
          <Route path="/" element={<SettingsTabs />}>
            {(features.ach ||
              features.allow_external_account_linking_without_transferable_accounts) && (
              <Route
                path="linked_accounts"
                element={
                  <ExternalAccountManager
                    externalAccounts={externalAccounts}
                    uatMicrodepAmntOne={uatMicrodepAmntOne}
                    uatMicrodepAmntTwo={uatMicrodepAmntTwo}
                  />
                }
              />
            )}
            {(featureEquals(
              features as unknown as Features,
              "p2p",
              "m2m" as FeatureValue,
            ) ||
              features.bill_pay ||
              features.wires) && (
              <Route
                path="recipients"
                element={<Recipients accounts={accounts} />}
              />
            )}
            <Route
              path="security"
              element={
                <SecuritySettingsContainer
                  accounts={accounts}
                  devices={devices}
                  features={features}
                />
              }
            />
            {features.cards &&
              features?.olb_enable_replace_a_card &&
              ["/cards", "/cards/:id/*"].map((path) => (
                <Route key={path} path={path} element={<BankCardList />} />
              ))}
            {features.cards && !features?.olb_enable_replace_a_card && (
              <Route path="cards" element={<CardManagement />} />
            )}
            {["", "profile"].map((path) => (
              <Route
                key={path}
                path={path}
                element={<UserSettingsProfilePage memberships={memberships} />}
              />
            ))}
            <Route path="*" element={<Navigate to="" />} />
          </Route>
        </Routes>
      </SudoProvider>
    </Router>
  </div>
);

const SettingsNavBarContainer = ({
  externalAccounts,
  uatMicrodepAmntOne,
  uatMicrodepAmntTwo,
  accounts,
  devices,
  features,
  memberships,
}: SettingsProps) => (
  <InstitutionSettingsContextProvider>
    <SettingsRouter
      externalAccounts={externalAccounts}
      uatMicrodepAmntOne={uatMicrodepAmntOne}
      uatMicrodepAmntTwo={uatMicrodepAmntTwo}
      accounts={accounts}
      devices={devices}
      features={features}
      memberships={memberships}
    />
  </InstitutionSettingsContextProvider>
);

export default SettingsNavBarContainer;
