import { memo } from "react";

import { useInvalidateRequestCache } from "../composites/requestCache/request.cache";

const BackgroundActions = () => {
  useInvalidateRequestCache();
  return null;
};

export default memo(BackgroundActions);
