import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ContentCard,
  EditLabelTooltip,
  ProgressButtons,
  useNotificationContext,
} from "cerulean";
import { useLocalization } from "@fluent/react";

import ApiHttp from "byzantine/src/ApiHttp";
import { useCurrentUser } from "../../../../contexts/CurrentUserContext";
import HeaderWithSteps from "../../../../HeaderWithSteps";
import { useSudoContext } from "../../../../SudoContext";
import Address from "../../../profile_settings/UserSettingsProfilePage/Address";
import style from "./ConfirmDetails.module.scss";

type DetailsCardProps = {
  header: string;
  body: React.ReactNode;
  tooltip?: React.ReactNode;
};

const DetailsCard = ({ header, body, tooltip = null }: DetailsCardProps) => (
  <div className={style.detailsCard}>
    <ContentCard kind="bordered" radiusSize="m">
      <div className={style.detailsHeader}>
        <h4 className="fontSize--m padding--bottom--xxs fontFamily--default margin--right--xxs">
          {header}
        </h4>
        {tooltip}
      </div>
      <div>{body}</div>
    </ContentCard>
  </div>
);

type ConfirmDetailsProps = {
  card: API.Card;
};

const ConfirmDetails = ({ card }: ConfirmDetailsProps) => {
  const { totalSteps, formData, cancelSudo, establishSudo } = useSudoContext();
  const reason = formData?.reason || null;
  const navigate = useNavigate();

  const { currentUser } = useCurrentUser();
  const { sendNotification } = useNotificationContext();
  const { l10n } = useLocalization();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!reason) {
      navigate(`/cards/replace-a-card/${card.id}/reason`);
    }
  }, [reason]);

  /* TODO: This code was copied from mobile. Maybe we should add the switch to byzantine Card.ts as a shared method? */
  const reasonText = useMemo(() => {
    switch (reason) {
      case "lost":
        return l10n.getString("replace-card-confirm-reason-lost");
      case "stolen":
        return l10n.getString("replace-card-confirm-reason-stolen");
      case "damaged":
        return l10n.getString("replace-card-confirm-reason-damaged");
      case "never-received":
        return l10n.getString("replace-card-confirm-reason-never-received");
      default:
        return "";
    }
  }, [reason]);

  return (
    <>
      <HeaderWithSteps
        headerText={l10n.getString("screen-title-replace-card-confirm")}
        subheaderText={l10n.getString("replace-card-confirmation", {
          email: currentUser?.getMaskedEmail() || "your email address",
        })}
        step={totalSteps}
        totalSteps={totalSteps}
      />
      <div>
        <DetailsCard
          header={l10n.getString("replace-card-details-card")}
          body={`${card.name} **${card.last_four_digits}`}
        />
        <DetailsCard
          header={l10n.getString("replace-card-details-reason")}
          body={reasonText}
        />
        <DetailsCard
          header={l10n.getString("replace-card-details-mailing-address")}
          body={<Address address={card.shipping.address as API.Address} />}
          tooltip={
            <EditLabelTooltip
              tooltipText={l10n.getString(
                "replace-card-details-incorrect-address-tooltip",
              )}
            />
          }
        />
        <DetailsCard
          header={l10n.getString("replace-card-details-shipping-method")}
          body={
            <>
              <div>{l10n.getString("replace-card-shipping-method-line-1")}</div>
              <div>{l10n.getString("replace-card-shipping-method-line-2")}</div>
              <div>{l10n.getString("replace-card-shipping-method-line-3")}</div>
            </>
          }
        />
      </div>
      <div className={style.progressButtonsContainer}>
        <ProgressButtons
          backLabel={l10n.getString("button-back")}
          nextLabel={l10n.getString("button-submit")}
          onBack={cancelSudo}
          isNextLoading={isLoading}
          onNext={async () => {
            setIsLoading(true);
            await establishSudo("/cards", async () => {
              try {
                await ApiHttp.fetch(
                  `cards/${card.id}/replace_card`,
                  {
                    method: "POST",
                    headers: { "API-Version": "0017" },
                  },
                  // "never-received" is actually just "lost" behind-the-scenes
                  { reason: reason !== "never-received" ? reason : "lost" },
                );
                setIsLoading(false);
                sendNotification({
                  type: "success",
                  text: "Card ordered.",
                });
              } catch (error: unknown) {
                const errorMessage =
                  typeof error === "string" && error
                    ? error
                    : "There was a problem ordering your card.";
                sendNotification({
                  type: "negative",
                  text: errorMessage,
                });

                setIsLoading(false);
              }
            });
            setIsLoading(false);
          }}
        />
      </div>
    </>
  );
};

export default ConfirmDetails;
