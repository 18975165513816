import ZELLE_LOGO from "../../assets/images/zelle-logo.svg";

const W = 1568.63;
const H = 678.02;

export default function ZelleLogo({ size = 22 }: { size?: number }) {
  const height = size;
  const width = W * (height / H);
  return (
    <div className="alignChild--left--top">
      <img
        width={width}
        height={height}
        src={ZELLE_LOGO}
        alt="Zelle"
        aria-label="Zelle"
      />
      <sup
        style={{
          fontSize: "var(--font-size-xs)",
          marginLeft: 2,
          color: "var(--color-black))",
        }}
      >
        &reg;
      </sup>
    </div>
  );
}
