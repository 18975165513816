import React, { useState, useEffect } from "react";
import { useLocalization } from "@fluent/react";
import PropTypes from "prop-types";
import { Search } from "react-feather";

import Tabs from "../tabs/Tab";
import ThreadSearch from "./ThreadSearch";
import ThreadList from "./ThreadList";

export default function TabbedThreadList({
  selectedThreadId,
  setSelectedThreadId,
  loadAllThreads,
}) {
  const { l10n } = useLocalization();
  const [recentThreads, setRecentThreads] = useState(null);
  const [olderThreads, setOlderThreads] = useState(null);
  const [searchOpened, setSearchOpened] = useState(false);

  useEffect(() => {
    const threads = loadAllThreads();
    threads.startingThread.then((result) => {
      setRecentThreads(result.threads);
      if (result.threads.length > 0) {
        setSelectedThreadId(result.threads[0].threadId);
      }
    });
    threads.endingThread.then((result) => setOlderThreads(result.threads));
  }, []);

  useEffect(() => {
    const threads = loadAllThreads();
    threads.startingThread.then((result) => {
      setRecentThreads(result.threads);
    });
    threads.endingThread.then((result) => setOlderThreads(result.threads));
  }, [selectedThreadId]);

  function renderRecentThreads() {
    return (
      <ThreadList
        threads={recentThreads}
        selectedThreadId={selectedThreadId}
        onThreadSelected={(newSelectedThreadId) =>
          setSelectedThreadId(newSelectedThreadId)
        }
      />
    );
  }

  function renderOlderThreads() {
    return (
      <ThreadList
        threads={olderThreads}
        selectedThreadId={selectedThreadId}
        onThreadSelected={(newSelectedThreadId) =>
          setSelectedThreadId(newSelectedThreadId)
        }
      />
    );
  }

  const searchActions = [
    {
      label: l10n.getString("message-action-search"),
      fancyLabel: <Search size={18} />,
      className: "ui button message-icon-button",
      onSubmit: (callback) => {
        setSearchOpened(true);
        callback();
      },
    },
  ];

  const tabPanes = [
    {
      header: {
        text: l10n.getString("message-tab-recent"),
        actions: searchActions,
      },
      render: renderRecentThreads,
    },
    {
      header: {
        text: l10n.getString("message-tab-older"),
        actions: searchActions,
      },
      render: renderOlderThreads,
    },
  ];
  return (
    <div className="tabbed-thread-list">
      {searchOpened ? (
        <ThreadSearch
          closeSearch={() => setSearchOpened(false)}
          selectedThreadId={selectedThreadId}
          setSelectedThreadId={setSelectedThreadId}
        />
      ) : (
        <Tabs panes={tabPanes} />
      )}
    </div>
  );
}
TabbedThreadList.propTypes = {
  selectedThreadId: PropTypes.string,
  setSelectedThreadId: PropTypes.func.isRequired,
  replyDisclaimer: PropTypes.string,
  userMessageUploads: PropTypes.bool,
  loadAllThreads: PropTypes.func,
  reloadThreads: PropTypes.func,
};
