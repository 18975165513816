import React from "react";
import PropTypes from "prop-types";
import isEqual from "lodash.isequal";
import { Dropdown, DropdownListItem } from "cerulean";

const DropdownField = ({
  options,
  onChange = () => {},
  label = "",
  value = "",
  ...otherProps
}) => {
  /* a reusable selector that expects `options` to be an array of objects in the following format:
      {
        displayName: "string to be displayed when the dropdown is open",
        value: "what is saved as the field value when this option is selected",
      }
   */
  const triggerValue =
    options.find((option) => isEqual(option.value, value))?.displayName || "";

  return (
    <Dropdown triggerLabel={label} triggerValue={triggerValue} {...otherProps}>
      {options.map((option) => (
        <DropdownListItem
          key={option.value}
          anyItemSelected={!!value}
          isSelected={isEqual(option.value, value)}
          onClick={() => onChange(option.value)}
        >
          {option.displayName}
        </DropdownListItem>
      ))}
    </Dropdown>
  );
};
DropdownField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
};

export default DropdownField;
