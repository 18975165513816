import React from "react";
import PropTypes from "prop-types";
import { TextInput, formatNumber } from "@narmi/design_system";

interface AmountTextInputProps {
  onChange?: CallableFunction;
  value?: string;
  field?: string;
  label: string;
}

const AmountTextInput = ({
  onChange = () => {},
  value = "",
  label,
  ...textInputProps
}: AmountTextInputProps) => {
  const displayValue = value && value[0] !== "$" ? `$${value}` : value;
  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = event.target.value.replace(/[^\d.]/g, "");
    if (!parsedValue.match(/^\d*.{0,1}\d{0,2}$/)) return;
    if (!parsedValue) {
      onChange("$");
      return;
    }
    onChange(parsedValue);
  };
  const onFocus = () => {
    if (!value) onChange("$");
  };
  const onBlur = () => {
    if (value === "$") {
      onChange("");
      return;
    }
    const strippedValue = value.replace(/[$,]/g, "");
    onChange(formatNumber(strippedValue));
  };
  return (
    <TextInput
      onChange={handleInput}
      value={displayValue}
      onFocus={onFocus}
      onBlur={onBlur}
      inputMode="decimal"
      label={label}
      {...textInputProps}
    />
  );
};
AmountTextInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  field: PropTypes.string,
  label: PropTypes.string,
};

export default AmountTextInput;
