import { featureEquals, featureEnabled } from "../../../../Feature";

import type { FeatureQuery, FeatureValue, FeatureKey } from "./types";

export function isFeatureEnabled(features: API.Features, query: FeatureQuery) {
  return featureEnabled(features, query);
}

export function isFeatureDisabled(features: API.Features, feature: FeatureKey) {
  return featureEquals(features, feature, false);
}

export function doesFeatureEqual(
  features: API.Features,
  flag: FeatureKey,
  value: FeatureValue | FeatureValue[],
) {
  return featureEquals(features, flag, value);
}
