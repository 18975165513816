import * as Yup from "yup";

import { createForm } from "../../../forms";

export type Method = "" | "freeform" | "template";

export type MethodSelectorFormFields = {
  method: Method;
};

const validationSchema = Yup.object().shape({
  method: Yup.string().required("Required"),
});

const initialValues: MethodSelectorFormFields = {
  method: "",
};

export const methodSelectorForm = createForm({
  initialValues,
  validationSchema,
});
