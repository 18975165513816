import ApiHttp from "byzantine/src/ApiHttp";

const fetchIsSudo = () => ApiHttp.fetch("sudo");

const fetchSudoDevices = () => ApiHttp.fetch("sudo", { method: "POST" });

const fetchSudoCode = (selectedDeviceId: string) =>
  // we will get a 403 forbidden here, as we're fetching an MFA code
  ApiHttp.fetch(
    "sudo",
    { method: "POST" },
    { device_id: selectedDeviceId },
  ).catch((response) => ({
    message: response.message,
  }));

export { fetchIsSudo, fetchSudoDevices, fetchSudoCode };
