import React from "react";
import PropTypes from "prop-types";
import { catcat } from "cerulean";
import Transaction from "byzantine/src/Transaction";
import HeroBanner from "../HeroBanner";
import HoldsCard from "./HoldsCard";
import TransactionsCard from "./TransactionsCard";
import ScheduledTransfersCard from "./ScheduledTransfersCard";
import AccountDetailsCard from "./AccountDetailsCard";
import BalancesCard from "./BalancesCard";
import StatementsCard from "./StatementsCard";
import ServicesAndRequests from "./ServicesAndRequests";
import PendingWiresCard from "./PendingWiresCard";
import { AccountContextProvider } from "../contexts/AccountContext";
import { InstitutionSettingsContextProvider } from "../contexts/InstitutionSettingsContext";
import AccountBanner from "./AccountBanner";

import style from "../DualColumnLayout.module.scss";

const AccountPageContainer = ({
  accounts,
  accountUuid = "",
  transactionsJson,
  limitsJson,
  replyTime,
}) => {
  if (!accountUuid) {
    return null;
  }
  const limits = limitsJson ? JSON.parse(limitsJson) : {};
  const account = accounts.find((a) => a.id === accountUuid);

  const transactions = JSON.parse(transactionsJson).map((t) =>
    Transaction.deserialize(t, account),
  );

  return (
    <AccountContextProvider accounts={accounts}>
      <InstitutionSettingsContextProvider>
        <AccountBanner account={account} />
        <HeroBanner account={account} />
        <div className={style.pageLayout}>
          <div className={catcat(style.leftColumn, style.second)}>
            <ScheduledTransfersCard accountUuid={accountUuid} limits={limits} />
            <PendingWiresCard accountUuid={accountUuid} />
            <HoldsCard accountUuid={accountUuid} />
            <TransactionsCard
              account={account}
              recentTransactions={transactions}
              replyTime={replyTime}
            />
          </div>
          <div className={catcat(style.rightColumn, style.first)}>
            <BalancesCard account={account} />
            <AccountDetailsCard account={account} />
          </div>
          <div className={catcat(style.rightColumn, style.third)}>
            <StatementsCard />
            <ServicesAndRequests account={account} />
          </div>
        </div>
      </InstitutionSettingsContextProvider>
    </AccountContextProvider>
  );
};

AccountPageContainer.propTypes = {
  accounts: PropTypes.array,
  accountUuid: PropTypes.string,
  transactionsJson: PropTypes.string,
  limitsJson: PropTypes.string,
  replyTime: PropTypes.string,
};

export default AccountPageContainer;
