/* eslint-disable camelcase */

import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Account from "byzantine/src/Account";
import AccountContext from "../../contexts/AccountContext";
import { useInstitutionSettings } from "../../contexts/InstitutionSettingsContext";
import { useUserFeatures } from "../../contexts/UserFeaturesContext";
import useActions from "../../hooks/useActions";
import LoanPaymentByCard from "./payment_by_card/LoanPaymentByCard";
import LoanPaydown from "./paydown/LoanPaydown";

interface LoanPaymentRouterProps {
  limits: object;
}

interface UserFeatures {
  loan_payment_by_card: boolean;
  loan_principal_payment: boolean;
}

const LoanPaymentRouter = ({ limits }: LoanPaymentRouterProps) => {
  const { accounts } = useContext(AccountContext) as { accounts: Account[] };
  const { ach_allows_pull = false } = useInstitutionSettings();
  const { loan_payment_by_card, loan_principal_payment } =
    useUserFeatures() as UserFeatures;

  const [toAccountId, setToAccountId] = useState<API.AccountId>();
  const { goToPrevious } = useActions();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (!loan_payment_by_card && !loan_principal_payment) {
      navigate(`/funds?${params.toString()}`, { replace: true });
      return;
    }

    const loan_id = params.get("to_account_id") as API.AccountId;
    const loan = accounts.find((account) => account.id === loan_id);

    params.delete("to_account_id");
    params.delete("previous");
    window.history.replaceState(
      {},
      "",
      `${document.location.href.split("?")[0]}?${params.toString()}`
    );

    if (
      !loan ||
      !loan.isValidInternalOrExternalTransferDestination(ach_allows_pull)
    ) {
      return;
    }

    if (loan.isPayableByCard() && loan_payment_by_card) {
      setToAccountId(loan_id);
    } else if (loan.isInternalLoan() && loan_principal_payment) {
      setToAccountId(loan_id);
    }
  }, [accounts]);

  if (loan_payment_by_card) {
    return (
      <LoanPaymentByCard
        limits={limits}
        loanId={toAccountId}
        goToPrevious={goToPrevious}
      />
    );
  }
  if (loan_principal_payment) {
    return (
      <LoanPaydown
        limits={limits}
        loanId={toAccountId}
        goToPrevious={goToPrevious}
      />
    );
  }

  return null;
};
export default LoanPaymentRouter;
