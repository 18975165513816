const ALL = "*";
const WRITE = "write";
const READ = "read";

const canPerformOperation = (
  requiredOperationPermission: API.Permission["operation"],
  userOperationPermission: API.Permission["operation"],
) => {
  switch (userOperationPermission) {
    case ALL: {
      return true;
    }
    case WRITE: {
      return (
        requiredOperationPermission === WRITE ||
        requiredOperationPermission === READ
      );
    }
    case READ: {
      return requiredOperationPermission === READ;
    }
    default:
      return false;
  }
};

export const hasPermission = (
  permissions: API.Permission[],
  { model_str: modelStr, operation, uuid }: API.Permission,
) => {
  const found = permissions.find(
    (permission) =>
      [modelStr, ALL].includes(permission.model_str) &&
      [uuid, ALL].includes(permission.uuid),
  );
  if (found) {
    return canPerformOperation(operation, found.operation);
  }
  return false;
};
