// @ts-ignore
import React, {
  ComponentType,
  Component,
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
  ReactNode,
} from "react";
import PropTypes from "prop-types";
import Portal from "../Portal";
import SuccessMessage from "./SuccessMessage";
import NegativeMessage from "./NegativeMessage";
import InfoMessage from "./InfoMessage";

const GENERIC_ERROR_MESSAGE =
  "We are having trouble processing your request. Please contact Support.";

const isNotificationType = (notificationType: string): notificationType is NotificationType =>
  ["success", "negative", "info", "dismissable_success"].includes(notificationType);

interface NotificationProps {
  isPortaled?: boolean;
}

const Notification = ({ isPortaled = true }: NotificationProps) => {
  const { notificationState, sendNotification, deleteNotification } = useNotificationContext();

  useEffect(() => {
    // transforms query strings into toasts on initial load
    const params = new URLSearchParams(window.location.search);
    params.forEach((val, key) => {
      if (isNotificationType(key)) {
        sendNotification({ type: key, text: val });
      }
    });
    const urlSearchParams = new URLSearchParams(window.location.search);
    ["success", "negative", "info", "dismissable_success"].forEach((type) =>
      urlSearchParams.delete(type)
    );
    const queryString = urlSearchParams.toString();
    window.history.replaceState({}, "", `${document.location.href.split("?")[0]}?${queryString}`);
  }, []);

  const renderNotification = () => {
    const notifications = notificationState.map((notification) => {
      const { type, text, creationTime } = notification;
      if (!creationTime) {
        return;
      }

      const getMessageComponentType = () => {
        switch (type) {
          case "success": {
            return SuccessMessage;
          }
          case "negative": {
            return NegativeMessage;
          }
          case "info": {
            return InfoMessage;
          }
          case "dismissable_success": {
            return SuccessMessage;
          }
          default: {
            return "div";
          }
        }
      };
      const MessageComponentType = getMessageComponentType();
      return (
        <MessageComponentType
          key={JSON.stringify(notification)}
          dismissMessage={() => {
            deleteNotification(notification);
          }}
          text={text}
          creationTime={creationTime}
          notificationType={type}
        />
      );
    });

    return <>{notifications}</>;
  };

  const notifications = Boolean(notificationState.length) ? renderNotification() : null;

  return isPortaled ? (
    <Portal rootId="notifications-container">{notifications}</Portal>
  ) : (
    notifications
  );
};
Notification.propTypes = {};

interface NotificationContextValue {
  notificationState: NotificationWithText[];
  sendNotification: (notification: NotificationWithText) => void;
  sendSuccessNotification: (notificationText: string) => void;
  sendNegativeNotification: (notificationText: string) => void;
  sendInfoNotification: (notificationText: string) => void;
  sendDismissiableSuccessNotification: (notificationText: string) => void;
  deleteNotification: (notification: NotificationWithText) => void;
  clearNotifications: () => void;
  sendNotificationToParent?: (notification: NotificationWithText) => void;
  deleteNotificationForParent?: (notification: NotificationWithText) => void;
  clearParentNotifications?: () => void;
  hasParentNotificationContext: boolean;
}

export const NotificationContext = createContext<NotificationContextValue>({
  notificationState: [],
  sendNotification: () => {},
  sendSuccessNotification: () => {},
  sendNegativeNotification: () => {},
  sendInfoNotification: () => {},
  sendDismissiableSuccessNotification: () => {},
  deleteNotification: () => {},
  clearNotifications: () => {},
  hasParentNotificationContext: false,
});
export const useNotificationContext = () => useContext(NotificationContext);

type NotificationType = "success" | "negative" | "info" | "dismissable_success";

interface NotificationWithText {
  type: NotificationType;
  text: string;
  creationTime?: number;
}

interface NotificationContextProviderProps {
  children: ReactNode;
}

interface NotificationContextProviderProps {
  isPortaled?: boolean;
}

export const NotificationContextProvider = ({
  children,
  isPortaled = true,
}: NotificationContextProviderProps) => {
  const {
    sendNotification: sendNotificationToParent,
    deleteNotification: deleteNotificationForParent,
    clearNotifications: clearParentNotifications,
  } = useNotificationContext();
  const [notificationState, setNotificationState] = useState<NotificationWithText[]>([]);

  const sendNotification = useCallback((notification: NotificationWithText) => {
    // add toast to notification state (no duplicate messages allowed)
    setNotificationState((currentState) => {
      // if the new notification's text is an array, convert to string.
      let cleanedText = notification.text;
      if (Array.isArray(notification.text)) {
        cleanedText = notification.text.join(". ");
      }
      if (cleanedText === "Network Error") {
        cleanedText = GENERIC_ERROR_MESSAGE;
      }

      // prevents adding duplicate notifications
      if (!currentState.every((n) => n.type !== notification.type || n.text !== cleanedText)) {
        return currentState;
      }

      const newNotification = {
        type: notification.type,
        text: cleanedText,
        creationTime: Date.now(),
      };

      setTimeout(() => {
        setNotificationState((currentState) =>
          currentState.filter((n) => n.creationTime !== newNotification.creationTime)
        );
      }, 5000);

      return [...currentState, newNotification];
    });
  }, []);

  const sendSuccessNotification = useCallback(
    (notificationText: string) => {
      sendNotification({
        text: notificationText,
        type: "success",
      });
    },
    [sendNotification]
  );

  const sendNegativeNotification = useCallback(
    (notificationText: string) => {
      sendNotification({
        text: notificationText,
        type: "negative",
      });
    },
    [sendNotification]
  );

  const sendInfoNotification = useCallback(
    (notificationText: string) => {
      sendNotification({
        text: notificationText,
        type: "info",
      });
    },
    [sendNotification]
  );

  const sendDismissiableSuccessNotification = useCallback(
    (notificationText: string) => {
      sendNotification({
        text: notificationText,
        type: "dismissable_success",
      });
    },
    [sendNotification]
  );

  const deleteNotification = useCallback((notification: NotificationWithText) => {
    // removes toast with the same type and text
    setNotificationState((currentState) =>
      currentState.filter((n) => n.type !== notification.type || n.text !== notification.text)
    );
  }, []);

  const clearNotifications = useCallback(() => {
    setNotificationState([]);
  }, []);

  const notificationValue = useMemo(
    () => ({
      notificationState,
      sendNotification,
      sendSuccessNotification,
      sendNegativeNotification,
      sendInfoNotification,
      sendDismissiableSuccessNotification,
      deleteNotification,
      clearNotifications,
      sendNotificationToParent,
      deleteNotificationForParent,
      clearParentNotifications,
      hasParentNotificationContext: true,
    }),
    [
      notificationState,
      sendNotification,
      sendSuccessNotification,
      sendNegativeNotification,
      sendInfoNotification,
      sendDismissiableSuccessNotification,
      deleteNotification,
      clearNotifications,
      sendNotificationToParent,
      deleteNotificationForParent,
      clearParentNotifications,
    ]
  );

  return (
    <NotificationContext.Provider value={notificationValue}>
      <Notification isPortaled={isPortaled} />
      {children}
    </NotificationContext.Provider>
  );
};

NotificationContextProvider.propTypes = {
  children: PropTypes.node,
};

export function withNotifications(
  WrappedComponent: ComponentType<{
    notificationState: NotificationWithText[];
    sendNotification: (notification: NotificationWithText) => void;
  }>
) {
  class WithNotifications extends Component {
    static contextType = NotificationContext;
    static displayName = `WithNotifications(${
      WrappedComponent ? WrappedComponent.displayName : null
    })`;

    render() {
      return WrappedComponent ? (
        <WrappedComponent
          notificationState={(this.context as NotificationContextValue).notificationState}
          sendNotification={(this.context as NotificationContextValue).sendNotification}
          {...this.props}
        />
      ) : (
        <></>
      );
    }
  }

  return WithNotifications;
}

export default NotificationContext;
