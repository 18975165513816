import React, {
  createContext,
  useCallback,
  useContext,
  type PropsWithChildren,
} from "react";

import { useObjectMemo } from "../../hooks";

import { requestHooks } from "./bootstrap.hooks";

import type { BootstrapFetchRequest } from "./bootstrap.types";
import type { RequestHooks } from "./bootstrap.hooks";

export function createBootstrap<T extends RequestHooks>(...keys: T[]) {
  const hooks = keys.map((name) => requestHooks[name]);
  const BootstrapContext = createContext<BootstrapFetchRequest[]>([]);

  const useBootstrap = () => {
    const results = useContext(BootstrapContext)!;
    const sends = results.map((r) => r.send);

    const load = useCallback(() => {
      sends.forEach((s) => s());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, sends);

    const alreadyHasData = results.every((r) => r.hasData);
    const error = results.some((r) => r.error);
    const loading = results.some((r) => r.loading);
    const refreshing = alreadyHasData && loading;

    return useObjectMemo({
      loading: !refreshing && loading,
      load,
      refreshing,
      refresh: load,
      error,
    });
  };

  const Provider = ({ children }: PropsWithChildren) => {
    const results = hooks.map((hook) => hook());
    return (
      <BootstrapContext.Provider value={results}>
        {children}
      </BootstrapContext.Provider>
    );
  };

  return {
    Provider,
    useBootstrap,
  };
}
