import React from "react";

const objectKeys = <T extends object>(obj: T) =>
  Object.keys(obj) as Array<keyof T>;

/**
 * Memoize an object by shallow comparison of its values.
 * Works best with objects constructed from values memoized upstream.
 *
 * def useExample() => {
 *   const data = useSelector(selectData);
 *   const fn = useCallback(() => { ... }, [data]);
 *
 *   return useObjectMemo({ data, fn });
 * }
 *
 */
export function useObjectMemo<T extends object>(
  obj: T,
  ignore: Array<keyof T> = [],
): T {
  const keys = objectKeys(obj).filter((k) => !ignore.includes(k));
  keys.sort(); // Consistent dependencies order

  const deps = keys.map((k) => obj[k]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(() => obj, deps);
}
