import { ReactNode, createContext } from "react";
import { useFluentLocalization, LoadingShim } from "cerulean";
import { LocalizationProvider } from "@fluent/react";

export const LangSelectContext = createContext({
  isMultilingual: false,
  localeCodes: ["en"],
  localeNames: ["English"],
  selectedLocale: "en",
  setSelectedLocale: (lang: string) => {}, // eslint-disable-line @typescript-eslint/no-unused-vars
});

export interface AppLocalizationProviderProps {
  /** Application content */
  children: ReactNode;
  /** Institution internal_name */
  internalName: string;
  s3ImagesBucket: string;
}

const AppLocalizationProvider = ({
  internalName,
  s3ImagesBucket,
  children,
}: AppLocalizationProviderProps) => {
  const {
    l10n,
    isLoading,
    isMultilingual,
    localeCodes,
    localeNames,
    selectedLocale,
    setSelectedLocale,
  } = useFluentLocalization({
    internalName,
    s3ImagesBucket,
    productCode: "olb",
    apiUrl: "/v1/",
    isMfe: true, // azul is a "microfrontend" due to its multiple React roots
  });

  return isLoading ? (
    <LoadingShim isLoading>
      <div style={{ height: "100vh" }} />
    </LoadingShim>
  ) : (
    <>
      <LocalizationProvider l10n={l10n}>
        <LangSelectContext.Provider
          value={{
            isMultilingual,
            localeCodes,
            localeNames,
            selectedLocale,
            setSelectedLocale,
          }}
        >
          {children}
        </LangSelectContext.Provider>
      </LocalizationProvider>
    </>
  );
};

export default AppLocalizationProvider;
