import React, { useState, useEffect, RefObject } from "react";

const useIsInDialog = (ref: RefObject<HTMLElement> | null) => {
  const [isInDialog, setIsInDialog] = useState(false);

  useEffect(() => {
    if (ref?.current) {
      let parent = ref.current?.parentElement;

      while (parent) {
        const role = parent?.getAttribute?.("role");
        if (role === "dialog") {
          setIsInDialog(true);
          break;
        }
        parent = parent?.parentElement;
      }
    }
  }, [ref]);

  return isInDialog;
};

export default useIsInDialog;
