import { useEffect, useRef } from "react";
import ApiHttp from "byzantine/src/ApiHttp";
import { useLocalization } from "@fluent/react";
import { nanoid } from "nanoid";
import { useLoadingContext, useNotificationContext } from "cerulean";
import useActions from "../hooks/useActions";

const Zelle = () => {
  const { l10n } = useLocalization();
  const { sendNotification } = useNotificationContext();
  const { setIsLoading } = useLoadingContext();
  const { goToPrevious } = useActions();

  const formRef = useRef<HTMLFormElement>(null);
  const fiIdInputRef = useRef<HTMLInputElement>(null);
  const pIdInputRef = useRef<HTMLInputElement>(null);
  const tokenInputRef = useRef<HTMLInputElement>(null);

  const closeIframe = (event: MessageEvent) => {
    if (event.data.event === "jha:ztk:message.frame.close") {
      setIsLoading(true);
      goToPrevious();
    }
  };

  useEffect(() => {
    let deviceId = window.localStorage.getItem("device-id");
    if (!deviceId) {
      deviceId = nanoid();
      window.localStorage.setItem("device-id", deviceId);
    }

    const getToken = async (id: string) => {
      if (
        !formRef?.current ||
        !fiIdInputRef?.current ||
        !pIdInputRef?.current ||
        !tokenInputRef?.current
      ) {
        return;
      }
      setIsLoading(true);
      try {
        const tokenResponse = await ApiHttp.fetch(
          "zelle/jha/token",
          { method: "POST" },
          { device_id: id }
        );
        if (!tokenResponse?.jweToken) throw Error("no jwe token returned");

        formRef.current.action = tokenResponse.url;
        fiIdInputRef.current.value = tokenResponse.fiId;
        pIdInputRef.current.value = tokenResponse.pId;
        tokenInputRef.current.value = tokenResponse.jweToken;

        formRef.current.submit();
      } catch {
        sendNotification({
          type: "negative",
          text: l10n.getString("error-unknown", null, "Something went wrong"),
        });
      } finally {
        setIsLoading(false);
      }
    };

    getToken(deviceId);
    window.addEventListener("message", closeIframe);
    return () => window.removeEventListener("message", closeIframe);
  }, []);

  return (
    <>
      <iframe
        src=""
        title="ztkFrame"
        id="ztkFrame"
        name="ztkLaunchFrame"
        className="zelle-iframe"
      />
      <form
        ref={formRef}
        method="POST"
        encType="application/json"
        target="ztkLaunchFrame"
      >
        <input ref={fiIdInputRef} name="fiId" type="hidden" />
        <input ref={pIdInputRef} name="pId" type="hidden" />
        <input name="activity" value="" type="hidden" />
        <input ref={tokenInputRef} name="token" type="hidden" />
      </form>
    </>
  );
};

export default Zelle;
