import { useState } from "react";
import { useLocalization } from "@fluent/react";

import { ContentCard, phoneFormatter } from "cerulean";

import { useCurrentUser } from "../../../../contexts/CurrentUserContext";
import { useUserFeatures } from "../../../../contexts/UserFeaturesContext";
import ProfileSettingRow from "../../ProfileSettingRow";
import AddressSettings from "../Address/AddressSettings";
import UsernameEditDialog from "./UsernameEditDialog";
import EmailEditDialog from "./EmailEditDialog";
import PhoneNumberEditDialog from "./PhoneNumberEditDialog";

const ContactInfoCard = () => {
  const { currentUser } = useCurrentUser();
  const { l10n } = useLocalization();
  const userFeatures: {
    can_change_username?: boolean; // eslint-disable-line camelcase
    address_change?: boolean; // eslint-disable-line camelcase
    phone_change?: boolean; // eslint-disable-line camelcase
  } = useUserFeatures();
  const isPersonalBankingUser = !currentUser?.isBusiness();
  const isBusinessUser = currentUser?.isBusiness();

  const [isUsernameDialogOpen, setIsUsernameDialogOpen] = useState(false);

  const [isEmailEditDialogOpen, setIsEmailEditDialogOpen] = useState(false);
  const [isPhoneNumberEditDialogOpen, setIsPhoneNumberEditDialogOpen] =
    useState(false);

  const getEmailTooltipText = () => {
    let emailTooltipText = null;

    if (currentUser?.isBusinessAccountHolder()) {
      emailTooltipText = l10n.getString(
        "tooltip-business-email-setting-account-holder"
      );
    } else if (isBusinessUser) {
      emailTooltipText = l10n.getString(
        "tooltip-business-email-setting-subuser"
      );
    }

    return emailTooltipText;
  };

  return (
    <ContentCard>
      <h4 className="contact-info-heading nds-sans fontSize--heading5">
        {isBusinessUser
          ? l10n.getString("settings-business-user-card-title")
          : l10n.getString("settings-user-card-title")}
      </h4>
      <ProfileSettingRow
        label="Username"
        value={currentUser?.username}
        editOnClick={() => setIsUsernameDialogOpen(true)}
        isReadOnly={!userFeatures?.can_change_username}
      />
      <UsernameEditDialog
        isOpen={isUsernameDialogOpen}
        closeDialog={() => setIsUsernameDialogOpen(false)}
      />
      <ProfileSettingRow
        label="Email"
        value={currentUser?.email}
        editOnClick={() => setIsEmailEditDialogOpen(true)}
        testId="email-setting"
        tooltipText={getEmailTooltipText()}
      />
      <EmailEditDialog
        isOpen={isEmailEditDialogOpen}
        closeDialog={() => setIsEmailEditDialogOpen(false)}
      />
      <ProfileSettingRow
        label="Phone"
        value={
          currentUser?.phone &&
          phoneFormatter(currentUser?.phone.number as string)
        }
        editOnClick={() => setIsPhoneNumberEditDialogOpen(true)}
        isReadOnly={!userFeatures?.phone_change}
        testId="phone-setting"
        tooltipText={
          isBusinessUser
            ? l10n.getString("tooltip-business-phone-number-setting")
            : null
        }
      />
      <PhoneNumberEditDialog
        closeDialog={() => setIsPhoneNumberEditDialogOpen(false)}
        isOpen={isPhoneNumberEditDialogOpen}
      />
      {isPersonalBankingUser && <AddressSettings />}
    </ContentCard>
  );
};

export default ContactInfoCard;
