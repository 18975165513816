export const cents = (value: number | string) =>
  Math.round(Number(value)) as Cents;
export const dollars = (value: number | string) => Number(value) as Dollars;
export const centsToDollars = (c: Cents) => dollars(c / 100);
export const dollarsToCents = (d: Dollars) => cents(d * 100);
export const sanitizeTextCurrency = (text: string) =>
  text.replace(/[^0-9]/g, ""); // text is pennies, so disallow decimal
export const currencyToCents = (text: string) =>
  cents(sanitizeTextCurrency(text));

export const zeroDollars = dollars(0);
export const zeroCents = cents(0);
