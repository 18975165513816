import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Row, SeparatorList, TextInput } from "@narmi/design_system";

import ContextForm from "../ContextForm";
import PhoneTextInput from "../PhoneTextInput";
import { useNotificationContext } from "../NotificationContext";
import { useLoadingContext } from "../LoadingContext";

const VERIFICATION_STATES = Object.freeze({
  REQUEST_CODE: "request_code",
  SUBMIT_CODE: "submit_code",
  COMPLETE: "complete",
});

const AddPhoneMfaDevice = ({
  onCancel,
  onRequestCode,
  onVerifyCode,
  onUseADifferentPhoneNumber,
  label = "Phone number",
  labelVerificationCode = "Verification code",
  labelCancel = "Cancel",
  labelVerify = "Verify",
  labelResendCode = "Resend code",
  labelDifferentPhone = "Use a different phone number",
  field,
}) => {
  const { sendNotification } = useNotificationContext();
  const [verificationState, setVerificationState] = useState(VERIFICATION_STATES.REQUEST_CODE);
  const [seed, setSeed] = useState("");
  const { setIsLoading } = useLoadingContext();

  const submitHandler = async (callback, verificationState) => {
    const callbackOrNull = (err) => (callback ? callback(err) : null);
    if (verificationState === VERIFICATION_STATES.REQUEST_CODE) {
      try {
        await onRequestCode();
      } catch (err) {
        if (err.id === "two_factor_authentication_code_requested" && err.seed) {
          setSeed(err.seed);
          setVerificationState(VERIFICATION_STATES.SUBMIT_CODE);
          sendNotification({
            type: "info",
            text: err.message,
          });
          callbackOrNull();
        } else if (err?.phone_number?.[0]) {
          callbackOrNull({ [field]: { phone_number: err.phone_number[0] } });
        } else {
          callbackOrNull(err);
        }
      }
    } else if (verificationState === VERIFICATION_STATES.SUBMIT_CODE) {
      try {
        await onVerifyCode(seed);
        setVerificationState(VERIFICATION_STATES.COMPLETE);
        callbackOrNull();
      } catch (err) {
        if (err.non_field_errors?.[0]) {
          callbackOrNull({ [field]: { token: err.non_field_errors?.[0] } });
        } else {
          callbackOrNull(err);
        }
      }
    }
  };

  const renderVerificationInput = () => {
    if (
      ![VERIFICATION_STATES.REQUEST_CODE, VERIFICATION_STATES.SUBMIT_CODE].includes(
        verificationState
      )
    )
      return null;
    let inputComponent = null;
    if (verificationState === VERIFICATION_STATES.REQUEST_CODE) {
      inputComponent = (
        <ContextForm.Field required key="phone_number">
          <PhoneTextInput label={label} field="phone_number" />
        </ContextForm.Field>
      );
    } else {
      inputComponent = (
        <ContextForm.Field required key="token">
          <TextInput field="token" label={labelVerificationCode} />
        </ContextForm.Field>
      );
    }
    return (
      <div className="add-phone-mfa-device-row">
        <Row>
          <Row.Item>
            <div>{inputComponent}</div>
          </Row.Item>
          <Row.Item shrink>
            <div className="mfa-button-wrapper">
              {onCancel && (
                <Button kind="negative" label={labelCancel} onClick={onCancel} type="button" />
              )}
              <ContextForm.Action
                onSubmit={(callback) => {
                  submitHandler(callback, verificationState);
                }}
              >
                <Button label={labelVerify} testId="verify-mfa-device-button" />
              </ContextForm.Action>
            </div>
          </Row.Item>
        </Row>
      </div>
    );
  };

  const resendCodeHandler = () => {
    setIsLoading(true);
    setVerificationState(VERIFICATION_STATES.SUBMIT_CODE);
    submitHandler(null, VERIFICATION_STATES.REQUEST_CODE).finally(() => setIsLoading(false));
  };

  const useADifferentNumberHandler = () => {
    setVerificationState(VERIFICATION_STATES.REQUEST_CODE);
    onUseADifferentPhoneNumber();
  };

  const renderFooterButtons = () => {
    if (verificationState !== VERIFICATION_STATES.SUBMIT_CODE) return null;
    return (
      <div className="margin--top--s">
        <SeparatorList
          items={[
            <Button
              key="button-resend"
              label={labelResendCode}
              kind="plain"
              type="button"
              size="s"
              onClick={() => resendCodeHandler()}
            />,
            <Button
              key="button-different-phone"
              label={labelDifferentPhone}
              kind="plain"
              type="button"
              size="s"
              onClick={useADifferentNumberHandler}
            />,
          ]}
        />
      </div>
    );
  };

  return (
    <>
      {renderVerificationInput()}
      {renderFooterButtons()}
    </>
  );
};

AddPhoneMfaDevice.propTypes = {
  onCancel: PropTypes.func,
  onRequestCode: PropTypes.func,
  onVerifyCode: PropTypes.func,
  onUseADifferentPhoneNumber: PropTypes.func,
  field: PropTypes.any,
  label: PropTypes.string,
  labelCancel: PropTypes.string,
  labelVerificationCode: PropTypes.string,
  labelResendCode: PropTypes.string,
  labelDifferentPhone: PropTypes.string,
  labelVerify: PropTypes.string,
};

export default AddPhoneMfaDevice;
