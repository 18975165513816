import { useMemo } from "react";

const useWelcomeOffer = () => {
  const welcomeOfferRaw = document.getElementById("welcome_offer")?.textContent;
  return useMemo(() => {
    let welcomeOffer = null;
    if (welcomeOfferRaw) {
      welcomeOffer = JSON.parse(welcomeOfferRaw);
    }
    return welcomeOffer;
  }, [welcomeOfferRaw]);
};

export default useWelcomeOffer;
