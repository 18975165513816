import { useSelector } from "react-redux";

import { useFeature } from "../features";
import {
  selectBAccount,
  selectMinPaymentDueDate,
  selectMinimumPayment,
} from "../../entities";
import { isLoan } from "../../entities/helpers/accounts.helpers";
import filters from "../../../../filters";
import { useObjectMemo } from "../../../hooks";

import type { RootState } from "../../entities/types/rootState.types";

export const useCanPayPrincipalOnly = (accountId: API.AccountId) => {
  const { featureEnabled } = useFeature();

  const isValidLoan = useSelector((state: RootState) => {
    const account = selectBAccount(state, accountId);
    return isLoan(account);
  });

  return (
    featureEnabled({
      and: ["internal_transfers", "loan_principal_payment"],
    }) && isValidLoan
  );
};

export const useMinimumPayment = (accountId: API.AccountId) => {
  const value = useSelector((state: RootState) => {
    if (accountId) {
      return selectMinimumPayment(state, accountId) || (0 as Cents);
    }
    return 0 as Cents;
  });

  const dueDate = useSelector((state: RootState) => {
    return selectMinPaymentDueDate(state, accountId) || "";
  });

  let filteredDate = "";
  if (dueDate) {
    filteredDate = filters.shortDate(dueDate);
  }

  return useObjectMemo({
    value,
    display: filters.currency(value),
    displayDueDate: filteredDate,
  });
};
