import { Popover } from "@narmi/design_system";
import React from "react";
import Option from "./Option";
import type { Property } from "csstype";

const Options = ({
  children,
  className,
  wrapperDisplay = "inline-flex",
  matchTriggerWidth = false,
  offset = 0,
  float = "none",
  width = "auto",
  position = "relative",
}: React.PropsWithChildren<{
  className?: string;
  wrapperDisplay?: string;
  matchTriggerWidth?: boolean;
  offset?: number;
  float?: Property.Float;
  width?: string;
  position?: Property.Position;
}>) => {
  const [open, setOpen] = React.useState(false);

  return (
    <div className="options" style={{ width: width, position: position }}>
      <Popover
        closeOnContentClick
        wrapperDisplay={wrapperDisplay}
        matchTriggerWidth={matchTriggerWidth}
        offset={offset}
        content={
          <>
            <div
              role="button"
              tabIndex={0}
              onKeyUp={({ key }) => {
                if (key === "Enter") setOpen(!open);
              }}
              onClick={setOpen.bind(null, false)}
            />
            <div className={`container ${className}`}>{children}</div>
          </>
        }
        type="plain"
      >
        <div className={`options-traffic-light ${open ? " open" : ""} `}>
          <span className="clickable narmi-icon-more-vertical" style={{ float: float }} />
        </div>
      </Popover>
    </div>
  );
};

Options.Option = Option;

export default Options;
