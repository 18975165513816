import React, { useState } from "react";
import { useLocalization } from "@fluent/react";
import PropTypes from "prop-types";
import ApiHttp from "byzantine/src/ApiHttp";
import TabbedThreadList from "./TabbedThreadList";
import ThreadMessageList from "./ThreadMessageList";
import NewThreadDialog from "../NewThreadDialog";
import BaseBanner from "../BaseBanner";
import "./MessageCenterLayout.css";

export default function MessageCenterLayout({
  currentUserUUID,
  replyDisclaimer,
  replyTime,
  userMessageUploads,
}) {
  const { l10n } = useLocalization();
  const [selectedThreadId, setSelectedThreadId] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

  const loadThreads = async ({ startDate, endDate }) => {
    const params = { order_by: "updated_at" };
    if (startDate) {
      params.start_date = startDate;
    }
    if (endDate) {
      params.end_date = endDate;
    }
    const result = await ApiHttp.fetch("threads", { params });
    return {
      threads: result.threads.map((thread) => ({
        threadId: thread.id,
        subject: thread.subject,
        updatedAt: thread.updated_at,
        isRead: thread.is_read,
      })),
    };
  };

  const loadAllThreads = () => {
    const startingThread = loadThreads({
      startDate: threeMonthsAgo.toISOString(),
    });
    const endingThread = loadThreads({ endDate: threeMonthsAgo.toISOString() });
    return { startingThread, endingThread };
  };

  return (
    <>
      <BaseBanner
        bannerName={l10n.getString("banner-message-center")}
        buttonFunction={() => {
          setDialogOpen(true);
        }}
        buttonName={`+ ${l10n.getString("banner-button-add-message")}`}
        features={{ user_message_uploads: true }}
        orFeatureFlagChecks={["user_message_uploads"]}
      ></BaseBanner>
      <NewThreadDialog
        isDialogOpen={isDialogOpen}
        replyTime={replyTime}
        closeDialog={() => setDialogOpen(false)}
        callback={() => {
          loadAllThreads().startingThread.then((result) => {
            if (result.threads.length > 0) {
              setSelectedThreadId(result.threads[0].threadId);
            }
          });
        }}
      />
      <div className="message-center-layout">
        <TabbedThreadList
          selectedThreadId={selectedThreadId}
          setSelectedThreadId={setSelectedThreadId}
          userMessageUploads={userMessageUploads}
          loadAllThreads={loadAllThreads}
        />
        <ThreadMessageList
          threadId={selectedThreadId}
          currentUserUUID={currentUserUUID}
          replyTime={replyTime}
          replyDisclaimer={replyDisclaimer}
          userMessageUploads={userMessageUploads}
        />
      </div>
    </>
  );
}
MessageCenterLayout.propTypes = {
  currentUserUUID: PropTypes.string.isRequired,
  replyDisclaimer: PropTypes.string,
  replyTime: PropTypes.string,
  userMessageUploads: PropTypes.bool,
};
