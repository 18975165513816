import { useCallback } from "react";

import { useDispatch } from "react-redux";

import { useRequestWithFeedback } from "../../../composites/request/request.hooks";
import { useObjectMemo } from "../../../hooks";
import { actions as accountsActions } from "../slices/accounts.slice";
import { actions as accountSortingActions } from "../slices/accountSorting.slice";

import type { AccountSorting } from "../../../../types/models/v1";
import type { NetworkPayload } from "../../../composites/request/request.types";
import type Account from "../../../../Account";

type AnyAccountsData = { accounts: API.AnyAccount[] };

export const useFetchAccounts = () => {
  const dispatch = useDispatch();
  const { send, loading, status } = useRequestWithFeedback<AnyAccountsData>();
  const fetchAccounts = useCallback(() => {
    send({
      action: {
        url: "accounts/",
        options: {
          method: "GET",
          timeoutMilliseconds: 90 * 1000,
        },
      },
      onData: (data) => {
        dispatch(accountsActions.receive(data, true));
      },
    });
  }, [dispatch, send]);

  return useObjectMemo({
    send: fetchAccounts,
    loading,
    error: status === "error",
  });
};

type SortingResponse = {
  // eslint-disable-next-line camelcase
  account_sorting: AccountSorting[];
};

export const useFetchAccountSorting = () => {
  const dispatch = useDispatch();

  const { send, loading, status } = useRequestWithFeedback<SortingResponse>();

  const fetchSorting = useCallback(() => {
    const action: NetworkPayload = {
      url: "accounts/sorting",
      options: {
        method: "GET",
      },
    };

    const onData = (data: SortingResponse) => {
      dispatch(accountSortingActions.receive(data));
    };

    send({
      action,
      onData,
    });
  }, [dispatch, send]);

  return useObjectMemo({
    send: fetchSorting,
    loading,
    error: status === "error",
  });
};

export const ACCOUNT_PRODUCT_GROUP = {
  CHECKING: "Checking",
  SAVINGS: "Savings",
  CREDIT_CARD: "Credit cards",
  LOAN: "Loans",
  CD: "CDs",
  EXTERNAL: "External accounts",
  UNKNOWN: "Others",
};

export const isLoan = (account: Account) => {
  return ["mortgage", "lease", "loan", "line_of_credit"].includes(
    account.product_type,
  );
};
