import type { NetworkPayload } from "../../../composites";

export const API = {
  fetchTemplates: () => {
    return {
      url: "fedwire_templates/",
      options: {
        method: "GET",
      },
    } satisfies NetworkPayload;
  },

  deleteTemplate: (fedwireTemplateId: API.FedwireTemplateId) => {
    return {
      url: `fedwire_templates/${fedwireTemplateId}/`,
      options: {
        method: "DELETE",
        isJsonResponse: false,
      },
    } satisfies NetworkPayload;
  },
};
