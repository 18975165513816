/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

const name = "institution";

type InstitutionSliceState = {
  isFetching: boolean;
  lastUpdated: null;
  data: EmptyObject | API.Institution;
  authenticatingApp: API.NafApp | null;
};

const initialState: InstitutionSliceState = {
  isFetching: false,
  lastUpdated: null,
  data: {},
  authenticatingApp: null,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    upsert: (state, action: PayloadAction<API.Institution>) => {
      state.data = action.payload;
    },
    updateAuthenticatingInstitutionApp: (
      state,
      action: PayloadAction<API.NafApp | null>,
    ) => {
      state.authenticatingApp = action.payload;
    },
  },
});

export const { actions } = slice;

export default {
  [name]: slice.reducer,
};
