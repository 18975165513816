export const LIMIT_TYPES = {
  ACH_PUSH: "ach_push",
  ACH_PULL: "ach_pull",
  ACH_PULL_NOW: "immediate_ach_pull",
  RDC: "rdc",
  RDC_NOW: "immediate_rdc",
  WIRE: "wire",
} as const;

export const TRANSFER_LIMIT_TYPES: API.Limits.Type[] = [
  "ach_pull",
  "ach_push",
  "immediate_ach_pull",
];

export const WIRE_LIMIT_TYPES: API.Limits.Type[] = ["wire"];

export const PRESET_LIMIT_TYPES = {
  transfer: TRANSFER_LIMIT_TYPES,
  wire: WIRE_LIMIT_TYPES,
} as const;
