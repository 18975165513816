import React from "react";
import PropTypes from "prop-types";
import { TextInput } from "@narmi/design_system";
import ContextForm from "../ContextForm";

// basic email validation to confirm that input has a @ and there is at least one char on either side of @
// returning falsey counterintuitively means that no errors are found
export function validateEmail(value, disabled) {
  if (disabled) return null;
  const invalidEmailError = "Please enter a valid email address.";
  if (!value) return invalidEmailError;

  // at least one character that's not a space or @ before the @
  // at least one character that's not a space or @ after the @, followed by a dot
  // at least one character that's not a space or @ after the dot
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Ensure email has no whitespace
  if (/\s/.test(value)) {
    return invalidEmailError;
  }

  if (!emailRegex.test(value)) {
    return invalidEmailError;
  }

  return null;
}

const EmailFormField = ({ label, required = false, disabled, fieldStyle, className }) => {
  return (
    <ContextForm.Field
      required={required}
      validate={(e) => validateEmail(e, disabled)}
      style={fieldStyle}
      className={className}
    >
      <TextInput
        name="email"
        field="email"
        label={label || "Email"}
        type="email"
        data-test-element="email-input"
        disabled={disabled}
      />
    </ContextForm.Field>
  );
};
EmailFormField.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  fieldStyle: PropTypes.object,
  className: PropTypes.string,
};
export default EmailFormField;
