import React, { memo, useMemo } from "react";

import { Provider as ReduxProvider } from "react-redux";

import { PlatformContext } from "../interfaces/platform.interface";
import { NetworkContext } from "../interfaces/network.interface";
import { ToastsContext } from "../interfaces/toasts.interface";
import ConfigProvider from "../interfaces/config.interface";
import { setupStore } from "../businessLogic/entities";

import BackgroundActions from "./backgroundActions";

import type { LibraryProps } from "./library.types";

function Provider({
  platform,
  network,
  toasts,
  store: passedStore,
  preloadedState,
  config,
  children,
}: React.PropsWithChildren<LibraryProps>) {
  const store = useMemo(() => {
    return passedStore || setupStore(preloadedState);
  }, [passedStore, preloadedState]);

  return (
    <ConfigProvider config={config}>
      <PlatformContext.Provider value={platform}>
        <NetworkContext.Provider value={network}>
          <ToastsContext.Provider value={toasts}>
            <ReduxProvider store={store}>
              <BackgroundActions />
              {children}
            </ReduxProvider>
          </ToastsContext.Provider>
        </NetworkContext.Provider>
      </PlatformContext.Provider>
    </ConfigProvider>
  );
}

export default memo(Provider);
