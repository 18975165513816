import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import cc from "classcat";
import { useLayer } from "react-laag";

const PO_BOX_REGEX = new RegExp(
  // Any changes here should also be done on byzantine's constants.js
  "\\b(?:!?(p\\.?\\s*[o0]b?\\.?)|pos(t|tal)\\s+(office|box)|(pobox\\s*[0-9]*)|box$|(box\\s*?#?[0-9]*$))(\\s+)?(?:box|[0-9]*)?\\b",
  "i" // case insensitive
);
const SEARCH_FIELD_ID = "searchPlaces";

const AddressInputAndMenu = ({
  streetAddressLabel,
  suggestions,
  error,
  getInputProps,
  getSuggestionItemProps,
  loading,
  onChange,
}) => {
  const isOpen = suggestions.length > 0;

  const { renderLayer, triggerProps, layerProps, triggerBounds } = useLayer({
    isOpen,
    overflowContainer: false,
    auto: true,
    snap: true,
    placement: "bottom-start",
    possiblePlacements: ["top-start", "bottom-start"],
    preferY: "bottom",
    triggerOffset: -1,
    containerOffset: 0,
  });

  return (
    <div className="ui search">
      <div
        className={cc([
          "nds-combobox-address",
          {
            "nds-combobox-address--active": isOpen,
          },
        ])}
      >
        <div className={cc(["nds-input-address", { error }])}>
          <div className="nds-input-box-address" {...triggerProps}>
            <div className={`nds-input-column-address`}>
              <input
                id={SEARCH_FIELD_ID}
                {...getInputProps({
                  placeholder: streetAddressLabel,
                })}
                aria-label={streetAddressLabel}
                key={"nds-text"}
                type="text"
                required
                onBlur={(event) => {
                  // if the user hits tab or clicks outside the box while the suggestion box is open
                  // onSelect does't get called by PlacesAutocomplete
                  // and the box closes but the whole address string remains as the value of the input.
                  // To avoid that, set the value to up until the first comma.
                  if (isOpen) {
                    const { value } = event.target;
                    if (value?.includes(",")) {
                      onChange(value.substring(0, value.indexOf(",")));
                    } else {
                      onChange(value);
                    }
                  }
                  getInputProps().onBlur();
                }}
                error={error}
              />
              <label htmlFor={SEARCH_FIELD_ID}>{streetAddressLabel}</label>
            </div>
          </div>
          {renderLayer(
            <div
              className={cc([
                "nds-combobox-list-address",
                "list--reset bgColor--white",
                {
                  "nds-combobox-list-address--active": isOpen,
                },
              ])}
              {...layerProps}
              style={{
                ...layerProps.style,
                width: triggerBounds?.width ? triggerBounds?.width : 0,
                zIndex: 4,
              }}
            >
              {loading && <div>Loading...</div>}
              <div
                className={cc([
                  "nds-combobox-item-address",
                  "alignChild--left--center padding--x--s padding--bottom--xs padding--top--s",
                  "title-row",
                ])}
              >
                {" "}
                <span>suggestions</span>
                <span className="narmi-icon-x fontSize--m" />
              </div>
              {suggestions.map((suggestion, i) => {
                return (
                  <div
                    className={cc([
                      "nds-combobox-item-address",
                      "alignChild--left--center padding--x--s padding--y--xs",
                    ])}
                    {...getSuggestionItemProps(suggestion)}
                    key={i}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
              <div
                className={cc([
                  "nds-combobox-item-address",
                  "alignChild--left--center padding--x--s padding--y--xs",
                ])}
              >
                <img src="https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const AutocompleteStreetAddress = ({
  address_type,
  onUpdate,
  applicantIndex,
  error = "",
  value,
  onChange,
  streetAddressLabel = "Street address",
}) => {
  const POSTAL_CODE_LENGTH = 5;
  const DEFAULT_COUNTRY_CODE = "US";

  // https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult.adr_address
  const geocodeByAdrAddress = (placeId) => {
    const ps = new window.google.maps.places.PlacesService(document.createElement("div"));
    const OK = window.google.maps.places.PlacesServiceStatus.OK;

    return new Promise((resolve, reject) => {
      ps.getDetails({ placeId }, (result, status) => {
        if (status !== OK) {
          reject(status);
        }
        resolve(result);
      });
    });
  };

  const updateAddress = (value) => {
    if (typeof value === "string") {
      // PlacesAutocomplete returns a string update
      value = { street_address: value };
    }
    const type = address_type === "Mailing address" ? "mailing" : "primary";
    value["country_code"] = DEFAULT_COUNTRY_CODE; //  API requires two character code
    if (applicantIndex !== undefined) {
      onUpdate(applicantIndex, value, type);
    } else {
      onUpdate(value, type);
    }
  };

  const parseAddress = (adr_address) => {
    let parse_place = document.createElement("div");
    parse_place.innerHTML = adr_address;
    let place_fields_map = {
      "street-address": "street_address",
      locality: "city",
      region: "region_code",
      "postal-code": "postal_code",
      "country-name": "country_code",
    };
    let place_fields = Object.keys(place_fields_map);
    let address = {};
    for (let i = 0; i < place_fields.length; i++) {
      let element = parse_place.querySelector("." + place_fields[i]);
      let key = place_fields_map[place_fields[i]];
      if (element) {
        key === "postal_code"
          ? (address[key] = element.innerHTML.substring(0, POSTAL_CODE_LENGTH))
          : (address[key] = element.innerHTML);
      } else {
        address[key] = "";
      }
    }
    return address;
  };

  const onSelect = (selected, placeId) => {
    geocodeByAdrAddress(placeId).then((result) => {
      let address = parseAddress(result.adr_address);
      updateAddress(address);
    });
  };

  return (
    <>
      <PlacesAutocomplete onChange={onChange} value={value} onSelect={onSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <AddressInputAndMenu
            streetAddressLabel={streetAddressLabel}
            suggestions={suggestions}
            error={error}
            getInputProps={getInputProps}
            getSuggestionItemProps={getSuggestionItemProps}
            loading={loading}
            onChange={onChange}
          />
        )}
      </PlacesAutocomplete>
      {error && (
        <div className="nds-input-error">
          <div className="fontSize--s margin--right--xxs narmi-icon-x-circle" />
          {error}
        </div>
      )}
    </>
  );
};

delete PlacesAutocomplete.propTypes.onChange;
delete PlacesAutocomplete.propTypes.value;

export default AutocompleteStreetAddress;
