import { useEffect, useState } from "react";
import {
  Button,
  ContentCard,
  LoadingSkeleton,
  MenuButton,
  Row,
  useLoadingContext,
  useNotificationContext,
} from "cerulean";
import Payee, { ErrorMessage } from "byzantine/src/Payee";
import Filters from "byzantine/src/filters";
import AddPayeeModal from "../../transfer/AddPayeeModal";
import DeleteDialog from "./DeleteDialog";

interface PayeeOptionsProps {
  openDeleteDialog: () => void;
}

const PayeeOptions = ({ openDeleteDialog }: PayeeOptionsProps) => (
  <MenuButton triggerIcon="more-vertical">
    <MenuButton.Item
      label="Delete"
      startIcon="trash-2"
      onSelect={openDeleteDialog}
    />
  </MenuButton>
);

interface PayeeCardProps {
  payee: Payee;
  fetchPayees: () => Promise<void>;
}

const PayeeCard = ({ payee, fetchPayees }: PayeeCardProps) => {
  const [isDeletePayeeDialogOpen, setIsDeletePayeeDialogOpen] = useState(false);
  const { sendNotification } = useNotificationContext();
  const { setIsLoading } = useLoadingContext();

  const deletePayee = async () => {
    setIsDeletePayeeDialogOpen(false);
    setIsLoading(true);
    try {
      await payee.delete();
      setIsLoading(false);
      sendNotification({
        type: "success",
        text: "Payee deleted.",
      });
      await fetchPayees();
    } catch (error: unknown) {
      const e = error as ErrorMessage;
      let toastMessage = "Unable to delete payee, please try again.";
      if (e?.reason?.toLowerCase() === Payee.ERROR.PENDING_PAYMENTS_EXIST) {
        toastMessage =
          "You cannot delete this payee due to pending payment(s).";
      }
      sendNotification({
        type: "negative",
        text: toastMessage,
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <ContentCard>
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <Row>
            <Row.Item>
              <div className="fontColor--heading fontWeight--semibold whiteSpace--truncate--multiLine--2">
                {Filters.titlecase(payee.name)}
              </div>
            </Row.Item>
            <Row.Item shrink>
              <div style={{ marginTop: "-4px" }}>
                <PayeeOptions
                  openDeleteDialog={() => setIsDeletePayeeDialogOpen(true)}
                />
              </div>
            </Row.Item>
          </Row>
          <div
            className="margin--top--xxs margin--bottom--s"
            style={{ height: "20px" }}
          >
            {payee.isPersonType() ? payee?.email : payee?.masked_account_number}
          </div>
          {payee?.description && (
            <div
              className="fontSize--s fontColor--secondary"
              style={{ marginTop: "auto" }}
            >
              {payee.description}
            </div>
          )}
        </div>
      </ContentCard>
      <DeleteDialog
        recipientName={Filters.titlecase(payee.name)}
        recipientType="payee"
        onDelete={deletePayee}
        isOpen={isDeletePayeeDialogOpen}
        closeDialog={() => setIsDeletePayeeDialogOpen(false)}
      />
    </>
  );
};

const PayeeSection = () => {
  const [payees, setPayees] = useState<Payee[]>([]);
  const [isAddPayeeDialogOpen, setIsAddPayeeDialogOpen] = useState(false);
  const [isFetchingPayees, setIsFetchingPayees] = useState(false);

  const { sendNotification } = useNotificationContext();

  const fetchPayees = async (payee?: Payee) => {
    setIsFetchingPayees(true);
    try {
      setPayees(await Payee.fetchPayees(payee));
    } catch {
      sendNotification({
        type: "negative",
        text: "Error fetching your payees. Please contact support.",
      });
    } finally {
      setIsFetchingPayees(false);
    }
  };

  useEffect(() => {
    fetchPayees();
  }, []);

  return (
    <>
      <div className="margin--top--xl margin--bottom--l">
        <Row>
          <Row.Item>
            <div className="fontSize--l fontColor--heading fontWeight--bold">
              Payees
            </div>
          </Row.Item>
          <Row.Item shrink>
            <Button
              kind="plain"
              label="Add a new payee"
              onClick={() => setIsAddPayeeDialogOpen(true)}
            />
          </Row.Item>
        </Row>
      </div>
      <LoadingSkeleton content="paragraph" isLoading={isFetchingPayees}>
        <div className="recipients-container">
          {payees?.map((payee) => (
            <PayeeCard key={payee.id} payee={payee} fetchPayees={fetchPayees} />
          ))}
          {!payees?.length && <div>You don&apos;t have any linked payees.</div>}
        </div>
      </LoadingSkeleton>
      <AddPayeeModal
        open={isAddPayeeDialogOpen}
        handleClose={() => setIsAddPayeeDialogOpen(false)}
        updatePayees={(newPayee: Payee) => {
          sendNotification({ type: "success", text: "Payee added." });
          fetchPayees(newPayee);
        }}
      />
    </>
  );
};

export default PayeeSection;
