/* eslint-disable camelcase */

import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Account from "byzantine/src/Account";
import AccountContext from "../contexts/AccountContext";
import { useInstitutionSettings } from "../contexts/InstitutionSettingsContext";
import { useUserFeatures } from "../contexts/UserFeaturesContext";
import SimpleTransfer from "./SimpleTransfer";

interface SimpleTransferRouterProps {
  limits: object;
}

interface UserFeatures {
  loan_payment_by_card: boolean;
  loan_principal_payment: boolean;
}

const SimpleTransferRouter = ({ limits }: SimpleTransferRouterProps) => {
  const { accounts } = useContext(AccountContext) as { accounts: Account[] };
  const { ach_allows_pull = false, ach_allows_push = false } =
    useInstitutionSettings();
  const { loan_payment_by_card, loan_principal_payment } =
    useUserFeatures() as UserFeatures;

  const navigate = useNavigate();

  const [toAccountId, setToAccountId] = useState<API.AccountId>();
  const [fromAccountId, setFromAccountId] = useState<API.AccountId>();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const from = params.get("from_account_id") as API.AccountId;
    if (
      from &&
      accounts
        .filter((account) =>
          account.isValidInternalOrExternalTransferSource(ach_allows_pull)
        )
        .map((account) => account.id)
        .includes(from)
    ) {
      setFromAccountId(from);
    }
    params.delete("from_account_id");
    const to = params.get("to_account_id") as API.AccountId;
    if (
      to &&
      accounts
        .filter((account) =>
          account.isValidInternalOrExternalTransferDestination(ach_allows_push)
        )
        .map((account) => account.id)
        .includes(to)
    ) {
      setToAccountId(to);

      // check if eligible for the loan payment by card or optionality flow
      const toAccount = accounts.find((account) => account.id === to);
      const isEligibleForLoanPaymentByCard =
        toAccount?.isPayableByCard() && loan_payment_by_card;
      const isEligibleForLoanPrincipalPayment =
        toAccount?.isInternalLoan() && loan_principal_payment;

      if (isEligibleForLoanPaymentByCard || isEligibleForLoanPrincipalPayment) {
        navigate(`/loans?${params.toString()}`, {
          replace: true,
        });
        return;
      }
    }
    params.delete("to_account_id");
    params.delete("previous");
    window.history.replaceState(
      {},
      "",
      `${document.location.href.split("?")[0]}?${params.toString()}`
    );
  }, [accounts]);

  return (
    <SimpleTransfer
      limits={limits}
      toAccountId={toAccountId}
      fromAccountId={fromAccountId}
    />
  );
};
export default SimpleTransferRouter;
