import { useEffect, useState, useCallback, ChangeEvent } from "react";
import {
  Button,
  ContextForm,
  Dialog,
  Row,
  PasswordTextInput,
  useFormData,
  useNotificationContext,
  LoadingSkeleton,
  useDebounce,
  Error as FieldError,
} from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";
import { useCurrentUser } from "../../../../contexts/CurrentUserContext";

interface PasswordEditBodyProps {
  closeDialog: () => void;
}

interface Validator {
  help_text: string; // eslint-disable-line camelcase
  name: string;
  min_length?: number; // eslint-disable-line camelcase
}

interface ChangePasswordValidationErrors {
  old_password?: string[]; // eslint-disable-line camelcase
  new_password1?: string[]; // eslint-disable-line camelcase
  new_password2?: string[]; // eslint-disable-line camelcase
}

interface PasswordValidateErrors {
  new_password?: string[]; // eslint-disable-line camelcase
}

interface SetPasswordResponse {
  password?: string;
}

interface SuccessfulUpdateResponse {
  message?: string;
}

const isSuccessfulUpdateResponse = (
  response: any
): response is SuccessfulUpdateResponse => !!response?.message;

const hasPasswordValidateErrors = (
  errors: any
): errors is PasswordValidateErrors => !!errors?.new_password;

const hasChangePasswordValidationErrors = (
  errors: any
): errors is ChangePasswordValidationErrors => !!(
  errors?.old_password ||
    errors?.new_password1 ||
    errors?.new_password2
);

const hasApiError = (errors: any): errors is SetPasswordResponse => errors?.password;

const PasswordEditBody = ({ closeDialog }: PasswordEditBodyProps) => {
  const { sendNotificationToParent, sendNotification } =
    useNotificationContext();
  const [passwordValidators, setPasswordValidators] = useState<Validator[]>([]);
  const [newPasswordErrors, setNewPasswordErrors] = useState<string[]>([]);
  const [oldPasswordErrors, setOldPasswordErrors] = useState<string[]>([]);
  const [confirmPasswordError, setConfirmPasswordError] = useState<
    string | null
  >(null);
  const [arePasswordValidatorsLoading, setArePasswordValidatorsLoading] =
    useState(true);
  const { formData, onChange } = useFormData({
    oldPassword: "",
    newPassword1: "",
    newPassword2: "",
  });
  const { currentUser } = useCurrentUser();

  const fetchPasswordValidators = async () => {
    setArePasswordValidatorsLoading(true);
    try {
      const response = await ApiHttp.fetch("password_validators", {
        method: "GET",
      });
      setPasswordValidators(response.results);
    } catch (error) {
      setPasswordValidators([]);
    }
    setArePasswordValidatorsLoading(false);
  };

  useEffect(() => {
    fetchPasswordValidators();
  }, []);

  const validatePasswordsMatch = useCallback(
    (password1: string, password2: string) => {
      const error =
        password1 !== password2
          ? "The two password fields didn't match."
          : null;
      setConfirmPasswordError(error);
    },
    []
  );

  const onPasswordChange = useCallback(
    async (password1: string, password2: string) => {
      try {
        await ApiHttp.fetch(
          "password_validate",
          {
            method: "POST",
          },
          { new_password: password1 }
        );
        // Successful
        setNewPasswordErrors([]);
      } catch (error) {
        if (hasPasswordValidateErrors(error)) {
          setNewPasswordErrors(error?.new_password || []);
        }
        const errorMessage = error instanceof Error ? error.message : error;
        sendNotification(errorMessage);
      }
      if (password2 !== "") {
        validatePasswordsMatch(password1, password2);
      }
    },
    [formData]
  );

  const debouncedOnPasswordChange = useDebounce(onPasswordChange, 1000);
  const debouncedOnConfirmPasswordChange = useDebounce(
    validatePasswordsMatch,
    1000
  );

  const onSubmit = async (callback: (arg?: unknown) => void) => {
    if (!currentUser) {
      return;
    }
    setOldPasswordErrors([]);
    setNewPasswordErrors([]);
    try {
      const response = await currentUser.updatePassword(
        formData.newPassword1,
        formData.newPassword2,
        formData.oldPassword
      );
      if (isSuccessfulUpdateResponse(response)) {
        sendNotificationToParent({ type: "success", text: response.message });
      }
      closeDialog();
      callback();
    } catch (error) {
      if (hasChangePasswordValidationErrors(error)) {
        if (error.old_password) {
          setOldPasswordErrors(error.old_password);
        }
        if (error.new_password2) {
          setNewPasswordErrors(error.new_password2);
        }
        callback();
      } else if (hasApiError(error)) {
        callback(error?.password);
      } else if (error instanceof Error) {
        callback(error?.message);
      } else if (typeof error === "string") {
        callback(error);
      }
    }
  };

  if (arePasswordValidatorsLoading) {
    return (
      <>
        <LoadingSkeleton isLoading lines={3} />
        <LoadingSkeleton isLoading lines={6} showTitle />
      </>
    );
  }

  return (
    <>
      <div className="margin--top--s" />
      <ContextForm data={formData} onChange={onChange}>
        <ContextForm.Field required>
          <PasswordTextInput
            required
            field="oldPassword"
            label="Current password"
            value={formData?.oldPassword}
            aria-label="Current password"
            /*
            because of the unique way we handle password field errors (we validate on input change rather than submit),
            and because we can get multiple field errors for a given field here,
            we instead pass the first field error in here, which gets forwarded along to TextInput
            and results in the correct styling being applied to the input
            */
            fieldError={oldPasswordErrors?.length ? oldPasswordErrors[0] : ""}
          />
          {oldPasswordErrors?.length > 1 && // we then map all remaining field errors
            oldPasswordErrors
              .slice(1, oldPasswordErrors.length)
              .map((error) => <FieldError key={error} error={error} />)}
        </ContextForm.Field>
        <ContextForm.Field required>
          <PasswordTextInput
            field="newPassword1"
            label="New password"
            value={formData?.newPassword1}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              debouncedOnPasswordChange(
                event.target.value,
                formData.newPassword2
              );
            }}
            aria-label="New password"
            fieldError={newPasswordErrors?.length ? newPasswordErrors[0] : ""}
          />
          {newPasswordErrors?.length > 1 &&
            newPasswordErrors
              .slice(1, newPasswordErrors.length)
              .map((error) => <FieldError key={error} error={error} />)}
        </ContextForm.Field>
        <ContextForm.Field required>
          <PasswordTextInput
            field="newPassword2"
            label="Confirm new password"
            value={formData?.newPassword2}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              debouncedOnConfirmPasswordChange(
                event.target.value,
                formData.newPassword1
              );
            }}
            aria-label="Confirm new password"
            fieldError={confirmPasswordError}
          />
        </ContextForm.Field>

        <div className="password-requirements-container">
          <div className="password-requirements-title fontWeight--semibold">
            Password requirements
          </div>
          <ul className="password-requirements">
            {passwordValidators.map((validator) => (
              <li key={validator.name}>{validator.help_text}</li>
            ))}
          </ul>
        </div>
        <Row alignItems="center" justifyContent="end">
          <Row.Item shrink>
            <Button
              type="button"
              onClick={closeDialog}
              kind="negative"
              label="Cancel"
            />
          </Row.Item>
          <Row.Item shrink>
            <ContextForm.Action onSubmit={onSubmit}>
              <Button kind="primary" label="Save changes" />
            </ContextForm.Action>
          </Row.Item>
        </Row>
      </ContextForm>
    </>
  );
};

interface PasswordEditDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
}

const PasswordEditDialog = ({
  isOpen,
  closeDialog,
}: PasswordEditDialogProps) => (
  <Dialog isOpen={isOpen} onUserDismiss={closeDialog} title="Edit password">
    <PasswordEditBody closeDialog={closeDialog} />
  </Dialog>
);

export default PasswordEditDialog;
