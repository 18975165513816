import React, { FunctionComponent } from "react";
import { TextInput } from "@narmi/design_system";

interface AccountNumberTextInputProps {
  onChange: (event: any) => void;
  value: string;
  error: string;
  label: string;
  maxLength: number;
}

const AccountNumberTextInput: FunctionComponent<AccountNumberTextInputProps> = ({
  onChange,
  value,
  error,
  label = "Account number",
  maxLength = 0,
}): JSX.Element => {
  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value.replace(/[^\d]/g, ""));
  };

  return (
    <TextInput
      id="account_number"
      label={label}
      field="account_number"
      onChange={handleInput}
      value={value}
      error={error}
      maxLength={maxLength}
    />
  );
};

export default AccountNumberTextInput;
