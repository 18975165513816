/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import {
  DueDiligenceFluentIds,
  getStringSettingFluentId,
  ProductFluentIds,
  SupportContactFluentIds,
  SupportFaqFluentIds,
  SupportLinksFluentIds,
  OaoDisclosuresFluentIds,
} from "./SettingFluentIds";

import type { FluentBundle } from "@fluent/bundle";
import type { ReactLocalization } from "@fluent/react";
import type {
  OaoProduct,
  ProductDisclosure,
  SupportContact,
  SupportFaqCategory,
  OaoDisclosure,
  SupportLink,
} from "../types/SettingLocalizers.types";
import type { DueDiligenceQuestion } from "../types";

const SETTINGS_LOCALE_SUFFIX = "-settings";

// Checks current bundles to determine if the setting
// should be translated or not
export const hasSettingTranslations = (bundles: FluentBundle[]): boolean => {
  const locales = [...bundles].map((b: FluentBundle) => b.locales[0]);
  const currentLocale = locales[0];
  const hasSettingsFtl = locales.some((l) =>
    l.includes(SETTINGS_LOCALE_SUFFIX),
  );
  return currentLocale !== "en" && hasSettingsFtl;
};

/**
 * Translates a string type setting
 * @usage
 * Takes `SETTING_NAME` as defined in python as `setting-name`
 * ```
 * translateStringSetting("institution-announcement", "My announcement!", l10n)
 * ```
 */
export const translateStringSetting = (
  settingName: string,
  settingValue: string = "",
  l10n: ReactLocalization,
): string =>
  !hasSettingTranslations(Array.from(l10n.bundles))
    ? settingValue
    : l10n.getString(getStringSettingFluentId(settingName), null, settingValue);

/**
 * @usage
 * ```
 * // single product
 * const myLocalizedProduct = getLocalizedProduct(product, l10n);
 *
 * // multiple products
 * ```
 * products.map((p) => getLocalizedProduct(p, l10n))
 * ```
 */
export const translateProduct = <T extends OaoProduct>(
  product: T,
  l10n: ReactLocalization,
): T => {
  const localizedProduct = { ...product };
  const shouldLocalize = hasSettingTranslations(Array.from(l10n.bundles));

  if (shouldLocalize) {
    const ids = new ProductFluentIds(product.id);

    localizedProduct.name = l10n.getString(ids.getName(), null, product.name);

    localizedProduct.description = l10n.getString(
      ids.getDescription(),
      null,
      product.description,
    );

    if (localizedProduct.details) {
      localizedProduct.details = localizedProduct.details.map((detail, i) =>
        l10n.getString(ids.getDetail(i), null, detail),
      );
    }

    if (localizedProduct.disclosures) {
      localizedProduct.disclosures = localizedProduct.disclosures.map(
        (disclosure, i) => {
          const translatedDisclosure: ProductDisclosure = {};
          if (disclosure.checkbox_text) {
            translatedDisclosure.checkbox_text = l10n.getString(
              ids.getDisclosureCheckboxText(i),
              null,
              disclosure.checkbox_text,
            );
          }
          if (disclosure.markdown) {
            translatedDisclosure.markdown = l10n.getString(
              ids.getDisclosureMarkdown(i),
              null,
              disclosure.markdown,
            );
          }
          if (disclosure.links) {
            translatedDisclosure.links = disclosure.links.map(
              ({ text, url }, linkIndex) => ({
                text: l10n.getString(
                  ids.getDisclosureLinkText(i, linkIndex),
                  null,
                  text,
                ),
                url,
              }),
            );
          }
          return translatedDisclosure;
        },
      );
    }
  }

  return localizedProduct;
};

export const translateDueDiligenceQuestions = <
  T extends DueDiligenceQuestion[],
>(
  questions: T,
  aoType: "cao" | "bao",
  l10n: ReactLocalization,
): T => {
  const shouldLocalize = hasSettingTranslations(Array.from(l10n.bundles));
  if (!shouldLocalize) {
    return questions;
  }
  const ids = new DueDiligenceFluentIds(aoType);
  const localizedQuestions = questions.map((question, index) => {
    const localizedQuestion = { ...question };
    localizedQuestion.question = l10n.getString(
      ids.getQuestion(index),
      null,
      question.question,
    );
    const { questionType } = question;
    if (!questionType) {
      return localizedQuestion;
    }
    if (questionType === "dropdown") {
      localizedQuestion.label = l10n.getString(
        ids.getQuestionLabel(index),
        null,
        question.label,
      );
    } else if (questionType === "freeform") {
      localizedQuestion.label = l10n.getString(
        ids.getQuestionLabel(index),
        null,
        question.label,
      );
      localizedQuestion.options = [...(localizedQuestion.options || [])].map(
        (option, optionIndex) =>
          l10n.getString(
            ids.getDropdownQuestionOption(index, optionIndex),
            null,
            option,
          ),
      );
    }
    return localizedQuestion;
  });
  return localizedQuestions as T;
};

/**
 * @usage
 * ```
 * const translatedContacts = translateSupportContacts(contacts, l10n)
 * ```
 */
export const translateSupportContacts = <T extends SupportContact[]>(
  contacts: T,
  l10n: ReactLocalization,
): T => {
  let localizedContacts = [...contacts];
  const shouldLocalize = hasSettingTranslations(Array.from(l10n.bundles));

  if (shouldLocalize) {
    const ids = new SupportContactFluentIds();

    localizedContacts = contacts.map((contact, i) => {
      contact.title = l10n.getString(ids.getTitle(i), null, contact.title);
      if (contact.numbers) {
        contact.numbers.map((number, numberIdx) => {
          number.name = l10n.getString(
            ids.getNumberName(i, numberIdx),
            null,
            number.name,
          );
          return number;
        });
      }
      return contact;
    });
  }

  return localizedContacts as T;
};

export const translateFaqQuestions = <T extends SupportFaqCategory[]>(
  categories: T,
  l10n: ReactLocalization,
): T => {
  const localizedCategories = [...categories];
  const shouldLocalize = hasSettingTranslations(Array.from(l10n.bundles));

  if (shouldLocalize) {
    const ids = new SupportFaqFluentIds();

    localizedCategories.forEach((category, i) => {
      const currentCategory = localizedCategories[i];
      currentCategory.category = l10n.getString(
        ids.getCategory(i),
        null,
        category.category,
      );

      (currentCategory.questions || []).forEach(
        (_: any, questionIndex: number) => {
          const currentQuestion = currentCategory.questions[questionIndex];
          currentQuestion.question = l10n.getString(
            ids.getQuestion(i, questionIndex),
            null,
            currentQuestion.question,
          );
          currentQuestion.answer = l10n.getString(
            ids.getAnswer(i, questionIndex),
            null,
            currentQuestion.answer,
          );
        },
      );
    });
  }

  return localizedCategories as T;
};

export const translateSupportLinks = <T extends SupportLink[]>(
  links: T,
  l10n: ReactLocalization,
): T => {
  let localizedLinks = [...links];
  const shouldLocalize = hasSettingTranslations(Array.from(l10n.bundles));

  if (shouldLocalize) {
    const ids = new SupportLinksFluentIds();
    localizedLinks = links.map(({ text }, i) => ({
      ...links[i],
      text: l10n.getString(ids.getText(i), null, text),
    }));
  }

  return localizedLinks as T;
};

/**
 * @usage
 * ```
 * const translatedDisclosures = translateOaoDisclosures(disclosures, l10n)
 * ```
 */
export const translateOaoDisclosures = <T extends OaoDisclosure[]>(
  disclosures: T,
  l10n: ReactLocalization,
): T => {
  let localizedDisclosures = [...disclosures];
  const shouldLocalize = hasSettingTranslations(Array.from(l10n.bundles));

  if (shouldLocalize) {
    const ids = new OaoDisclosuresFluentIds();

    localizedDisclosures = disclosures.map((disclosure, i) => {
      if (disclosure.markdown) {
        disclosure.markdown = l10n.getString(
          ids.getMarkdown(i),
          null,
          disclosure.markdown,
        );
      }
      if (disclosure.checkbox_text) {
        disclosure.checkbox_text = l10n.getString(
          ids.getCheckboxText(i),
          null,
          disclosure.checkbox_text,
        );
      }
      if (disclosure.links) {
        disclosure.links.map((link, linkIndex) => {
          link.text = l10n.getString(
            ids.getLinkText(i, linkIndex),
            null,
            link.text,
          );
          return link;
        });
      }
      return disclosure;
    });
  }

  return localizedDisclosures as T;
};
