import React, { useContext } from "react";
import { Row, Button, Tooltip } from "@narmi/design_system";
import ContextForm, { FormContext } from "../ContextForm";
import BackCancelButton from "../BackCancelButton";

const ProgressButtons = ({
  onBack,
  backLabel,
  onNext,
  nextLabel = "Next",
  kind = "primary",
  // Setting this property to true will disable the multiple click prevention that the loading state provides.
  dangerouslyDisableShowLoading,
  disabled,
  disableNext = false,
  hidePrev = false,
  backTooltip,
  shouldHideInlineToasts = false,
  isNextLoading = false,
}) => {
  const formContext = useContext(FormContext);
  const next = (
    <Button
      kind={kind}
      label={nextLabel}
      testId="next"
      disabled={disableNext}
      onClick={formContext ? () => {} : onNext}
      isLoading={isNextLoading}
    />
  );
  const getBackButton = () => {
    if (hidePrev) return;
    const back = <BackCancelButton onBack={onBack} backLabel={backLabel} disabled={disabled} />;
    return backTooltip ? <Tooltip text={backTooltip}>{back}</Tooltip> : back;
  };
  return (
    <div className="progress-buttons">
      <Row justifyContent="space-between" alignItems="center">
        <Row.Item>{getBackButton()}</Row.Item>
        <Row.Item shrink>
          {formContext ? (
            <ContextForm.Action
              onSubmit={onNext}
              dangerouslyDisableShowLoading={dangerouslyDisableShowLoading}
              shouldHideInlineToasts={shouldHideInlineToasts}
            >
              {next}
            </ContextForm.Action>
          ) : (
            next
          )}
        </Row.Item>
      </Row>
    </div>
  );
};

export default ProgressButtons;
