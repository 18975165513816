import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ApiHttp from "byzantine/src/ApiHttp";
import { useLoadingContext, useNotificationContext } from "cerulean";
import utils from "../../utils";

const BillPaySsoRedirect = () => {
  const { setIsLoading } = useLoadingContext();
  const { sendNotification } = useNotificationContext();
  const formRef = useRef<HTMLFormElement>(null);
  const p1InputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const handleSsoSuccess = ({
    url,
    session,
  }: {
    url: string;
    session: string;
  }) => {
    if (!formRef?.current || !p1InputRef?.current) {
      return;
    }
    window.history.replaceState({}, "", "/transfer");
    formRef.current.action = url;

    p1InputRef.current.value = session;
    formRef.current.submit();
  };

  const handleSsoError = (error: unknown) => {
    const defaultErrorMessage = "There was an error, please try again shortly.";
    const extractedError = utils.extractErrorMessage(
      error,
      defaultErrorMessage
    );
    navigate("/transfer", { replace: true });
    sendNotification({ type: "negative", text: extractedError });
  };

  const getSso = () => {
    setIsLoading(true);
    ApiHttp.fetch("signed_urls/bill_pay/")
      .then(handleSsoSuccess)
      .catch(handleSsoError);
  };

  useEffect(() => {
    getSso();
  }, []);

  return (
    <>
      <form ref={formRef} id="ipay-sso-form" hidden method="POST">
        <input ref={p1InputRef} id="ipay-p1-input" type="hidden" name="p1" />
        <input type="hidden" name="op" value="Login" />
        <input type="hidden" name="method" value="Session" />
      </form>
    </>
  );
};

export default BillPaySsoRedirect;
