import { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";

import ApiHttp from "./ApiHttp";
import Pagination from "./Pagination";
import SimpleSubject from "./SimpleSubject";

const apiRefreshNoticeSubject = new SimpleSubject();
export const publishApiRefreshNotice = (endpoint: string) =>
  apiRefreshNoticeSubject.publish(endpoint);

interface ApiPaginatedListProps {
  endpoint: string;
  queryParams?: object;
  keyname: string;
  pageSize: number;
  children: CallableFunction;
  errorCallback?: (error?: unknown) => void;
}

export default function ApiPaginatedList({
  endpoint,
  queryParams = {},
  keyname,
  pageSize,
  children,
  errorCallback = () => {},
}: ApiPaginatedListProps) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const queryParamsRef = useRef(JSON.stringify(queryParams));

  useEffect(() => {
    const loadItems = () => {
      setLoading(true);

      if (queryParamsRef.current !== JSON.stringify(queryParams)) {
        queryParamsRef.current = JSON.stringify(queryParams);

        if (page !== 1) {
          // if the search query changed, make sure we're on the first page
          setPage(1);
          // return early as this useEffect will trigger on the page change
          return;
        }
      }

      ApiHttp.fetch(endpoint, {}, { ...queryParams, page, per_page: pageSize })
        .then((response) => {
          setItems(response[keyname]);
          setTotal(response.meta.total);
        })
        .catch(errorCallback)
        .finally(() => setLoading(false));
    };
    loadItems();
    apiRefreshNoticeSubject.subscribe(endpoint, loadItems);
    return () => apiRefreshNoticeSubject.unsubscribe(endpoint, loadItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, JSON.stringify(queryParams), keyname, page, pageSize]);

  const pagination = new Pagination({
    total,
    page,
    pageSize,
    navigatePage: (newPage: number) => setPage(newPage),
  });

  return children({ loading, items, pagination });
}
ApiPaginatedList.propTypes = {
  endpoint: PropTypes.string.isRequired,
  queryParams: PropTypes.object,
  keyname: PropTypes.string.isRequired,
  pageSize: PropTypes.number,
  children: PropTypes.func.isRequired,
};
ApiPaginatedList.defaultProps = {
  queryParams: {},
};
