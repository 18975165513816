// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sectionCard--mFhqO{box-shadow:var(--elevation-low);border-radius:4px;background-color:var(--color-white)}.sectionCard--mFhqO:first-child{margin-top:0}@media(min-width: 1280px){.sectionCard--mFhqO:first-child{margin-top:initial}}.cardTitle--VOcvX+*{padding-top:var(--space-s)}.transactionsCard--Fnu3P{padding-bottom:var(--space-xs) !important}", "",{"version":3,"sources":["webpack://./components/SectionCard.module.scss"],"names":[],"mappings":"AAAA,oBACE,+BAAA,CACA,iBAAA,CACA,mCAAA,CAGF,gCACE,YAAA,CAGF,0BACE,gCACE,kBAAA,CAAA,CAIJ,oBACE,0BAAA,CAGF,yBACE,yCAAA","sourcesContent":[".sectionCard {\n  box-shadow: var(--elevation-low);\n  border-radius: 4px;\n  background-color: var(--color-white);\n}\n\n.sectionCard:first-child {\n  margin-top: 0;\n}\n\n@media (min-width: 1280px) {\n  .sectionCard:first-child {\n    margin-top: initial;\n  }\n}\n\n.cardTitle + * {\n  padding-top: var(--space-s);\n}\n\n.transactionsCard {\n  padding-bottom: var(--space-xs) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionCard": "sectionCard--mFhqO",
	"cardTitle": "cardTitle--VOcvX",
	"transactionsCard": "transactionsCard--Fnu3P"
};
export default ___CSS_LOADER_EXPORT___;
