import { ReactNode } from "react";
import PropTypes from "prop-types";
import styles from "./ButtonBar.module.scss";

interface ButtonBarProps {
  children: ReactNode;
  className?: string;
}

const ButtonBar = ({ className, children }: ButtonBarProps) => (
  <div className={`${styles.buttonbar} ${className}`}>{children}</div>
);

ButtonBar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default ButtonBar;
