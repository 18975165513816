import ApiHttp from "./ApiHttp";

interface MembershipProps {
  id: string;
}

export default class Membership {
  public readonly id: string;

  constructor({ id }: MembershipProps) {
    this.id = id;
  }

  public getDescription() {
    return `*${this.id.slice(-4)}`;
  }

  public delete(): Promise<{ deleted?: boolean; error?: string }> {
    return ApiHttp.fetch(`accounts/membership/${this.id}`, {
      method: "DELETE",
    });
  }

  /**
   * Factory method returning a new instance of Account from
   * an indigo.serialized Account
   * */
  static deserialize(membershipId: string) {
    const membership = new Membership({
      id: membershipId,
    });

    return membership;
  }
}
