import * as Yup from "yup";

import { cents } from "../../../../utils";
import { createForm } from "../../../forms";

export type SendViaTemplateFormFields = {
  fedwireTemplate?: API.FedwireTemplate;
  fedwireTemplateId?: API.FedwireTemplateId;
  amount?: Cents;
  memo?: string;
  wireReason?: string;
};

const validationSchema = Yup.object().shape({
  fedwireTemplate: Yup.object().nullable().required("Required"),
  fedwireTemplateId: Yup.string().required("Required"),
  amount: Yup.number().when(
    "fedwireTemplate",
    ([fedwireTemplate]: Array<API.FedwireTemplate | undefined>, schema) => {
      if (!fedwireTemplate?.amount) {
        return Yup.number()
          .integer("Please enter a number.")
          .positive("Please enter a positive amount.")
          .required("Required");
      }
      return schema;
    },
  ),
  memo: Yup.string().when(
    "fedwireTemplate",
    ([fedwireTemplate]: Array<API.FedwireTemplate | undefined>, schema) => {
      if (!fedwireTemplate?.memo) {
        return Yup.string()
          .min(1)
          .max(140, "Memo cannot exceed 140 characters")
          .required("Required");
      }
      return schema;
    },
  ),
  wireReason: Yup.string().when(
    "fedwireTemplate",
    ([fedwireTemplate]: Array<API.FedwireTemplate | undefined>, schema) => {
      if (!fedwireTemplate?.wire_reason) {
        return Yup.string().required("Required");
      }
      return schema;
    },
  ),
});

const initialValues: SendViaTemplateFormFields = {
  fedwireTemplateId: "" as API.FedwireTemplateId,
  fedwireTemplate: undefined,
  amount: cents(0),
  wireReason: "",
  memo: "",
};

export const sendViaTemplateForm = createForm({
  initialValues,
  validationSchema,
});
