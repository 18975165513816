import { Button } from "@narmi/design_system";
import Feature from "byzantine/src/Feature";

import { useUserFeatures } from "../contexts/UserFeaturesContext";
import SectionCard from "../SectionCard";

const StatementsCard = () => {
  const features = useUserFeatures();

  return (
    <Feature features={features} flag="statements">
      <SectionCard>
        <SectionCard.Title
          text="Statements"
          button={
            <Button
              kind="plain"
              onClick={() => window.location.assign("/statements")}
              label="View statements"
            />
          }
        />
      </SectionCard>
    </Feature>
  );
};

export default StatementsCard;
