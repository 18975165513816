import {
  isBusinessUser,
  businessPermissions,
  createDeepEqualSelector,
  type RootState,
} from "../../entities";

import * as PERMISSION from "./constants";
import { hasPermission } from "./utils";

/**
 * A user has a business permission if they specifically have the given
 * permission specified or they are either not a business user (regular user)
 */
export const hasBusinessPermission = createDeepEqualSelector(
  [
    (state) => isBusinessUser(state),
    (state) => businessPermissions(state),
    (_, permission: API.Permission) => permission,
  ],
  (isBizUser, permissions, permission) => {
    return !isBizUser || hasPermission(permissions, permission);
  },
);

export const hasDualApprovalPermission = (state: RootState) =>
  hasBusinessPermission(state, PERMISSION.BUSINESS.WRITE.DUAL_APPROVAL_REQUEST);
