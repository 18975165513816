import { type NetworkPayload, paramsToQuery } from "../../../composites";

const resourceUrl = "limits";

export const API = {
  fetch: (type: API.Limits.Type | "all"): NetworkPayload => ({
    url:
      resourceUrl +
      (type && type !== "all" ? `?${paramsToQuery([["type", type]])}` : ""),
    options: {
      method: "GET",
    },
  }),
};
