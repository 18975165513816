const catcat = (...args: unknown[]) =>
  args
    .map((arg) => {
      if (typeof arg === "object" && !Array.isArray(arg) && arg !== null) {
        return Object.entries(arg)
          .map(([key, value]) => value && key)
          .filter((x) => typeof x === "string" && x !== "" && typeof x !== "boolean");
      }

      return arg;
    })
    .flat()
    .filter((x) => typeof x === "string")
    .join(" ")
    .trim();

export default catcat;
