/* eslint-disable no-param-reassign */
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import type { AccountSorting } from "../../../../types/models/v1";

type AccountSortingSliceState = {
  sorting: AccountSorting[];
  isFetching: boolean;
};

export const initialState: AccountSortingSliceState = {
  sorting: [],
  isFetching: false,
};

const name = "accountSorting";

export const accountSortingSlice = createSlice({
  name,
  initialState,
  reducers: {
    receive(
      state,
      action: PayloadAction<{
        // eslint-disable-next-line camelcase
        account_sorting: AccountSorting[];
      }>,
    ) {
      const { account_sorting: sorting } = action.payload;
      state.sorting = sorting || [];
      state.isFetching = false;
    },
  },
});

export const { actions } = accountSortingSlice;

export default {
  [name]: accountSortingSlice.reducer,
};
