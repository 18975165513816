/* eslint-disable no-param-reassign */
import { createSlice, nanoid } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { AccountsSliceState } from "../types/accounts.types";

export const initialState: AccountsSliceState = {
  isFetching: false,
  didFetch: false,
  byId: {},
  lastUpdated: null,
};

export const slice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    request: (state) => {
      state.isFetching = true;
    },
    receive: {
      prepare: (
        data: { account: API.AnyAccount } | { accounts: API.AnyAccount[] },
        all = false,
      ) => ({
        payload: {
          id: nanoid(),
          accounts: ("accounts" in data
            ? data.accounts
            : [data.account]) as API.AnyAccount[],
          receivedAt: Date.now(),
          all: all as boolean,
        },
      }),
      reducer: (
        state,
        action: PayloadAction<{
          id: string;
          accounts: API.AnyAccount[];
          all: boolean;
          receivedAt: number;
        }>,
      ) => {
        const { accounts, all, receivedAt } = action.payload;

        state.isFetching = false;
        state.didFetch = true;
        state.lastUpdated = receivedAt;

        if (all) {
          state.byId = {};
        }

        accounts.forEach((account) => {
          state.byId[account.id] = account;
        });
      },
    },
    fail: (state) => {
      state.isFetching = false;
    },
    update: (state, action: PayloadAction<{ account: API.Account }>) => {
      const { account } = action.payload;
      state.byId[account.id] = account;
    },
    unlink: (state, action: PayloadAction<{ accounts: API.Account[] }>) => {
      const { accounts } = action.payload;
      accounts.forEach(({ id }) => {
        delete state.byId[id];
      });
    },
  },
});

const name = "accounts";

export const { actions } = slice;

export default {
  [name]: slice.reducer,
};
