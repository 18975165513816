import * as Yup from "yup";

import { createForm } from "../../../forms";
import finance from "../../../../../finance";

export type ACHDestinationFormType = {
  accountNumber: string;
  routingNumber: string;
  accountType: string;
};

const validationSchema = Yup.object().shape({
  accountNumber: Yup.string().required("Required").max(34),
  routingNumber: Yup.string()
    .required("Required")
    .test(
      "length",
      "Please enter a valid 9-digit routing number.",
      (val) => val.length === 9,
    )
    .test("valid", "Please enter a valid 9-digit routing number.", (val) => {
      const isValid = finance.abaRoutingNumberIsValid(val);
      return isValid;
    }),
  accountType: Yup.string().required("Required"),
});

const initialValues: ACHDestinationFormType = {
  accountNumber: "",
  routingNumber: "",
  accountType: "",
};

// define form schema and validations
export const ACHDestinationForm = createForm({
  initialValues,
  validationSchema,
});
