import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useLocalization } from "@fluent/react";
import { LoadingSkeleton, useNotificationContext } from "cerulean";

import ApiHttp from "byzantine/src/ApiHttp";
import type { Features } from "byzantine/src/Feature";
import { ComposeProviders, modules } from "byzantine";
import { useUserFeatures } from "../../../../contexts/UserFeaturesContext";
import TravelNoteForm from "./TravelNoticeForm/TravelNoticeForm";
import ExistingTravelNotice from "./ExistingTravelNotice/ExistingTravelNotice";

interface TravelNotesResponse {
  results: API.CardTravelNote[];
}

interface TravelNoticeProps {
  card: API.Card;
  onUserDismiss: () => void;
}

const TravelNotice = ({ card, onUserDismiss }: TravelNoticeProps) => {
  const navigate = useNavigate();
  const { l10n } = useLocalization();
  const { sendNotification } = useNotificationContext();
  const userFeatures = useUserFeatures() as Features;
  const [isLoading, setIsLoading] = useState(true);
  const [travelNotes, setTravelNotes] = useState<API.CardTravelNote[]>([]);
  const isValid = card.state === "normal";

  const Providers = [modules.travelNotes.createTravelNoteForm.Provider];
  const goBack = () => navigate(`/cards/${card.id}`);

  useEffect(() => {
    if (!userFeatures.card_travel_notes || !isValid) {
      return;
    }
    const fetchTravelNotes = async () => {
      try {
        const response = await ApiHttp.fetch<TravelNotesResponse>(
          `cards/${card.id}/travel_note/`,
        );
        if (response?.results) {
          setTravelNotes(response.results);
        }
      } catch (error) {
        sendNotification({
          type: "negative",
          text: l10n.getString("travel-notice-unavailable"),
        });
        navigate(`/cards/${card.id}`, { replace: true });
      } finally {
        setIsLoading(false);
      }
    };

    fetchTravelNotes();
  }, [userFeatures.card_travel_notes, isValid]);

  if (!userFeatures.card_travel_notes || !isValid) {
    return <Navigate to={`/cards/${card.id}`} replace={true} />;
  }

  return (
    <ComposeProviders components={Providers}>
      <LoadingSkeleton isLoading={isLoading}>
        {!travelNotes.length ? (
          <TravelNoteForm
            onUserDismiss={onUserDismiss}
            goBack={goBack}
            cardId={card.id}
          />
        ) : (
          <ExistingTravelNotice
            onUserDismiss={onUserDismiss}
            notes={travelNotes}
            goBack={goBack}
            cardId={card.id}
          />
        )}
      </LoadingSkeleton>
    </ComposeProviders>
  );
};

export default TravelNotice;
