import { useLocalization } from "@fluent/react";
import { Dialog } from "cerulean";
import { modules } from "byzantine";

type SECDialogType = {
  isOpen: boolean;
  handleClose: () => void;
  codes: API.ACHPayment.SECCode[];
};

const SECDialog = ({ isOpen, handleClose, codes }: SECDialogType) => {
  const { l10n } = useLocalization();

  return (
    <Dialog
      isOpen={isOpen}
      onUserDismiss={handleClose}
      title={l10n.getString("ach-payment-sec-modal-title")}
    >
      {codes.map((code) => (
        <div
          key={`code__${code}`}
          className="margin--top--m fontColor--secondary"
        >
          <h4 className="fontSize--m nds-sans margin--top--s margin--bottom--s">
            {`${code} payment`}
          </h4>
          <p>
            {l10n.getString(modules.achPayments.getFluentIdForSECCode(code))}
          </p>
        </div>
      ))}
    </Dialog>
  );
};

export default SECDialog;
