import React, { memo } from "react";

import { ContentCard, Button, Error } from "cerulean";

import styles from "../Form.module.scss";
import TemplatePreview from "../../TemplatePreview";

import { useTemplateSelector } from "./TemplateSelector.container";

function TemplateSelector() {
  const {
    showManageTemplatesUI,
    sortedFedwireTemplateIds,
    selectedTemplateId,
    onSelectTemplate,
    manageTemplates,
    createNewTemplate,
    method,
    error,
  } = useTemplateSelector();

  if (method !== "template") {
    return null;
  }

  return (
    <ContentCard kind="bordered" paddingSize="none">
      <div className={styles.manageTemplatesRow}>
        <p className={styles.wireTemplatesFormSubHeader}>Templates</p>
        {sortedFedwireTemplateIds.length && showManageTemplatesUI ? (
          <Button
            label="Manage templates"
            kind="plain"
            onClick={manageTemplates}
            size="s"
          />
        ) : null}
      </div>

      <div className={styles.templateList}>
        {sortedFedwireTemplateIds.map((templateId) => (
          <TemplatePreview
            key={templateId}
            templateId={templateId}
            onSelect={onSelectTemplate}
            selected={selectedTemplateId === templateId}
          />
        ))}
      </div>
      {!sortedFedwireTemplateIds.length ? (
        <div className={styles.emptyContainer}>
          <p className={styles.emptyText}>
            You don’t have any templates set up yet.
          </p>
          <Button
            label="Set up a template"
            kind="primary"
            onClick={createNewTemplate}
            size="s"
          />
        </div>
      ) : null}

      <div className={styles.templateListError}>
        <Error error={error} marginTop="s" />
      </div>
    </ContentCard>
  );
}

export default memo(TemplateSelector);
