import {
  Button,
  ContextForm,
  TextInput,
  useFormData,
  useLoadingContext,
} from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";

import { useActionContext } from "./contexts/ActionContextProvider";
import { useSelectedDeviceContext } from "./contexts/SelectedDeviceContextProvider";
import HeaderWithSteps from "../HeaderWithSteps";
import fetchCode from "./helpers";

const TfaEnter = ({ totalSteps, step }:
{
  step?: number;
  totalSteps?: number;
}) => {
  const { selectedDeviceId, helpMessage, setHelpMessage } =
    useSelectedDeviceContext() as { selectedDeviceId: string; helpMessage: React.ReactNode; setHelpMessage: () => void };
  const { goToReview } = useActionContext() as { goToReview: () => void };
  const { setIsLoading } = useLoadingContext() as { setIsLoading: (state: boolean) => void };

  const { formData, onChange } = useFormData();

  const sendCode = (callback: (error?: object) => void) => {
    ApiHttp.fetch(
      "sudo",
      { method: "POST" },
      { one_time_password: formData.one_time_password }
    )
      .then(() => {
        goToReview();
      })
      .catch(() => {
        callback({
          one_time_password:
            "Incorrect verification code entered, please try again.",
        });
      });
  };

  const requestCodeAgain = () => {
    setIsLoading(true);
    return fetchCode(selectedDeviceId, setHelpMessage).finally(() => {
      setIsLoading(false);
    });
  };

  const subheader = (
    /*
    For most flows that use HeaderWithSteps, spacing b/t header and subheader is 8px on l/m, 4px on s/xs.
    For sudo, it's always 4px, so we need to subtract 4px here
    Also, we only want 20px of margin b/t the resend code button and the content (vs. the typical 40px)
    */
    <div style={{ marginTop: "-4px", marginBottom: "-20px" }}>
      {helpMessage || "A verification code was just sent to your device."}
      <div className="margin--top--xs">
        <Button
          kind="plain"
          onClick={requestCodeAgain}
          label="Resend code"
          size="s"
        />
      </div>
    </div>
  );

  return (
    <>
      <HeaderWithSteps
        step={step}
        totalSteps={totalSteps}
        headerText="Enter verification code"
        subheaderText={subheader}
      />
      <ContextForm data={formData} onChange={onChange}>
        <ContextForm.Field required>
          <TextInput field="one_time_password" label="Verification code" />
        </ContextForm.Field>
        <ContextForm.Action onSubmit={sendCode} dangerouslyDisableShowLoading>
          <div className="margin--top--xl">
            <Button label="Continue" />
          </div>
        </ContextForm.Action>
      </ContextForm>
    </>
  );
};

export default TfaEnter;
