import * as Yup from "yup";

import { createForm } from "../../../forms";

export type ACHPaymentRecipientFormType = {
  selectedRecipientId: API.RecipientId;
};

// ToDo implement form error translations
const validationSchema = Yup.object().shape({
  selectedRecipientId: Yup.string().required("Required"),
});

const initialValues: ACHPaymentRecipientFormType = {
  selectedRecipientId: "" as API.RecipientId,
};

// define form schema and validations
export const ACHPaymentRecipientForm = createForm({
  initialValues,
  validationSchema,
});
