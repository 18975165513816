import React, { useEffect, useState } from "react";
import { useLocalization } from "@fluent/react";
import PropTypes from "prop-types";
import { Button, Dialog } from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";
import TemplateSetting from "byzantine/src/settings";
import Institution from "byzantine/src/Institution";
import User from "byzantine/src/User";
import { translateStringSetting } from "byzantine/src/l10n/SettingLocalizers";

import "./ReferAMember.css";

const IgnoreWarningsModal = ({
  open,
  handleClose,
  message = "There was an error fetching your referral code. Please reach out to support.",
}) => (
  <Dialog title="Error" isOpen={open} onUserDismiss={handleClose}>
    <div className="margin--top--s">{message}</div>
  </Dialog>
);

IgnoreWarningsModal.propTypes = {
  setModalOpen: PropTypes.func,
  open: PropTypes.bool,
  message: PropTypes.string,
  handleClose: PropTypes.func,
};

export default function ReferAMember({ currentUser }) {
  const { l10n } = useLocalization();

  const [referralCode, setReferralCode] = useState([]);
  const [error, setError] = useState();
  const [modalOpen, setModalOpen] = useState(false);

  const fetchReferralCodes = async () => {
    try {
      const response = await ApiHttp.fetch("referral_code/", { method: "GET" });
      if (response?.referral_code) {
        setReferralCode(response.referral_code);
      }
    } catch {
      setError(l10n.getString("error-error"));
    }
  };

  useEffect(() => {
    fetchReferralCodes();
  }, []);

  const [settings, setSettings] = useState({});
  useEffect(() => {
    const institution = new Institution();
    institution
      .getSettings()
      .then((settingsResponse) => setSettings(settingsResponse));
  }, []);

  const templateContext = { referral_code: referralCode };
  const evaluatedReferralShareMessage = new TemplateSetting(
    settings.referral_share_message,
  ).evaluate(templateContext);
  const evaluatedReferralNewCustomerUrl = new TemplateSetting(
    currentUser.isBusiness()
      ? settings.referral_new_business_customer_url
      : settings.referral_new_customer_url,
  ).evaluate(templateContext);
  const evaluatedReferralEmailSubject = new TemplateSetting(
    settings.referral_email_subject,
  ).evaluate(templateContext);
  const encodedReferralEmailSubject = encodeURIComponent(
    evaluatedReferralEmailSubject,
  );
  const encodedReferralShareMessage = encodeURIComponent(
    evaluatedReferralShareMessage,
  );
  const encodedReferralNewCustomerUrl = encodeURIComponent(
    evaluatedReferralNewCustomerUrl,
  );

  const body = `${encodedReferralShareMessage} ${encodedReferralNewCustomerUrl}`;
  const subject = `${encodedReferralEmailSubject}`;

  const sendEmail = () => {
    window.location.href = `mailto:?subject=${subject}&body=${body}`;
  };

  return (
    <div style={{ minHeight: "800px" }}>
      <div className="refer-a-member-page-layout-header">
        <div className="refer-a-member-page-layout">
          <div className="refer-a-member-title">
            {translateStringSetting(
              "referral-offer-text",
              settings.referral_offer_text,
              l10n,
            )}
          </div>
          <div className="refer-a-member-subtitle">
            {translateStringSetting(
              "referral-additional-offer-text",
              settings.referral_additional_offer_text,
              l10n,
            )}
          </div>
          <div
            style={{
              paddingTop: "20px",
              textAlign: "center",
              paddingBottom: "12px",
            }}
          >
            {error ? (
              <Button
                label={l10n.getString("button-send-invite")}
                style={{ fontSize: "20px", width: "200px", height: "49px" }}
                onClick={() => {
                  setModalOpen(true);
                }}
              />
            ) : (
              <Button
                label={l10n.getString("button-send-invite")}
                style={{ fontSize: "20px", width: "200px", height: "49px" }}
                onClick={sendEmail}
              />
            )}
          </div>
          {error ? (
            <Button
              kind="plain"
              onClick={() => {
                setModalOpen(true);
              }}
              label={l10n.getString("refer-copy-link")}
            />
          ) : (
            <Button
              kind="plain"
              label={l10n.getString("refer-copy-link")}
              onClick={(event) => {
                navigator.clipboard.writeText(evaluatedReferralNewCustomerUrl);
                const { target } = event;
                target.innerHTML = l10n.getString("refer-link-copied");
              }}
            />
          )}
          <IgnoreWarningsModal
            open={modalOpen}
            handleClose={() => setModalOpen(false)}
            message={l10n.getString("dialog-support-warning")}
          />
        </div>
      </div>
      <div className="number-layout">
        <div className="number">
          <div className="circle">1</div>
          <div className="titletext">
            &nbsp;{l10n.getString("heading-refer-invite")}&nbsp;
          </div>
          <div className="subtext">
            {l10n.getString("let-friends-know-about-us", {
              institutionName: settings.short_name,
            })}
          </div>
        </div>
        <div className="number">
          <div className="circle">2</div>
          <div className="titletext">
            &nbsp;{l10n.getString("heading-refer-apply")}&nbsp;
          </div>
          <div className="subtext">{l10n.getString("friends-can-apply")}</div>
        </div>
        <div className="number">
          <div className="circle">3</div>
          <div className="titletext">
            &nbsp;{l10n.getString("heading-refer-receive")}&nbsp;
          </div>
          <p className="subtext" style={{ width: "280px", height: "40px" }}>
            {l10n.getString("refer-bonus-amount", {
              amount: settings.referral_amount,
            })}
          </p>
        </div>
      </div>
      <div
        className="refer-a-member-page-layout"
        style={{ paddingTop: "60px" }}
      >
        <Button
          kind="plain"
          style={{ fontSize: "12px", color: "var(--nds-grey)" }}
          onClick={() => window.open(settings.referral_terms_url, "_blank")}
        >
          {l10n.getString("refer-terms-apply")}
        </Button>
      </div>
    </div>
  );
}

ReferAMember.propTypes = {
  currentUser: PropTypes.instanceOf(User),
};
