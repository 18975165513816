import { Button } from "cerulean";
import { entities, modules } from "byzantine";

import Address from "../../settings/profile_settings/UserSettingsProfilePage/Address";

import styles from "./RecipientCard.module.scss";

interface RecipientCardProps {
  recipientId: API.WireRecipientId;
  onDelete: () => void;
}

const RecipientCard = ({ recipientId, onDelete }: RecipientCardProps) => {
  const recipient = entities.wires.useOneWireRecipient(recipientId);
  if (!recipient) return null;
  const destinationAccountPreview =
    modules.wireTemplates.getDestinationAccountPreview(recipient);
  return (
    <div className={styles.selectedRecipient}>
      <div className={styles.selectedRecipientTopContainer}>
        <div className={styles.recipientName}>{recipient.name}</div>
        <div className={styles.recipientActions}>
          <Button
            key="delete-recipient"
            kind="plain"
            size="s"
            onClick={onDelete}
          >
            Remove
          </Button>
        </div>
      </div>
      <div className={styles.recipientAddress}>
        <Address address={recipient.address} />
      </div>

      <div className={styles.templateInstitution}>
        <div className={styles.institutionLogo}>
          <span className="narmi-icon-bank" />
        </div>
        <p>{destinationAccountPreview}</p>
      </div>
    </div>
  );
};

export default RecipientCard;
