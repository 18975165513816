import {
  useState,
  createContext,
  useEffect,
  ReactNode,
  useContext,
} from "react";

import Institution from "byzantine/src/Institution";

const defaultInstitutionSettings: API.Institution = { long_name: "" };
const InstitutionSettingsContext = createContext(defaultInstitutionSettings);

interface InstitutionSettingsContextProviderProps {
  children: ReactNode;
}

export const InstitutionSettingsContextProvider = ({
  children,
}: InstitutionSettingsContextProviderProps) => {
  const [settings, setSettings] = useState<API.Institution>(
    defaultInstitutionSettings
  );

  useEffect(() => {
    const institution = new Institution();
    institution
      .getSettings()
      .then((settingsResponse) => setSettings(settingsResponse));
  }, []);

  return (
    <InstitutionSettingsContext.Provider value={settings}>
      {children}
    </InstitutionSettingsContext.Provider>
  );
};

export const useInstitutionSettings = () =>
  useContext(InstitutionSettingsContext);

export default InstitutionSettingsContext;
