import { useState } from "react";
import { useLocalization } from "@fluent/react";

import { Button, Dialog, Row } from "cerulean";

interface TravelNoticeDeleteButtonProps {
  formattedDate: string;
  deleteTravelNote: () => void;
}

const TravelNoticeDeleteButton = ({
  formattedDate,
  deleteTravelNote,
}: TravelNoticeDeleteButtonProps) => {
  const { l10n } = useLocalization();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(true);

  const onSubmit = async () => {
    setIsButtonEnabled(false);
    await deleteTravelNote();
    setIsButtonEnabled(true);
  };
  return (
    <>
      <Button
        kind="plain"
        label={l10n.getString("travel-notice-delete-button")}
        onClick={() => setIsDialogOpen(true)}
      />
      <Dialog
        isOpen={isDialogOpen}
        onUserDismiss={() => setIsDialogOpen(false)}
        title={l10n.getString("travel-notice-delete-dialog-title")}
        width={"385px"}
        footer={
          <Row alignItems="center" justifyContent="end">
            <Row.Item shrink>
              <Button
                onClick={() => setIsDialogOpen(false)}
                kind="negative"
                label={l10n.getString("travel-notice-cancel-button")}
              />
            </Row.Item>
            <Row.Item shrink>
              <Button
                onClick={onSubmit}
                isLoading={!isButtonEnabled}
                kind="primary"
                label={l10n.getString("travel-notice-delete-confirm-button")}
              />
            </Row.Item>
          </Row>
        }
      >
        <div className="margin--bottom--l">
          {l10n.getString("travel-notice-delete-dialog-body", {
            formattedDate,
          })}
        </div>
      </Dialog>
    </>
  );
};

export default TravelNoticeDeleteButton;
