import { useCallback } from "react";

import { useSelector } from "react-redux";

import { isBusinessUser, selectUser } from "../selectors/user.selectors";
import { useRequestWithFeedback } from "../../../composites/request/request.hooks";
import { useAppDispatch } from "../utils/useAppDispatch.hook";
import { actions } from "../slices/user.slice";
import { useObjectMemo } from "../../../hooks";

import type { BootstrapFetchRequest } from "../../bootstrap/bootstrap.types";

export const useUser = () => {
  return useSelector(selectUser);
};

export const useIsBusinessUser = () => {
  return useSelector(isBusinessUser);
};

export const useFetchUser = (): BootstrapFetchRequest => {
  const { send, loading, status } =
    useRequestWithFeedback<API.GetUserResponse>();

  const dispatch = useAppDispatch();

  const fetch = useCallback(() => {
    send({
      action: {
        url: "me/",
        options: {
          method: "GET",
        },
      },
      onData: ({ users: [user] }) => {
        dispatch(actions.upsert(user));
      },
    });
  }, [dispatch, send]);

  const user = useUser();

  return useObjectMemo({
    send: fetch,
    loading,
    error: status === "error",
    hasData: user !== null,
  });
};
