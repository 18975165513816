import { combineReducers, configureStore } from "@reduxjs/toolkit";

import institution from "../slices/institution.slice";
import user from "../slices/user.slice";
import accounts from "../slices/accounts.slice";
import accountSorting from "../slices/accountSorting.slice";
import endToEndTest from "../slices/endToEndTest.slice";
import featureStatus from "../slices/featureStatus.slice";
import fedwireTemplates from "../fedwireTemplates/slice";
import wires from "../wires/slice";
import limits from "../limits/slice";
import recipients from "../recipients/slice";
import savvyMoney from "../savvyMoney/slice";

export const reducers = {
  ...institution,
  ...user,
  ...accounts,
  ...accountSorting,
  ...endToEndTest,
  ...featureStatus,
  ...fedwireTemplates,
  ...wires,
  ...limits,
  ...recipients,
  ...savvyMoney,
};

const rootReducer = combineReducers(reducers);

const defaultStore = configureStore({
  reducer: rootReducer,
});

export const DEFAULT_STATE = defaultStore.getState();

export type AppDispatch = typeof defaultStore.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type PreloadedState = Partial<RootState>;

export const setupStore = (preloadedState?: PreloadedState) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};
