import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useLibrary } from "../../providers/useLibrary.hook";
import { useObjectMemo, useReferenceTo } from "../../hooks";
import requestCache from "../requestCache/request.cache";

import { REQUEST_STATUS } from "./request.types";
import { useErrorHandling } from "./errors";
import { parseErrorResponse, type AnyException } from "./request.helpers";

import type {
  Request,
  RequestWithFeedback,
  RunProps,
  RunManyProps,
  Data,
  RequestState,
  ErrorResult,
  ErrorResponse,
} from "./request.types";

const noop = () => null;

type MessagingConfig = RunProps["messaging"];

type Empty = Record<string, never>;
const EMPTY: Empty = {};

const useRequest = <D = Data, E = Data>(): Request<D, E> => {
  const [state, setState] = useState<RequestState<D>>({
    loading: false,
    data: EMPTY,
    error: null,
    status: REQUEST_STATUS.idle,
  });

  const updateState = useCallback((patch: Partial<typeof state>) => {
    setState((current) => ({ ...current, ...patch }));
  }, []);

  const start = useCallback(() => {
    updateState({
      status: REQUEST_STATUS.loading,
      data: EMPTY,
      loading: true,
    });
  }, [updateState]);

  const receive = useCallback(
    (d: D) => {
      updateState({
        status: REQUEST_STATUS.success,
        data: d,
      });
    },
    [updateState],
  );

  const fail = useCallback(
    (e: ErrorResponse<E>) => {
      updateState({
        status: REQUEST_STATUS.error,
        error: e,
      });
    },
    [updateState],
  );

  const finish = useCallback(
    () => updateState({ loading: false }),
    [updateState],
  );

  return useObjectMemo({ state, start, receive, fail, finish });
};

const useMessaging = () => {
  const { throwToast } = useLibrary("toasts");
  const config = useRef<RunProps["messaging"]>();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const initialize = useCallback((options: MessagingConfig) => {
    setSuccessMessage("");
    setErrorMessage("");
    config.current = options;
  }, []);

  const success = useCallback(() => {
    const options = config.current;
    const toastSuccessMessage = options?.toast?.success;
    if (toastSuccessMessage) {
      throwToast({
        kind: "success",
        message: toastSuccessMessage,
      });
    }
    const textSuccessMessage = options?.text?.success;
    if (textSuccessMessage) {
      setSuccessMessage(textSuccessMessage);
    }
  }, [throwToast]);

  const error = useCallback(() => {
    const options = config.current;
    const toastErrorMessage = options?.toast?.error;
    if (toastErrorMessage) {
      throwToast({
        kind: "error",
        message: toastErrorMessage,
      });
    }
    const textErrorMessage = options?.text?.error;
    if (textErrorMessage) {
      setErrorMessage(textErrorMessage);
    }
  }, [throwToast]);

  const partial = useCallback(() => {
    const options = config.current;
    const toastPartialMessage = options?.toast?.partial;
    if (toastPartialMessage) {
      throwToast({
        kind: "info",
        message: toastPartialMessage,
      });
    }
    const textPartialMessage = options?.text?.partial;
    if (textPartialMessage) {
      throwToast({
        kind: "info",
        message: textPartialMessage,
      });
    }
  }, [throwToast]);

  const state = useObjectMemo({ successMessage, errorMessage });
  return useObjectMemo({ state, initialize, success, partial, error });
};

type HandleErrorFn<D, E> = ReturnType<typeof useErrorHandling<D, E>>;

// ToDo add params for polling, timeout, etc.
const useRequestWithMessaging = <D = Data, E = Data>(
  handleError?: HandleErrorFn<D, E>,
): RequestWithFeedback<D, E> => {
  const { fetchApi } = useLibrary("network");
  const request = useRequest<D, E>();
  const messaging = useMessaging();

  const {
    start,
    receive,
    fail,
    finish,
    state: { loading },
  } = request;
  const { initialize, success, error } = messaging;

  const loadingRef = useReferenceTo(loading);
  const dbblConfig = useLibrary("config");

  const send = useCallback(
    (props: RunProps<D, E>) => {
      if (loadingRef.current) {
        return Promise.resolve() as Promise<void>;
      }
      const {
        action: { url, options = {} },
        optimistic,
        onData = noop,
        onSuccess = noop,
        onError = noop,
        onFinally = noop,
      } = props;
      initialize(props.messaging);
      start();
      optimistic?.update();

      const sendRequest = async () => {
        const response = await fetchApi(url, options);
        // Check strict equality, must intentionally specify "false" if no JSON otherwise default true
        // @TODO Promise.resolve() returns Promise<D>, we specifically want to return undefined if no data #33279
        return options.isJsonResponse === false
          ? (Promise.resolve() as Promise<D>)
          : (response?.json() as Promise<D>);
      };

      let response: Promise<D>;

      const cacheKey = requestCache.getCacheKey(url, options);
      const existingResponse = requestCache.checkCache<D>(
        cacheKey,
        dbblConfig.network.cache.ttlMilliseconds,
      );

      if (existingResponse === false) {
        response = sendRequest();
        requestCache.addItem(cacheKey, response);
      } else {
        response = existingResponse;
      }

      response
        .catch((err: ErrorResponse<E>) => {
          if (handleError) {
            return handleError(err, props, sendRequest);
          }
          throw err;
        })
        .then((d) => {
          onData(d);
          receive(d);
          success();
          onSuccess();
        })
        .catch((err: ErrorResponse<E>) => {
          fail(err);
          optimistic?.rollback();
          error();
          onError(err);
        })
        .finally(() => {
          finish();
          onFinally();
        });
      return response;
    },
    [
      loadingRef,
      initialize,
      start,
      fetchApi,
      receive,
      success,
      fail,
      error,
      finish,
      handleError,
      dbblConfig.network.cache.ttlMilliseconds,
    ],
  );

  return useObjectMemo({ send, ...messaging.state, ...request.state });
};

export const useRequestWithFeedback = <D = Data, E = AnyException<Data>>() => {
  const handleError = useErrorHandling<D, E>();
  return useRequestWithMessaging(handleError);
};

export const useRequestWithoutErrorHandling = <D = Data, E = Data>() => {
  return useRequestWithMessaging<D, E>();
};

const wasFulfilled = <D>(
  item: PromiseSettledResult<D>,
): item is PromiseFulfilledResult<D> => item.status === "fulfilled";
const wasRejected = <D>(
  item: PromiseSettledResult<D>,
): item is PromiseRejectedResult => item.status === "rejected";

const newArray = <T>(size: number, fill: T | (() => T)) =>
  new Array(size).fill(null).map(fill instanceof Function ? fill : () => fill);

const useArrayState = <T>(
  size: number,
  initial: T,
): [T[], (index: number, value: T) => void] => {
  const [values, setValues] = useState(newArray(size, initial));
  const actualSize = values.length;

  useEffect(() => {
    if (actualSize !== size) setValues(newArray(size, initial));
  }, [actualSize, size, initial]);

  const setValue = useCallback(
    (index: number, value: T) => {
      setValues(values.map((old, idx) => (idx === index ? value : old)));
    },
    [values],
  );

  return [values, setValue];
};

const EMPTY_STATE: RequestState = {
  loading: false,
  data: {},
  status: REQUEST_STATUS.idle,
  error: null,
};

const useRequests = <D extends Data = Data>(n: number) => {
  const [states, setState] = useArrayState(n, EMPTY_STATE as RequestState<D>);

  const start = useCallback(
    (i: number) => {
      setState(i, {
        ...states[i],
        status: REQUEST_STATUS.loading,
        data: {},
        loading: true,
      });
    },
    [states, setState],
  );

  const receive = useCallback(
    (i: number, d: D) => {
      setState(i, {
        ...states[i],
        status: REQUEST_STATUS.success,
        data: d,
      });
    },
    [states, setState],
  );

  const fail = useCallback(
    (i: number, e?: unknown) => {
      setState(i, {
        ...states[i],
        status: REQUEST_STATUS.error,
        error: e,
      });
    },
    [states, setState],
  );

  const finish = useCallback(
    (i: number) => {
      setState(i, {
        ...states[i],
        loading: false,
      });
    },
    [states, setState],
  );

  return useMemo(() => {
    return states.map((state, i) => ({
      state,
      start: start.bind(null, i),
      receive: receive.bind(null, i),
      fail: fail.bind(null, i),
      finish: finish.bind(null, i),
    }));
  }, [states, start, receive, fail, finish]);
};

export const useManyRequestsWithFeedback = <D extends Data = Data>(
  size: number,
) => {
  const { fetchApi } = useLibrary("network");
  const requests = useRequests<D>(size);
  const messaging = useMessaging();

  const { initialize, success, partial, error } = messaging;
  const requestFns = requests
    .map(({ start, receive, fail, finish }) => [start, receive, fail, finish])
    .flat();

  const sendAll = useCallback(
    (props: RunManyProps<D>) => {
      const {
        actions,
        onData = noop,
        onSuccess = noop,
        onPartial = noop,
        onError = noop,
        onFinally = noop,
      } = props;
      initialize(props.messaging);

      const promises = actions.map(async ({ url, options = {} }, index) => {
        if (!requests[index])
          throw new Error(
            `Number of actions (${actions.length}) exceeds preset limit (${size})`,
          );
        const { start, receive, fail, finish } = requests[index];

        start();
        try {
          const raw = await fetchApi(url, options);
          const data = (await raw.json()) as D;
          receive(data);
          return data;
        } catch (err) {
          fail(err);
          throw err;
        } finally {
          finish();
        }
      });

      Promise.allSettled(promises)
        .then((results) => {
          const allSucceded = results.every(wasFulfilled);
          const allFailed = results.every(wasRejected);
          const data: Array<D | null> = [];
          const errors: ErrorResult[] = [];

          results.forEach((result, index) => {
            const action = actions[index];
            if (wasRejected(result)) {
              errors.push({ error: result.reason, action });
              data.push(null);
            } else {
              data.push(result.value);
            }
          });

          if (allSucceded) {
            onData(data as D[]);
            onSuccess();
            success();
          } else if (allFailed) {
            onError();
            error();
          } else {
            onData(data);
            onPartial(errors);
            partial();
          }
        })
        .finally(onFinally);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchApi, initialize, success, partial, error, size, ...requestFns],
  );

  // ToDo: Memoize this
  return {
    sendAll,
    ...messaging.state,
    requests: requests.map((r) => r.state),
    loading: requests.some((r) => r.state.loading),
  };
};

export const useParsedErrorToastCallback = (
  fallbackMessage: string,
  callback?: () => void,
) => {
  const { throwToast } = useLibrary("toasts");
  const onError = useCallback(
    async <E extends AnyException>(e: ErrorResponse<E>) => {
      const { errors } = await parseErrorResponse(e);
      const message = errors[0]?.description || fallbackMessage;
      throwToast({
        kind: "error",
        message,
      });
      callback?.();
    },
    [callback, fallbackMessage, throwToast],
  );
  return onError;
};
