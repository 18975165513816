import { useEffect, useRef } from "react";
import ApiHttp from "byzantine/src/ApiHttp";
import { useLocalization } from "@fluent/react";
import { useLoadingContext, useNotificationContext } from "cerulean";

export enum ZelleUrl {
  FIS = "zelle/fis/token",
  FISERV = "zelle/fiserv",

}

const Zelle = ({ url }: {url: ZelleUrl}) => {
  const { l10n } = useLocalization();
  const { sendNotification } = useNotificationContext();
  const { setIsLoading } = useLoadingContext();

  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const getSso = async () => {
      if (!iframeRef?.current) return;

      setIsLoading(true);
      try {
        const response = await ApiHttp.fetch(url, { method: "POST" });
        if (!response?.preencoded_url) throw Error("no sso url returned");
        iframeRef.current.src = response.preencoded_url;
      } catch {
        sendNotification({
          type: "negative",
          text: l10n.getString("error-unknown", null, "Something went wrong"),
        });
      } finally {
        setIsLoading(false);
      }
    };

    getSso();
  }, []);

  return <iframe ref={iframeRef} className="zelle-iframe" />;
};

export default Zelle;