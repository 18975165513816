import { useState } from "react";
import { useLocalization } from "@fluent/react";
import isEqual from "lodash.isequal";
import omit from "lodash.omit";

import { useCurrentUser } from "../../../../contexts/CurrentUserContext";
import { useInstitutionSettings } from "../../../../contexts/InstitutionSettingsContext";
import { useUserFeatures } from "../../../../contexts/UserFeaturesContext";
import ProfileSettingRow from "../../ProfileSettingRow";
import Address from "./Address";
import AddressEditDialog from "./AddressEditDialog";

const AddressSettings = () => {
  const institutionSettings = useInstitutionSettings();
  const userFeatures: {
    address_change?: boolean; // eslint-disable-line camelcase
  } = useUserFeatures();
  const { l10n } = useLocalization();
  const { currentUser } = useCurrentUser();
  const primaryAddress = currentUser?.getPrimaryAddress();
  const mailingAddress = currentUser?.getMailingAddress();
  const [isAddressDialogOpen, setIsAddressDialogOpen] = useState(false);
  const openAddressDialog = () => setIsAddressDialogOpen(true);
  const doAddressesMatch =
    isEqual(omit(primaryAddress, ["type"]), omit(mailingAddress, ["type"])) ||
    !mailingAddress;

  const hasCoreAddress = institutionSettings.core_update_address;
  const userHasAddressChangeFeature = userFeatures.address_change;

  const isReadOnly = !(
    userHasAddressChangeFeature && currentUser?.isPersonalUserOrBusinessAdmin()
  );

  const primaryAddressLabel = currentUser?.isBusiness()
    ? l10n.getString("label-business-primary-address")
    : l10n.getString("label-personal-primary-address");
  const mailingAddressLabel = currentUser?.isBusiness()
    ? l10n.getString("label-business-mailing-address")
    : l10n.getString("label-personal-mailing-address");
  const samePrimaryAndMailingLabel = currentUser?.isBusiness()
    ? l10n.getString("label-same-business-address")
    : l10n.getString("label-same-residential-address");

  let primaryAddressTooltipText = null;
  let mailingAddressTooltipText = null;

  // a personal user can edit the address if they have the address_change feature
  // a business user can edit the address if they have the address_change feature AND have ownership over the organization
  const userIsAddressOwnerButCantUpdate =
    !userHasAddressChangeFeature &&
    currentUser?.isPersonalUserOrBusinessAdmin();

  if (userIsAddressOwnerButCantUpdate) {
    primaryAddressTooltipText = `Please contact support to edit your ${primaryAddressLabel.toLocaleLowerCase()}.`;
    mailingAddressTooltipText = `Please contact support to edit your ${mailingAddressLabel.toLocaleLowerCase()}.`;
  }

  return (
    <>
      {hasCoreAddress && (
        <ProfileSettingRow
          label={primaryAddressLabel}
          tooltipText={primaryAddressTooltipText}
          value={primaryAddress && <Address address={primaryAddress} />}
          editOnClick={openAddressDialog}
          isReadOnly={isReadOnly}
          testId="primary-address-setting"
        />
      )}
      {hasCoreAddress && institutionSettings.has_mailing_address && (
        <ProfileSettingRow
          label={mailingAddressLabel}
          tooltipText={mailingAddressTooltipText}
          value={
            doAddressesMatch
              ? samePrimaryAndMailingLabel
              : mailingAddress && <Address address={mailingAddress} />
          }
          isReadOnly={isReadOnly}
          editOnClick={openAddressDialog}
        />
      )}
      <AddressEditDialog
        isOpen={isAddressDialogOpen}
        closeDialog={() => setIsAddressDialogOpen(false)}
        doAddressesMatch={doAddressesMatch}
      />
    </>
  );
};

export default AddressSettings;
