import React, { ReactNode } from "react";
import { ContentCard as NdsContentCard } from "@narmi/design_system";

export type ContentCardKinds = "plain" | "elevated" | "interactive" | "bordered";

export type ContentCardPaddingSize = "xs" | "s" | "m" | "l" | "xl" | "none";

export type ContentCardRadiusSize = "s" | "m" | "l";

const ContentCard = ({
  kind = "elevated",
  paddingSize = "l",
  onClick = () => {},
  isSelected = false,
  radiusSize = "s",
  testId,
  children,
}: {
  kind?: ContentCardKinds;
  paddingSize?: ContentCardPaddingSize;
  onClick?: () => void;
  isSelected?: boolean;
  radiusSize?: ContentCardRadiusSize;
  testId?: string;
  children: ReactNode;
}) => {
  return (
    <NdsContentCard
      kind={kind}
      paddingSize={paddingSize}
      onClick={onClick}
      isSelected={isSelected}
      testId={testId}
      radiusSize={radiusSize}
    >
      {children}
    </NdsContentCard>
  );
};

export default ContentCard;
