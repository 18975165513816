import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line no-use-before-define
import { Button, LoadingSkeleton, RadioButtons } from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";
import { useScreenStateContext } from "./contexts/ScreenStateContextProvider";
import { useSelectedDeviceContext } from "./contexts/SelectedDeviceContextProvider";
import HeaderWithSteps from "../HeaderWithSteps";
import fetchCode from "./helpers";
import SCREEN_STATES from "./constants";

const TfaChoose = ({
  totalSteps,
  step,
}: {
  step?: number;
  totalSteps?: number;
}) => {
  const { setScreenState } = useScreenStateContext() as {
    setScreenState?: (state: string) => void;
  };
  const { selectedDeviceId, setSelectedDeviceId, setHelpMessage } =
    useSelectedDeviceContext() as {
      selectedDeviceId: string;
      setSelectedDeviceId: (id: string) => void;
      setHelpMessage: () => void;
    };
  const [loadingDevices, setLoadingDevices] = React.useState(true);
  const [devices, setDevices] = React.useState<{ name: string; id: string }[]>(
    [],
  );

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 }); // scroll to top of page (otherwise we start at the same height as where the user clicked "Review" on the prior page)
    // we expect a 403 forbidden here, we're fetching MFA devices
    ApiHttp.fetch("sudo", { method: "POST" })
      .catch((response) => {
        if (!response.devices?.length) {
          return;
        }
        setSelectedDeviceId(response.device?.id);
        setDevices(response.devices);
      })
      .finally(() => {
        setLoadingDevices(false);
      });
  }, []);

  const sendDevice = () => {
    if (!selectedDeviceId) return;
    fetchCode(selectedDeviceId, setHelpMessage).then(() => {
      if (setScreenState) {
        setScreenState(SCREEN_STATES.ENTER);
      }
    });
  };

  const subheader = (
    /*
    For most flows that use HeaderWithSteps, spacing b/t header and subheader is 8px on l/m, 4px on s/xs.
    For sudo, it's always 4px, so we need to subtract 4px here
    */
    <div style={{ marginTop: "-4px" }}>
      For your security, we need to verify your identity. How would you like
      to receive your verification code?
    </div>
  );
  return (
    <LoadingSkeleton isLoading={loadingDevices}>
      <HeaderWithSteps
        step={step}
        totalSteps={totalSteps}
        headerText="Let's verify your account"
        subheaderText={subheader}
      />
      {devices?.length ? (
        <>
          <div>
            <RadioButtons
              name="deviceSelect"
              options={Object.fromEntries(devices.map((d) => [d.name, d.id]))}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSelectedDeviceId(e.target.value)
              }
              value={selectedDeviceId}
            />
          </div>
          <div className="margin--top--xl">
            <Button label="Continue" onClick={sendDevice} />
          </div>
        </>
      ) : (
        <div>
          <span>
            Please go to your{" "}
            <Button
              kind="plain"
              onClick={() => window.location.assign("/settings")}
              label="Settings"
            />{" "}
            and add a two factor authentication device.
          </span>
        </div>
      )}
    </LoadingSkeleton>
  );
};
TfaChoose.propTypes = {
  text: PropTypes.string,
};

export default TfaChoose;
