import React from "react";
import { Tooltip } from "@narmi/design_system";

const EditLabelTooltip = ({ tooltipText }: { tooltipText: string }) => {
  return (
    <Tooltip text={tooltipText}>
      <span className="narmi-icon-info"></span>
    </Tooltip>
  );
};

export default EditLabelTooltip;
