import { useState, ReactNode } from "react";
import Account from "byzantine/src/Account";
import {
  GroupedAccounts,
  isGroupedAccountsArray,
  filterAndGroupAccounts,
  DEFAULT_ACCOUNT_MINIMUM_FOR_GROUPING,
} from "byzantine/src/AccountUtils";

export interface RenderAccountProps {
  isDestination: boolean;
  value?: string;
  isIndented: boolean;
  account: Account;
  closeDropdown: () => void;
}

export interface RenderGroupProps {
  numGroups: number;
  children: ReactNode;
}

export interface RenderHeaderProps {
  group: ReactNode;
}

interface RenderContentProps {
  groupedAccounts: GroupedAccounts | Account[];
  isDestination: boolean;
  value?: string;
  isIndented: boolean;
  closeDropdown: () => void;
  RenderAccount: (props: RenderAccountProps) => ReactNode;
  RenderEmpty: () => ReactNode;
  RenderGroup: (props: RenderGroupProps) => ReactNode;
  RenderHeader: (props: RenderHeaderProps) => ReactNode;
}

const RenderContent = ({
  groupedAccounts,
  isDestination,
  isIndented,
  RenderAccount,
  RenderEmpty,
  RenderGroup,
  RenderHeader,
  closeDropdown,
  value,
}: RenderContentProps) => {
  const numGroups = isGroupedAccountsArray(groupedAccounts)
    ? groupedAccounts.length
    : Array.from(groupedAccounts).length;

  if (isGroupedAccountsArray(groupedAccounts)) {
    return groupedAccounts.map((groupedAccount) => (
      <RenderAccount
        key={groupedAccount.id}
        account={groupedAccount}
        value={value}
        isIndented={isIndented}
        closeDropdown={closeDropdown}
        isDestination={isDestination}
      />
    ));
  }

  if (!(groupedAccounts instanceof Map)) {
    return <RenderEmpty />;
  }

  return Array.from(groupedAccounts, ([groupName, accountsForGroup]) => (
    <RenderGroup key={groupName} numGroups={numGroups}>
      <RenderHeader group={groupName} />
      {accountsForGroup.map((account) => (
        <RenderAccount
          account={account}
          key={account.id}
          isDestination={isDestination}
          isIndented={true}
          value={value}
          closeDropdown={closeDropdown}
        />
      ))}
    </RenderGroup>
  ));
};

interface RenderSearchProps {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
}

export type AccountFilter<A = Account> = (account: A) => boolean;

interface RenderSearchProps {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
}

interface AccountListProps {
  alwaysGroup?: boolean;
  accounts?: Account[];
  filter?: AccountFilter;
  isDestination?: boolean;
  isIndented?: boolean;
  value?: string;
  RenderSearch?: (props: RenderSearchProps) => ReactNode;
  RenderAccount?: (props: RenderAccountProps) => ReactNode;
  RenderEmpty?: () => ReactNode;
  RenderGroup?: (props: RenderGroupProps) => ReactNode;
  RenderHeader?: (props: RenderHeaderProps) => ReactNode;
  closeDropdown?: () => void;
}

const AccountList = ({
  alwaysGroup = false,
  accounts = [],
  filter = (account: Account) => !!account,
  isDestination = true,
  isIndented = false,
  RenderSearch = () => null,
  RenderAccount = () => null,
  RenderEmpty = () => null,
  RenderGroup = ({ children }: { children: ReactNode }) => <>{children}</>,
  RenderHeader = () => null,
  closeDropdown = () => null,
  value,
}: AccountListProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const shouldGroup =
    alwaysGroup || accounts?.length >= DEFAULT_ACCOUNT_MINIMUM_FOR_GROUPING;
  const groupedAccounts = shouldGroup
    ? filterAndGroupAccounts(accounts, filter, searchTerm)
    : accounts;
  return (
    <>
      {shouldGroup && (
        <RenderSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      )}
      <RenderContent
        groupedAccounts={groupedAccounts}
        isDestination={isDestination}
        isIndented={isIndented}
        RenderAccount={RenderAccount}
        RenderEmpty={RenderEmpty}
        RenderGroup={RenderGroup}
        RenderHeader={RenderHeader}
        closeDropdown={closeDropdown}
        value={value}
      />
    </>
  );
};

export default AccountList;
