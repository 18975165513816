import { useEffect, useState } from "react";
import { useLocalization } from "@fluent/react";
import {
  Button,
  Checkbox,
  Dialog,
  ContextForm,
  Row,
  useFormData,
  useNotificationContext,
} from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";

interface DisclosureUrls {
  esignUrl: string;
  privacyUrl: string;
  termsUrl: string;
}

interface DisclosuresReconsentDialogBodyProps extends DisclosureUrls {
  setOnUserDismiss: CallableFunction;
  showEsign: boolean;
}

const DisclosuresReconsentDialogBody = ({
  esignUrl,
  privacyUrl,
  termsUrl,
  showEsign,
  setOnUserDismiss,
}: DisclosuresReconsentDialogBodyProps) => {
  const { sendNotification } = useNotificationContext();
  const { l10n } = useLocalization();
  const { formData, onChange } = useFormData({});
  const [redirectUrl, setRedirectUrl] = useState(
    `/?success=${l10n
      .getString("tos-acceptance-success-toast")
      .split(" ")
      .join("+")}`
  );

  useEffect(() => {
    /* grabbing the URI that the user was navigating to before the redirect */
    const queryParams = new URLSearchParams(window.location.search);
    if (!queryParams.get("next")) {
      return;
    }
    const newUrl = new URL(
      queryParams.get("next") as string,
      window.location.origin
    );
    const newParams = new URLSearchParams(newUrl.search);
    newParams.append("success", l10n.getString("tos-acceptance-success-toast"));
    setRedirectUrl(`${newUrl.pathname}?${newParams.toString()}`);

    queryParams.delete("next");
    window.history.replaceState(
      {},
      "",
      `${document.location.href.split("?")[0]}?${queryParams.toString()}`
    );
  }, []);

  useEffect(() => {
    if (sendNotification) {
      setOnUserDismiss(() => () => {
        sendNotification({
          type: "negative",
          text: l10n.getString("dismiss-tos-dialog-error"),
        });
      });
    }
  }, [sendNotification]);

  const onSubmit = async (callback: (arg?: unknown) => void) => {
    try {
      await ApiHttp.fetch("terms", { method: "POST" });
      window.history.replaceState({}, "", redirectUrl);
      window.location.reload();
    } catch (err) {
      callback();
      sendNotification({
        type: "negative",
        text: err,
      });
    }
  };

  return (
    <>
      <div className="margin--bottom--xl margin--top--xs">
        {l10n.getString("tos-dialog-instructions")}
      </div>

      <div className="margin--bottom--xl">
        {esignUrl && showEsign && (
          <div className="margin--bottom--s">
            <Button
              kind="plain"
              label={l10n.getString("disclosure-esign")}
              startIcon="file-text1"
              as="a"
              href={esignUrl}
              target="_blank"
              rel="noopener noreferrer"
            />
          </div>
        )}
        {privacyUrl && (
          <div className="margin--bottom--s">
            <Button
              kind="plain"
              label={l10n.getString("disclosure-privacy")}
              startIcon="file-text1"
              as="a"
              href={privacyUrl}
              target="_blank"
              rel="noopener noreferrer"
            />
          </div>
        )}
        {termsUrl && (
          <Button
            kind="plain"
            label={l10n.getString("disclosure-tos")}
            startIcon="file-text1"
            as="a"
            href={termsUrl}
            target="_blank"
            rel="noopener noreferrer"
          />
        )}
      </div>
      <ContextForm data={formData} onChange={onChange}>
        <ContextForm.Field required className="margin--bottom--xl">
          <Checkbox
            field="accept-tos"
            label={l10n.getString("tos-acceptance-checkbox-label")}
          />
        </ContextForm.Field>
        <Row alignItems="center" justifyContent="end">
          <Row.Item shrink>
            <ContextForm.Action onSubmit={onSubmit}>
              <Button
                kind="primary"
                label={l10n.getString("tos-submit-button-label")}
              />
            </ContextForm.Action>
          </Row.Item>
        </Row>
      </ContextForm>
    </>
  );
};

interface DisclosuresReconsentDialogProps extends DisclosureUrls {
  showEsignJson: string;
}

const DisclosuresReconsentDialog = ({
  esignUrl,
  privacyUrl,
  termsUrl,
  showEsignJson,
}: DisclosuresReconsentDialogProps) => {
  const { l10n } = useLocalization();
  const [onUserDismiss, setOnUserDismiss] = useState<CallableFunction>(
    () => {}
  );

  return (
    <Dialog
      onUserDismiss={onUserDismiss}
      title={l10n.getString("tos-dialog-title")}
      isOpen={true}
    >
      <DisclosuresReconsentDialogBody
        esignUrl={esignUrl}
        privacyUrl={privacyUrl}
        termsUrl={termsUrl}
        showEsign={JSON.parse(showEsignJson)}
        setOnUserDismiss={setOnUserDismiss}
      />
    </Dialog>
  );
};

export default DisclosuresReconsentDialog;
