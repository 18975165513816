import { useEffect, useState } from "react";
import { Localized, useLocalization } from "@fluent/react";
import { Features, featureEnabled } from "byzantine/src/Feature";
import { useLoadingContext } from "cerulean";
import App from "byzantine/src/App";
import { modules } from "byzantine";
import OptionCard from "../OptionCard";
import { useUserFeatures } from "../contexts/UserFeaturesContext";
import { useInstitutionSettings } from "../contexts/InstitutionSettingsContext";
import AppAuthorize from "../AppAuthorize";
import ZelleLogo from "./ZelleLogo";
import { ACH_PAYMENT_ROUTE } from "./ach/ACHPaymentRoutes";

type FeatureMapping = {
  route: string;
  title: string | React.ReactNode;
  translationTitle?: string;
  icon?: string;
  iconSize?: string;
  description: string;
  translationDescription?: string;
};

const VALID_MOM_OPTIONS = [
  "funds",
  "bill_pay",
  "p2p",
  "wires",
  "zelle",
  "loan",
  "ach_payments",
] as const;

type moneyMovementFeaturesDataProps = {
  type: "naf" | (typeof VALID_MOM_OPTIONS)[number];
  nafData?: App | undefined;
  data?: FeatureMapping | undefined;
};

const transferFeatureMapping = (flag: string): FeatureMapping | null => {
  if (flag === "funds") {
    return {
      icon: "transfer-arrows",
      iconSize: "24px",
      title: "Transfers",
      translationTitle: "heading-transfer",
      description: "Transfer funds to or from an internal or external account",
      translationDescription: "description-transfer",
      route: "/funds",
    };
  }
  if (flag === "bill_pay") {
    return {
      icon: "dollar-bill-line",
      iconSize: "24px",
      title: "Bill pay",
      translationTitle: "heading-bill-pay",
      description: "Pay a bill online or set up future payments",
      translationDescription: "description-bill-pay",
      route: "/bill_pay",
    };
  }
  if (flag === "p2p") {
    return {
      icon: "m2m",
      iconSize: "24px",
      title: "Member to member",
      translationTitle: "heading-m-to-m",
      description: "Send money to a bank member using their account number",
      translationDescription: "description-m-to-m",
      route: "/m2m",
    };
  }
  if (flag === "wires") {
    return {
      icon: "wires",
      iconSize: "24px",
      title: "Wire",
      translationTitle: "heading-wire",
      description: "Send funds to an individual or company",
      translationDescription: "description-wire",
      route: "/wires",
    };
  }
  if (flag === "zelle") {
    return {
      title: <ZelleLogo />,
      description: "Send money to recipients at other financial institutions",
      translationDescription: "description-zelle",
      route: "/zelle",
    };
  }
  if (flag === "loan--principal") {
    return {
      icon: "loan",
      iconSize: "24px",
      title: "Loan",
      translationTitle: "heading-loan",
      description: "Make a loan payment or one-time principal payment",
      translationDescription: "description-loan-principal-payment",
      route: "/loans",
    };
  }
  if (flag === "loan--card") {
    return {
      icon: "loan",
      iconSize: "24px",
      title: "Loan",
      translationTitle: "heading-loan",
      description: "Make a loan payment by bank account or debit card",
      translationDescription: "description-loan-pay-by-card",
      route: "/loans",
    };
  }
  if (flag === "ach_payments") {
    return {
      icon: "bank",
      iconSize: "24px",
      title: "ACH Payment",
      translationTitle: "ach-payment-card-title",
      description: "Send a standard ACH payment to a recipient",
      translationDescription: "ach-payment-card-content",
      route: ACH_PAYMENT_ROUTE,
    };
  }
  return null;
};

const CustomizableTransferMenu = () => {
  // the style rules change depending on if there are an odd or even number of options =(
  const features = useUserFeatures() as Features;
  const { setIsLoading } = useLoadingContext();
  const institutionSettings = useInstitutionSettings();
  const transferMenus = institutionSettings?.banking_menus?.transfer?.options;
  const [moneyMovementFeaturesData, setMoneyMovementFeaturesData] = useState<
    moneyMovementFeaturesDataProps[] | undefined | null[]
  >([]);

  const { showWireTransferOptionInMenu } =
    modules.wireTemplates.useWirePermissions();

  useEffect(() => {
    // this returns an array of objects that have all information necessary to render the non-naf features
    setIsLoading(true);
    const data: moneyMovementFeaturesDataProps[] = transferMenus
      ?.map((option) => {
        if (option.type === "naf") {
          const app = institutionSettings?.apps?.find(
            (a) => a.client_id === option.client_id,
          );
          return { type: option.type, nafData: App.deserialize(app) };
        } else if (option.type === "funds") {
          if (featureEnabled(features, { or: ["internal_transfers", "ach"] })) {
            return {
              type: option.type,
              data: transferFeatureMapping(option.type),
            };
          }
          return null;
        } else if (option.type === "loan") {
          if (featureEnabled(features, { or: ["internal_transfers", "ach"] })) {
            if (featureEnabled(features, { or: "loan_payment_by_card" })) {
              return {
                type: option.type,
                data: transferFeatureMapping("loan--card"),
              };
            }
            if (featureEnabled(features, { or: "loan_principal_payment" })) {
              return {
                type: option.type,
                data: transferFeatureMapping("loan--principal"),
              };
            }
          }
        } else if (option.type === "wires") {
          if (!showWireTransferOptionInMenu) {
            return null;
          }
          return {
            type: option.type,
            data: transferFeatureMapping(option.type),
          };
        } else if (option.type === "ach_payments") {
          if (featureEnabled(features, { or: ["ach_payments"] })) {
            return {
              type: option.type,
              data: transferFeatureMapping(option.type),
            };
          }
        } else if (featureEnabled(features, { or: [option.type] })) {
          return {
            type: option.type,
            data: transferFeatureMapping(option.type),
          };
        }

        return null;
      })
      .filter((d) => d !== null) as moneyMovementFeaturesDataProps[];
    setMoneyMovementFeaturesData(data);
    if (transferMenus?.length) {
      setIsLoading(false);
    }
  }, [
    transferMenus,
    features,
    institutionSettings?.apps,
    showWireTransferOptionInMenu,
  ]);

  const columns =
    moneyMovementFeaturesData && moneyMovementFeaturesData?.length < 4 ? 3 : 2;

  const { l10n } = useLocalization();

  if (!moneyMovementFeaturesData) {
    return null;
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Localized id="heading-how-send-money">
        <h1 style={{ marginBottom: 30 }}>How do you want to send money?</h1>
      </Localized>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          maxWidth: `${300 * columns}px`,
        }}
      >
        {moneyMovementFeaturesData?.map((feature) => {
          if (feature?.type === "naf") {
            return (
              <div
                className="margin--all--xs nds-span-12 transfer-option-card" // matches default customClassName in OptionCard
                key={feature?.nafData?.client_id}
              >
                <AppAuthorize
                  app={feature?.nafData}
                  institution={institutionSettings}
                >
                  <OptionCard
                    icon={feature?.nafData?.image}
                    title={feature?.nafData?.name}
                    description={feature?.nafData?.description}
                    customClassName=""
                    iconSize="24px"
                  />
                </AppAuthorize>
              </div>
            );
          }
          if (feature?.type && VALID_MOM_OPTIONS.includes(feature?.type)) {
            return (
              <OptionCard
                key={feature?.type}
                icon={feature?.data?.icon}
                iconSize={feature?.data?.iconSize}
                title={
                  typeof feature.data?.title === "string"
                    ? l10n.getString(
                      feature?.data?.translationTitle || "",
                      null,
                      feature?.data?.title,
                    )
                    : feature?.data?.title
                }
                description={l10n.getString(
                  feature?.data?.translationDescription as string,
                  null,
                  feature?.data?.description,
                )}
                route={feature?.data?.route}
                shouldOpenInNewTab={
                  !!features?.disable_billpay_native_flow &&
                  feature?.type === "bill_pay"
                }
              />
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default CustomizableTransferMenu;
