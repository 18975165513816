import { ContentCard, Button, catcat as cc } from "cerulean";
import { useMarketingTarget } from "byzantine/src/dbbl/businessLogic/modules/marketing/marketing.container";
import utils from "byzantine/src/utils";

interface MarketingCardProps {
  address: string;
}

interface MarketingTarget {
  title: string;
  body: string;
  icon: string;
  linkText: string;
}

const MarketingCard = ({ address }: MarketingCardProps) => {
  const { loading, config, linkUrl } =
    useMarketingTarget<MarketingTarget>(address);

  if (loading || !config || !linkUrl) {
    return null;
  }

  const { title, body, icon, linkText: actionText } = config;

  return (
    <div className="margin--bottom--l">
      <ContentCard kind="elevated">
        <div className="fontColor--theme--primary fontSize--l  margin--bottom--s fontWeight--bold alignChild--left-center">
          <span
            className={cc([
              utils.ndsIconPrepend(icon),
              "margin--right--xs",
              "fontSize--heading2",
            ])}
          />
          <div>{title}</div>
        </div>
        <p className="margin--bottom--s">{body}</p>
        <Button
          as="a"
          href={linkUrl}
          kind="plain"
          ariaLabel={`${title} - ${actionText}`}
          label={actionText || "Learn more"}
          rel="noreferrer"
          target="_blank"
        />
      </ContentCard>
    </div>
  );
};

export default MarketingCard;
