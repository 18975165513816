import apiWithFetch, {
  ApiHttpClass,
  API_VERSION,
  OtsTokenError,
} from "./ApiHttp";

import type { FetchOptions } from "./types";

export { ApiHttpClass, API_VERSION, FetchOptions, OtsTokenError };
export default apiWithFetch;
