/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const name = "featureStatus";

type UserSliceState = {
  ga: API.GAFeatures;
};

const initialState: UserSliceState = {
  ga: {},
};

const slice = createSlice({
  name,
  initialState,
  reducers: {},
});

export const { actions } = slice;

export default {
  [name]: slice.reducer,
};
