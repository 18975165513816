/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-declaration-merging */
import { DateTime } from "luxon";

// eslint-disable-next-line import/no-cycle
import User from "./User";
import Filters from "./filters";
import Balances from "./Balances";
import ApiHttp from "./ApiHttp";
import utils from "./utils";
import { ACCOUNT_PRODUCT_GROUP } from "./dbbl/businessLogic/entities/helpers/accounts.helpers";
import { formatNumber } from "./dbbl/businessLogic/entities/utils/currency";

export const AccountType = {
  Deposit: "deposit",
  Credit: "credit",
  Debit: "debit",
} as const;

export const ProductType = {
  Savings: "savings",
  Checking: "checking",
  CertificateOfDeposit: "certificate_of_deposit",
  HSA: "hsa",
  IRA: "ira",
  IRACertificateOfDeposit: "ira_certificate_of_deposit",
  MoneyMarket: "money_market",
  LineOfCredit: "line_of_credit",
  CreditCard: "credit_card",
  Loan: "loan",
  Mortgage: "mortgage",
  Lease: "lease",
  Unknown: "unknown",
} as const;

type LoanDetails = {
  minimum_payment: number;
  interest_rate: number;
  next_payment_at: string;
};

type AccountMetadata = {
  [key: string]: {
    value: null | string;
    public: boolean; // only public items are returned to clients, but the client may want to make them non-public
    format: null | "date" | "currency" | "percentage";
  };
};

interface BaseAccount {
  id: API.AccountId;
  name: string;
  nickname: string;
  features: string[];
  number: string;
  product: {
    type: API.ProductType;
    description?: string;
  };
  users: API.UserId[];
  hidden: boolean;
  favorited: boolean;
  loan_details: LoanDetails | EmptyObject;
  verified: boolean;
  check_micr: null | string;
  fi_name: string;
  fi_svg: string;
  created_at: USDateString;
  updated_at: Timestamp;
}

declare global {
  namespace API {
    type AccountId = Brand<string, "AccountId">;
    type MembershipId = Brand<string, "MembershipId">;

    type AccountType = (typeof AccountType)[keyof typeof AccountType];
    type ProductType = (typeof ProductType)[keyof typeof ProductType];

    interface Account extends BaseAccount {
      type: AccountType;
      source: "institution";
      metadata: AccountMetadata;
      balances: AccountBalances;
      state: "active";
      routing: "";
    }

    interface Loan extends Account {
      loan_details: LoanDetails;
    }
    interface ExternalAccount extends BaseAccount {
      type: AccountType;
      source: "external";
      balances: EmptyObject;
      metadata: EmptyObject;
      state: "pending" | "verified" | "removed";
      routing: `${number}`;
    }

    type AnyAccount = Account | ExternalAccount;
  }
}

type MetadataFormaters = Record<
  Exclude<AccountMetadata[string]["format"], null>,
  (...params: any[]) => string
>;
type MetadataDetail = { label: string; value: string | null };

const defaultFormatters: MetadataFormaters = {
  currency: Filters.currency,
  percentage: Filters.percent,
  date: Filters.americanDate,
} as const;

function isNotEmpty<T extends object>(data: T | EmptyObject): data is T {
  return !!data && Object.keys(data).length > 0;
}

type DeserializedAccount = Omit<
  API.AnyAccount,
  "balances" | "users" | "loan_details"
> & {
  balances: Balances;
  account_type: API.Account["type"];
  product_type: API.Account["product"]["type"];
  users: User[];
  loan_details:
    | EmptyObject
    | (Omit<LoanDetails, "minimum_payment"> & { minimum_payment: string });
};

type AccountProps = Partial<DeserializedAccount>;

interface AccountHeadlineType {
  title: string;
  type: string;
  value: string;
  nextPaymentDate?: string;
}

type StopCheckPaymentType =
  | { min_check_number: string; amount: string; max_check_number?: never }
  | { min_check_number: string; amount?: never; max_check_number: string };

interface Account extends DeserializedAccount {}

class Account {
  constructor(props: AccountProps) {
    Object.assign(this, props);
    // External accounts shouldn't ever be favorited,
    // for now just hardcode this until we feel right to do a data migration
    this.favorited = props.source === "institution" ? !!props.favorited : false;
    this.hidden = !!props.hidden;
    this.users = props.users || [];
    this.features = props.features || [];
    this.loan_details = { ...(props.loan_details || {}) };
    this.metadata = { ...(props.metadata || {}) };
    this.balances = props.balances || new Balances({});
  }

  // taken from integrations.base Account types, also includes the EXTERNAL_ACCOUNTS group
  static PRODUCT_GROUPS = {
    Favorites: ["favorites"],
    Checking: ["checking"],
    Savings: ["savings", "hsa", "ira", "money_market"],
    "Credit cards": ["credit_card"],
    Loans: ["line_of_credit", "mortgage", "lease", "loan"],
    CDs: ["certificate_of_deposit", "ira_certificate_of_deposit"],
    Others: ["unknown"],
    "External accounts": ["external_account"],
  };

  getProductGroup() {
    // Get the product group type, ignoring the "Favorites" special case
    const wasFavorited = this.favorited;
    this.favorited = false;
    const type = this.getGroupName();
    this.favorited = wasFavorited;
    return type;
  }

  getBalanceHeadline(): AccountHeadlineType[] {
    const group = this.getProductGroup();
    const isCurrentBalanceHeadline =
      this.account_type === "deposit" ||
      ["credit_card", "mortgage"].includes(this.product_type || "");

    switch (group) {
      case ACCOUNT_PRODUCT_GROUP.CHECKING:
      case ACCOUNT_PRODUCT_GROUP.SAVINGS:
        return [
          {
            type: "available-balance",
            title: "Available balance",
            value: this.balances.available,
          },
        ];

      default:
        if (isCurrentBalanceHeadline) {
          return [
            {
              type: "current-balance",
              title: "Current balance",
              value: this.balances.ledger,
            },
          ];
        }
        return [
          {
            type: "default",
            title: "Balance",
            value: this.balances.ledger,
          },
        ];
    }
  }

  getPaymentHeadlines(): AccountHeadlineType[] {
    if (["", null, undefined].includes(this.loan_details?.minimum_payment))
      return [];

    let formattedDate = "";

    const paymentDetails: string[] = [
      this.product_type === "credit_card" ? "Minimum payment" : "Next payment",
    ];
    const type =
      this.product_type === "credit_card" ? "minimum-payment" : "next-payment";

    // display when the next payment is due if it exists
    if (this.loan_details?.next_payment_at) {
      formattedDate = Filters.americanDate(this.loan_details.next_payment_at);
      const paymentDueDate = `(due ${formattedDate})`;
      paymentDetails.push(paymentDueDate);
    }
    return [
      {
        type,
        nextPaymentDate: formattedDate,
        title: paymentDetails.join(" "),
        value: formatNumber(this.loan_details.minimum_payment),
      },
    ];
  }

  getMiscHeadlines(): AccountHeadlineType[] {
    const miscHeadlines: AccountHeadlineType[] = [];

    // display maturity date if it exists
    if (this.metadata?.maturity_date?.value) {
      miscHeadlines.push({
        type: "maturity-date",
        title: "Maturity date",
        value: this.formattedMetadata().maturity_date.value as string,
      });
    }

    return miscHeadlines;
  }

  displayHeadlineData() {
    const contents: AccountHeadlineType[] = [];
    contents.push(...this.getBalanceHeadline());
    contents.push(...this.getPaymentHeadlines());
    contents.push(...this.getMiscHeadlines());
    return contents;
  }

  // Headline stats are split into helper functions based on headline type.
  // These functions also ship with a specific type property used for headline translation
  getHeadlineStats(): Omit<AccountHeadlineType, "type">[] {
    const contents: AccountHeadlineType[] = [];
    contents.push(...this.getBalanceHeadline());
    contents.push(...this.getPaymentHeadlines());
    contents.push(...this.getMiscHeadlines());
    return contents.map(({ type, ...restContents }) => restContents);
  }

  getMaskedNumber() {
    // First try to split from (e.g. 122=X) into a number (e.g. 123) if needed
    const accountAndShareId = this.number?.split("=") || [""];
    const accountNumber = accountAndShareId[0];
    if (accountNumber.length <= 4) return this.number || "";
    // show suffix if it has one, else last four of number
    return accountAndShareId.length > 1
      ? `${accountNumber.substring(accountNumber.length - 2)}=${
          accountAndShareId[1]
        }`
      : accountNumber.substring(accountNumber.length - 4);
  }

  getName() {
    return this.nickname || this.name || "";
  }

  getNameWithoutMaskedAccountNumber() {
    // (\*{2,}[0-9]{2,})?  Two or more stars and two or more numbers is present or not present.
    // ([-=])?             Match one or none for dash or equals.
    // *{2,}               Two or more stars.
    // [0-9]{2,}$          Two or more numbers at the end of the string.
    const maskedAccountNumberRegex =
      /(\*{2,}[0-9]{2,})?([-=])?\*{2,}[0-9]{2,}$/;
    return this.name?.replace(maskedAccountNumberRegex, "").trim() || "";
  }

  getShortDescription(includeSpaces = true) {
    const nicknameOrName = this.getName();
    // External account names are serialized to include the number, so don't add the number to the customer facing name
    if (this.isExternal()) {
      return nicknameOrName;
    }
    const separator = includeSpaces ? " - " : "-";
    return `${nicknameOrName}${separator}${this.getMaskedNumber()}`;
  }

  getDescription(isDestination = false) {
    const shortDescription = this.getShortDescription();
    // use available balance unless it is a credit account used as a destination
    const relevantBalance =
      isDestination && this.isCredit()
        ? this.balances?.ledger
        : this.balances?.available;
    if (!relevantBalance) {
      return shortDescription;
    }
    return `${shortDescription} (${relevantBalance})`;
  }

  getGroupName() {
    if (this.favorited) return "Favorites";
    const productType = this.isExternal()
      ? "external_account"
      : this.product_type;
    return Object.entries(Account.PRODUCT_GROUPS).reduce(
      (prevGroupName, current) => {
        const [groupName, productTypes] = current;
        if (
          productTypes.includes(
            String(productType).toLowerCase().replace(/ /g, "_"),
          )
        )
          return groupName;
        return prevGroupName;
      },
      "Others",
    );
  }

  isInternal() {
    return this.source === "institution";
  }

  isExternal() {
    return !this.isInternal();
  }

  isCredit() {
    return this.account_type === "credit";
  }

  isDeposit() {
    return !this.isCredit();
  }

  isCD() {
    return Account.PRODUCT_GROUPS.CDs.includes(this.product_type || "");
  }

  isChecking() {
    return Account.PRODUCT_GROUPS.Checking.includes(this.product_type || "");
  }

  isInternalLoan() {
    return (
      this.isInternal() &&
      Account.PRODUCT_GROUPS.Loans.includes(this.product_type || "")
    );
  }

  isValidTransferSource() {
    return (
      this.features.indexOf("transfer_source") > -1 &&
      !this.features.includes("zero_balance")
    );
  }

  isValidAchTransferSource() {
    // balance check was performed before granting this feature to the account
    return this.features.includes("ach_source");
  }

  isValidTransferDestinationForSource(
    sourceAccount: Account | undefined | null,
    achAllowsPush: boolean,
    supportsInternalTransfers = true,
    loanPrincipalPayment: boolean = false,
  ) {
    // if loan paydown functionality enabled, hide loan accounts from simple transfers options
    if (loanPrincipalPayment && this.isInternalLoan()) return false;
    if (!sourceAccount) {
      return (
        this.isValidTransferDestination() ||
        (achAllowsPush && this.isValidAchTransferDestination())
      );
    }
    if (sourceAccount.id === this.id) return false;
    if (sourceAccount.isExternal()) {
      // Assume here that ACH pull must be allowed for external source to have been chosen
      return this.isInternal() && this.isValidAchTransferDestination();
    }
    // at this point, we know that the source account is internal
    // destination cannot be internal if internal transfers are not supported
    if (!supportsInternalTransfers && this.isInternal()) return false;
    // if the source account does not support ACHs, destination cannot be external
    if (!sourceAccount.isValidAchTransferSource() && this.isExternal())
      return false;

    return this.isValidTransferDestination();
  }

  isValidTransferSourceForDestination(
    destinationAccount?: Account | null,
    supportsInternalTransfers = true,
  ) {
    if (!destinationAccount) {
      return this.isValidTransferSource();
    }
    if (destinationAccount.id === this.id) return false;
    if (destinationAccount.isExternal()) {
      // Assume here that ACH pull must be allowed for external destination to have been chosen
      return this.isInternal() && this.isValidAchTransferSource();
    }
    // at this point, we know that the destination account is internal
    // source cannot be internal if internal transfers are not supported
    if (!supportsInternalTransfers && this.isInternal()) return false;
    // if the destination account does not support ACHs, source cannot be external
    if (
      !destinationAccount.isValidAchTransferDestination() &&
      this.isExternal()
    )
      return false;
    return this.isValidTransferSource();
  }

  isValidTransferDestination() {
    return this.features.indexOf("transfer_destination") > -1;
  }

  isValidAchTransferDestination() {
    return this.features.indexOf("ach_destination") > -1;
  }

  isValidInternalOrExternalTransferSource(achAllowsPull: boolean) {
    return this.isValidTransferSource() && (achAllowsPull || this.isInternal());
  }

  isValidInternalOrExternalTransferDestination(achAllowsPush: boolean) {
    return (
      this.isValidTransferDestination() && (achAllowsPush || this.isInternal())
    );
  }

  isValidBillpaySource() {
    return this.isInternal() && this.isChecking();
  }

  isPayableByCard() {
    return (
      this.isInternal() &&
      ["mortgage", "lease", "loan"].includes(this.product_type || "")
    );
  }

  isAmortizedLoan() {
    return this?.metadata?.loan_amortized?.value === "yes";
  }

  availableBalanceAsFloat() {
    return this.balances.available
      ? parseFloat(this.balances.available.replace(/[^\d.-]/g, ""))
      : 0;
  }

  transferableBalanceAsFloat() {
    if (this.metadata.transferable_balance?.value) {
      return parseFloat(this.metadata.transferable_balance.value);
    }
    return this.availableBalanceAsFloat();
  }

  ledgerBalanceAsFloat() {
    return this.balances.ledger
      ? parseFloat(this.balances.ledger.replace(/[^\d.-]/g, ""))
      : 0;
  }

  primaryBalanceAsFloat() {
    return this.balances.primary
      ? parseFloat(this.balances.primary.replace(/[^\d.-]/g, ""))
      : 0;
  }

  formattedMetadata(
    externalFormatters: Partial<MetadataFormaters> = defaultFormatters,
  ) {
    // remap names in case we don't name things consistently

    const noop = <T>(x: T) => x;
    const formatters = (format: keyof MetadataFormaters) =>
      externalFormatters[format] || defaultFormatters[format] || noop;

    if (this.metadata.previous_payment_at) {
      this.metadata.previous_payment_date = this.metadata.previous_payment_at;
      delete this.metadata.previous_payment_at;
    }
    if (this.metadata.regular_loan_payment) {
      this.metadata.regular_payment = this.metadata.regular_loan_payment;
      delete this.metadata.regular_loan_payment;
    }
    if (this.metadata.loan_amortized && this.metadata.loan_amortized.public) {
      this.metadata.loan_amortized.public = false;
    }
    const formattedMetadata = Object.entries(this.metadata)
      .filter(([_, values]) => values && values.value && values.public) // eslint-disable-line no-unused-vars
      .reduce(
        (previous, [key, { format, value }]) => {
          try {
            previous[key] = {
              label: Filters.humanize(Filters.titlecase(key)),
              value: formatters(format as keyof MetadataFormaters)(
                format === "date"
                  ? DateTime.fromISO(value as string).toJSDate()
                  : value,
              ),
            };
          } catch {
            /* if formatting errors out, omit from returned formatted metadata */
          }
          return previous;
        },
        {} as Record<string, MetadataDetail>,
      );

    // include account details not contained within the metadata property
    const otherDetails = {
      accountNumber: { label: "Account number", value: this?.number },
      routingNumber: { label: "Routing number", value: this?.routing },
      checkMicr: { label: "Check MICR", value: this?.check_micr },
      availableCredit: {
        label: "Available credit",
        value: this?.balances.available,
      },
      minimumPayment: {
        label: "Minimum payment",
        value: this?.loan_details?.minimum_payment
          ? formatters("currency")(this.loan_details.minimum_payment)
          : null,
      },
      apr: {
        label: "APR",
        value: this?.loan_details?.interest_rate
          ? formatters("percentage")(this.loan_details.interest_rate)
          : null,
      },
    };

    return { ...formattedMetadata, ...otherDetails } as typeof otherDetails &
      Record<string, MetadataDetail>;
  }

  accountDetails(
    externalFormatters: Partial<MetadataFormaters> = defaultFormatters,
  ) {
    const {
      payoff_amount,
      accountNumber,
      routingNumber,
      checkMicr,
      availableCredit,
      maturity_date,
      interest_rate,
      dividend_rate,
      minimumPayment,
      past_due,
      regular_payment,
      previous_payment_date,
      apr,
      ...otherMetadata
    } = this.formattedMetadata(externalFormatters);

    const showAvailableCredit = ["credit_card", "line_of_credit"].includes(
      this.product_type || "",
    );

    const defaultDetails = [
      payoff_amount,
      accountNumber,
      routingNumber,
      checkMicr,
      showAvailableCredit ? availableCredit : null,
      maturity_date,
      interest_rate || dividend_rate, // some cores label rate as interest rate, others as dividend rate.
      minimumPayment,
      past_due,
      regular_payment,
      previous_payment_date,
    ].filter((detail) => detail?.label && detail?.value);

    const expandedDetails = [apr, ...Object.values(otherMetadata)].filter(
      (detail) => detail?.label && detail?.value,
    );

    return { defaultDetails, expandedDetails };
  }

  getPreviousPaymentDateValue() {
    /* `previous_payment_at` gets mutated into `previous_payment_date` so we should check both */
    return (this.metadata?.previous_payment_date?.value ||
      this.metadata?.previous_payment_at?.value) as DateString;
  }

  canCalculateLoanPayoff(userFeatures: API.UserFeatures) {
    return (
      this.product_type === "loan" &&
      !userFeatures.hide_loan_calculator &&
      Boolean(
        this.metadata &&
          this.metadata.interest_type &&
          this.metadata.interest_type.value === "365" &&
          this.metadata.interest_days &&
          this.metadata.interest_days.value &&
          this.getPreviousPaymentDateValue(),
      )
    );
  }

  calculatePayoffInfo(date: ConstructorParameters<typeof Date>[0]) {
    const from = DateTime.fromJSDate(
      new Date(this.getPreviousPaymentDateValue()),
    );
    const to = DateTime.fromJSDate(new Date(date));
    const days = to.diff(from, "days").as("days");
    const interestDays = Number(this.metadata.interest_days.value);
    const dayRate = this.loan_details.interest_rate / interestDays;
    const principal = utils.parseValueAsFloat(this.balances.ledger || 0) * 100;
    let compoundedBalance = principal;
    for (let i = 0; i < days; i += 1) {
      compoundedBalance = compoundedBalance * dayRate + compoundedBalance;
    }
    return {
      total: compoundedBalance,
      interest: compoundedBalance - principal,
      principal,
      payoffDate: date,
    };
  }

  stopCheckPayment(payload: StopCheckPaymentType) {
    if (payload?.amount && typeof payload.amount === "string") {
      (payload as any).amount = utils.dollarsToPennies(
        payload.amount.replace(/[^\d.]/g, ""),
      );
    }
    const url = `accounts/${this.id}/stops`;
    return ApiHttp.fetch(url, { method: "POST" }, payload);
  }

  withdrawCashiersCheck<T extends { amount: string }>(payload: T) {
    (payload as any).amount = utils.dollarsToPennies(
      payload.amount.replace(/[^\d.]/g, ""),
    );
    const url = `accounts/${this.id}/withdrawals`;
    return ApiHttp.fetch(url, { method: "POST" }, payload);
  }

  toggleFavorited() {
    return ApiHttp.fetch(
      `accounts/${this.id}`,
      { method: "PUT" },
      { favorited: !this.favorited },
    );
  }

  toggleHidden() {
    return ApiHttp.fetch(
      `accounts/${this.id}`,
      { method: "PUT" },
      { hidden: !this.hidden },
    );
  }

  /**
   * Notably, if the `accounts` param contains both deposit and credit accounts,
   * this will return the sum of the primary balance of deposit accounts ONLY.
   * This is because favorited account collections can include both types of accounts,
   * and mixing credit and deposit values is confusing.
   *
   * @param {Array} accounts - Account objects to sum the balance of
   * @returns {Number} the summed balance of all accounts.
   */
  static sumBalancesForAccounts(accounts: Account[]) {
    if (!accounts.every((a) => a.isInternal()))
      throw Error("must be internal accounts");
    const allCredit = accounts.every((a) => a.isCredit());
    const subset = !allCredit
      ? accounts.filter((a) => a.isDeposit())
      : accounts;
    return subset.reduce((pv, cv) => pv + cv.primaryBalanceAsFloat(), 0);
  }

  static getAccountFromId(accountId: Account["id"], accounts: Account[]) {
    if (!accounts || accounts.length < 1) {
      return null;
    }
    return accounts.find((account) => account.id === accountId);
  }

  static getTransactionType(
    to_account_id: Account["id"],
    from_account_id: Account["id"],
    accounts: Account[],
  ) {
    const toAccount = accounts.find((a) => a.id === to_account_id);
    const fromAccount = accounts.find((a) => a.id === from_account_id);
    const transactionType =
      toAccount?.account_type === "credit" ||
      fromAccount?.product_type === "certificate_of_deposit"
        ? "Payment"
        : "Transfer";
    return transactionType;
  }

  static addExternalAccount<T>(payload: T) {
    return ApiHttp.fetch(
      "accounts",
      { method: "POST" },
      {
        ...payload,
        ...{ agreement: true },
      },
    );
  }

  updateBalances(balances: Balances | API.AccountBalances) {
    if (balances.account_id && balances.account_id !== this.id) {
      /* empty */
    } else if (balances instanceof Balances) {
      this.balances = balances;
    } else {
      this.balances = Balances.deserialize({
        ...balances,
        account_id: this.id,
      });
    }
  }

  /**
   * Factory method returning a new instance of Account from
   * an indigo.serialized Account
   * */
  static deserialize(payload: API.AnyAccount) {
    const { balances, loan_details, ...rest } = payload;
    const loanDetails = isNotEmpty(loan_details)
      ? ({
          ...loan_details,
          minimum_payment: Filters.penniesToDollars(
            loan_details.minimum_payment,
          ).toString(),
        } as DeserializedAccount["loan_details"])
      : {};

    const account = new Account({
      ...rest,
      loan_details: loanDetails,
      account_type: payload.type,
      product_type: payload.product?.type,
      // WARNING: AccountUsers only contain the user UUID and nothing else
      users: payload.users?.map((payloadUser) => new User(payloadUser as any)),
    });

    if (isNotEmpty(balances)) {
      account.updateBalances(balances);
    }

    return account;
  }

  serialize() {
    return {
      id: this.id,
      name: this.nickname,
      favorited: this.favorited,
    };
  }
}

export default Account;
