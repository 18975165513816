import { useEffect, useState } from "react";
import {
  Button,
  ContentCard,
  LoadingSkeleton,
  MenuButton,
  Row,
  useLoadingContext,
  useNotificationContext,
} from "cerulean";
import WireRecipient from "byzantine/src/WireRecipient";
import DeleteDialog from "../DeleteDialog";
import AddRecipientDialog from "../../../transfer/wires/recipients/AddRecipientDialog";
import EditRecipientDialog from "../../../transfer/wires/recipients/EditRecipientDialog";

interface ManageRecipientPopoverProps {
  setIsDeleteWireRecipientDialogOpen: (bool: boolean) => void;
  setIsEditWireRecipientDialogOpen: (bool: boolean) => void;
}

const ManageRecipientPopover = ({
  setIsDeleteWireRecipientDialogOpen,
  setIsEditWireRecipientDialogOpen,
}: ManageRecipientPopoverProps) => (
  <MenuButton triggerIcon="more-vertical">
    <MenuButton.Item
      label="Edit"
      startIcon="edit-2"
      onSelect={() => {
        setIsEditWireRecipientDialogOpen(true);
      }}
    />
    <MenuButton.Item
      label="Delete"
      startIcon="trash-2"
      onSelect={() => {
        setIsDeleteWireRecipientDialogOpen(true);
      }}
    />
  </MenuButton>
);

interface WireRecipientCardProps {
  wireRecipient: WireRecipient;
  fetchWireRecipients: () => Promise<void>;
}

const WireRecipientCard = ({
  wireRecipient,
  fetchWireRecipients,
}: WireRecipientCardProps) => {
  const [isDeleteWireRecipientDialogOpen, setIsDeleteWireRecipientDialogOpen] =
    useState(false);
  const [isEditWireRecipientDialogOpen, setIsEditWireRecipientDialogOpen] =
    useState(false);
  const { sendNotification } = useNotificationContext();
  const { setIsLoading } = useLoadingContext();

  const deleteWireRecipient = async () => {
    setIsDeleteWireRecipientDialogOpen(false);
    setIsLoading(true);
    try {
      await wireRecipient.delete();
      setIsLoading(false);
      sendNotification({
        type: "success",
        text: "Wire recipient deleted.",
      });
      await fetchWireRecipients();
    } catch {
      sendNotification({
        type: "negative",
        text: "Unable to delete wire recipient, please try again.",
      });
      setIsLoading(false);
    }
  };

  const updateWireRecipient = async (recipient: WireRecipient) => {
    setIsEditWireRecipientDialogOpen(false);
    setIsLoading(true);
    try {
      await recipient.update();
      setIsLoading(false);
      sendNotification({
        type: "success",
        text: "Wire recipient updated.",
      });
      await fetchWireRecipients();
    } catch {
      sendNotification({
        type: "negative",
        text: "Unable to update wire recipient, please try again.",
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <ContentCard>
        <div className="recipient-section-header">
          <div
            className="fontColor--heading fontWeight--semibold"
            style={{ overflowWrap: "anywhere" }}
          >
            {wireRecipient.name}
          </div>
          <div style={{ marginTop: "-4px" }}>
            <ManageRecipientPopover
              setIsDeleteWireRecipientDialogOpen={
                setIsDeleteWireRecipientDialogOpen
              }
              setIsEditWireRecipientDialogOpen={
                setIsEditWireRecipientDialogOpen
              }
            />
          </div>
        </div>
        <div className="margin--top--xxs">
          {wireRecipient.maskedAccountNumber}
        </div>
      </ContentCard>
      <DeleteDialog
        recipientName={wireRecipient.name}
        recipientType="wire recipient"
        onDelete={deleteWireRecipient}
        isOpen={isDeleteWireRecipientDialogOpen}
        closeDialog={() => setIsDeleteWireRecipientDialogOpen(false)}
      />
      <EditRecipientDialog
        closeDialog={() => setIsEditWireRecipientDialogOpen(false)}
        isOpen={isEditWireRecipientDialogOpen}
        recipient={wireRecipient}
        updateWireRecipient={updateWireRecipient}
      />
    </>
  );
};

const WireRecipientsSection = () => {
  const [wireRecipients, setWireRecipients] = useState<WireRecipient[]>([]);
  const [isAddWireRecipientDialogOpen, setIsAddWireRecipientDialogOpen] =
    useState(false);
  const [isFetchingWireRecipients, setIsFetchingWireRecipients] =
    useState(false);

  const { sendNotification } = useNotificationContext();
  const fetchWireRecipients = async () => {
    setIsFetchingWireRecipients(true);
    try {
      setWireRecipients((await WireRecipient.fetchAll()) || []);
    } catch {
      sendNotification({
        type: "negative",
        text: "Unable to retrieve your wire recipients, please try again.",
      });
    } finally {
      setIsFetchingWireRecipients(false);
    }
  };
  useEffect(() => {
    fetchWireRecipients();
  }, []);

  return (
    <>
      <div className="margin--top--xl margin--bottom--l">
        <Row>
          <Row.Item>
            <div className="fontSize--l fontColor--heading fontWeight--bold">
              Wire recipients
            </div>
          </Row.Item>
          <Row.Item shrink>
            <Button
              kind="plain"
              label="Add a new wire recipient"
              onClick={() => setIsAddWireRecipientDialogOpen(true)}
            />
          </Row.Item>
        </Row>
      </div>
      <LoadingSkeleton content="paragraph" isLoading={isFetchingWireRecipients}>
        <div className="recipients-container">
          {wireRecipients?.map((wireRecipient) => (
            <WireRecipientCard
              key={wireRecipient.id}
              wireRecipient={wireRecipient}
              fetchWireRecipients={fetchWireRecipients}
            />
          ))}
          {!wireRecipients?.length && (
            <div>You don&apos;t have any linked wire recipients.</div>
          )}
        </div>
      </LoadingSkeleton>
      <AddRecipientDialog
        open={isAddWireRecipientDialogOpen}
        handleClose={() => setIsAddWireRecipientDialogOpen(false)}
        recipients={wireRecipients}
        updateTransferFormWithRecipient={() => {}}
        setRecipients={setWireRecipients}
      />
    </>
  );
};

export default WireRecipientsSection;
