import type { MouseEventHandler } from "react";
import React, { memo, useCallback, useMemo } from "react";

import { modules } from "byzantine";
import cc from "classcat";
import { IconButton } from "cerulean";

import styles from "./TemplatePreview.module.scss";

type Props = {
  templateId: API.FedwireTemplateId;
  selected?: boolean;
  onSelect: (fedwireTemplateId: API.FedwireTemplateId) => void;
  disabled?: boolean;
};

const FlashUpdated = ({
  versionId,
}: {
  versionId: API.FedwireTemplate["version_id"];
}) => {
  const updated = modules.wireTemplates.useTemplateUpdated(versionId);
  return updated ? <p className={styles.updated}>Just updated</p> : null;
};

const FedwireTemplatePreview = ({ templateId, selected, onSelect }: Props) => {
  const { collapsed, onToggle } =
    modules.wireTemplates.useFedwireTemplatePreview();

  const cardStyle = useMemo(
    () => cc([styles.roundCard, selected && styles.selectedCard]),
    [selected]
  );

  const onClick = useCallback(
    () => onSelect(templateId),
    [onSelect, templateId]
  );

  const onTogglePreview = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      event.stopPropagation();
      onToggle();
    },
    [onToggle]
  );

  const details = modules.wireTemplates.useTemplateDetails(templateId);
  if (!details) {
    return null;
  }
  const {
    templateDetails,
    recipientDetails,
    bankDetails,
    title,
    subheader,
    versionId,
  } = details;

  return (
    <div onClick={onClick} className={cardStyle}>
      <div className={styles.container}>
        <div className={styles.shrink}>
          <p className={styles.header}>{title}</p>
          <p className={styles.subHeader}>{subheader}</p>
          <FlashUpdated versionId={versionId} />
        </div>
        <div className={styles.toggle}>
          <div className={styles.dropdownArrow}>
            <IconButton
              kind="plain"
              label="Edit"
              name={collapsed ? "chevron-down" : "chevron-up"}
              onClick={onTogglePreview}
              textSize="l"
            />
          </div>
        </div>
      </div>
      {!collapsed ? (
        <div>
          <div className={styles.divider} />
          <div className={styles.detailsContainer}>
            <div>
              <p className={styles.detailsHeader}>{templateDetails.header}</p>
              {templateDetails.rows.map((row, i) => (
                <p
                  key={`templateDetails-${row}-${i}`}
                  className={styles.detailsValue}
                >
                  {row}
                </p>
              ))}
            </div>
            <div>
              <p className={styles.detailsHeader}>{recipientDetails.header}</p>
              {recipientDetails.rows.map((row, i) => (
                <p
                  key={`recipientDetails-${row}-${i}`}
                  className={styles.detailsValue}
                >
                  {row}
                </p>
              ))}
            </div>
            <div>
              <p className={styles.detailsHeader}>{bankDetails.header}</p>
              <div className={styles.bankDetails}>
                <div className={styles.templateInstitution}>
                  <div className={styles.institutionLogo}>
                    <span className="narmi-icon-bank" />
                  </div>
                </div>
                {bankDetails.rows.map((row, i) => (
                  <p
                    key={`bankDetails-${row}-${i}`}
                    className={styles.detailsValue}
                  >
                    {row}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default memo(FedwireTemplatePreview);
