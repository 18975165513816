import type { NetworkPayload } from "../../../composites/request/request.types";

export const API = {
  getMarketingTarget: (
    address: string,
    token: string,
    unixTimestamp: number,
  ) => {
    return {
      // this url is prefixed by dbbl
      url: `promotions/${address}/?token=${token}&ts=${unixTimestamp}`,
      options: {
        method: "GET",
        isJsonResponse: true,
      },
    } satisfies NetworkPayload;
  },
  getMarketingLink: (address: string, token: string, unixTimestamp: number) => {
    // this url is not passed through dbbl so it needs to be manually prefixed with /v1/
    return `/v1/promotions/${address}/redirect/?token=${token}&ts=${unixTimestamp}`;
  },
  getMarketingImage: (
    address: string,
    token: string,
    unixTimestamp: number,
  ) => {
    // this url is not passed through dbbl so it needs to be manually prefixed with /v1/
    return `/v1/promotions/${address}/image/?token=${token}&ts=${unixTimestamp}`;
  },
  getMarketingDestination: (
    address: string,
    token: string,
    unixTimestamp: number,
  ) => {
    return {
      url: `promotions/${address}/destination/?token=${token}&ts=${unixTimestamp}`,
      options: {
        method: "GET",
        isJsonResponse: true,
      },
    } satisfies NetworkPayload;
  },
  getSegmintIframeUrl: () => "/v1/promotions/pingback/",
};
