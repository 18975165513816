import React from "react";

import _ from "lodash";

export function useDeepEqualMemo<T>(
  factory: () => T,
  deps: React.DependencyList,
): T;
export function useDeepEqualMemo<T>(obj: T): T;
export function useDeepEqualMemo<T>(
  factory: () => T | T,
  dependencies?: React.DependencyList,
) {
  const value = dependencies === undefined ? (factory as T) : dependencies;
  const ref = React.useRef<typeof value>(value);
  const signalRef = React.useRef<number>(0);

  if (!_.isEqual(value, ref.current)) {
    ref.current = value;
    signalRef.current += 1;
  }

  const callback = typeof factory === "function" ? factory : () => ref.current;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(callback, [signalRef.current]);
}

export function useDeepEqualEffect(
  effect: React.EffectCallback,
  dependencies: React.DependencyList,
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useEffect(effect, useDeepEqualMemo(dependencies));
}
