import { useEffect } from "react";
import { useLocalization } from "@fluent/react";
import { Button, Dialog, useLoadingContext } from "cerulean";
import { useInstitutionSettings, InstitutionSettingsContextProvider } from "./contexts/InstitutionSettingsContext";
import styles from "./OAuth2Authorize.module.scss";

const OAuth2AuthorizeComponent = ({ name, scopes }: { name: string; scopes: string[] }) => {
  const { l10n } = useLocalization();
  const { setIsLoading } = useLoadingContext();
  const institutionSettings = useInstitutionSettings();

  useEffect(() => {
    setIsLoading(true);
    if (institutionSettings.long_name) {
      setIsLoading(false);
    }
  }, [institutionSettings.long_name]);

  const onUserDismiss = () => {
    document.getElementById("cancel-button")?.click()
  };

  const handleAuthorizeClick = async () => {
    document.getElementById("authorize-button")?.click()
  }

  return (
    <Dialog
      isOpen={true}
      onUserDismiss={onUserDismiss}
      title={l10n.getString("dialog-title-authorize-oauth2", { appName: name })}
      footer={
        <div className={styles.authorizeButtons}>
          <Button
            kind="negative"
            onClick={onUserDismiss}
            label={l10n.getString("button-cancel")}
          />
          <Button onClick={handleAuthorizeClick} label={l10n.getString("button-authorize")} />
        </div>
      }
    >
      <div className={styles.authorizeAppHeader}>
        <div className={styles.genericBankLogo}>
          <span className={`narmi-icon-bank ${styles.icon}`} />
        </div>
        <div className={styles.appAccountAccess}>
          {name} wants to access your <span className={styles.institutionName}>{institutionSettings.long_name}</span> account(s).
        </div>
      </div>
      <div className={styles.appPermissions}>
          This application requires the following permissions:
        <ul>
          {scopes.map((scope) => (<li key={scope}>{scope}</li>))}
        </ul>
      </div>
    </Dialog>
  );
};

const OAuth2Authorize = ({ name, scopes }: { name: string; scopes: string }) => (
  <InstitutionSettingsContextProvider>
    <OAuth2AuthorizeComponent name={name} scopes={JSON.parse(scopes)} />
  </InstitutionSettingsContextProvider>
);

export default OAuth2Authorize;