import { Button } from "@narmi/design_system";
import utils from "byzantine/src/utils";
import SectionCard from "../../SectionCard";

interface PromotionCardProps {
  promotion: {
    title: string;
    description: string;
    icon: string;
    url: string;
    action_text?: string; // eslint-disable-line camelcase
  };
}

const PromotionCard = ({ promotion }: PromotionCardProps) => (
  <SectionCard classNames="dashboard-section-card padding--bottom--l">
    <div className="dashboard-section-container">
      <div className="dashboard-item-border-box" data-testid="promotion-item">
        <div className="dashboard-item promotion-item">
          <div className="dashboard-item-subsection promotion-icon-section">
            <span className={utils.ndsIconPrepend(promotion.icon)}></span>
          </div>
          <div className="dashboard-item-subsection dashboard-item-title-section">
            {promotion.title}
          </div>
        </div>
        <div className="dashboard-item-description">
          {promotion.description}
        </div>
        <div className="margin--top--s">
          <Button
            as="a"
            href={promotion.url}
            kind="plain"
            label={promotion.action_text || "Learn more"}
            rel="noreferrer"
            target="_blank"
          />
        </div>
      </div>
    </div>
  </SectionCard>
);

export default PromotionCard;
