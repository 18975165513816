import { useState, useEffect, useMemo } from "react";
import { useLocalization } from "@fluent/react";
import { ComposeProviders, entities } from "byzantine";
import { Drawer, catcat as cc } from "cerulean";
import { ResponsiveFlex } from "@narmi/design_system";
import styles from "./AddRecipientDrawer.module.scss";
import ProfileSection from "./ProfileSection";
import ACHDestinationSection from "./ACHDestinationSection";
import WireDestinationSection from "./WireDestinationSection";
import DrawerLayout from "../../../DrawerLayout";

type SectionNameProps = "profile" | "ach" | "wire";
type SectionNameStateType = SectionNameProps | "";
type SectionNamesMapperType = {
  [key in SectionNameProps]: SectionNameProps;
};
type DrawerTypeProps = "add" | "edit";
type DrawerTypesMapperType = {
  [key in DrawerTypeProps]: DrawerTypeProps;
};

type RecipientDrawerProps = {
  isOpen: boolean;
  fromACHFlow?: boolean;
  handleClose: () => void;
  recipientId?: API.RecipientId;
  drawerType: DrawerTypeProps;
};

export type SectionProps = {
  title?: string;
  isOpen: boolean;
  isDisabled: boolean;
  // referring to when clicking "save" button or when clicking header to close section
  onClose: () => void;
  onOpen: () => void;
  // referring to when clicking on "save" button
  onSave: () => void;
  recipientId?: API.RecipientId;
  // drawer in add recipient drawer and edit recipient drawer has slight variations
  drawerType?: DrawerTypeProps;
};

export interface WireSectionProps extends SectionProps {
  savedACHDestination: boolean;
}

const SECTION_NAMES: SectionNamesMapperType = {
  profile: "profile",
  ach: "ach",
  wire: "wire",
};

export const DRAWER_TYPES: DrawerTypesMapperType = {
  add: "add",
  edit: "edit",
};

// Add or Edit Recipient Drawer
const RecipientDrawer = ({
  handleClose,
  isOpen,
  fromACHFlow,
  drawerType,
  recipientId,
}: RecipientDrawerProps) => {
  const { l10n } = useLocalization();
  const isEditDrawer = drawerType === DRAWER_TYPES.edit;
  const isAddDrawer = drawerType === DRAWER_TYPES.add;

  // in add drawer, always default open to profile section
  const [enabledSection, setEnabledSection] = useState<SectionNameStateType>(
    isAddDrawer ? SECTION_NAMES.profile : "",
  );
  // in add drawer, done button is default disabled
  const [doneButtonEnabled, setDoneButtonEnabled] =
    useState<boolean>(isEditDrawer);
  const [savedACHDestination, setSavedACHDestination] =
    useState<boolean>(false);
  const [savedWireDestination, setSavedWireDestination] =
    useState<boolean>(false);

  const enableSection = (section: SectionNameStateType) => {
    setEnabledSection(section);
  };

  const enableAllSections = () => {
    setEnabledSection("");
  };

  // handles toggling section logic
  // if user saves profile section in add drawer from ach flow, only open ach section
  // if user toggles any other section, enable all sections
  const handleSaveSection = () => {
    if (enabledSection === SECTION_NAMES.ach) {
      setSavedACHDestination(true);
    } else if (enabledSection === SECTION_NAMES.wire) {
      setSavedWireDestination(true);
    } else if (enabledSection === SECTION_NAMES.profile) {
      if (isAddDrawer && fromACHFlow) {
        enableSection(SECTION_NAMES.ach);
        return;
      }
    }

    enableAllSections();
  };

  // handles all "done" button logic
  // done button is only enabled if all sections are closed
  // for add drawer from ach flow, done button is enabled when ach has been saved
  // for add drawer from settings, done button is enabled when ach or wire has been saved
  useEffect(() => {
    if (enabledSection) {
      setDoneButtonEnabled(false);
    } else if (!enabledSection) {
      if (isEditDrawer) {
        setDoneButtonEnabled(true);
      } else if (isAddDrawer && fromACHFlow && savedACHDestination) {
        setDoneButtonEnabled(true);
      } else if (
        isAddDrawer &&
        !fromACHFlow &&
        (savedACHDestination || savedWireDestination)
      ) {
        setDoneButtonEnabled(true);
      }
    }
  }, [enabledSection]);

  const resetDrawer = () => {
    // reset to state defaults
    enableSection(isAddDrawer ? SECTION_NAMES.profile : "");
    setDoneButtonEnabled(isEditDrawer);
    setSavedACHDestination(false);
    setSavedWireDestination(false);
  };

  const handleCloseDrawer = () => {
    resetDrawer();
    handleClose();
  };

  const profileSectionDisabled = Boolean(
    enabledSection && enabledSection !== SECTION_NAMES.profile,
  );
  const achSectionDisabled = Boolean(
    enabledSection && enabledSection !== SECTION_NAMES.ach,
  );
  const wireSectionDisabled = Boolean(
    enabledSection && enabledSection !== SECTION_NAMES.wire,
  );
  const additionalDetailsDisabled = fromACHFlow && wireSectionDisabled;

  const getWireSectionHeader = useMemo(() => {
    if (fromACHFlow && !savedWireDestination) {
      return "Wire (optional)";
    }
    return "Wire";
  }, [fromACHFlow, savedWireDestination]);

  const Providers = [
    entities.recipients.RecipientProfileForm.Provider,
    entities.recipients.ACHDestinationForm.Provider,
    entities.recipients.WireDestinationForm.Provider,
  ];

  return (
    <ComposeProviders components={Providers}>
      <Drawer
        depth="633px"
        isOpen={isOpen}
        onUserDismiss={handleCloseDrawer}
        showControls={false}
        paddingSize="none"
      >
        <DrawerLayout
          onSave={handleCloseDrawer}
          onCancel={handleCloseDrawer}
          saveLabel={l10n.getString("recipient-drawer-done-button")}
          isSaveDisabled={!doneButtonEnabled}
        >
          <ResponsiveFlex gapSize="s">
            <h2>
              {l10n.getString(
                isAddDrawer
                  ? "add-recipient-drawer-title"
                  : "edit-recipient-drawer-title",
              )}
            </h2>
            <p>
              {l10n.getString(
                isAddDrawer
                  ? "add-recipient-drawer-subtitle"
                  : "edit-recipient-drawer-subtitle",
              )}
            </p>
            <ProfileSection
              isOpen={enabledSection === SECTION_NAMES.profile}
              isDisabled={profileSectionDisabled}
              onClose={enableAllSections}
              onOpen={() =>
                !profileSectionDisabled && enableSection(SECTION_NAMES.profile)
              }
              onSave={handleSaveSection}
              recipientId={recipientId}
              drawerType={drawerType}
            />
            <ACHDestinationSection
              isOpen={enabledSection === SECTION_NAMES.ach}
              isDisabled={achSectionDisabled}
              onClose={enableAllSections}
              onOpen={() =>
                !achSectionDisabled && enableSection(SECTION_NAMES.ach)
              }
              onSave={handleSaveSection}
              recipientId={recipientId}
            />

            {/* launching add drawer from ach flow nestles wire section under "additional details" */}
            {fromACHFlow && isAddDrawer ? (
              <div
                className={cc(styles.additionalDetailsSection, {
                  [styles.disabledSection]: additionalDetailsDisabled,
                })}
              >
                <h2>
                  {l10n.getString(
                    "add-recipient-drawer-additional-details-title",
                  )}
                </h2>
                <p>
                  {l10n.getString(
                    "add-recipient-drawer-additional-details-subtitle",
                  )}
                </p>
              </div>
            ) : null}

            <WireDestinationSection
              title={getWireSectionHeader}
              isOpen={enabledSection === SECTION_NAMES.wire}
              isDisabled={wireSectionDisabled}
              onClose={enableAllSections}
              onOpen={() =>
                !wireSectionDisabled && enableSection(SECTION_NAMES.wire)
              }
              savedACHDestination={savedACHDestination}
              onSave={handleSaveSection}
              recipientId={recipientId}
            />
          </ResponsiveFlex>
        </DrawerLayout>
      </Drawer>
    </ComposeProviders>
  );
};

export default RecipientDrawer;
