import { catcat as cc, Tag } from "cerulean";
import VISA_LOGO from "../../../../assets/images/visa_logo_official.svg";
import MASTERCARD_LOGO from "../../../../assets/images/mastercard_logo_unofficial.svg";

import styles from "./CardImage.module.scss";

interface CardImageProps {
  name?: string;
  textColor: string;
  isLocked: boolean;
  image: string;
  lastFourDigits: string;
  network: API.CardProvider;
  size?: "small" | "large";
}

type PixelString = `${number}px`;

const getCardStyle = (network: API.Card["network"], size = "small") => {
  const CARD_STYLES: Record<
    API.Card["network"],
    {
      aspectRatio: number;
      logo: string;
      bottom: PixelString;
      right: PixelString;
    }
  > = {
    Mastercard: {
      aspectRatio: 152 / 108,
      logo: MASTERCARD_LOGO,
      bottom: size === "small" ? "7px" : "8px",
      right: "0px",
    },
    Visa: {
      aspectRatio: 1920 / 620,
      logo: VISA_LOGO,
      bottom: size === "small" ? "11.65px" : "18.01px",
      right: size === "small" ? "12px" : "20px",
    },
  };

  if (network in CARD_STYLES) {
    return CARD_STYLES[network];
  }

  return CARD_STYLES.Visa;
};
const CardImage = ({
  name,
  textColor,
  isLocked,
  image,
  lastFourDigits,
  size = "small",
  network,
}: CardImageProps) => {
  const { aspectRatio, logo, ...remainingStyleProps } = getCardStyle(
    network,
    size,
  );

  const width: PixelString = size === "small" ? "32px" : "68px";
  const height = `${parseInt(width.split("px")[0], 10) / aspectRatio}px`;
  return (
    <div
      className={cc(styles.cardWrapper, {
        [styles.large]: size === "large",
      })}
    >
      <div
        className={cc(
          styles.cardContainer,
          { [styles.large]: size === "large" },
          { [styles.small]: size === "small" },
        )}
      >
        <div
          className={styles.cardSvg}
          style={{
            color: textColor,
            filter: isLocked ? "opacity(0.4)" : "",
            backgroundImage: `url('${image}')`,
          }}
        >
          {name && <span className={styles.cardName}>{name}</span>}
          <span className={styles.mask}>**</span>
          <span className={styles.lastFour}>{lastFourDigits}</span>
          <div
            className={styles.cardLogo}
            style={{
              backgroundImage: `url('${logo}')`,
              width,
              height,
              ...remainingStyleProps,
            }}
          />
        </div>
        {isLocked && (
          <div className={styles.lockedTag}>
            <Tag label="Locked" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CardImage;
