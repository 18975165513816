import React from "react";
import { TextInput } from "@narmi/design_system";

/**
 *
 * NDS TextInput configured for entering a routing number.
 * `RoutingNumberTextInput` is intended to be used as a controlled component.
 */

// borrowed from byzantine (since we can't currently import)
export const validateRoutingNumber = (
  string: string | undefined,
  errorMsg: string = "Required"
): string | null => {
  if (!string || Number(string) === 0) {
    // if it's falsey, it cannot be a routing number. Technically, 000000000
    // is a valid routing number, but it's inactive.
    return errorMsg;
  }

  const genericError = "Please enter a valid 9-digit routing number.";

  const routing = string.toString();

  // must be exactly 9 digits
  const match = routing.match("^\\d{9}$");

  if (!match) {
    return genericError;
  }

  // The first two digits of the nine digit RTN must be in the ranges 00
  // through 12, 21 through 32, 61 through 72, or 80. Ref:
  // https://en.wikipedia.org/wiki/Routing_transit_number
  const firstTwo = parseInt(routing.substring(0, 2), 10);
  const firstTwoValid =
    (firstTwo >= 0 && firstTwo <= 12) ||
    (firstTwo >= 21 && firstTwo <= 32) ||
    (firstTwo >= 61 && firstTwo <= 72) ||
    firstTwo === 80;

  if (!firstTwoValid) {
    return genericError;
  }

  // Calculate the checksum, ref:
  // http://www.siccolo.com/Articles/SQLScripts/how-to-create-sql-to-calculate-routing-check-digit.html
  const weights = [3, 7, 1];
  let sum = 0;
  for (let i = 0; i < 8; i += 1) {
    sum += parseInt(routing[i], 10) * weights[i % 3];
  }
  if ((10 - (sum % 10)) % 10 === parseInt(routing[8], 10)) {
    return null;
  }
  return genericError;
};

interface RoutingNumberTextInputProps {
  label: string;
  value?: string;
  onChange?: (value: string) => void;
  name?: string;
  id?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  error?: string;
}

type InputEvent = { inputType: string };

const RoutingNumberTextInput = ({
  value = "",
  onChange,
  label,
  name = "routing_number",
  id = "routing_number",
  onBlur = undefined,
  error = "",
  ...otherTextInputProps
}: RoutingNumberTextInputProps): JSX.Element => (
  <TextInput
    id={id}
    name={name}
    label={label}
    value={value || ""}
    // By default React.ChangeEvent.nativeEvent does not include inputType
    onChange={(event: React.ChangeEvent<HTMLInputElement> & { nativeEvent: InputEvent }) => {
      const strippedValue = event.target.value.replace(/[^\d]/g, "").substring(0, 9);
      // allows for deletion of inputted value if last displayed char is non-numeric
      // if the stripped target value is the same as the stored parent value, then
      // the backspace action deleted a non-numeric character.
      if (event.nativeEvent.inputType === "deleteContentBackward" && strippedValue === value) {
        onChange && onChange(value.substring(0, value.length - 1));
        return;
      }
      onChange && onChange(strippedValue);
    }}
    onBlur={onBlur}
    error={error}
    {...otherTextInputProps}
  />
);

export default RoutingNumberTextInput;
