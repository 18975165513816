import React, { FunctionComponent, ReactNode } from "react";
import { TextInput } from "@narmi/design_system";

interface ZipCodeTextInputProps {
  onChange: (event: any) => void;
  value: string;
  label: string;
  field: string;
  error: string;
  allowExtendedZip?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const ZipCodeTextInput: FunctionComponent<ZipCodeTextInputProps> = ({
  onChange,
  value,
  label,
  error,
  allowExtendedZip = true,
  onBlur = undefined,
}): ReactNode => {
  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let parsedValue;
    if (event.target.value.length < 6) {
      parsedValue = event.target.value.substring(0, 5).replace(/[^\d]/g, "");
    } else {
      if (allowExtendedZip) {
        parsedValue =
          event.target.value.substring(0, 5).replace(/[^\d]/g, "") +
          "-" +
          event.target.value.substring(5, 10).replace(/[^\d]/g, "");
      } else {
        parsedValue = event.target.value.substring(0, 5).replace(/[^\d]/g, "");
      }
    }
    onChange(parsedValue);
  };

  return (
    <TextInput
      onChange={handleInput}
      value={value}
      inputMode="numeric"
      label={label}
      error={error}
      onBlur={onBlur}
    />
  );
};

export default ZipCodeTextInput;
