import { ACCOUNT_TYPE_OPTIONS } from "./consts";

const idIfy = (id: string) => {
  return id as API.RecipientId;
};

export const ADDRESS: API.Address = {
  street_address: "200 west 20th",
  street_address_2: "",
  country_code: "US",
  city: "new york",
  region_code: "NY",
  postal_code: "10022",
};

export const RECIPIENTS: API.Recipient[] = [
  {
    id: idIfy("bts-1"),
    name: "rap mon",
    nickname: "rapper boi",
    type: "person",
    ach_destination: {
      institution_name: "bank of rap mon",
      account_type: ACCOUNT_TYPE_OPTIONS.BUSINESS_CHECKING,
      account_number: "111111111",
      routing_number: "222222222",
    },
    fedwire_destination: null,
  },
  {
    id: idIfy("bts-2"),
    name: "jin",
    type: "person",
    ach_destination: null,
    fedwire_destination: {
      institution_name: "bank of jin",
      account_type: ACCOUNT_TYPE_OPTIONS.BUSINESS_SAVINGS,
      account_number: "333333333",
      routing_number: "444444444",
      address: ADDRESS,
    },
  },
  {
    id: idIfy("bts-3"),
    name: "jimin",
    type: "person",
    ach_destination: null,
    fedwire_destination: {
      institution_name: "bank of jimin",
      account_type: ACCOUNT_TYPE_OPTIONS.PERSONAL_CHECKING,
      account_number: "555555555",
      routing_number: "666666666",
      address: ADDRESS,
    },
  },

  {
    id: idIfy("bts-4"),
    name: "Josh Hughes",
    nickname: "VFX noob",
    type: "person",
    ach_destination: {
      institution_name: "bank of rap mon",
      account_type: ACCOUNT_TYPE_OPTIONS.BUSINESS_CHECKING,
      account_number: "111111111",
      routing_number: "222222222",
    },
    fedwire_destination: null,
  },
  {
    id: idIfy("bts-5"),
    name: "Beverly Mah",
    nickname: "DBBLer",
    type: "person",
    ach_destination: null,
    fedwire_destination: {
      institution_name: "bank of jin",
      account_type: ACCOUNT_TYPE_OPTIONS.BUSINESS_SAVINGS,
      account_number: "333333333",
      routing_number: "444444444",
      address: ADDRESS,
    },
  },
  {
    id: idIfy("bts-6"),
    name: "Johnathan Weisner",
    nickname: "DBBLer",
    type: "person",
    ach_destination: null,
    fedwire_destination: {
      institution_name: "bank of jimin",
      account_type: ACCOUNT_TYPE_OPTIONS.PERSONAL_CHECKING,
      account_number: "555555555",
      routing_number: "666666666",
      address: ADDRESS,
    },
  },

  {
    id: idIfy("bts-7"),
    name: "Arianna Luna",
    nickname: "Designer extraordinaire ",
    type: "person",
    ach_destination: {
      institution_name: "bank of rap mon",
      account_type: ACCOUNT_TYPE_OPTIONS.BUSINESS_CHECKING,
      account_number: "111111111",
      routing_number: "222222222",
    },
    fedwire_destination: null,
  },
  {
    id: idIfy("bts-8"),
    name: "Kyle Kennedy",
    nickname: "Brother in shirts",
    type: "person",
    ach_destination: null,
    fedwire_destination: {
      institution_name: "bank of jin",
      account_type: ACCOUNT_TYPE_OPTIONS.BUSINESS_SAVINGS,
      account_number: "333333333",
      routing_number: "444444444",
      address: ADDRESS,
    },
  },
  {
    id: idIfy("bts-9"),
    name: "Jason Zwick",
    nickname: "Keyboard connoisseur",
    type: "person",
    ach_destination: null,
    fedwire_destination: {
      institution_name: "bank of jimin",
      account_type: ACCOUNT_TYPE_OPTIONS.PERSONAL_CHECKING,
      account_number: "555555555",
      routing_number: "666666666",
      address: ADDRESS,
    },
  },
  {
    id: idIfy("bts-13"),
    name: "Jane Doe",
    nickname: "The OG",
    type: "person",
    ach_destination: {
      institution_name: "bank of rap mon",
      account_type: ACCOUNT_TYPE_OPTIONS.BUSINESS_CHECKING,
      account_number: "111111111",
      routing_number: "222222222",
    },
    fedwire_destination: null,
  },
  {
    id: idIfy("bts-14"),
    name: "Shaggy Rogers",
    nickname: "SCOOB",
    type: "business",
    ach_destination: null,
    fedwire_destination: {
      institution_name: "bank of jin",
      account_type: ACCOUNT_TYPE_OPTIONS.BUSINESS_SAVINGS,
      account_number: "333333333",
      routing_number: "444444444",
      address: ADDRESS,
    },
  },
  {
    id: idIfy("bts-15"),
    name: "Scooby Doo",
    nickname: "Ruh-Roh",
    type: "business",
    ach_destination: null,
    fedwire_destination: {
      institution_name: "bank of jimin",
      account_type: ACCOUNT_TYPE_OPTIONS.PERSONAL_CHECKING,
      account_number: "555555555",
      routing_number: "666666666",
      address: ADDRESS,
    },
  },

  {
    id: idIfy("bts-16"),
    name: "Daphne Blake",
    type: "business",
    ach_destination: {
      institution_name: "bank of rap mon",
      account_type: ACCOUNT_TYPE_OPTIONS.BUSINESS_CHECKING,
      account_number: "111111111",
      routing_number: "222222222",
    },
    fedwire_destination: null,
  },
  {
    id: idIfy("bts-17"),
    name: "Velma Dinkley",
    type: "business",
    ach_destination: null,
    fedwire_destination: {
      institution_name: "bank of jin",
      account_type: ACCOUNT_TYPE_OPTIONS.BUSINESS_SAVINGS,
      account_number: "333333333",
      routing_number: "444444444",
      address: ADDRESS,
    },
  },
  {
    id: idIfy("bts-18"),
    name: "Scrappy-Doo",
    type: "business",
    ach_destination: null,
    fedwire_destination: {
      institution_name: "bank of jimin",
      account_type: ACCOUNT_TYPE_OPTIONS.PERSONAL_CHECKING,
      account_number: "555555555",
      routing_number: "666666666",
      address: ADDRESS,
    },
  },
];

export const RECIPIENT_WITH_ACH_DESTINATION: API.Recipient = {
  id: idIfy("with-ach-destination"),
  name: "With ACH Destination",
  nickname: "with ach destination",
  type: "business",
  ach_destination: {
    institution_name: "JPMorgan Chase Bank",
    account_number: "123456",
    routing_number: "021000021",
    account_type: "Personal checking",
  },
  fedwire_destination: null,
};

export const RECIPIENT_WITH_PROFILE: API.Recipient = {
  id: idIfy("with-recipient-profile"),
  name: "With Recipient Profile",
  nickname: "with profile",
  type: "business",
  ach_destination: null,
  fedwire_destination: null,
};

export const RECIPIENT_WITH_WIRE_DESTINATION: API.Recipient = {
  id: idIfy("with-ach-destination"),
  name: "With wire Destination",
  nickname: "with wire destination",
  type: "business",
  ach_destination: null,
  fedwire_destination: {
    institution_name: "JPMorgan Chase Bank",
    account_type: "Personal checking",
    account_number: "12345",
    routing_number: "021000021",
    address: {
      street_address: "200 west 20th",
      street_address_2: "",
      country_code: "US",
      city: "new york",
      region_code: "NY",
      postal_code: "10022",
    },
  },
};
