// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".toggleCardContainer--bURrG{margin-bottom:var(--space-l)}.toggleCardContainer--bURrG div.nds-contentCard{height:102px;padding-top:var(--space-l) !important}.toggleCardContainer--bURrG .toggleContainer--uFitu{display:flex;border-bottom:1px solid var(--color-lightGrey);padding-bottom:var(--space-s);justify-content:space-between;font-weight:var(--font-weight-semibold);line-height:20px}.toggleCardContainer--bURrG .toggleDescription--uyovC{padding-top:var(--space-s);font-size:24px}", "",{"version":3,"sources":["webpack://./components/settings/cards/CardManagerDrawer/ManageACard/LockCard/LockCard.module.scss"],"names":[],"mappings":"AAAA,4BACE,4BAAA,CAEA,gDACE,YAAA,CACA,qCAAA,CAGF,oDACE,YAAA,CACA,8CAAA,CACA,6BAAA,CACA,6BAAA,CACA,uCAAA,CACA,gBAAA,CAGF,sDACE,0BAAA,CACA,cAAA","sourcesContent":[".toggleCardContainer {\n  margin-bottom: var(--space-l);\n\n  div:global(.nds-contentCard) {\n    height: 102px;\n    padding-top: var(--space-l) !important;\n  }\n\n  .toggleContainer {\n    display: flex;\n    border-bottom: 1px solid var(--color-lightGrey);\n    padding-bottom: var(--space-s);\n    justify-content: space-between;\n    font-weight: var(--font-weight-semibold);\n    line-height: 20px;\n  }\n\n  .toggleDescription {\n    padding-top: var(--space-s);\n    font-size: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggleCardContainer": "toggleCardContainer--bURrG",
	"toggleContainer": "toggleContainer--uFitu",
	"toggleDescription": "toggleDescription--uyovC"
};
export default ___CSS_LOADER_EXPORT___;
