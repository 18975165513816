/* eslint-disable camelcase */

import { useContext } from "react";
import { useLocalization } from "@fluent/react";
import ReactMarkdown from "react-markdown";
import { Dialog } from "cerulean";
import InstitutionSettingsContext from "../../contexts/InstitutionSettingsContext";

type LimitsAndDisclosuresDialog = {
  isOpen: boolean;
  handleClose: () => void;
};

const LimitsAndDisclosuresDialog = ({
  isOpen,
  handleClose,
}: LimitsAndDisclosuresDialog) => {
  const { l10n } = useLocalization();
  const { ach_payment_disclosure } = useContext(InstitutionSettingsContext);

  return (
    <Dialog
      isOpen={isOpen}
      onUserDismiss={handleClose}
      title={l10n.getString("ach-payment-limits-modal-title")}
    >
      {ach_payment_disclosure ? (
        <div className="fontColor--secondary">
          <ReactMarkdown linkTarget="_blank">
            {ach_payment_disclosure}
          </ReactMarkdown>
        </div>
      ) : null}
    </Dialog>
  );
};

export default LimitsAndDisclosuresDialog;
