type StatusDefinition = { header: API.WireDisplayState; subheader: string };

export const DEFAULT_DEFINITIONS: StatusDefinition[] = [
  { header: "Submitted", subheader: "End user submitted wire" },
  { header: "Rejected", subheader: "Rejected by staff" },
  { header: "Cancelled", subheader: "Rejected by an additional holder" },
];

export const BUSINESS_DEFINITIONS: StatusDefinition[] = [
  ...DEFAULT_DEFINITIONS,
  {
    header: "Pending dual approval",
    subheader: "Additional account holder needs to approve the wire",
  },
  {
    header: "Rejected approval",
    subheader: "Dual approval rejected by the wire",
  },
];
