import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  DetailedHTMLProps,
  HTMLAttributes,
} from "react";
import rafSchd from "raf-schd";
import { characterSize, punctuationSize } from "./characterSizes";

const getContentWidth = (element: HTMLElement) => {
  const { paddingLeft, paddingRight } = getComputedStyle(element);
  const width = element.clientWidth - parseFloat(paddingLeft) - parseFloat(paddingRight);

  return width;
};

const getSmallestContainingWidth = (element: HTMLElement) => {
  let parent = element?.parentElement;
  let smallestWidth = Number.MAX_SAFE_INTEGER;

  while (parent) {
    const width = getContentWidth(parent);
    if (width < smallestWidth) {
      smallestWidth = width;
    }
    parent = parent?.parentElement;
  }

  return smallestWidth;
};

const estimateTextWidthAtFontSize = (text: string, fontSize: number) => {
  const chars = text.split("");
  let width = 0;

  for (let char in chars) {
    const isPunctuation = [",", ".", " "].includes(char);
    const { width: charWidth } = isPunctuation
      ? punctuationSize[fontSize]
      : characterSize[fontSize];

    width += charWidth;
  }

  return width;
};

const calculateMaxFontSize = (parentWidth: number | null, text: string) => {
  if (parentWidth !== null) {
    for (let fontSize = 1; fontSize < 59; fontSize += 1) {
      const nextTextWidth = estimateTextWidthAtFontSize(text, fontSize + 1);
      if (nextTextWidth > parentWidth) {
        return fontSize;
      }
    }
  }

  return 60;
};

interface TextFitProps {
  text: string;
  ariaLive?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>["aria-live"];
}

const TextFit = ({ text, ariaLive }: TextFitProps) => {
  const parentRef = useRef<HTMLDivElement | null>(null);
  const [parentWidth, setParentWidth] = useState<number | null>(null);
  const [fontSize, setFontSize] = useState(60);

  const windowResize = useCallback(
    rafSchd(() => {
      if (parentRef?.current) {
        const width = getSmallestContainingWidth(parentRef.current);
        setParentWidth(width);
      }
    }),
    [parentRef]
  );

  useEffect(() => {
    window.addEventListener("resize", windowResize);

    return () => {
      window.removeEventListener("resize", windowResize);
    };
  }, []);

  useEffect(() => {
    windowResize();
  }, [parentRef]);

  useEffect(() => {
    const newFontSize = calculateMaxFontSize(parentWidth, text);
    setFontSize(newFontSize);
  }, [parentWidth, text]);

  return (
    <div
      ref={parentRef}
      aria-live={ariaLive}
      style={{
        fontSize,
        height: "100%",
        overflow: "hidden",
        transition: "font-size 100ms",
      }}
    >
      {text}
    </div>
  );
};

export default TextFit;
