import { useObjectMemo } from "../../../../hooks";
import { useFeature } from "../../features";
import { PERMISSION, useBusinessPermissions } from "../../permissions";

const WIRE_PERMISSIONS = [PERMISSION.BUSINESS.WRITE.WIRE_TRANSACTION];

export const useWirePermissions = () => {
  const { featureEnabled, featureEquals } = useFeature();

  const showWireTransferOptionInMenu = featureEnabled("wires");
  const fedwireTemplatesEnabled = featureEquals("wire_templates", "fedwire");

  const [hasSuperWiresPermission] = useBusinessPermissions(WIRE_PERMISSIONS);

  const showSendViaTemplateUI =
    fedwireTemplatesEnabled && hasSuperWiresPermission;

  const showManageTemplatesUI =
    fedwireTemplatesEnabled && hasSuperWiresPermission;

  return useObjectMemo({
    showWireTransferOptionInMenu,
    fedwireTemplatesEnabled,
    showSendViaTemplateUI,
    showManageTemplatesUI,
  });
};
