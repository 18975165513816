import { useSelector } from "react-redux";
import _ from "lodash";

import { selectFedwireTemplates, selectOneFedwireTemplate } from "./selectors";

export const useFedwireTemplates = () => {
  return useSelector(selectFedwireTemplates);
};

export const useOneFedwireTemplate = (
  fedwireTemplateId?: API.FedwireTemplateId,
) => {
  return useSelector((state) => {
    return selectOneFedwireTemplate(state, fedwireTemplateId);
  }, _.isEqual);
};
