import { useState, useEffect, memo } from "react";
import { LoadingSkeleton } from "cerulean";
import { entities } from "byzantine";
import Container from "../../Container";
import RecipientScreen from "./RecipientScreen";
import PaymentScreen from "./PaymentScreen";

const ACHPaymentContainer = memo(() => {
  const [currentScreen, setCurrentScreen] = useState("recipient");

  // call all the bootstrap request hooks, specifically `useFetchRecipients` which will populate the store with recipients
  const { loading, load } = entities.recipients.bootstrap.useBootstrap();
  useEffect(() => {
    load();
  }, [load]);

  const renderContent = () => {
    switch (currentScreen) {
      case "recipient": {
        return <RecipientScreen navigateToScreen={setCurrentScreen} />;
      }
      case "payment": {
        return <PaymentScreen navigateToScreen={setCurrentScreen} />;
      }
      default: {
        return <RecipientScreen navigateToScreen={setCurrentScreen} />;
      }
    }
  };

  return (
    <LoadingSkeleton isLoading={loading}>
      <Container>{renderContent()}</Container>
    </LoadingSkeleton>
  );
});

export default ACHPaymentContainer;
