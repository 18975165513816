import * as Yup from "yup";

import { createForm } from "../../../forms";
import finance from "../../../../../finance";

export type WireDestinationFormType = {
  accountNumber: string;
  routingNumber: string;
  address: AddressType;
  "address.streetAddress"?: string;
  "address.streetAddress2"?: string;
  "address.city"?: string;
  "address.regionCode"?: string;
  "address.postalCode"?: string;
};

type AddressType = {
  streetAddress: string;
  streetAddress2?: string;
  city: string;
  regionCode: string;
  postalCode: string;
};

const validationSchema = Yup.object().shape({
  accountNumber: Yup.string().required("Required").max(34),
  routingNumber: Yup.string()
    .required("Required")
    .test(
      "length",
      "Please enter a valid 9-digit routing number.",
      (val) => val.length === 9,
    )
    .test("valid", "Please enter a valid 9-digit routing number.", (val) => {
      const isValid = finance.abaRoutingNumberIsValid(val);
      return isValid;
    }),
  address: Yup.object().shape({
    streetAddress: Yup.string().required("Required").max(35),
    streetAddress2: Yup.string().max(35),
    city: Yup.string().required("Required").max(17),
    regionCode: Yup.string().required("Required"),
    postalCode: Yup.string().required("Required").max(10),
  }),
});

const initialValues: WireDestinationFormType = {
  accountNumber: "",
  routingNumber: "",
  address: {
    streetAddress: "",
    streetAddress2: "",
    city: "",
    regionCode: "",
    postalCode: "",
  },
};

// define form schema and validations
export const WireDestinationForm = createForm({
  initialValues,
  validationSchema,
});
