import { useCallback } from "react";

import { useSelector } from "react-redux";

import { selectInstitution } from "../selectors/institution.selectors";
import { useRequestWithFeedback } from "../../../composites/request/request.hooks";
import { useAppDispatch } from "../utils/useAppDispatch.hook";
import { useObjectMemo } from "../../../hooks";
import { actions } from "../slices/institution.slice";

import type { BootstrapFetchRequest } from "../../bootstrap/bootstrap.types";

export const useInstitution = () => {
  return useSelector(selectInstitution);
};

export const useFetchInstitution = () => {
  const { send, loading, status } =
    useRequestWithFeedback<API.GetInstitutionResponse>();

  const dispatch = useAppDispatch();

  const fetch = useCallback(
    (type: "public" | "private" = "private") => {
      return new Promise<API.Institution | undefined>((resolve) => {
        send({
          action: {
            url: "institution/",
            options: {
              method: "GET",
              disableAuth: type === "public",
            },
          },
          onData: ({ institution }) => {
            dispatch(actions.upsert(institution));
            resolve(institution);
          },
          onError: () => {
            resolve(undefined);
          },
        });
      });
    },
    [dispatch, send],
  );

  const institution = useInstitution();

  return useObjectMemo({
    send: fetch,
    loading,
    error: status === "error",
    hasData: institution !== null,
  }) satisfies BootstrapFetchRequest;
};

export const useInstitutionHelpers = () => {
  const dispatch = useAppDispatch();

  const updateAuthenticatingInstitutionApp = useCallback(
    (app: API.NafApp | null) => {
      dispatch(actions.updateAuthenticatingInstitutionApp(app));
    },
    [dispatch],
  );

  return useObjectMemo({
    updateAuthenticatingInstitutionApp,
  });
};
