import { useMarketingImage } from "byzantine";
import { useState } from "react";

export default function MarketingImageTargetWeb({
  address,
  // onImageLoad is called when the image successfully loads.
  onImageLoad,
  // onImageError is called if the image does not load. Note that this may be part of normal operation,
  // for example if there is no ad to serve.
  onImageError,
  // debugSize is used for demo and testing purposes. This will return with a placeholder image
  // to make testing easier.
  debugSize,
}: {
  address: string;
  onImageLoad?: () => void;
  onImageError?: () => void;
  debugSize?: { width: number; height: number };
}) {
  const [isError, setIsError] = useState(false);

  const { linkUrl, imageUrl, segmintIframeUrl } = useMarketingImage(
    address,
    debugSize,
  );

  if (isError) {
    return null;
  }

  return (
    <>
      <iframe
        src={segmintIframeUrl}
        style={{ width: 0, height: 0, border: 0, position: "absolute" }}
      />
      <a href={linkUrl} target="_blank" rel="noopener noreferrer">
        <img
          src={imageUrl}
          onLoad={onImageLoad}
          onError={() => {
            setIsError(true);
            onImageError?.();
          }}
          // TODO: this may become dynamic in the future
          alt="Promotional image"
        />
      </a>
    </>
  );
}
