import * as Yup from "yup";

import { createForm } from "../../forms";

export type TravelNoteFormFields = {
  endDate: DateString | null;
  startDate: DateString | null;
  travelDestinationCodes: Record<string, string> | null;
};

const MAX_COUNTRIES = 19;

const validationSchema = Yup.object({
  endDate: Yup.string()
    .required("End date required.")
    .nonNullable("End date required."),
  startDate: Yup.string()
    .required("Start date required.")
    .nonNullable("Start date required."),
  travelDestinationCodes: Yup.lazy((val) =>
    Array.isArray(val)
      ? Yup.array()
          .min(1, "Destination required.")
          .max(MAX_COUNTRIES, "Maximum number of countries selected.")
      : Yup.object()
          .nonNullable("Destination required.")
          .test(
            "min",
            "Destination required.",
            (destinations) =>
              !!destinations && Object.keys(destinations).length >= 1,
          )
          .test(
            "max",
            "Maximum number of countries selected.",
            (destinations) => Object.keys(destinations).length <= MAX_COUNTRIES,
          ),
  ),
});

const initialValues: TravelNoteFormFields = {
  endDate: null,
  startDate: null,
  travelDestinationCodes: null,
};

export const createTravelNoteForm = createForm({
  initialValues,
  validationSchema,
});
