import { useCallback } from "react";

import { useSelector } from "react-redux";

import { useAppDispatch } from "../utils";
import { useObjectMemo } from "../../../hooks";

import { actions } from "./slice";
import { selectOneWireRecipient } from "./selectors";

export const useWireRecipients = () => {
  const dispatch = useAppDispatch();
  const upsertOne = useCallback(
    (recipient: API.WireRecipient) => {
      dispatch(actions.upsertOneRecipient(recipient));
    },
    [dispatch],
  );
  return useObjectMemo({
    upsertOne,
  });
};

export const useOneWireRecipient = (recipientId?: API.WireRecipientId) => {
  return useSelector((state) => selectOneWireRecipient(state, recipientId));
};
