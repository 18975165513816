import type { FetchOptions, HeadersOptions } from "./types";

interface FetchResponse<T> {
  data: T;
  status: number;
  statusText: string;
  headers: HeadersOptions;
  message?: string;
}

const convertResponseToFetchResponse = <T>({
  response,
  data,
}: {
  response: Response;
  data: any;
}): FetchResponse<T> => ({
  ...response,
  data: data as T,
  status: response.status,
  statusText: response.statusText,
  headers: Object.fromEntries(Array.from((response.headers as any).entries())),
});

interface EnhancedError<T> extends Error {
  code?: string;
  request?: Request;
  response?: FetchResponse<T>;
  data: any;
  headers: HeadersOptions;
}

interface ConvertToErrorProps {
  data: any;
  response: Response;
}

const convertToError = <T>({
  data,
  response,
}: ConvertToErrorProps): EnhancedError<T> => {
  const error = new Error(
    `Request failed with status code ${response.status}`,
  ) as EnhancedError<T>;

  if (response.status) {
    error.code = `${response.status}`;
  }

  error.response = convertResponseToFetchResponse<T>({ response, data });
  error.data = data;
  error.headers = error.response.headers;

  return error;
};

const parseData = (response: Response) => {
  const contentType = response.headers.get("content-type");

  if (contentType?.indexOf("application/json") !== -1) {
    return response.json().catch(() => null);
  }
  return response.text().catch(() => null);
};

export const REQUESTS_WITHOUT_BODY = ["GET", "HEAD", "OPTIONS"];

const customFetch = async <T>(
  url: string,
  options: FetchOptions,
  params?: Record<string, string>,
): Promise<FetchResponse<T> | EnhancedError<T>> => {
  const isEmptyParams = JSON.stringify(params) === "{}" || !params;
  const delimiter = url.includes("?") ? "&" : "?";
  const response = await fetch(
    `${url}${!isEmptyParams ? `${delimiter}${new URLSearchParams(params)}` : ""}`,
    options,
  );

  const data = await parseData(response);

  if (!response.ok) {
    throw convertToError({
      response,
      data,
    });
  }

  return convertResponseToFetchResponse({ response, data });
};

export default customFetch;
