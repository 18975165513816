import React, { useCallback, useState, useRef, forwardRef, useEffect } from "react";
import type { KeyboardEventHandler, ForwardedRef } from "react";

import { Button } from "@narmi/design_system";

import { Dropdown, DropdownListItem, SearchBar } from "../Dropdown";

interface ListItemProps<T> {
  item: T;
  value: string;
  isIndented: boolean;
  onChange: (itemId: string) => void;
  searchTerm: string;
  closeDropdown: () => void;
  getItemLabel: (item: T) => string;
  getItemValue: (item: T) => string;
}

type ListMap = Map<string, HTMLElement> | null;

interface HighlightProps {
  value: string;
  searchTerm: string;
}

const Highlight = ({ value, searchTerm }: HighlightProps) => {
  if (!searchTerm) {
    return <>{value}</>;
  }

  const regex = new RegExp(`(${searchTerm})`, "gi");
  const parts = value.split(regex);

  return <>{parts.map((part, index) => (regex.test(part) ? <b key={index}>{part}</b> : part))}</>;
};

const ListItem = forwardRef<HTMLDivElement, ListItemProps<any>>(function Item<T>(
  {
    item,
    value,
    isIndented,
    onChange,
    searchTerm,
    closeDropdown,
    getItemLabel,
    getItemValue,
  }: ListItemProps<T>,
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <DropdownListItem
      anyItemSelected={!!value}
      isSelected={value === getItemValue(item)}
      isIndented={isIndented}
      closeDropdown={closeDropdown}
      onClick={() => {
        onChange(getItemValue(item));
      }}
      ariaLabel={`Item: ${getItemLabel(item)}`}
      ref={ref}
      boldSelected
    >
      <Highlight value={getItemLabel(item)} searchTerm={searchTerm} />
    </DropdownListItem>
  );
});

interface SearchSelectorProps<T> {
  field: string;
  value: string;
  items: T[];
  label: string;
  onChange: (itemId: string) => void;
  error?: string;
  filter: (item: T, searchTerm: string) => boolean;
  getItemLabel: (item: T) => string;
  getItemValue: (item: T) => string;
  bottomAction?: { label: string; onClick: () => void };
}

const SearchSelector = <T,>({
  value,
  items,
  label,
  onChange,
  error,
  filter,
  getItemLabel,
  getItemValue,
  bottomAction,
}: SearchSelectorProps<T>) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [indexFocused, setIndexFocused] = useState<number>(-1);
  const listRef = useRef<ListMap>(null);
  const bottomActionRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isDropdownOpen) {
      searchInputRef?.current?.focus();
    }
  }, [isDropdownOpen]);

  const onSearchTermUpdate = (term: string) => {
    setIndexFocused(-1);
    setSearchTerm(term);
  };

  const filteredItems = items.filter((item) => filter(item, searchTerm));

  const getListRefMap = useCallback(() => {
    if (!listRef.current) {
      listRef.current = new Map<string, HTMLElement>();
    }

    return listRef.current;
  }, [listRef]);

  const closeDropdown = () => setIsDropdownOpen(false);

  const getRefNodeByIndex = useCallback(
    (index: number) => {
      const listRefs = getListRefMap();

      if (index >= filteredItems.length && bottomAction) {
        return bottomActionRef.current;
      }

      return listRefs.get(getItemValue(filteredItems[index]));
    },
    [getListRefMap, filteredItems, getItemValue]
  );

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      const list = getListRefMap();
      const lowestIndex = 0;
      const highestIndex = filteredItems.length - (bottomAction ? 0 : 1);

      switch (event.key) {
        case "Enter": {
          event.preventDefault();
          if (filteredItems[indexFocused]) {
            onChange(getItemValue(filteredItems[indexFocused]));
          }
          return;
        }
        case "ArrowDown": {
          if (!isDropdownOpen) {
            setIsDropdownOpen(true);
          }
          setIndexFocused((prevIndexFocused) => {
            event.preventDefault();
            let nextIndex;

            if (prevIndexFocused + 1 > highestIndex) {
              nextIndex = lowestIndex;
            } else {
              nextIndex = prevIndexFocused + 1;
            }
            const listItem = getRefNodeByIndex(nextIndex);
            listItem?.focus();
            return nextIndex;
          });
          return;
        }
        case "ArrowUp": {
          if (!isDropdownOpen) {
            setIsDropdownOpen(true);
          }
          event.preventDefault();
          setIndexFocused((prevIndexFocused) => {
            let nextIndex;

            if (prevIndexFocused - 1 < lowestIndex) {
              nextIndex = highestIndex;
            } else {
              nextIndex = prevIndexFocused - 1;
            }
            const listItem = getRefNodeByIndex(nextIndex);
            listItem?.focus();
            return nextIndex;
          });
          return;
        }
        case "tab": {
          const listItem = list.get(getItemValue(filteredItems[indexFocused]));
          listItem?.blur();

          setIndexFocused(-1);
          break;
        }
        default:
      }
    },
    [
      indexFocused,
      setIndexFocused,
      filteredItems,
      onChange,
      isDropdownOpen,
      getRefNodeByIndex,
      getListRefMap,
      getItemLabel,
      getItemValue,
    ]
  );

  const itemList = filteredItems.map((item) => (
    <ListItem
      key={getItemValue(item)}
      item={item}
      value={value}
      searchTerm={searchTerm}
      isIndented={false}
      closeDropdown={closeDropdown}
      getItemValue={getItemValue}
      onChange={onChange}
      getItemLabel={getItemLabel}
      ref={(node: HTMLDivElement) => {
        const listRefMap = getListRefMap();
        if (node) {
          listRefMap?.set(getItemValue(item), node);
        } else {
          listRefMap?.delete(getItemValue(item));
        }
      }}
    />
  ));

  const selectedItem = items.find((item) => getItemValue(item) === value);

  return (
    <div onKeyDown={onKeyDown} className="searchSelector">
      <Dropdown
        triggerLabel={label}
        triggerValue={selectedItem ? getItemLabel(selectedItem) : ""}
        defaultOpen={false}
        ariaLabel={label}
        keyHandler={() => {}}
        isOpen={isDropdownOpen}
        setIsOpen={setIsDropdownOpen}
        error={error || ""}
      >
        <SearchBar
          searchTerm={searchTerm}
          setSearchTerm={onSearchTermUpdate}
          ref={searchInputRef}
        />
        {itemList}
        {bottomAction && (
          <DropdownListItem
            anyItemSelected={!!value}
            onClick={bottomAction.onClick}
            ariaLabel={bottomAction.label}
            isSelected={false}
            isIndented={false}
            closeDropdown={closeDropdown}
            ref={bottomActionRef}
            boldSelected
          >
            <Button kind="plain" label={bottomAction.label} />
          </DropdownListItem>
        )}
      </Dropdown>
    </div>
  );
};

export default SearchSelector;
