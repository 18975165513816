import { ReactNode, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Filters from "byzantine/src/filters";
import Wire from "byzantine/src/Wire";
import { Tag, formatNumber, useNotificationContext } from "cerulean";

import Account from "byzantine/src/Account";
import SectionCard from "../SectionCard";
import UserFeaturesContext from "../contexts/UserFeaturesContext";
import AccountContext from "../contexts/AccountContext";
import WireStatusesDialog from "./WireStatusesDialog";
import { useCurrentUser } from "../contexts/CurrentUserContext";

interface WireDescriptionProps {
  wire: Wire;
}

const WireDescription = ({ wire }: WireDescriptionProps) => {
  const title = `Wire to ${wire.beneficiary_name}`;

  if (!wire.template_name) {
    return <div>{title}</div>;
  }

  return (
    <div className="alignChild--left--top">
      {title}
      <div className="padding--x--xs">
        <Tag label="Template" kind="outline" />
      </div>
    </div>
  );
};

interface PendingWireTableProps {
  pendingWires: Wire[];
  infoIcon: ReactNode;
}

const PendingWireTable = ({
  pendingWires,
  infoIcon,
}: PendingWireTableProps) => (
  <div className="card-table three sparse">
    <div className="row-wrapper">
      <div className="row-item-header">DESCRIPTION</div>
      <div className="row-item-header">
        STATUS
        {infoIcon}
      </div>
      <div className="row-item-header align-right">AMOUNT</div>
    </div>
    {pendingWires.map((wire) => (
      <div key={wire.id} className="row-wrapper">
        <div className="row-items-column">
          <div className="row-item">
            <WireDescription wire={wire} />
            <div className="font-size-s-medium-grey">
              {Filters.longMonthDayYear(wire.created_at)}
            </div>
          </div>
        </div>
        <div className="row-items-column reverse">
          <div className="row-item responsive-text-styling">
            {wire.display_state}
          </div>
          <div className="row-item align-right number">
            {formatNumber(wire.amount)}
          </div>
        </div>
      </div>
    ))}
  </div>
);

interface PendingWiresCardProps {
  accountUuid: string;
}

interface UserFeatures {
  recent_wires_consumer: boolean;
  recent_wires_business: boolean;
}

const PendingWiresCard = ({ accountUuid }: PendingWiresCardProps) => {
  const { currentUser } = useCurrentUser();
  const features = useContext(UserFeaturesContext) as UserFeatures;
  const { sendNotification } = useNotificationContext();
  const { accounts } = useContext(AccountContext) as { accounts: Account[] };
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pendingWires, setPendingWires] = useState<Wire[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const isValidWireSource = !!accounts
    .filter((a) => a.isInternal() && a.isValidTransferSource())
    .find((a) => a.id === accountUuid);

  if (
    !isValidWireSource ||
    (!currentUser?.isBusiness() && !features.recent_wires_consumer) ||
    (currentUser?.isBusiness() && !features.recent_wires_business)
  ) {
    return null;
  }

  const infoIcon = (className: string) => (
    <span
      className={`${className} narmi-icon-info clickable`}
      onClick={() => setIsDialogOpen(true)}
      onKeyUp={({ key }) => {
        if (key === "Enter") setIsDialogOpen(true);
      }}
      aria-label="Open statuses dialog"
      role="button"
      tabIndex={0}
    />
  );

  useEffect(() => {
    const getPendingWires = async () => {
      try {
        setIsLoading(true);
        setPendingWires(await Wire.getPendingWires(accountUuid));
      } catch {
        sendNotification({
          type: "negative",
          text: "Error fetching your pending wires. Please contact support.",
        });
      } finally {
        setIsLoading(false);
      }
    };

    getPendingWires();
  }, []);

  return (
    <SectionCard
      isLoading={isLoading}
      isExpanded={isCardVisible}
      setIsExpanded={setIsCardVisible}
      kind={isCardVisible ? "transactions" : undefined}
      title="Recent wires"
      titleIcon={infoIcon("pending-wires-info-icon")}
    >
      {pendingWires?.length > 0 && (
        <>
          <PendingWireTable
            pendingWires={pendingWires}
            infoIcon={infoIcon("margin--left--xxs")}
          />
          <WireStatusesDialog
            isDialogOpen={isDialogOpen}
            closeDialog={() => setIsDialogOpen(false)}
          />
        </>
      )}
    </SectionCard>
  );
};
PendingWiresCard.propTypes = {
  accountUuid: PropTypes.string.isRequired,
};

export default PendingWiresCard;
