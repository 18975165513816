import { ReactNode } from "react";

import { catcat as cc } from "cerulean";
import styles from "./ActionBar.module.scss";

interface ActionBarProps {
  children: ReactNode;
}

const ActionBar = ({ children }: ActionBarProps) => (
  <div className={cc(styles.actionBar, "margin--top--xl")}>{children}</div>
);

export default ActionBar;
