import { useMemo } from "react";
import { useLocalization } from "@fluent/react";

import { en, getCountryName } from "byzantine/src/l10n/Country";
import { getSelectedLocale } from "byzantine/src/l10n/InstanceHelpers";

import { MultiSelect } from "cerulean";
import filters from "byzantine/src/filters";
import { useInstitutionSettings } from "../../../contexts/InstitutionSettingsContext";

interface MultiCountryPickerProps {
  label: string;
  onChange?: CallableFunction;
  value?: string[];
  error?: string;
}

const MultiCountryPicker = ({
  label,
  onChange,
  value,
  error,
}: MultiCountryPickerProps) => {
  const { l10n } = useLocalization();
  const currentLocale = getSelectedLocale(l10n);
  const { travel_notes_excluded_countries: excludedCountries = [] } =
    useInstitutionSettings();

  const validCountries = useMemo(() => {
    const allCountries = en;
    return Object.entries(allCountries)
      .filter(([code]) => !excludedCountries.includes(code))
      .map(([code]) => ({
        code,
        name: getCountryName(code, currentLocale),
        flag: filters.getFlagEmoji(code),
      }));
  }, [excludedCountries.length]);

  return (
    <MultiSelect
      label={label}
      name="country-picker"
      onSelectedItemsChange={onChange}
      fieldValue={value}
      errorText={error}
    >
      {validCountries.map(({ code, name, flag }) => (
        <MultiSelect.Item key={code} value={code} tokenLabel={name}>
          <span className="padding--right--xs">{flag}</span>
          {name}
        </MultiSelect.Item>
      ))}
    </MultiSelect>
  );
};

export default MultiCountryPicker;
