import { useCallback, useState } from "react";

import { modules, useObjectMemo } from "byzantine";
import { useNavigate } from "react-router-dom";
import ApiHttp from "byzantine/src/ApiHttp";

type UIState = "form" | "2FA" | "review";

export const useSendViaTemplate = () => {
  const { onContinue } = modules.wireTemplates.useSubmitSendViaTemplateForm();
  const { createWireViaTemplate: onSubmit, loading } =
    modules.wireTemplates.useCreateWireViaTemplate();
  const [uiState, setUiState] = useState<UIState>("form");
  const navigate = useNavigate();

  const submitReview = useCallback(
    (idempotencyKey: UUID) => {
      onSubmit(idempotencyKey, {
        onSuccess: () => navigate("/"),
        onError: () => setUiState("form"),
      });
    },
    [navigate, onSubmit]
  );

  const submitForm = useCallback(async () => {
    const next = () => setUiState("review");
    const trySudo = () =>
      ApiHttp.fetch("sudo")
        .then(() => setUiState("review"))
        .catch(() => setUiState("2FA"));
    onContinue(next, trySudo);
  }, [onContinue]);

  const complete2FA = useCallback(() => {
    setUiState("review");
  }, []);

  const resetFlow = useCallback(() => {
    setUiState("form");
  }, []);

  const cancel = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return useObjectMemo({
    uiState,
    submitForm,
    complete2FA,
    submitReview,
    resetFlow,
    loading,
    cancel,
  });
};
