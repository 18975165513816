// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cardImageContainer--dOS4Q{margin-bottom:28px}.bannerInfo--WR0Ig{margin-bottom:var(--space-l)}.bannerInfo--WR0Ig div.nds-alert{padding:var(--space-s) !important;font-size:var(--font-size-s)}", "",{"version":3,"sources":["webpack://./components/settings/cards/CardManagerDrawer/ManageACard/ManageACard.module.scss"],"names":[],"mappings":"AAAA,2BACE,kBAAA,CAGF,mBACE,4BAAA,CAEA,iCACE,iCAAA,CAGA,4BAAA","sourcesContent":[".cardImageContainer {\n  margin-bottom: 28px;\n}\n\n.bannerInfo {\n  margin-bottom: var(--space-l);\n\n  div:global(.nds-alert) {\n    padding: var(\n      --space-s\n    ) !important; // padding-all on the Alert is aggressive and uses !important\n    font-size: var(--font-size-s);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardImageContainer": "cardImageContainer--dOS4Q",
	"bannerInfo": "bannerInfo--WR0Ig"
};
export default ___CSS_LOADER_EXPORT___;
