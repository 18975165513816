import { useContext } from "react";

import { contextMap } from "./library.context";

import type { LibraryValues } from "./library.types";

type ContextMap = typeof contextMap;
type LibraryNames = keyof ContextMap;

const ambiguouslyTypedContextMap = contextMap as Record<
  LibraryNames,
  React.Context<unknown>
>;

export function useLibrary<T extends LibraryNames>(libraryName: T) {
  const context = ambiguouslyTypedContextMap[libraryName];
  const library = useContext(context);
  if (!library) {
    throw new Error(
      `Context error: ${libraryName} is undefined. You likely forgot to pass the interface implementation to <LibraryProvider/>.`,
    );
  }
  return library as Required<LibraryValues>[T];
}
