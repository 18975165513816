import type { PropsWithChildren } from "react";
import React, { memo } from "react";

import { LibraryProvider } from "byzantine";

import webPlatform from "./platform.implementation";
import webNetwork from "./network.implementation";
import useWebToasts from "./toasts.implmentation";

import type { PreloadedState } from "byzantine";

type Props = {
  preloadedState: PreloadedState;
};

const DBBLLibraryProvider = ({
  preloadedState,
  children,
}: PropsWithChildren<Props>) => {
  const toasts = useWebToasts();
  return (
    <LibraryProvider
      platform={webPlatform}
      network={webNetwork}
      toasts={toasts}
      preloadedState={preloadedState}
    >
      {children}
    </LibraryProvider>
  );
};

export default memo(DBBLLibraryProvider);
