import { useCallback } from "react";

import { Navigate, useNavigate } from "react-router-dom";
import { Button } from "cerulean";
import { modules } from "byzantine";

import Template from "./Template";
import BackButton from "./BackButton";
import TemplateDrawer from "./TemplateDrawer";
import useIsTemplateDrawerRoute from "./TemplateDrawer/useIsTemplateDrawerRoute";
import styles from "./TemplatesList.module.scss";

const TemplatesList = () => {
  const { templatesWithRecipients } = modules.wireTemplates.useData();
  const navigate = useNavigate();
  const isDrawerRoute = useIsTemplateDrawerRoute();
  const { showManageTemplatesUI } = modules.wireTemplates.useWirePermissions();

  const goBack = useCallback(() => {
    navigate("/wires");
  }, [navigate]);

  if (!showManageTemplatesUI) {
    return <Navigate to={"/"} replace />;
  }

  return (
    <div className="content-container">
      <div className="narmi-grid-container">
        <div className="narmi-grid-content">
          <div className={styles.backButtonContainer}>
            <BackButton text="Back to wires" onClick={goBack} />
          </div>
          <h1 className={styles.header}>Templates</h1>
          <div className={styles.templateList}>
            {templatesWithRecipients.length === 0 && (
              <div>You don&lsquo;t have any templates yet.</div>
            )}
            {templatesWithRecipients.map(({ template, recipient }) => (
              <Template
                key={template.id}
                template={template}
                recipient={recipient}
              />
            ))}
          </div>
          <Button
            kind="primary"
            onClick={() => {
              navigate("new");
            }}
          >
            + New template
          </Button>
          <TemplateDrawer isOpen={isDrawerRoute} />
        </div>
      </div>
    </div>
  );
};

export default TemplatesList;
