import { useLocation, useNavigate } from "react-router-dom";
import { SearchSelector } from "cerulean";

interface RecipientSelectorProps {
  field: string;
  value: string;
  recipients: API.WireRecipient[];
  label: string;
  onChange: (recipientId: API.WireRecipientId) => void;
  error?: string;
}

const RecipientSelector = ({
  value,
  recipients,
  label,
  onChange,
  error,
}: RecipientSelectorProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <SearchSelector<API.WireRecipient>
      value={value}
      items={recipients}
      label={label}
      onChange={onChange}
      filter={(recipient: API.WireRecipient, searchTerm: string) =>
        recipient.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
      }
      getItemLabel={(recipient: API.WireRecipient) => recipient.name}
      getItemValue={(recipient: API.WireRecipient) => recipient.id}
      bottomAction={{
        label: "+ Add a new recipient",
        onClick: () => {
          navigate("/wires/templates/recipients/new", {
            state: { from: location.pathname },
          });
        },
      }}
      error={error}
    />
  );
};

export default RecipientSelector;
