/* eslint-disable @typescript-eslint/no-unsafe-declaration-merging */
import Filters from "./filters";

import type Account from "./Account";

declare global {
  namespace API {
    type AccountBalanceId = Brand<`abl_${string}`, "AccountBalanceId">;

    type AccountBalances = {
      id: AccountBalanceId;
      account_id: API.AccountId;
      primary: Cents;
      available: Cents;
      ledger: Cents;
      updated_at: Timestamp;
    };
  }
}

type DeserializedBalances = {
  primary: string;
  available: string;
  ledger: string;
  raw: API.AccountBalances;
} & Pick<API.AccountBalances, "id" | "account_id" | "updated_at">;

interface Balances extends DeserializedBalances {}

class Balances {
  constructor(props: Partial<DeserializedBalances>) {
    Object.assign(this, props);
  }

  updateAccount(account: Account) {
    account.updateBalances(this);
  }

  displayAvailableBalance() {
    return {
      title: "Available balance",
      value: this.available,
      description:
        "Your immediately available funds to use, including any pending transactions or temporary holds on your account.",
    };
  }

  displayCurrentBalance() {
    return {
      title: "Current balance",
      value: this.ledger,
      description:
        "The total amount of funds in your account, not including temporary holds or pending transactions.",
    };
  }

  displayBreakdownData() {
    return [
      {
        type: "available",
        ...this.displayAvailableBalance(),
      },
      {
        type: "current",
        ...this.displayCurrentBalance(),
      },
    ];
  }

  displayBreakdown() {
    return [this.displayAvailableBalance(), this.displayCurrentBalance()];
  }

  static deserialize(payload: API.AccountBalances) {
    return new Balances({
      ...payload,
      primary: Filters.currency(payload?.primary),
      ledger: Filters.currency(payload?.ledger),
      available: Filters.currency(payload?.available),
      raw: payload,
    });
  }
}

export default Balances;
