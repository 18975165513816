import { useState, useEffect } from "react";
import { useLocalization } from "@fluent/react";
import { entities } from "byzantine";
import { TextInput, RadioButtons } from "cerulean";
import {
  CollapsibleCard,
  ResponsiveFlex,
  Button,
  Row,
  Tooltip,
} from "@narmi/design_system";
import styles from "./AddRecipientDrawer.module.scss";
import { DRAWER_TYPES, SectionProps } from "./RecipientDrawer";
import * as formAdapters from "../../../../src/adapters";

const ProfileSection = ({
  isOpen,
  isDisabled,
  onClose,
  onOpen,
  onSave,
  recipientId,
  drawerType,
}: SectionProps) => {
  const { l10n } = useLocalization();
  const [savedProfile, setSavedProfile] = useState(false);
  const { form, handleSubmit, error } =
    entities.recipients.useRecipientProfileForm(recipientId);
  const radioOptions = entities.recipients.useProfilePurposeOptions();

  // reset the form every time user opens the drawer in case there are old values
  useEffect(() => {
    if (!recipientId) {
      form.handleReset(form.initialValues);
    }
  }, []);

  const onSuccess = () => {
    // only if submit is successful then we allow user to "close" the card
    setSavedProfile(true);
    onSave();
  };

  const handleClickSave = () => {
    handleSubmit(onSuccess);
  };

  const handleClose = () => {
    // if user opens add drawer, only allow user to toggle profile section if they have saved profile section
    if (drawerType === DRAWER_TYPES.add) {
      savedProfile && onClose();
    } else {
      onClose();
    }
  };

  return (
    <CollapsibleCard
      onClose={handleClose}
      onOpen={onOpen}
      title="Profile"
      isDisabled={isDisabled}
      isOpen={isOpen}
      trigger="caret-end"
    >
      <ResponsiveFlex gapSize="s">
        {error ? (
          <div className="message-content negative">
            <span className="narmi-icon-info margin--right--m" />
            An error occured. Please try again.
          </div>
        ) : null}
        <div className={styles.radioButtonContainer}>
          <RadioButtons
            kind="row"
            name="type"
            options={radioOptions}
            {...formAdapters.radio(form, "type")}
          />
        </div>
        <TextInput
          label="Recipient name"
          {...formAdapters.input(form, "name")}
        />
        <TextInput
          label="Nickname (optional)"
          endContent={
            <Tooltip
              text={l10n.getString("add-recipient-drawer-nickname-tooltip")}
            >
              <i className="narmi-icon-info" />
            </Tooltip>
          }
          {...formAdapters.input(form, "nickname")}
        />
        <Row justifyContent="end">
          <Row.Item shrink>
            <Button kind="primary" onClick={handleClickSave}>
              {l10n.getString("button-save")}
            </Button>
          </Row.Item>
        </Row>
      </ResponsiveFlex>
    </CollapsibleCard>
  );
};

export default ProfileSection;
