/* eslint-disable no-param-reassign */
import { createSlice, nanoid } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

type LimitsSlice = {
  isFetching: boolean;
  lastUpdated: null | number;
  data: API.Limits.ByType;
};

const initialState: LimitsSlice = {
  isFetching: false,
  lastUpdated: null,
  data: {},
};

const name = "limits";

const slice = createSlice({
  name,
  initialState,
  reducers: {
    request: (state) => {
      state.isFetching = true;
    },
    fail: (state) => {
      state.isFetching = false;
    },
    receive: {
      prepare: (data: API.Limits.Body) => ({
        payload: {
          id: nanoid(),
          limits: data.limits,
          receivedAt: Date.now(),
        },
      }),
      reducer: (
        state,
        action: PayloadAction<{
          limits: API.Limits.ByType;
          receivedAt: number;
        }>,
      ) => {
        const { limits, receivedAt } = action.payload;
        state.isFetching = false;
        state.lastUpdated = receivedAt;
        Object.assign(state.data, limits);
      },
    },
  },
});

export const { actions, reducer } = slice;

export default {
  [name]: slice.reducer,
};
