import React, { memo, useMemo, type PropsWithChildren } from "react";
import { createContext } from "react";

import _ from "lodash";

export const DEFAULT_CONFIG = {
  network: {
    cache: {
      ttlMilliseconds: 5 * 1000,
    },
  },
} as const;

type IConfig = typeof DEFAULT_CONFIG;

export const ConfigContext = createContext<IConfig | undefined>(undefined);

export type ConfigProps = {
  config?: RecursivePartial<IConfig>;
};

export type ConfigValues = {
  config: IConfig;
};

const ContextProvider = ({
  config = {},
  children,
}: PropsWithChildren<ConfigProps>) => {
  const value = useMemo(
    () => _.merge(DEFAULT_CONFIG, config),
    [config],
  ) as IConfig;
  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};

export default memo(ContextProvider);
