import { useCallback } from "react";

import { useObjectMemo } from "../../../hooks";
import { useFeature } from "../features";

import { ACHPaymentForm } from "./ACHPayment.form";
import { useCreateACHPayment } from "./requests";

export const useSECCodes = () => {
  const { features } = useFeature();
  return features.allowed_std_ent_cls_codes || [];
};

export const useACHPaymentForm = () => {
  const { createACHPayment } = useCreateACHPayment();
  const form = ACHPaymentForm.useForm();
  const { submitForm } = form;

  const handleSubmit = useCallback(
    async (onSuccess?: () => void) => {
      // validate form
      const result = await submitForm();
      if (result.success) {
        // passing in `onSuccess` does some magic in createACHProfile's `send`
        // `send` will call `onSuccess`
        createACHPayment({ onSuccess });
      }
    },
    [submitForm, createACHPayment],
  );

  return useObjectMemo({
    form,
    handleSubmit,
  });
};
