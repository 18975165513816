import App from "byzantine/src/App";
import SectionCard from "../SectionCard";
import AppAuthorize from "../AppAuthorize";

interface ServiceItemProps {
  service: App;
}

const ServiceItem = ({ service }: ServiceItemProps) => (
  <div className="dashboard-item-border-box">
    <div className="brand-hover">
      <AppAuthorize app={service} dataClassnames="services-button">
        <div className="dashboard-item">
          <div className="dashboard-item-subsection dashboard-item-icon-section">
            <span className={service.getNdsIconClassname()}></span>
          </div>
          <div className="dashboard-item-subsection dashboard-item-text-section">
            <div className="dashboard-item-name">{service.name}</div>
            <div className="dashboard-item-description">
              {service.description}
            </div>
          </div>
        </div>
      </AppAuthorize>
    </div>
  </div>
);

interface ServicesCardProps {
  services: App[];
}

const ServicesCard = ({ services }: ServicesCardProps) => {
  if (services.length < 1) return null;
  return (
    <SectionCard isLoading={false} classNames="dashboard-section-card">
      <SectionCard.Title text="Services" className="services-title" />
      <div className="dashboard-section-container">
        {services.map((service) => (
          <ServiceItem key={service.id} service={service} />
        ))}
      </div>
    </SectionCard>
  );
};

export default ServicesCard;
