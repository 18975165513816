import _ from "lodash";

import type { InstitutionFeatures } from "./types";

export type Query = string | string[];
export type FeatureValue = boolean | null | string | object;
export const { isEmpty } = _;
export type { InstitutionFeatures as Features };

export type FeaturesProps = {
  features: InstitutionFeatures | undefined;
  fallback?: JSX.Element;
  flag?: string;
  equals?: string;
  or?: string[];
  and?: string[];
  not?: string | string[];
  children: JSX.Element;
};

// return true if query in features, false otherwise
export const featureEnabled = (
  features: InstitutionFeatures,
  where: { or?: Query; and?: Query; not?: Query },
): boolean => {
  const { or, and, not } = where;
  // first check if we have a disable condition
  const notValue = (not_: Query): boolean => {
    if (Array.isArray(not_)) {
      return not_.some((q) => featureEquals(features, q));
    }
    return featureEquals(features, not_);
  };
  if (not && notValue(not)) return false;

  const andValue = (and_: Query): boolean => {
    if (Array.isArray(and_)) {
      return and_.every((q) => featureEquals(features, q));
    }
    return featureEquals(features, and_);
  };
  // optionally enforce a combination of features
  if (and && !andValue(and)) return false;

  // if "and" or "not" was supplied and "or" was not, return true since there's nothing to check for "or"
  if ((and || not) && (!or || !or.length)) return true;

  // check our "or" clause
  const orValue = (or_: Query): boolean => {
    if (Array.isArray(or_)) {
      return or_.some((q) => featureEquals(features, q));
    }
    return featureEquals(features, or_);
  };
  return !!(or && orValue(or));
};

export const featureEquals = (
  features: InstitutionFeatures,
  flag: string,
  setting?: FeatureValue | FeatureValue[],
): boolean => {
  if (flag === undefined) return false;

  // if flag is not in features
  if (!Object.hasOwnProperty.call(features, flag)) return false;

  // @ts-expect-error features might have flags this client is unaware of
  const feature = features[flag] as FeatureValue;
  const isObject = typeof feature === "object";

  // feature is in object but is undefined (this shouldn't happen)
  if (feature === undefined) return false;

  // setting is not provided, return false if feature is null, false, [], "", {}
  // this matches the truthiness rules of python
  if (setting === undefined)
    return !(
      [null, false, "", {}].includes(feature) ||
      (Array.isArray(feature) && feature.length === 0) ||
      (isObject && _.isEmpty(feature))
    );

  // setting is an array, check if any of the values in the array match
  if (Array.isArray(setting))
    return setting.some((s) => featureEquals(features, flag, s));

  // feature is an array, check if setting is in array
  if (Array.isArray(feature)) return feature.includes(setting);

  return feature === setting;
};

export default function Feature(props: FeaturesProps): JSX.Element | null {
  if (!props.features) return null;
  const or = props.or || [];
  if (props.flag) or.push(props.flag);
  let feature = featureEnabled(props.features, {
    or,
    and: props.and,
    not: props.not,
  });
  if (props.flag && props.equals) {
    // handle equals property
    if (!featureEquals(props.features, props.flag, props.equals)) {
      feature = false;
    }
  }

  if (!feature && !props.fallback) {
    return null;
  }
  if (!feature && props.fallback) {
    return props.fallback;
  }
  return props.children;
}
