import PropTypes from "prop-types";
import { Row } from "cerulean";

interface LoanReviewRowProps {
  label: string;
  content?: string;
}

const LoanReviewRow = ({ label, content }: LoanReviewRowProps) => {
  if ([null, undefined, ""].includes(content)) return null;
  return (
    <div className="loan-review-row">
      <Row alignItems="center">
        <Row.Item shrink>
          <span className="attribute-label">{label}</span>
        </Row.Item>
        <Row.Item>
          <div className="attribute-content">{content}</div>
        </Row.Item>
      </Row>
    </div>
  );
};
LoanReviewRow.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.string,
};

export default LoanReviewRow;
