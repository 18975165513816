import { ContentCard } from "cerulean";

import { useCurrentUser } from "../../../contexts/CurrentUserContext";
import AddressSettings from "./Address/AddressSettings";

const BusinessInfoCard = () => {
  const { currentUser } = useCurrentUser();
  const primaryAddress = currentUser?.getPrimaryAddress();
  const mailingAddress = currentUser?.getMailingAddress();

  // we can update this when we add additional fields to this section
  // currently, we should hide it if there are no addresses
  if (!primaryAddress && !mailingAddress) {
    return null;
  }

  return (
    <ContentCard>
      <h4 className="contact-info-heading nds-sans fontSize--heading5">
        Business info
      </h4>
      <AddressSettings />
    </ContentCard>
  );
};

export default BusinessInfoCard;