import Wire from "byzantine/src/Wire";
import { Dialog } from "cerulean";
import { useCurrentUser } from "../contexts/CurrentUserContext";

interface WireStatusesDialogProps {
  isDialogOpen: boolean;
  closeDialog: () => void;
}
const WireStatusesDialog = ({
  isDialogOpen,
  closeDialog,
}: WireStatusesDialogProps) => {
  const { currentUser } = useCurrentUser();
  const wireStatuses = currentUser?.isBusiness()
    ? Wire.getBusinessPendingStatuses()
    : Wire.getConsumerPendingStatuses();

  return (
    <Dialog
      isOpen={isDialogOpen}
      onUserDismiss={closeDialog}
      title="Wire statuses"
    >
      <div className="margin--top--s">
        {wireStatuses.map(([status, meaning]) => (
          <div key={status} className="margin--bottom--l">
            <div className="attribute-label">{status}</div>
            <div>{meaning}</div>
          </div>
        ))}
      </div>
    </Dialog>
  );
};

export default WireStatusesDialog;
