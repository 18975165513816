import { useCallback } from "react";

import { useDispatch } from "react-redux";

import { useObjectMemo } from "../../../hooks";
import { useManyRequestsWithFeedback } from "../../../composites/request/request.hooks";
import { notNull } from "../../../composites/request/request.helpers";

import { API } from "./api";
import { actions as limitsActions } from "./slice";

export function useFetchMultipleLimitsRequest(
  limitTypes: API.Limits.Type[] = [],
) {
  const { sendAll, loading } = useManyRequestsWithFeedback<API.Limits.Body>(
    limitTypes.length,
  );

  const dispatch = useDispatch();

  const fetchMultiple = useCallback(() => {
    const actions = limitTypes.map((type) => API.fetch(type));
    const onData = (data: Array<API.Limits.Body | null>) => {
      data.filter(notNull).forEach((d) => {
        dispatch(limitsActions.receive(d));
      });
    };
    sendAll({
      actions,
      onData,
    });
  }, [dispatch, limitTypes, sendAll]);

  return useObjectMemo({ loading, sendAll: fetchMultiple });
}
