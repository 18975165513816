import { TextInput } from "@narmi/design_system";
import {
  StateSelector,
  ZipCodeTextInput,
  AutocompleteStreetAddress,
  ContextForm,
} from "cerulean";
import Address from "byzantine/src/Address";

interface BankingUSAddressFieldProps {
  addressType?: string;
  onUpdate?: (val: Partial<Address>) => void;
  data: any;
  errors?: any;
  title?: string;
  showTitle?: boolean;
  streetAddressLabel?: string;
  apartmentLabel?: string;
  cityLabel?: string;
  stateLabel?: string;
  zipCodeLabel?: string;
  marginTop?: string;
  marginBottom?: string;
  field?: string;
}

const BankingUSAddressField = ({
  addressType,
  onUpdate,
  data,
  errors = {},
  title,
  showTitle = true,
  streetAddressLabel = "Street Address",
  apartmentLabel = "Apt/Fl/Suite",
  cityLabel = "City",
  stateLabel = "State",
  zipCodeLabel = "Zip code",
  marginTop = "margin--top--l",
  marginBottom = "margin--bottom--l",
}: BankingUSAddressFieldProps) => {
  const isAutocompleteApiAvailable = !!(window as any).google?.maps;
  return (
    <>
      {addressType && showTitle && (
        <div
          className="margin--top--xl fontWeight--bold"
          style={{ color: "RGB(var(--nds-black))" }}
        >
          {title || addressType}
        </div>
      )}
      <div className={`banking-address-grid ${marginTop} ${marginBottom}`}>
        <ContextForm.Field required style={{ flex: 2, marginBottom: 0 }}>
          {isAutocompleteApiAvailable ? (
            <AutocompleteStreetAddress
              value={data.street_address || ""}
              address_type={addressType}
              onUpdate={onUpdate}
              error={errors.street_address}
              streetAddressLabel={streetAddressLabel}
              field="street_address"
            />
          ) : (
            <TextInput
              label={streetAddressLabel}
              value={data.street_address || ""}
              error={errors.street_address}
              field="street_address"
            />
          )}
        </ContextForm.Field>
        <div>
          <ContextForm.Field style={{ flex: 1, marginBottom: 0 }}>
            <TextInput
              label={apartmentLabel}
              value={data.street_address_2}
              error={errors.street_address_2}
              field="street_address_2"
            />
          </ContextForm.Field>
        </div>
      </div>
      <ContextForm.Field required>
        <TextInput
          label={cityLabel}
          value={data.city}
          error={errors.city}
          field="city"
        />
      </ContextForm.Field>
      <div className={`banking-address-grid ${marginTop} ${marginBottom}`}>
        <ContextForm.Field required style={{ flex: 1, marginBottom: 0 }}>
          <StateSelector
            label={stateLabel}
            value={data.region_code}
            error={errors.region_code}
            field="region_code"
          />
        </ContextForm.Field>
        <ContextForm.Field required style={{ flex: 2, marginBottom: 0 }}>
          <ZipCodeTextInput
            label={zipCodeLabel}
            value={data.postal_code}
            error={errors.postal_code}
            field="postal_code"
          />
        </ContextForm.Field>
      </div>
    </>
  );
};

export default BankingUSAddressField;
