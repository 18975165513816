/* eslint-disable no-param-reassign */
/* eslint-disable no-throw-literal */
// TODO grab this from "react-native-quick-crypto" for mobile and "crypto" for web
import { Buffer } from "buffer";

import createHmac from "create-hmac";
import queryString from "query-string";

import Filters from "../filters";
import Utils from "../utils";

import customFetch, { REQUESTS_WITHOUT_BODY } from "./customFetch";
import {
  isApplicationResponse,
  isApplicationsResponse,
  isRawResponse,
} from "./types";

import type { Dollars, Cents } from "../shared";
import type {
  FetchOptions,
  HttpClient,
  HttpMethod,
  RequestResponse,
  HeadersOptions,
  ResponseApplication,
  Applicant,
  Application,
  Product,
} from "./types";

// use v1 of the api
export const API_VERSION = "/v1/";

export class OtsTokenError extends Error {
  constructor(message: string) {
    super(message);
    this.message = message;
    this.name = "OtsTokenError";
  }
}

const DEFAULT_HEADERS: HeadersOptions = {
  Pragma: "no-cache",
  "Cache-Control": "no-store, no-cache",
  Accept: "application/json",
  "Content-Type": "application/json",
  "Accept-Language": "en",
};

const DEFAULT_MAX_ATTEMPTS = 3;
const EPSILON_SECONDS = 60;

const buildAuthHeaders = (token?: string, secret?: string) => {
  if (!token) {
    return {};
  }

  const authHeaders: HeadersOptions = {
    Authorization: `Bearer ${token}`,
  };

  if (secret) {
    const created = String(Math.floor(Date.now() / 1000));
    const signature = createHmac("sha256", secret)
      .update(`created: ${created}`, "utf8")
      .digest("base64");
    authHeaders.Signature = `keyId="${token}",algorithm="hmac-sha256",signature="${signature}",headers="created"`;
    authHeaders.created = created;
  }

  return authHeaders;
};

const getDefaultApiUrl = () => {
  let REACT_APP_PUBLIC_API_URL;
  let NEXT_PUBLIC_PUBLIC_API_URL;

  try {
    // eslint-disable-next-line prefer-destructuring
    REACT_APP_PUBLIC_API_URL = process.env.REACT_APP_PUBLIC_API_URL;
    // eslint-disable-next-line prefer-destructuring
    NEXT_PUBLIC_PUBLIC_API_URL = process.env.NEXT_PUBLIC_PUBLIC_API_URL;
  } catch {
    // if process is not defined, we just want to prefix with API_VERSION
    return "";
  }
  let defaultApiUrl = "";

  if (REACT_APP_PUBLIC_API_URL) {
    defaultApiUrl = REACT_APP_PUBLIC_API_URL;
  } else if (NEXT_PUBLIC_PUBLIC_API_URL) {
    defaultApiUrl = NEXT_PUBLIC_PUBLIC_API_URL;
  }
  // we had accidentally set REACT_APP_PUBLIC_API_URL to include '/v1/' in
  // the past but then switched to letting the client manage which version
  // of the api it supports (also eat trailing '/' if present)
  return defaultApiUrl.replace(/(\/v1)?\/?$/, "");
};

interface GetCsrfTokenOptions {
  csrfToken?: string;
  headers: HeadersOptions;
  endpoint: string;
  payload: any;
  method: HttpMethod;
  apiUrl: string;
}

class ApiHttpClass {
  static csrfToken: string | null = null;

  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  static isJWTExpired(token: string | null) {
    // not using atob here as that's browser only
    const payloadBase64 = token?.split(".")[1];
    if (!payloadBase64) {
      return false;
    }
    const decodedJson = Buffer.from(payloadBase64, "base64").toString();
    const decoded = JSON.parse(decodedJson);
    const { exp } = decoded;
    if (!exp) {
      return false;
    }
    const expired = Date.now() >= (exp - EPSILON_SECONDS) * 1000;
    return expired;
  }

  getCsrfToken({
    csrfToken,
    headers,
    endpoint,
    payload,
    method,
    apiUrl,
  }: GetCsrfTokenOptions): Promise<string | null | void> {
    let getCsrf: Promise<string | null | void> = new Promise((resolve) => {
      resolve();
    });

    if (ApiHttpClass.isJWTExpired(ApiHttpClass.csrfToken)) {
      ApiHttpClass.csrfToken = null;
    }

    if (method.toUpperCase() === "GET") {
      // if doing a get, build our querystring if not already specified
      if (!/\?/.test(endpoint)) {
        endpoint += `?${queryString.stringify(payload)}`; // eslint-disable-line no-param-reassign
      }
    } else if (ApiHttpClass.csrfToken) {
      getCsrf = Promise.resolve(ApiHttpClass.csrfToken);
    } else if (csrfToken) {
      getCsrf = Promise.resolve(csrfToken);
    } else {
      getCsrf = (async () => {
        const response = await this.httpClient.request({
          url: `${apiUrl}${API_VERSION}csrf`,
          method: "GET",
          headers,
          withCredentials: true,
        });
        const token = response?.headers?.["x-csrftoken"];

        if (token) {
          ApiHttpClass.csrfToken = token;
          return token;
        }
        ApiHttpClass.csrfToken = null;
        throw "No csrf token from server";
      })();
    }

    return getCsrf;
  }

  async fetch<T = any>(
    endpoint: string,
    options: FetchOptions = {},
    payload = {},
    attempts: number = DEFAULT_MAX_ATTEMPTS,
  ): Promise<T> {
    const method = options.method || "GET";
    const apiUrl = options.apiUrl || getDefaultApiUrl();
    const params = options.params || {};
    const endpointIsUrl = Boolean(options.endpointIsUrl);

    const headers = {
      ...DEFAULT_HEADERS,
      ...buildAuthHeaders(options.token, options.secret),
      ...(options.headers || {}),
    };

    const url = endpointIsUrl ? endpoint : `${apiUrl}${API_VERSION}${endpoint}`;

    try {
      const csrfToken = await this.getCsrfToken({
        csrfToken: options.csrfToken,
        headers,
        endpoint,
        payload,
        method,
        apiUrl,
      });

      if (csrfToken) {
        headers["x-csrftoken"] = csrfToken;
      }

      const data = ApiHttpClass.serialize(
        payload,
        options.shouldSkipSerializeAmount,
      );

      const response: RequestResponse<T> = await this.httpClient.request<T>({
        url,
        data,
        method,
        headers,
        params,
        withCredentials: true,
        onUploadProgress: options.onUploadProgress,
      });

      if (isApplicationResponse(response)) {
        ApiHttpClass.deserialize(response.data.application);
      }

      if (isApplicationsResponse(response)) {
        response.data.applications.forEach((application) => {
          ApiHttpClass.deserialize(application);
        });
      }
      // for responses with attachments, we need the file name to save the attachment as
      if (isRawResponse<T>(response, !!options.rawResponse)) {
        return response;
      }

      return response?.data;
    } catch (error: any) {
      if (options.rawResponse) {
        throw error;
      }

      if (error?.response?.status === 500) {
        throw "A server error occurred. Please try again later or contact Support.";
      }

      if (error?.response?.status === 404) {
        throw "A permissions error occurred. Please contact Support.";
      }

      if (!error?.response?.data) {
        throw error.message;
      }

      if (
        Array.isArray(error?.response?.data?.errors) &&
        Array.isArray(error?.response?.data?.warnings)
      ) {
        // if we only have warnings, allow overriding
        if (
          error?.response?.data?.warnings?.length &&
          !error?.response?.data?.errors?.length
        ) {
          throw error;
        }
      }

      let message = "";
      // if we managed to get an error message from the server
      if (error?.response?.data?.message?.non_field_errors) {
        // if we got a django serializer non-field error, use that
        message = error?.response?.data?.message?.non_field_errors;
      } else if (error?.response?.data?.message) {
        message = error?.response?.data?.message;
      } else {
        message = error.response.data;
      }

      // if csrf is invalid, clear it and try again
      if (/csrf/i.test(message) && error?.response?.status !== 500) {
        if (!attempts) {
          throw error;
        }

        ApiHttpClass.csrfToken = null;

        return this.fetch(endpoint, options, payload, attempts - 1);
      }

      // Handle AuthorizeNet expired OTS Token, so it doesn't conflict with token below.
      if (/Invalid OTS Token/i.test(message)) {
        throw new OtsTokenError(
          "There was an error communicating to the secure payment gateway, please try again.",
        );
      }

      // special handling for when your token is expired
      if (/Token/i.test(message) && /(invalid|expired)/i.test(message)) {
        if (!attempts) {
          throw error;
        }
        // hit login endpoint to discard any http-only token, throw expired error
        return this.fetch("session", { apiUrl }, {}, attempts - 1);
      }

      // special handling for when a submit is attempted on an incomplete application
      if (error?.response?.data.id === "application_incomplete") {
        throw {
          message,
          id: error.response.data.id,
          error: new Error(),
        };
      }

      // special handling for when an application post triggers the existing user email verification
      if (error?.response?.data.id === "email_verification") {
        throw {
          message,
          id: error.response.data.id,
          error: new Error(),
        };
      }

      // special handling for when an mfa code is requested
      if (
        [
          "two_factor_authentication_code_requested",
          "two_factor_authentication_required",
        ].includes(error.response.data.id)
      ) {
        throw {
          message,
          id: error.response.data.id,
          seed: error.response.data.seed,
          devices: error.response.data.devices,
          device: error.response.data.device,
          error: new Error(),
        };
      }

      throw message;
    }
  }

  static cullNullValues(payload: any) {
    // django serializers will return null values for all keys, ignore uninitialized keys
    const result: any = {};

    if (typeof payload === "string") {
      return payload;
    }

    if ([undefined, null].includes(payload)) {
      return undefined;
    }

    Object.keys(payload).forEach((key) => {
      if (payload[key] !== null) {
        if (Array.isArray(payload[key])) {
          result[key] = payload[key].map((a: any) =>
            ApiHttpClass.cullNullValues(a),
          );
        } else if (typeof payload[key] === "object") {
          result[key] = ApiHttpClass.cullNullValues(payload[key]);
        } else {
          result[key] = payload[key];
        }
      }
    });

    return result;
  }

  static serialize(
    application: ResponseApplication,
    shouldSkipSerializeAmount?: boolean,
  ): Application | FormData {
    if (application instanceof FormData) {
      return application;
    }
    const result = Utils.deepCopy(application) as Application;

    if (application.applicants && application.applicants.length) {
      result.applicants = application.applicants.map((applicant) => {
        const resultApplicant: Applicant = {
          ...applicant,
          date_of_birth: applicant.date_of_birth,
        };

        if (resultApplicant.date_of_birth) {
          resultApplicant.date_of_birth = new Date(
            resultApplicant.date_of_birth,
          )
            .toISOString()
            .substr(0, 10);
        }

        return resultApplicant;
      });

      result.applicants[0].is_primary = true;
    }

    if (application.selected_products) {
      result.selected_products = application.selected_products.map(
        (product) => {
          const resultProduct: Product = {
            ...product,
            amount: product.amount as unknown as Cents, // Default if we should skip serialization of amount
            minimum_balance: product.minimum_balance as unknown as Cents,
          };

          if (!shouldSkipSerializeAmount) {
            resultProduct.amount = Filters.dollarsToPennies(
              Number(product.amount) as Dollars,
            );
          }

          resultProduct.minimum_balance = Filters.dollarsToPennies(
            product.minimum_balance,
          );

          return resultProduct;
        },
      );
    }

    if (application.funding) {
      result.funding = {
        ...application.funding,
        amount: Filters.dollarsToPennies(application.funding.amount),
      };
    }

    return { ...result };
  }

  static deserialize(application: ResponseApplication) {
    if (application.applicants) {
      application.applicants.forEach((applicant) => {
        if (applicant.date_of_birth) {
          const options: {
            day: "2-digit";
            month: "2-digit";
            year: "numeric";
            timeZone: "UTC";
          } = {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            timeZone: "UTC",
          };

          applicant.date_of_birth = new Date(
            applicant.date_of_birth,
          ).toLocaleDateString("en-US", options);
        }
      });
    }

    if (application.selected_products) {
      application.selected_products.forEach((product) => {
        product.amount = Filters.penniesToDollars(product.amount);
        product.minimum_balance = Filters.penniesToDollars(
          product.minimum_balance,
        );
      });
    }
  }

  parseServerErrors = (errors?: { [key: string]: any }) => {
    if (!errors) {
      return {};
    }

    return Object.keys(errors).reduce((acc: { [key: string]: any }, key) => {
      const error = errors[key];

      if (typeof error === "string") {
        acc[key] = error;
      } else if (Array.isArray(error)) {
        acc[key] = error.join(" ");
      } else if (typeof error === "object") {
        acc[key] = this.parseServerErrors(error);
      }

      return acc;
    }, {});
  };
}

export const fetchHttpClient: HttpClient = {
  request: async ({ url, method, headers, data, params, withCredentials }) => {
    if (data instanceof FormData) {
      // If the data is a FormData object, we don't want to set the Content-Type header
      delete headers["Content-Type"];
    }

    return customFetch<any>(
      url,
      {
        method,
        headers: {
          ...headers,
        },
        credentials: withCredentials ? "include" : "same-origin",
        ...(REQUESTS_WITHOUT_BODY.includes(method)
          ? {}
          : {
              body: data instanceof FormData ? data : JSON.stringify(data),
            }),
      },
      REQUESTS_WITHOUT_BODY.includes(method) ? { ...data, ...params } : params,
    );
  },
};

// Using the ApiHttpClass class with fetch
const apiWithFetch = new ApiHttpClass(fetchHttpClient);

// This export is so that any type of HttpClient can be used with the ApiHttpClass class
export { ApiHttpClass };
export default apiWithFetch;
