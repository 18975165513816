import React, { useReducer } from "react";
import { mergeObjects } from ".";

const useData = (props = {}) => {
  const dataReducer = (state, action) => {
    switch (action.type) {
      case "update": {
        if (action.field) {
          return { ...state, ...{ [action.field]: action.update } };
        }
        return action.update;
      }
      case "updateField": {
        let update;
        if (Array.isArray(state[action.field])) {
          // if the parent state is an array, then the field  on the update is the index position of the array
          update = new Array();
          update[action.field] = action.update;
        } else {
          const previous = state[action.field] || {};
          const updated =
            typeof action.update === "object" ? { ...previous, ...action.update } : action.update;
          update = { [action.field]: updated };
        }
        const updatedState = mergeObjects(state, update);
        if (props.dispatchData) {
          props.dispatchData({
            type: "updateField",
            field: props.field,
            update: updatedState,
          });
        }
        return updatedState;
      }
      case "deleteField": {
        const copy = { ...state };
        delete copy[action.field];
        if (props.dispatchData) {
          props.dispatchData({
            type: "update",
            field: props.field,
            update: copy,
          });
        }
        return copy;
      }
      default:
        throw new Error();
    }
  };
  const [data, dispatchData] = useReducer(dataReducer, props.initialData || {});
  return { data, dispatchData };
};

export default useData;
