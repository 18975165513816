import { useEffect, useRef } from "react";

export const useReferenceTo = <T>(x: T) => {
  const ref = useRef(x);

  useEffect(() => {
    ref.current = x;
  }, [x]);

  return ref;
};
