import { Localized, useLocalization } from "@fluent/react";
import { Button } from "@narmi/design_system";
import {
  ContextForm,
  useNotificationContext,
  PasswordTextInput,
  useFormData,
  CsrfTokenMiddleware,
  jsonFromDocument,
} from "cerulean";
import { RefObject, useEffect, useRef } from "react";
import "./authentication.css";

interface ChangePasswordFormProps {
  form: RefObject<HTMLFormElement>;
  formData: {
    new_password1?: string;
    new_password2?: string;
  };
  onChange: CallableFunction;
}
const ChangePasswordForm = ({
  form,
  formData,
  onChange,
}: ChangePasswordFormProps) => {
  const { l10n } = useLocalization();
  return (
    <ContextForm
      ref={form}
      data={formData}
      onChange={onChange}
      autoComplete="off"
      method="post"
    >
      <ContextForm.Field required>
        <PasswordTextInput
          name="new_password1"
          field="new_password1"
          label={l10n.getString("label-new-password")}
        />
      </ContextForm.Field>

      <ContextForm.Field required>
        <PasswordTextInput
          name="new_password2"
          field="new_password2"
          label={l10n.getString("label-confirm-new-password")}
        />
      </ContextForm.Field>
      <CsrfTokenMiddleware />
      <ContextForm.ActionBar>
        <ContextForm.Action
          onSubmit={(_: any, e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            if (form?.current) {
              form.current.submit();
            }
          }}
        >
          <Button label={l10n.getString("button-submit")} />
        </ContextForm.Action>
      </ContextForm.ActionBar>
    </ContextForm>
  );
};

const ChangePasswordContainer = () => {
  const { sendNotification } = useNotificationContext();
  const { formData, onChange } = useFormData();
  const { l10n } = useLocalization();

  const form = useRef<HTMLFormElement>(null);

  const passwordChangeSubmitted = jsonFromDocument(
    "password_change_done",
    "false",
  );

  const isValidLink = jsonFromDocument("is_valid_link", "false");

  useEffect(() => {
    const formErrors = jsonFromDocument("password_errors", "[]");
    if (formErrors.length) {
      sendNotification({ type: "negative", text: formErrors[0] });
    }
  }, []);

  let header = l10n.getString("heading-invalid-reset-password");
  let subText = (
    <Localized
      id="subheading-invalid-reset-password"
      elems={{
        resetLink: <Button as="a" kind="plain" href="/password_reset"></Button>,
      }}
    >
      <span>
        {
          "The password reset link was invalid, possibly because it has already been used. Please <resetLink>request a new password reset.</resetLink>"
        }
      </span>
    </Localized>
  );

  if (isValidLink && !passwordChangeSubmitted) {
    header = l10n.getString("heading-reset-password-form");
    subText = <Localized id="subheading-reset-password-form"></Localized>;
  } else if (passwordChangeSubmitted) {
    header = l10n.getString("heading-reset-password-success");
    subText = <Localized id="subheading-reset-password-success"></Localized>;
  }

  return (
    <div className="authentication-container">
      <div className="mobile-web-extra-space">
        <h1>{header}</h1>
        {subText}
      </div>
      {isValidLink && !passwordChangeSubmitted && (
        <ChangePasswordForm
          form={form}
          formData={formData}
          onChange={onChange}
        />
      )}
      {passwordChangeSubmitted && (
        <ContextForm.ActionBar>
          <Button
            label={l10n.getString("label-back-to-login")}
            onClick={() => window.location.assign("/login")}
          />
        </ContextForm.ActionBar>
      )}
    </div>
  );
};

export default ChangePasswordContainer;
