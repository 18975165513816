import { ReactNode } from "react";
import styles from "./Container.module.scss";

interface ContainerProps {
  children: ReactNode;
}

const Container = ({ children }: ContainerProps) => (
  <div className={styles.container}>
    <div className={styles.mobileWebExtraSpace}>{children}</div>
  </div>
);

export default Container;
