import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  ContentCard,
  useFormData,
  useLoadingContext,
  useNotificationContext,
} from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";
import Utils from "byzantine/src/utils";
import AlertsList from "./AlertsList";
import NewAlertDialog from "./NewAlertDialog";
import NoAlerts from "./NoAlerts";

const TransactionAndBalanceAlerts = ({ accounts }) => {
  const [savedSearches, setSavedSearches] = useState([]);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const { formData, onChange, setFormData } = useFormData({
    alert_type: "email",
  });
  const { sendNotification } = useNotificationContext();
  const { setIsLoading } = useLoadingContext();

  const fetchAlerts = () => {
    ApiHttp.fetch("saved_searches").then((response) => {
      setSavedSearches(response?.saved_searches);
    });
  };

  useEffect(() => {
    fetchAlerts();
  }, []);

  const onSaveAlert = () => {
    setDialogIsOpen(false);
    setIsLoading(true);
    const queryTokens = [];
    if (formData.amount_gt)
      queryTokens.push(`amount>${Utils.parseValueAsFloat(formData.amount_gt)}`);
    if (formData.amount_lt)
      queryTokens.push(`amount<${Utils.parseValueAsFloat(formData.amount_lt)}`);
    if (formData.balance_gt)
      queryTokens.push(
        `balance>${Utils.parseValueAsFloat(formData.balance_gt)}`
      );
    if (formData.balance_lt)
      queryTokens.push(
        `balance<${Utils.parseValueAsFloat(formData.balance_lt)}`
      );
    if (formData.account_et) queryTokens.push(`account:${formData.account_et}`);
    if (formData.keywords) queryTokens.push(formData.keywords);
    const queryString = queryTokens.join(" ");

    const payload = {
      query: queryString,
      alert_type: formData.alert_type,
    };
    ApiHttp.fetch("saved_searches", { method: "POST" }, payload)
      .then(() => {
        fetchAlerts();
        sendNotification({
          type: "success",
          text: "Alert created.",
        });
        setFormData({ alert_type: "email" }); // set default alert type back to email
      })
      .catch((err) => {
        sendNotification({
          type: "negative",
          text: err,
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="security-card">
      <ContentCard>
        <div className="alerts-title-container">
          <h3 className="semibold">Transaction & balance alerts</h3>
          <Button onClick={() => setDialogIsOpen(true)} style={{textWrap: "nowrap"}}>New alert</Button>
        </div>
        <div className="manage-alerts-text">
          <p>Keep your accounts safe and manage them more efficiently.</p>
        </div>
        <div className="active-alerts">
          <h4>Active alerts</h4>
          {savedSearches.length > 0 ? (
            <AlertsList
              accounts={accounts}
              alerts={savedSearches}
              fetchAlerts={fetchAlerts}
              setIsLoading={setIsLoading}
            />
          ) : (
            <NoAlerts fetchAlerts={fetchAlerts} setIsLoading={setIsLoading} />
          )}
        </div>
      </ContentCard>
      <NewAlertDialog
        accounts={accounts}
        data={formData}
        dialogIsOpen={dialogIsOpen}
        onChange={onChange}
        onSaveAlert={onSaveAlert}
        setDialogIsOpen={setDialogIsOpen}
        setFormData={setFormData}
      />
    </div>
  );
};
TransactionAndBalanceAlerts.propTypes = {
  accounts: PropTypes.array.isRequired,
};

export default TransactionAndBalanceAlerts;
