import { useCallback } from "react";

import { modules, useObjectMemo } from "byzantine";
import { useNavigate } from "react-router-dom";

export const useTemplateSelector = () => {
  const {
    sortedFedwireTemplateIds,
    showManageTemplatesUI,
    selectedTemplateId,
    onSelectTemplate,
    method,
    error,
    resetForm,
  } = modules.wireTemplates.useTemplateSelector();

  const navigate = useNavigate();
  const manageTemplates = useCallback(() => {
    navigate("templates");
    resetForm();
  }, [navigate, resetForm]);

  const createNewTemplate = useCallback(() => {
    navigate("templates/new");
    resetForm();
  }, [navigate, resetForm]);

  return useObjectMemo({
    showManageTemplatesUI,
    sortedFedwireTemplateIds,
    selectedTemplateId,
    onSelectTemplate,
    createNewTemplate,
    manageTemplates,
    method,
    error,
  });
};
