/* eslint-disable camelcase */
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ProgressButtons } from "cerulean";
import { entities, modules, cents } from "byzantine";
import { DateTime } from "luxon";
import { Row, ResponsiveFlex } from "@narmi/design_system";
import { useLocalization } from "@fluent/react";
import filters from "byzantine/src/filters";
import Account from "byzantine/src/Account";
import styles from "./ReviewScreen.module.scss";
import { ACH_PAYMENT_ROUTE } from "./ACHPaymentRoutes";
import HeaderWithSteps from "../../HeaderWithSteps";
import { useSudoContext } from "../../SudoContext";
import AccountContext from "../../contexts/AccountContext";

const ReviewScreen = () => {
  const { l10n } = useLocalization();
  const navigate = useNavigate();
  const { totalSteps } = useSudoContext();
  const { accounts } = useContext(AccountContext);
  const { form, handleSubmit } = modules.achPayments.useACHPaymentForm();
  const selectedRecipient = entities.recipients.useSelectedRecipient();

  if (!selectedRecipient) return null;

  const {
    values: { amount, fromAccount, secCode },
  } = form;
  const { name, ach_destination, nickname } =
    selectedRecipient as API.Recipient;
  const {
    account_number: accountNumber,
    routing_number: routingNumber,
    institution_name: institutionName,
  } = ach_destination as API.Destination;

  const senderAccount = accounts.find(
    (account: Account) => account.id === fromAccount,
  );

  const handleClickSubmit = () => {
    const onSuccess = () => {
      window.location.assign("/");
    };
    handleSubmit(onSuccess);
  };

  const renderRow = (row: string[]) => {
    const [heading, content] = row;
    return (
      <Row>
        <Row.Item>
          <p className="fontColor--secondary">{heading}</p>
        </Row.Item>
        <Row.Item shrink>
          <p>{content}</p>
        </Row.Item>
      </Row>
    );
  };

  const senderRows = [
    [l10n.getString("ach-payment-review-from"), senderAccount.name],
    [l10n.getString("ach-payment-review-company"), "COMING SOON.."], // TODO: need to integrate with endpoint to fetch ach company
    [
      l10n.getString("ach-payment-review-date"),
      DateTime.now().toFormat("MM/dd/yyyy"),
    ],
    [l10n.getString("ach-payment-transaction-title"), `${secCode} payment`],
  ];

  const recipientRows = [
    [l10n.getString("ach-payment-recipient-title"), name],
    [l10n.getString("ach-payment-review-bank"), institutionName],
    [l10n.getString("ach-payment-review-account"), accountNumber],
    [l10n.getString("ach-payment-review-routing"), routingNumber],
  ];

  return (
    <ResponsiveFlex gapSize="xl">
      <HeaderWithSteps
        headerText={l10n.getString("ach-payment-review-title")}
        step={totalSteps}
        totalSteps={totalSteps}
      />
      <div className={styles.reviewCardContainer}>
        <div className={styles.reviewCardHeader}>
          <p>
            {l10n.getString("ach-payment-review-sub-title")} to {name}{" "}
            {nickname ? (
              <span className="fontColor--secondary">{`(${nickname})`}</span>
            ) : null}
          </p>
          <h1>{`${filters.currency(cents(amount))}`}</h1>
        </div>
        <div className={styles.reviewCardSection}>
          <ResponsiveFlex gapSize="m">
            {senderRows.map((row) => renderRow(row))}
          </ResponsiveFlex>
        </div>
        <div className={styles.reviewCardSection}>
          <ResponsiveFlex gapSize="m">
            {recipientRows.map((row) => renderRow(row))}
          </ResponsiveFlex>
        </div>
      </div>
      <ProgressButtons
        backLabel={l10n.getString("button-back")}
        nextLabel={l10n.getString("ach-payment-review-action")}
        onBack={() => {
          navigate(`/${ACH_PAYMENT_ROUTE}`);
        }}
        onNext={handleClickSubmit}
      />
    </ResponsiveFlex>
  );
};

export default ReviewScreen;
