import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { Drawer } from "cerulean";
import { modules } from "byzantine";

import RecipientForm from "./RecipientForm";
import TemplateForm from "./TemplateForm";

interface TemplateDrawerProps {
  isOpen: boolean;
}

const TemplateDrawer = ({ isOpen }: TemplateDrawerProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Drawer
      isOpen={isOpen}
      paddingSize="none"
      onUserDismiss={() => {
        if (location.state?.from.includes("/wires/templates")) {
          navigate(location.state?.from);
        } else {
          navigate("/wires/templates");
        }
      }}
      showControls={false}
      depth="633px"
    >
      <modules.wireTemplates.upsertTemplateForm.Provider>
        <Routes>
          <Route path="/new" element={<TemplateForm />} />
          <Route path="/edit/:templateId" element={<TemplateForm />} />
          <Route path="/recipients/new" element={<RecipientForm />} />
        </Routes>
      </modules.wireTemplates.upsertTemplateForm.Provider>
    </Drawer>
  );
};

export default TemplateDrawer;
