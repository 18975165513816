import React, { createContext, ReactNode, useContext, useState } from "react";
import { Button, Row } from "@narmi/design_system";
import { useNotificationContext } from "../NotificationContext";
import Dialog from "../Dialog";

interface AxiosError {
  response?: {
    data?: {
      errors?: [];
      warnings?: [];
    };
  };
}

export const extractIgnoredWarnings = (error: unknown): [] => {
  if (!(error as AxiosError)?.response?.data) return [];
  // api can return arrays of errors and warnings
  if (
    Array.isArray((error as AxiosError)?.response?.data?.errors) &&
    Array.isArray((error as AxiosError)?.response?.data?.warnings)
  ) {
    // if we only have warnings, allow overriding
    if (
      (error as AxiosError).response?.data?.warnings?.length &&
      !(error as AxiosError).response?.data?.errors?.length
    ) {
      return (error as AxiosError).response?.data?.warnings as [];
    }
  }
  return [];
};

export const getWarningMessage = (warnings: { description?: string }[]) => {
  // Join array of warnings as sentences
  return warnings
    .map((warning) => {
      const warningDescription = (warning as { description?: string }).description;
      if (!warningDescription) return "";
      // if we already have punctuation, passthrough
      if (/[\.\?\!]$/.test(warningDescription)) return warningDescription;
      // otherwise add a period
      return `${warningDescription}.`;
    })
    .filter((a) => a)
    .join(" ");
};

const IgnoreWarningsDialog = ({
  open,
  handleClose,
  onSubmit,
  confirmLabel = "Confirm",
  cancelLabel = "Cancel",
  dialogHeadingLabel = "Are you sure?",
  children,
}: {
  open: boolean;
  handleClose: () => void;
  onSubmit: () => void;
  cancelLabel?: string;
  confirmLabel?: string;
  dialogHeadingLabel?: string;
  children: ReactNode;
}) => {
  const actions = (
    <Row justifyContent="end" alignItems="center">
      <Row.Item shrink>
        <Button kind="negative" onClick={handleClose} label={cancelLabel} />
      </Row.Item>
      <Row.Item shrink>
        <Button onClick={onSubmit.bind(null)} label={confirmLabel} />
      </Row.Item>
    </Row>
  );

  return (
    <Dialog isOpen={open} title={dialogHeadingLabel} footer={actions} onUserDismiss={handleClose}>
      <p className="padding--top--s">{children}</p>
    </Dialog>
  );
};

interface FormReviewWarningsContextValue {
  handleWarnings: (
    errors: unknown,
    action: { onSubmit: () => {} },
    callback: (errors?: {}) => {}
  ) => void;
}
const FormReviewWarningsContext = createContext<FormReviewWarningsContextValue>({
  handleWarnings: (errors, action, callback) => {
    callback(errors || undefined);
  },
});
export const useFormReviewWarningsContext = () => useContext(FormReviewWarningsContext);

export const FormReviewWarningsContextProvider = ({
  setIgnoreWarnings,
  confirmWithToastMessage,
  children,
}: {
  setIgnoreWarnings: (warnings: {}[]) => void;
  confirmWithToastMessage?: boolean;
  children: ReactNode;
}) => {
  const noop = () => {};
  const { sendNotification } = useNotificationContext();
  const [dialogWarningMessage, setDialogWarningMessage] = useState("");
  const [dialogConfirmLabel, setDialogConfirmLabel] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState(() => noop);

  const handleWarningsViaToast = (warnings: {}[], action: { warningCta?: string }) => {
    const warningMessage = `${getWarningMessage(warnings)} ${
      action.warningCta || "Double-check your info and submit again to proceed."
    }`;
    // we want to send a toast message to confirm re-submit
    sendNotification({
      type: "negative",
      text: warningMessage,
    });

    return Promise.resolve();
  };

  const handleWarningsViaDialog = (
    warnings: [],
    action: {
      label?: string;
      warningCta?: string;
      onSubmit: (errors?: {}) => {};
    },
    handleErrors: (errors: {}) => {}
  ) => {
    // pop up a confirmation dialog
    const warningMessage = `${getWarningMessage(warnings)} ${
      action.warningCta || "Are you sure you wish to proceed?"
    }`;
    setDialogWarningMessage(warningMessage);
    setDialogConfirmLabel(action.label || "");
    setDialogAction(() => () => {
      action.onSubmit((errors: unknown) => {
        if (!errors) return dialogHandleClose();
        return handleErrors(errors);
      });
    });
    setDialogOpen(true);
    return Promise.resolve();
  };

  const handleWarnings = (
    errors: unknown,
    action: { warningCta?: string; onSubmit: () => {} },
    handleErrors: (errors?: {}) => {}
  ) => {
    const warnings: [] = extractIgnoredWarnings(errors);
    // if we do not handle this error case here, raise so the next handling can trigger
    if (!warnings.length) return handleErrors(errors || undefined);
    // the user will trigger another onSubmit when confirming, so mutate the payload
    setIgnoreWarnings(warnings);
    if (confirmWithToastMessage) return handleWarningsViaToast(warnings, action);
    return handleWarningsViaDialog(warnings, action, handleErrors);
  };

  const dialogHandleClose = () => {
    // dismiss the warnings we added when dismissing dialog
    setIgnoreWarnings([]);
    setDialogConfirmLabel("");
    setDialogAction(() => () => {});
    setDialogOpen(false);
  };

  return (
    <FormReviewWarningsContext.Provider
      value={{
        handleWarnings,
      }}
    >
      {!confirmWithToastMessage && dialogOpen && (
        <IgnoreWarningsDialog
          open={dialogOpen}
          handleClose={dialogHandleClose}
          onSubmit={dialogAction}
          confirmLabel={dialogConfirmLabel}
        >
          {dialogWarningMessage}
        </IgnoreWarningsDialog>
      )}
      {children}
    </FormReviewWarningsContext.Provider>
  );
};

export default FormReviewWarningsContext;
