import { memo } from "react";

import { modules } from "byzantine";

import WireTransferRedesign from "../../../../components/transfer/wires/WireTransfer";

import WireTemplatesScreen from "./templates/templates.screen";

type Props = {
  limits: API.Limits.ByType;
};

function WireTransfer({ limits }: Props) {
  const { fedwireTemplatesEnabled } =
    modules.wireTemplates.useWirePermissions();

  if (fedwireTemplatesEnabled) {
    return <WireTemplatesScreen />;
  }

  return <WireTransferRedesign limits={limits} />;
}

export default memo(WireTransfer);
