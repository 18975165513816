import { useCallback } from "react";

import { useObjectMemo } from "../../../hooks";

import { createTravelNoteForm } from "./createTravelNote.form";

export const useCreateTravelNoteForm = () => {
  const form = createTravelNoteForm.useForm();
  const { submitForm, resetForm, values } = form;

  const onValidate = useCallback(async () => {
    const result = await submitForm();
    return result.success;
  }, [submitForm]);

  return useObjectMemo({
    form,
    values,
    resetForm,
    onValidate,
  });
};
