import cc from "classcat";
import { useLocalization } from "@fluent/react";
import Address from "byzantine/src/Address";
import { useCurrentUser } from "../contexts/CurrentUserContext";

interface AddressTitleProps {
  noMailing: boolean;
  address?: Address;
  aligned: "left" | "right";
}

const AddressTitle = ({
  noMailing,
  address,
  aligned = "right",
}: AddressTitleProps) => {
  const { currentUser } = useCurrentUser();
  const { l10n } = useLocalization();

  const addressClasses = cc([
    "profile-field-value",
    "pulled",
    { right: aligned === "right", left: aligned === "left" },
  ]);

  if (noMailing) {
    return (
      <span className={addressClasses}>
        {currentUser?.isBusiness()
          ? l10n.getString(
            "label-same-business-address",
            null,
            "Same as business address",
          )
          : l10n.getString(
            "label-same-residential-address",
            null,
            "Same as residential address",
          )}
      </span>
    );
  }
  if (!address) {
    return null;
  }
  return address.street_address_2 ? (
    <span className={addressClasses}>
      <div>
        {address.street_address}, {address.street_address_2}
      </div>
      <div>
        {address.city}, {address.region_code} {address.postal_code}
      </div>
    </span>
  ) : (
    <span className={addressClasses}>
      <div>{address.street_address}</div>
      <div>
        {address.city}, {address.region_code} {address.postal_code}
      </div>
    </span>
  );
};

export default AddressTitle;
