import { useSelector } from "react-redux";
import _ from "lodash";

import { hasBusinessPermission } from "./selectors";

export const useBusinessPermissions = (permissions: API.Permission[]) => {
  return useSelector(
    (state) => permissions.map((p) => hasBusinessPermission(state, p)),
    _.isEqual,
  );
};
