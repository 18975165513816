import { createContext } from "react";

export interface IToasts {
  throwToast: ({
    kind,
    message,
  }: {
    kind: "success" | "info" | "error";
    message: string;
  }) => void;
  clearAllToasts: () => void;
}

export const ToastsContext = createContext<IToasts | undefined>(undefined);

export type ToastsProps = {
  toasts?: IToasts;
};
