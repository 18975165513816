import type { ComponentType, PropsWithChildren, ReactNode } from "react";
import React from "react";

type ChildrenProps<T = unknown> = Required<PropsWithChildren<T>>;

type ComposeProvidersProps = ChildrenProps<{
  components: ReadonlyArray<ComponentType<ChildrenProps>>;
}>;

const ComposeProviders = (props: ComposeProvidersProps): ReactNode => {
  return props.components.reduceRight(
    (memo, Component) => <Component>{memo}</Component>,
    props.children,
  );
};

export default ComposeProviders;
