import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Institution from "byzantine/src/Institution";
import WireRecipient from "byzantine/src/WireRecipient";
import { ContextForm } from "cerulean";
import WireTransferForm from "./form/WireTransferForm";
import AddRecipientDialog from "./recipients/AddRecipientDialog";
import { useCurrentUser } from "../../contexts/CurrentUserContext";
import { useSudoContext } from "../../SudoContext";

const WireTransfer = ({ limits, Header }) => {
  const navigate = useNavigate();
  const [showNewRecipientForm, setShowNewRecipientForm] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const { currentUser } = useCurrentUser();
  const {
    useSetInitialSteps,
    formData,
    onFormDataChange: onChange,
    establishSudo,
    setDestinationRoute,
  } = useSudoContext();

  const goToTransferPage = () => {
    navigate("/");
  };

  const destinationRoute = "/wires/review";
  useEffect(() => {
    WireRecipient.fetchAll().then((wireRecipients) => {
      setRecipients(wireRecipients);
    });
    if (!formData?.recipient) {
      onChange({ recipient: {} });
    }
    useSetInitialSteps(null);
    setDestinationRoute(destinationRoute);
  }, []);

  const checkSudo = () => {
    new Institution().getSettings().then((settings) => {
      if (!settings.sudo_mode_required_for_wires) {
        navigate(destinationRoute);
        return;
      }
      establishSudo(destinationRoute);
    });
  };

  return (
    <>
      <ContextForm nativeForm={false} data={formData} onChange={onChange}>
        <div className="wire-transfer-container">
          <div className="mobile-web-extra-space">
            <WireTransferForm
              onSubmit={checkSudo}
              cancel={goToTransferPage}
              limits={limits}
              onChange={onChange}
              isBusiness={currentUser?.isBusiness()}
              recipients={recipients}
              setShowNewRecipientForm={setShowNewRecipientForm}
              Header={Header}
            />
          </div>
        </div>
      </ContextForm>
      <AddRecipientDialog
        open={showNewRecipientForm}
        handleClose={() => setShowNewRecipientForm(false)}
        recipients={recipients}
        updateTransferFormWithRecipient={onChange}
        setRecipients={setRecipients}
      />
    </>
  );
};

WireTransfer.propTypes = {
  limits: PropTypes.object,
  Header: PropTypes.element,
};

export default WireTransfer;
