import { useMemo } from "react";

import { useNotificationContext } from "cerulean";

import type { IToasts } from "byzantine";

const useWebToasts = () => {
  const { sendNotification, clearNotifications } = useNotificationContext();
  return useMemo<IToasts>(
    () => ({
      throwToast: ({ kind, message }) => {
        sendNotification({
          type: kind === "error" ? "negative" : kind,
          text: message,
        });
      },
      clearAllToasts: clearNotifications,
    }),
    [sendNotification, clearNotifications]
  );
};

export default useWebToasts;
