import * as Yup from "yup";

import { createForm } from "../../../forms";

export type AddOrEditTemplateFormFields = {
  wireRecipientId: API.WireRecipientId;
  name: string;
  sourceAccountId: API.AccountId;
  wireReason?: string;
  amount?: Cents;
  memo?: string;
};

const validationSchema = Yup.object().shape({
  wireRecipientId: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  sourceAccountId: Yup.string().required("Required"),
  wireReason: Yup.string(),
  amount: Yup.number().integer("Please enter a number."),
  memo: Yup.string().min(1).max(140, "Memo cannot exceed 140 characters"),
});

const initialValues: AddOrEditTemplateFormFields = {
  wireRecipientId: "" as API.WireRecipientId,
  name: "",
  sourceAccountId: "" as API.AccountId,
  wireReason: undefined,
  amount: undefined,
  memo: undefined,
};

export const transformApiTemplateToForm = ({
  recipient: wireRecipientId,
  source_account: sourceAccountId,
  name,
  amount,
  wire_reason: wireReason,
  memo,
}: API.FedwireTemplate): AddOrEditTemplateFormFields => {
  return {
    wireRecipientId,
    sourceAccountId,
    name,
    amount: amount || undefined,
    wireReason: wireReason || undefined,
    memo: memo || undefined,
  };
};

export const upsertTemplateForm = createForm({
  initialValues,
  validationSchema,
});
