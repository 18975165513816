import { useDeepEqualMemo } from "../../../hooks/useDeepEqual.hook";

import type { ErrorHandler } from "./errorHandler.types";
import type { ErrorResponse, RunProps } from "../request.types";

type HandleErrorFn<D = unknown, E = unknown> = (
  error: ErrorResponse<E>,
  context: RunProps<D, E>,
  retry: () => Promise<D>,
) => Promise<D>;

type HandlerHook = () => ErrorHandler;
const HANDLERS: Array<() => ErrorHandler> = [];
export function registerErrorHandlerHook(handler: HandlerHook) {
  if (!HANDLERS.includes(handler)) HANDLERS.push(handler);
}

async function resolveErrorResponse<E>(err: ErrorResponse<E>) {
  if (err.response) {
    return {
      status: err.response.status,
      payload: await err.response.json(),
    };
  }
  return null;
}

export default function useErrorHandling<D, E>() {
  const handlers = HANDLERS.map((hook) => hook());

  return useDeepEqualMemo(
    () =>
      /**
       * If a matching handler is found, attempt to handle the error, and then retry the request.
       * Toss the error back up to the caller if anything goes wrong.
       */
      async function handleError(err, context, retry) {
        const error = await resolveErrorResponse(err);
        if (!error) throw err;

        try {
          const handler = handlers.find((h) => h.shouldHandle(error, context));
          if (handler) {
            // It's important to await this before returning as we need to catch any errors below
            // and throw the original error
            const data = await handler.handle(error, retry);
            return data;
          }
        } catch {
          console.warn("Exception while handling error");
        }
        throw err;
      } satisfies HandleErrorFn<D, E>,
    [handlers],
  );
}
