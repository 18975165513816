const validStyles = ["currency", "percent"] as const;
type NumberFormatOptionsStyle = (typeof validStyles)[number];

export const formatNumber = (
  input: string,
  style: NumberFormatOptionsStyle = "currency",
) => {
  const number = parseFloat(input);
  const formatterOpts = {
    style,
    currencyDisplay: "narrowSymbol",
    // Range error if maximum and minimum are not both set
    maximumFractionDigits: Number.isInteger(number) ? 0 : 2,
    minimumFractionDigits: Number.isInteger(number) ? 0 : 2,
    ...(style === "currency" ? { currency: "USD" } : {}),
  } satisfies Intl.NumberFormatOptions;

  // only allow currency and percentage types for now
  if (!validStyles.includes(style)) {
    throw new Error(
      `formatNumber: invalid style argument. Must be one of: ${JSON.stringify(
        validStyles,
      )}`,
    );
  }

  return new Intl.NumberFormat("en-US", formatterOpts).format(number);
};
