import React from "react";
import PropTypes from "prop-types";
import { Row } from "@narmi/design_system";
import { useTranslation } from "react-i18next";

export interface InformationRowProps {
  label?: string | null;
  value?: string | null;
}

const InformationRow = ({ label, value }: InformationRowProps) => {
  const { t } = useTranslation();
  if (
    [null, undefined, ""].includes(label) ||
    [null, undefined, ""].includes(value)
  )
    return null;
  return (
    <div className="information-row">
      <Row alignItems="center">
        <Row.Item shrink>{label && t(label)}</Row.Item>
        <Row.Item>
          <div style={{ textAlign: "right" }}>{value}</div>
        </Row.Item>
      </Row>
    </div>
  );
};
InformationRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

export default InformationRow;
