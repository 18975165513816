import { ResponseError } from "byzantine";
import axios from "axios";
import createHmac from "create-hmac";

import type { AxiosError, AxiosResponse } from "axios";
import type { INetwork, Network } from "byzantine";

export const API_VERSION = "v1" as const;
const DEFAULT_MAX_ATTEMPTS = 3 as const;
const EPSILON_SECONDS = 60 as const;
const CSRF_TOKEN_HEADER_NAME = "x-csrftoken" as const;

const getDefaultApiUrl = () => {
  const replaceUrl = (url: string) => url.replace(/(\/v1)?\/?$/, "");

  try {
    (() => process.env)();
  } catch {
    return "";
  }

  const { REACT_APP_PUBLIC_API_URL, NEXT_PUBLIC_PUBLIC_API_URL } = process.env;

  if (REACT_APP_PUBLIC_API_URL) {
    return replaceUrl(REACT_APP_PUBLIC_API_URL);
  }

  if (NEXT_PUBLIC_PUBLIC_API_URL) {
    return replaceUrl(NEXT_PUBLIC_PUBLIC_API_URL);
  }

  return "";
};

const isTokenExpired = (token: string) => {
  const payloadBase64 = token?.split(".")[1];
  if (!payloadBase64) return false;
  const decodedJson = Buffer.from(payloadBase64, "base64").toString();
  const decoded = JSON.parse(decodedJson);
  const { exp } = decoded;
  if (!exp) return false;
  const expired = new Date().getTime() >= (exp - EPSILON_SECONDS) * 1000;
  return expired;
};

axios.defaults.headers = {
  Pragma: "no-cache",
  "Cache-Control": "no-store, no-cache",
  Accept: "application/json",
  "Content-Type": "application/json",
};

const defaultApiUrl = getDefaultApiUrl();

const buildAuthHeaders = (token?: string, secret?: string): Network.Headers => {
  if (!token) return {};
  const authHeaders: Network.Headers = {
    Authorization: `Bearer ${token}`,
  };
  if (secret) {
    const created = String(Math.floor(new Date().getTime() / 1000));
    const signature = createHmac("sha256", secret)
      .update(`created: ${created}`, "utf8")
      .digest("base64");
    authHeaders.Signature = `keyId="${token}",algorithm="hmac-sha256",signature="${signature}",headers="created"`;
    authHeaders.created = created;
  }
  return authHeaders;
};

export const defaultApiOptions = {
  method: "GET",
  customHeaders: {},
  timeoutMilliseconds: 30 * 1000,
  isJsonResponse: true,
} satisfies Network.Options;

const transformResponse = (response: AxiosResponse): Network.Response => {
  const { data, status, headers: responseHeaders } = response;
  return {
    bodyString: undefined,
    data: "",
    headers: responseHeaders,
    status,
    url: "",
    json: async () => data,
    text: async () => "",
  };
};

const fetchCSRFToken = async (attemptsLeft: number = DEFAULT_MAX_ATTEMPTS) => {
  if (attemptsLeft === 0) {
    return undefined;
  }
  return new Promise<string | undefined>((resolve) => {
    axios
      .request({
        url: `${defaultApiUrl}/${API_VERSION}/csrf`,
        withCredentials: true,
        method: "GET",
      })
      .then((response) => {
        resolve(response.headers[CSRF_TOKEN_HEADER_NAME]);
      })
      .catch(async () => {
        resolve(await fetchCSRFToken(attemptsLeft - 1));
      });
  });
};

let storedCSRFToken: string | undefined;
let existingCSRFTokenFetchPromise: Promise<string | undefined> | undefined;

const acquireCSRFToken = async () => {
  if (storedCSRFToken && !isTokenExpired(storedCSRFToken)) {
    return storedCSRFToken;
  }

  if (!existingCSRFTokenFetchPromise) {
    existingCSRFTokenFetchPromise = fetchCSRFToken();
  }

  const csrfToken = await existingCSRFTokenFetchPromise;
  storedCSRFToken = csrfToken;
  existingCSRFTokenFetchPromise = undefined;

  if (!csrfToken) {
    throw new Error("Cannot fetch CSRF token.");
  }

  return csrfToken;
};

const isWriteRequest = <T extends { method?: string }>(
  opts: T
): opts is Extract<T, { method: Network.RequiresPayload }> =>
  !!opts.method && ["POST", "PUT"].includes(opts.method);

const webNetwork: INetwork = {
  fetchApi: async (endPoint, optionsWithoutDefaults) => {
    const options = {
      ...defaultApiOptions,
      ...optionsWithoutDefaults,
    } as Network.Options;

    let csrfToken: string | undefined;
    if (options.method !== "GET") {
      csrfToken = await acquireCSRFToken();
    }

    const headers: Network.Headers = {
      ...axios.defaults.headers,
      ...buildAuthHeaders(options.token, options.secret),
      ...options.customHeaders,
      [CSRF_TOKEN_HEADER_NAME]: csrfToken,
    };

    if (isWriteRequest(options) && options.idempotencyKey) {
      headers["Idempotency-Key"] = options.idempotencyKey;
    }

    let url: string;
    try {
      if (new URL(endPoint).hostname) {
        url = endPoint;
      }
    } catch {
      url = `${defaultApiUrl}/${API_VERSION}/${endPoint}`;
    }
    return new Promise((resolve, reject) => {
      axios
        .request({
          url,
          method: options.method,
          headers,
          data: options.payload,
          timeout: options.timeoutMilliseconds,
        })
        .then((response: AxiosResponse) => {
          resolve(transformResponse(response));
        })
        .catch((error: Error | AxiosError) => {
          if (axios.isAxiosError(error) && error.response) {
            reject(
              new ResponseError(
                `bad response code: ${url} (${error.response.status})`,
                transformResponse(error.response)
              )
            );
          } else {
            reject(error);
          }
        });
    });
  },
};

export default webNetwork;
