import type {
  AddOrEditTemplateFormFields,
  SendViaTemplateFormFields,
} from "./forms";
import type { NetworkPayload } from "../../../composites";

export const API = {
  createWireViaTemplate: ({
    idempotencyKey,
    fedwireTemplate: { id: fedwire_template, version_id },
    wireReason: wire_reason,
    amount,
    memo,
  }: WithRequired<SendViaTemplateFormFields, "fedwireTemplate"> & {
    idempotencyKey: UUID;
  }): NetworkPayload => {
    return {
      url: "wires/fedwire_templates/",
      options: {
        method: "POST",
        payload: {
          fedwire_template,
          version_id,
          wire_reason: wire_reason || undefined,
          amount: amount || undefined,
          memo: memo || undefined,
        },
        idempotencyKey,
      },
    } satisfies NetworkPayload<API.CreateWireViaFedwireTemplate.Request>;
  },
  createOrEditTemplate: (
    {
      sourceAccountId: source_account,
      wireRecipientId: recipient,
      name,
      wireReason,
      amount,
      memo,
    }: AddOrEditTemplateFormFields,
    existingFedwireTemplateId?: API.FedwireTemplateId,
  ) => {
    return {
      url: `fedwire_templates/${
        existingFedwireTemplateId ? `${existingFedwireTemplateId}/` : ""
      }`,
      options: {
        method: existingFedwireTemplateId ? "PUT" : "POST",
        payload: {
          name,
          recipient,
          source_account,
          wire_reason: wireReason || null,
          amount: amount || null,
          memo: memo || null,
        },
      },
    } satisfies NetworkPayload<API.CreateOrUpdateFedwireTemplate.Request>;
  },
};
