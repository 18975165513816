import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  type PropsWithChildren,
} from "react";

import { type FormikValues, type FormikConfig, useFormik } from "formik";
import _ from "lodash";

import { useObjectMemo } from "../../hooks";

type Props<V> = Required<
  Pick<FormikConfig<V>, "initialValues" | "validationSchema">
> &
  Omit<FormikConfig<V>, "initialValues" | "validationSchema" | "onSubmit">;

export function createForm<Values extends FormikValues>(props: Props<Values>) {
  type FormType = ReturnType<typeof useFormik<Values>>;
  type OnSubmit = FormikConfig<Values>["onSubmit"];
  type ValidationSchema = FormikConfig<Values>["validationSchema"];
  const FormContext = createContext<FormType | undefined>(undefined);
  const FormValidation = createContext<{
    validationSchema: FormikConfig<Values>["validationSchema"];
    setValidationSchema: (schema: ValidationSchema) => void;
  }>({
    validationSchema: props.validationSchema,
    setValidationSchema: () => null,
  });
  const emptySubmit: OnSubmit = () => {};

  type FormSubmission =
    | {
        success: false;
      }
    | {
        success: true;
        values: Values;
      };

  const useForm = () => {
    const form = useContext(FormContext)!;
    const { setValidationSchema, validationSchema } =
      useContext(FormValidation);
    const { handleSubmit, values, validateForm } = form;

    const submitForm = useCallback<() => Promise<FormSubmission>>(async () => {
      handleSubmit();
      const success = _.isEmpty(await validateForm(values));
      if (success) {
        return {
          success,
          values,
        };
      }
      return {
        success,
      };
    }, [handleSubmit, validateForm, values]);

    const augmentedForm = useObjectMemo({
      ...form,
      submitForm,
      setValidationSchema,
      validationSchema,
    });

    return augmentedForm;
  };

  const Provider = ({ children }: PropsWithChildren) => {
    const form = useFormik({
      ...props,
      onSubmit: emptySubmit,
    });

    const [validationSchema, setValidationSchema] = useState(
      props.validationSchema,
    );

    return (
      <FormValidation.Provider
        value={{
          validationSchema,
          setValidationSchema,
        }}
      >
        <FormContext.Provider value={form}>{children}</FormContext.Provider>
      </FormValidation.Provider>
    );
  };

  return {
    Provider,
    useForm,
  };
}
