import { matchRoutes, useLocation } from "react-router-dom";

const DRAWER_ROUTES = [
  { path: "/wires/templates/new" },
  { path: "/wires/templates/edit/:templateId" },
  { path: "/wires/templates/recipients/new" },
  { path: "/wires/templates/recipients/edit/:recipientId" },
];

const useIsTemplateDrawerRoute = () => {
  const location = useLocation();
  return (matchRoutes(DRAWER_ROUTES, location?.pathname) || []).length > 0;
};

export default useIsTemplateDrawerRoute;
