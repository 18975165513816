import { useSelector } from "react-redux";

import { useSelectSavvyMoneyUrlByView } from "./selectors";

import type { SavvyMoneyViewUrl } from "./types";

export const useSavvyMoneyUrl = (view: SavvyMoneyViewUrl) => {
  const selectUrlByView = useSelectSavvyMoneyUrlByView(view);
  return useSelector(selectUrlByView);
};
