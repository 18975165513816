import ApiHttp from "./ApiHttp";

class Institution {
  private static settingsPromise: Promise<API.Institution>;

  getSettings(): Promise<API.Institution> {
    if (Institution.settingsPromise === undefined) {
      Institution.settingsPromise = ApiHttp.fetch("institution", {
        method: "GET",
      }).then((response: { institution: API.Institution }) => {
        return response.institution;
      });
    }

    return Institution.settingsPromise;
  }
}

export default Institution;
