// requests.ts
import { useCallback } from "react";

import { useDispatch } from "react-redux";

import {
  useRequestWithFeedback,
  type OverrideRunProps,
} from "../../../composites";
import { useObjectMemo } from "../../../hooks";

import { useSavvyMoneyUrl } from "./hooks";
import API from "./api";
import { actions } from "./slice";

import type { SavvyMoneyViewUrl } from "./types";

export const useFetchSavvyMoneyRedirectUrl = (
  view: SavvyMoneyViewUrl,
  isFeatureEnabled: boolean,
) => {
  const dispatch = useDispatch();

  const { send, loading, status } =
    useRequestWithFeedback<API.FetchSavvyRedirectUrlResponse>();

  const fetchSavvyMoneyRedirectUrl = useCallback(
    (
      overrideRunProps: OverrideRunProps<API.FetchSavvyRedirectUrlResponse> = {},
    ) => {
      if (!isFeatureEnabled) {
        return;
      }

      send({
        action: API.fetchSavvyRedirectUrl(view),
        onData: (result) => {
          dispatch(
            actions.upsertSavvyMoneyUrl({
              view,
              url: result.url,
            }),
          );
        },
        ...overrideRunProps,
      });
    },
    [dispatch, send, view, isFeatureEnabled],
  );

  const url = useSavvyMoneyUrl(view);

  return useObjectMemo({
    send: fetchSavvyMoneyRedirectUrl,
    loading,
    error: status === "error",
    hasData: Boolean(url),
  });
};
