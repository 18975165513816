// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".additionalDetailsSection--BmVq6{border-top:1px solid var(--border-color-default);margin-top:var(--space-l)}.additionalDetailsSection--BmVq6 h2{margin-top:var(--space-l);margin-bottom:var(--space-s)}.additionalDetailsSection--BmVq6 p{margin-bottom:var(--space-m)}.disabledSection--u6eJv h2,.disabledSection--u6eJv p{color:var(--color-mediumGrey)}.radioButtonContainer--Kasg4{width:60%}.drawerFooter--cYMa_{border-top:1px solid var(--border-color-default);margin-top:32px;padding-top:var(--space-l)}", "",{"version":3,"sources":["webpack://./components/transfer/ach/RecipientDrawer/AddRecipientDrawer.module.scss"],"names":[],"mappings":"AAAA,iCACE,gDAAA,CACA,yBAAA,CACA,oCACE,yBAAA,CACA,4BAAA,CAEF,mCACE,4BAAA,CAKF,qDAEE,6BAAA,CAIJ,6BACE,SAAA,CAGF,qBACE,gDAAA,CACA,eAAA,CACA,0BAAA","sourcesContent":[".additionalDetailsSection {\n  border-top: 1px solid var(--border-color-default);\n  margin-top: var(--space-l);\n  h2 {\n    margin-top: var(--space-l);\n    margin-bottom: var(--space-s);\n  }\n  p {\n    margin-bottom: var(--space-m);\n  }\n}\n\n.disabledSection {\n  h2,\n  p {\n    color: var(--color-mediumGrey);\n  }\n}\n\n.radioButtonContainer {\n  width: 60%;\n}\n\n.drawerFooter {\n  border-top: 1px solid var(--border-color-default);\n  margin-top: 32px;\n  padding-top: var(--space-l);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"additionalDetailsSection": "additionalDetailsSection--BmVq6",
	"disabledSection": "disabledSection--u6eJv",
	"radioButtonContainer": "radioButtonContainer--Kasg4",
	"drawerFooter": "drawerFooter--cYMa_"
};
export default ___CSS_LOADER_EXPORT___;
