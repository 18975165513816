import { useLocalization } from "@fluent/react";
import { pickBy } from "lodash";
import User from "byzantine/src/User";
import {
  Button,
  ContextForm,
  Dialog,
  Row,
  useFormData,
  useNotificationContext,
  PhoneTextInput,
  phoneFormatter,
} from "cerulean";
import { useCurrentUser } from "../../../../contexts/CurrentUserContext";

interface PhoneNumberEditDialogBodyProps {
  closeDialog: () => void;
}

const PhoneNumberEditDialogBody = ({
  closeDialog,
}: PhoneNumberEditDialogBodyProps) => {
  const { currentUser, setCurrentUser } = useCurrentUser();
  const { sendNotificationToParent } = useNotificationContext();
  const { formData, onChange } = useFormData({
    number: phoneFormatter(currentUser?.phone?.number || ""),
  });
  const { l10n } = useLocalization();

  const validateField = (value: string) => {
    if (!value) {
      return l10n.getString("error-required", null, "Must be filled in.");
    }

    return null;
  };

  const onSubmit = async (callback: (arg?: unknown) => void) => {
    try {
      const updatedUser = await currentUser?.updatePhoneNumber(formData.number);
      if (updatedUser) {
        const cleanedUpdatedUser = pickBy(
          updatedUser,
          (v) => ![undefined, '""', "[]", "{}"].includes(JSON.stringify(v))
        );
        setCurrentUser(new User({ ...currentUser, ...cleanedUpdatedUser }));
      }
      callback();
      sendNotificationToParent({
        type: "dismissable_success",
        text: l10n.getString(
          "phone-updated-success-message",
          null,
          "Phone updated. If you intend to use this phone as a Two-Factor Authentication device, please add it [here](/settings/security)."
        ),
      });
      closeDialog();
    } catch (error: unknown) {
      const e = error as { number: string };
      callback(e);
    }
  };

  return (
    <>
      <div className="margin--top--s" />
      <ContextForm data={formData} onChange={onChange}>
        <ContextForm.Field validate={validateField}>
          <PhoneTextInput
            label="Phone number"
            aria-label="Phone number"
            field="number"
          />
        </ContextForm.Field>
        <div className="margin--bottom--xl" />
        <Row alignItems="center" justifyContent="end">
          <Row.Item shrink>
            <Button
              type="button"
              onClick={closeDialog}
              kind="negative"
              label="Cancel"
            />
          </Row.Item>
          <Row.Item shrink>
            <ContextForm.Action onSubmit={onSubmit}>
              <Button kind="primary" label="Save changes" />
            </ContextForm.Action>
          </Row.Item>
        </Row>
      </ContextForm>
    </>
  );
};

interface PhoneNumberEditDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
}

const PhoneNumberEditDialog = ({
  isOpen,
  closeDialog,
}: PhoneNumberEditDialogProps) => (
  <Dialog isOpen={isOpen} onUserDismiss={closeDialog} title="Edit phone number">
    <PhoneNumberEditDialogBody closeDialog={closeDialog} />
  </Dialog>
);
export default PhoneNumberEditDialog;
