/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

const name = "fedwireTemplates";

type FedwireTemplatesState = {
  byId: { [key: API.FedwireTemplateId]: API.FedwireTemplate };
};

const initialState: FedwireTemplatesState = {
  byId: {},
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    upsertAll: (state, action: PayloadAction<Array<API.FedwireTemplate>>) => {
      state.byId = action.payload.reduceRight(
        (prev, cur) => ({ ...prev, [cur.id]: cur }),
        {},
      );
    },
    updateOne: (state, action: PayloadAction<API.FedwireTemplate>) => {
      const template = action.payload;
      state.byId[template.id] = template;
    },
    deleteOne: (state, action: PayloadAction<API.FedwireTemplateId>) => {
      const templateId = action.payload;
      delete state.byId[templateId];
    },
  },
});

export const { actions } = slice;

export default {
  [name]: slice.reducer,
};
