import { Link } from "react-router-dom";
import { Button } from "cerulean";

const BackButton = ({ to }: { to: string }) => (
  <Link to={to}>
    <Button type="button" kind="plain">
      Back
    </Button>
  </Link>
);

export default BackButton;
