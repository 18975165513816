import { ReactNode, useState } from "react";
import PropTypes from "prop-types";
import { TextFit } from "cerulean";
import Account from "byzantine/src/Account";
import BalanceDetailsModal from "./BalanceDetailsModal";

interface CardProps {
  children: ReactNode;
}

const Card = ({ children }: CardProps) => (
  <div className="nds-card borderless balances-card">{children}</div>
);

interface BalanceCardProps {
  account: Account;
}

const BalancesCard = ({ account }: BalanceCardProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const InfoIcon = () => (
    <span
      role="button"
      tabIndex={0}
      className="narmi-icon-info clickable"
      onKeyUp={({ key }) => {
        if (key === "Enter") {
          setDialogOpen(true);
        }
      }}
      onClick={() => {
        setDialogOpen(true);
      }}
    ></span>
  );

  const display = ["checking", "savings", "money_market"].includes(
    account.product_type,
  );

  return (
    <>
      {display && (
        <>
          <Card>
            <div className="balances-card">
              <div className="available-balance">
                <TextFit text={account.balances.available} />
              </div>
              <div className="fontSize--s alignChild--center--center">
                <div className="available-balance-title">Available balance</div>
                <InfoIcon />
              </div>
            </div>
          </Card>
          <BalanceDetailsModal
            account={account}
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
          />
        </>
      )}
    </>
  );
};

BalancesCard.propTypes = {
  account: PropTypes.object,
};

export default BalancesCard;
