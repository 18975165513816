import { useLocalization } from "@fluent/react";
import styles from "./SettingsBanner.module.scss";

const SettingsBanner = () => {
  const { l10n } = useLocalization();
  return (
    <div className={styles.banner}>
      <div className={styles.content}>
        <h1 className={styles.title}>{l10n.getString("heading-settings")}</h1>
      </div>
    </div>
  );
};

export default SettingsBanner;
