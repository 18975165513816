import { useContext, useEffect, forwardRef, ReactNode, Fragment } from "react";
import { useLocalization } from "@fluent/react";
import { Button, TruncatedAccount } from "@narmi/design_system";
import Account from "byzantine/src/Account";
import App from "byzantine/src/App";
import { featureEnabled } from "byzantine/src/Feature";
import utils from "byzantine/src/utils";
import { useNotificationContext } from "cerulean";

import AccountList from "./AccountList";
import AppAuthorize from "./AppAuthorize";
import NavBar from "./NavBar";
import AccountContext from "./contexts/AccountContext";
import { InstitutionSettingsContextProvider } from "./contexts/InstitutionSettingsContext";
import Details from "./Details";
import useAccountSorting from "./hooks/useAccountSorting";
import { useCurrentUser } from "./contexts/CurrentUserContext";
import { LegacyNafUrl, Institution } from "../types";

interface RenderGroupProps {
  children: ReactNode;
  numGroups: number;
}

const RenderGroup = ({ children, numGroups }: RenderGroupProps) => (
  <div className={`account-group navbar-accountGroupColumn--${numGroups}`}>
    {children}
  </div>
);

interface RenderHeaderProps {
  group: ReactNode;
}

const RenderHeader = ({ group }: RenderHeaderProps) => (
  <div className="group-header">{group}</div>
);

interface RenderAccountProps {
  account: Account;
}

const RenderAccount = forwardRef<HTMLAnchorElement, RenderAccountProps>(
  ({ account }, ref) => (
    <div>
      <a
        className="fontWeight--default nds-button nds-button--menu"
        href={`/accounts/${account.id}`}
        aria-label={`Account Group - ${account.getGroupName()} - ${
          account.name
        } ${account.getMaskedNumber()}`}
        ref={ref}
      >
        <TruncatedAccount
          name={account.name}
          lastFour={account.getMaskedNumber()}
        />
      </a>
    </div>
  ),
);

RenderAccount.displayName = "RenderAccount";

interface TopMenuProps {
  accounts: Account[];
  features: API.Features;
  institution: Institution;
  legacyNafUrls: LegacyNafUrl[];
  deviceIsRemembered: boolean;
}

interface BaseMenuItem {
  feature_flag?: string;
  superscript?: string;
}

interface AppMenuItem extends BaseMenuItem {
  app: App;
}

interface LinkMenuItem extends BaseMenuItem {
  url: string;
  openNewTab?: boolean;
}

interface AccountMenuItem extends BaseMenuItem {
  accounts: Account[];
}

interface NAFMenuItem extends BaseMenuItem {
  naf: true;
}

interface NestedMenu<T> extends BaseMenuItem {
  menu: T;
}

type AnyMenuItem<T> =
  | AppMenuItem
  | LinkMenuItem
  | AccountMenuItem
  | NAFMenuItem
  | NestedMenu<T>;

interface TopMenuType {
  [key: string]: AnyMenuItem<TopMenuType>;
}

type AnyMenuItemType = AnyMenuItem<TopMenuType>;

const isNestedMenuItem = (
  item: AnyMenuItemType,
): item is NestedMenu<TopMenuType> => "menu" in item;

const isLinkItem = (item: AnyMenuItemType): item is LinkMenuItem =>
  "url" in item;

const isAppItem = (item: AnyMenuItemType): item is AppMenuItem => "app" in item;

const isNafItem = (item: AnyMenuItemType): item is NAFMenuItem => "naf" in item;

const isAccountItem = (item: AnyMenuItemType): item is AccountMenuItem =>
  "accounts" in item;

interface RenderItemMenuProps {
  name: string;
  itemProps: AnyMenuItemType;
}

function TopMenu({
  accounts: preloadedAccounts,
  features,
  institution,
  legacyNafUrls,
  deviceIsRemembered,
}: TopMenuProps) {
  const accountContext = useContext(AccountContext);
  const { currentUser } = useCurrentUser();
  const accounts = accountContext?.accounts || preloadedAccounts;
  const { orderedAccounts = [] } = currentUser
    ? useAccountSorting(accounts)
    : {};

  const renderItemMenu = ({ name, itemProps }: RenderItemMenuProps) => {
    // Enable each menu item to be conditional by feature flag
    if (
      itemProps.feature_flag &&
      !featureEnabled(features, { or: itemProps.feature_flag })
    ) {
      return null;
    }

    let label: ReactNode = name;
    if (itemProps.superscript) {
      label = (
        <>
          {name}
          <sup>{itemProps.superscript}</sup>
        </>
      );
    }

    // Handle each potential menu option
    if (isLinkItem(itemProps)) {
      const { url, openNewTab } = itemProps;
      return (
        <Button
          as="a"
          label={label}
          kind="menu"
          href={!openNewTab ? url : undefined}
          onClick={openNewTab ? () => window.open(url, "_blank") : undefined}
          key={name}
        />
      );
    }

    if (isNestedMenuItem(itemProps)) {
      const renderedItems = Object.entries(itemProps.menu)
        .map(([nestedItemName, nestedItemProps]) =>
          renderItemMenu({ name: nestedItemName, itemProps: nestedItemProps }),
        )
        .filter(Boolean);

      const unpackedItems = renderedItems.flatMap((item) => {
        if (item && item.key !== "naf") {
          return item;
        }
        return item?.props.children;
      });

      const chunkSize = unpackedItems.length % 5 !== 1 ? 5 : 6;
      const chunkedItems = utils.shardArrayByChunkSize(
        unpackedItems,
        chunkSize,
      );
      return (
        <Details
          summary={<Button as="a" kind="menu" label={name} />}
          key={name}
          type="wide details"
        >
          {chunkedItems.map((menuChunk, index) => (
            <div key={index} className="tools">
              {menuChunk}
            </div>
          ))}
        </Details>
      );
    }

    if (isAccountItem(itemProps)) {
      return (
        <Details
          summary={<Button as="a" kind="menu" label="Accounts" tabIndex={-1} />}
          key="Accounts"
          type="wide details"
          ariaLabel="Accounts menu"
        >
          <AccountList
            accounts={orderedAccounts.filter((a) => a.isInternal())}
            alwaysGroup={true}
            RenderGroup={RenderGroup}
            RenderHeader={RenderHeader}
            RenderAccount={RenderAccount}
          />
        </Details>
      );
    }

    if (isAppItem(itemProps)) {
      return (
        <AppAuthorize app={itemProps.app} key={itemProps.app.name}>
          {itemProps.app.name}
        </AppAuthorize>
      );
    }

    if (isNafItem(itemProps)) {
      const listOfNafItems: { [key: string]: AppMenuItem | LinkMenuItem } = {};
      institution.apps?.forEach((app) => {
        listOfNafItems[app.name] = { app };
      });
      legacyNafUrls.forEach((link) => {
        listOfNafItems[link.title] = { url: link.url, openNewTab: true };
      });

      return (
        <Fragment key="naf">
          {Object.entries(listOfNafItems).map(([nafName, nafItemProps]) =>
            renderItemMenu({ name: nafName, itemProps: nafItemProps }),
          )}
        </Fragment>
      );
    }

    return null;
  };

  const menuItems = currentUser ? features.top_menu || {} : {};

  const { sendNotification } = useNotificationContext();
  const { l10n } = useLocalization();

  // For institutions that require MFA, inform users without a permitted device
  useEffect(() => {
    if (currentUser?.has_permitted_device === false && sendNotification) {
      sendNotification({
        type: "info",
        text: l10n.getString(
          "info-add-permitted-mfa-device",
          null,
          "We've updated our account security. Please add a new two-factor authentication device, which is required to access Digital Banking. [Add one now](/settings/security).",
        ),
      });
    }
  }, [currentUser?.has_permitted_device, sendNotification, l10n]);

  return (
    <NavBar
      features={features}
      deviceIsRemembered={deviceIsRemembered}
      institution={institution}
    >
      {Object.entries(menuItems)
        .map(([name, itemProps]) => renderItemMenu({ name, itemProps }))
        .filter(Boolean)}
    </NavBar>
  );
}

interface TopMenuContainerProps {
  accounts: Account[];
  features: API.Features;
  institution: Institution;
  legacyNafUrls: LegacyNafUrl[];
  deviceIsRemembered: boolean;
}

const TopMenuContainer = (props: TopMenuContainerProps) => (
  <InstitutionSettingsContextProvider>
    <TopMenu {...props} />
  </InstitutionSettingsContextProvider>
);

export default TopMenuContainer;
