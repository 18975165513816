import { useMemo } from "react";

import { useSelector } from "react-redux";

import {
  isFeatureEnabled,
  isFeatureDisabled,
  doesFeatureEqual,
} from "./isEnabled";
import { selectFeatures } from "./selectors";

import type { FeatureQuery, FeatureValue } from "./types";

export const useFeature = () => {
  const features = useSelector(selectFeatures);

  return useMemo(
    () => ({
      featureEnabled: (query: FeatureQuery | string) =>
        isFeatureEnabled(
          features,
          typeof query !== "string" ? query : { or: query },
        ),

      featureDisabled: (feature: string) =>
        isFeatureDisabled(features, feature),

      featureEquals: (flag: string, setting: FeatureValue | FeatureValue[]) =>
        doesFeatureEqual(features, flag, setting),

      features,
    }),
    [features],
  );
};
