/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

const name = "user";

type UserSliceState = {
  user: API.User | null;
};

const initialState: UserSliceState = {
  user: null,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    upsert: (state, action: PayloadAction<API.User | null>) => {
      state.user = action.payload;
    },
  },
});

export const { actions } = slice;

export default {
  [name]: slice.reducer,
};
