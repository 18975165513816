import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import { Checkbox, Grid, Confirm } from "semantic-ui-react";
import { ContentCard, LoadingSkeleton, Tooltip } from "cerulean";

import Organization from "byzantine/src/Organization";
import OrganizationUser from "byzantine/src/OrganizationUser";
import InstitutionSettingsContext, {
  InstitutionSettingsContextProvider,
} from "../contexts/InstitutionSettingsContext";

const DualApprovalCheckbox = ({ required, toggle }) => {
  const { l10n } = useLocalization();
  const checkbox = (
    <Checkbox
      data-testid="dual-approval-checkbox"
      toggle
      checked={required}
      onChange={toggle}
    />
  );

  return required ? (
    <Tooltip text={l10n.getString("approval-disable-support")}>
      {checkbox}
    </Tooltip>
  ) : (
    checkbox
  );
};

DualApprovalCheckbox.propTypes = {
  required: PropTypes.bool,
  toggle: PropTypes.func,
};

const DualApprovalOptIn = () => {
  const { l10n } = useLocalization();
  const [orgUuid, setOrgUuid] = useState(undefined);
  const [orgRole, setOrgRole] = useState(undefined);
  const [organization, setOrganization] = useState(undefined);

  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const { short_name, features } = useContext(InstitutionSettingsContext);

  // load org on mount and store it in the DOM (if not already populated)
  useEffect(() => {
    const bootstrapOrgData = async () => {
      setLoading(true);
      const currentOrgUser = await OrganizationUser.currentOrgUser();
      // check if member of an org
      if (!currentOrgUser) {
        setLoading(false);
        return;
      }

      setOrgUuid(currentOrgUser.organization_uuid);
      setOrgRole(currentOrgUser.role.name);

      // load dualApproval status
      const org = await Organization.dualApprovalRequired(
        currentOrgUser.organization_uuid,
      );
      setOrganization(() => org);
      setLoading(false);
    };
    bootstrapOrgData();
  }, []); // no dependencies, trigger only once

  /*
   *  Component Methods
   */

  const showConfirmModal = () => setShowConfirm(true);
  const closeConfirmModal = () => setShowConfirm(false);

  const toggleDualApproval = async () => {
    const org = await Organization.dualApprovalRequired(
      orgUuid,
      "POST",
      !organization.dual_approval_required,
    );
    setOrganization(() => org);
    closeConfirmModal();
    return org;
  };

  const maybeToggleRequired = () => {
    if (loading) {
      return false;
    }
    // can't turn off unless dual_approval_user_control is enabled
    if (
      organization.dual_approval_required &&
      !organization.dual_approval_user_control
    ) {
      return false;
    }

    if (!organization.dual_approval_required) {
      return showConfirmModal();
    }
    return toggleDualApproval();
  };

  if (
    !(
      orgRole &&
      OrganizationUser.ROLES_WITH_EDIT_PERMISSION.includes(orgRole) &&
      features &&
      features.dual_approval
    )
  ) {
    return null;
  }

  return (
    <div id="dual-approvals">
      <ContentCard>
        <Grid columns={2}>
          <Grid.Row verticalAlign="middle">
            <Grid.Column>
              <h3 className="no-margin">
                {l10n.getString("heading-dual-approvals")}
              </h3>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <LoadingSkeleton isLoading={loading} lines={1}>
                <DualApprovalCheckbox
                  required={organization?.dual_approval_required}
                  toggle={maybeToggleRequired}
                />
                <Confirm
                  data-testid="dual-approval-confirm"
                  header={l10n.getString("heading-dual-approvals")}
                  content={l10n.getString("confirm-enable-dual-approvals", {
                    shortName: short_name,
                  })}
                  cancelButton={l10n.getString("label-cancel")}
                  confirmButton={l10n.getString("button-confirm-turn-on")}
                  open={showConfirm}
                  onConfirm={toggleDualApproval}
                  onCancel={closeConfirmModal}
                />
              </LoadingSkeleton>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <LoadingSkeleton isLoading={loading} content={"paragraph"}>
          <Grid>
            <div>
              <Grid.Column>
                <p className="ui left floated padding--bottom--m">
                  {l10n.getString("dual-approval-description", {
                    shortName: short_name,
                  })}
                </p>
              </Grid.Column>
            </div>
          </Grid>
        </LoadingSkeleton>
      </ContentCard>
    </div>
  );
};

const DualApprovalOptInContainer = () => (
  <InstitutionSettingsContextProvider>
    <DualApprovalOptIn />
  </InstitutionSettingsContextProvider>
);

export default DualApprovalOptInContainer;
