import MarketingImageTargetWeb from "../../marketing";
import { useUserFeatures } from "../../contexts/UserFeaturesContext";
import { MarketingUserFeatures } from "../MarketingCardContainer/MarketingCardContainer";

type ImagePlacementAddress =
  | "b3bbe7e6-f68e-45e3-8124-691e18a4a3ca"
  | "fb06f1b7-87ff-4fcf-8a98-51a7fb4ba36d";

const placementAttributesByAddress: Record<
  ImagePlacementAddress,
  {
    width: number;
    height: number;
    containerClassName?: string;
  }
> = {
  "b3bbe7e6-f68e-45e3-8124-691e18a4a3ca": {
    width: 300,
    height: 250,
    containerClassName:
      "dashboard-sidebar-card-image alignChild--center--center",
  },
  "fb06f1b7-87ff-4fcf-8a98-51a7fb4ba36d": {
    width: 728,
    height: 90,
    containerClassName:
      "dashboard-banner-image alignChild--center--center margin--top--xl",
  },
};

interface MarketingImageBannerProps {
  address: ImagePlacementAddress;
}

const getPlacementSizeByAddress = (address: ImagePlacementAddress) =>
  placementAttributesByAddress[address];

const MarketingImage = ({ address }: MarketingImageBannerProps) => {
  const { width, height, containerClassName } =
    getPlacementSizeByAddress(address);
  const features = useUserFeatures() as MarketingUserFeatures;

  return features.olb_enable_marketing_banners ? (
    <div className={containerClassName}>
      <MarketingImageTargetWeb
        address={address}
        debugSize={{ width, height }}
      />
    </div>
  ) : null;
};

export default MarketingImage;
