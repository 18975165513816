import { useCallback } from "react";

import { modules, useObjectMemo } from "byzantine";
import { useNavigate } from "react-router-dom";

export type Method = "none" | "freeform" | "template";

export const OPTIONS =
  modules.wireTemplates.METHOD_OPTIONS.reverse().reduceRight(
    (prev, cur) => ({ ...prev, [cur.label]: cur.id }),
    {}
  );

export const useFormContainer = () => {
  const { submitMethod, method, form } =
    modules.wireTemplates.useMethodSelector();

  const navigate = useNavigate();
  const onCancel = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onSubmitMethod = useCallback(async () => {
    await submitMethod();
  }, [submitMethod]);

  return useObjectMemo({
    method,
    onCancel,
    onSubmitMethod,
    form,
  });
};
