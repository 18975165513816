import { matchRoutes, useLocation } from "react-router-dom";

const DRAWER_ROUTES = [
  { path: "/cards/:id" },
  { path: "/cards/:id/travel_notice" },
];

const useIsCardManagerDrawerOpen = () => {
  const location = useLocation();
  return (matchRoutes(DRAWER_ROUTES, location?.pathname) || []).length > 0;
};

export default useIsCardManagerDrawerOpen;
