import React from "react";
import { TextInput } from "@narmi/design_system";
import TooltipTextInput from "../TooltipTextInput";

/**
 *
 * NDS TextInput configured for entering Tax ID (SSN, EIN).
 * `TaxIDTextInput` is intended to be used as a controlled component.
 * Only numeric characters are permitted,
 * and the value displayed will be formatted as the user types as XXX - XX - XXXX (or XX - XXXXXXX if the `ein` prop is `true`).
 * However, the value transmitted up to the parent via `onChange` will only include numeric characters as XXXXXXXXX
 *
 */

export const formatEin = (value: string) => {
  if (!value) return "";
  if (value.length < 2) return value;
  return `${value.substring(0, 2)}-${value.substring(2, 9)}`;
};

export const formatSsnOrTaxID = (value: string) => {
  if (value.length < 3) return value;
  if (value.length < 5) return `${value.substring(0, 3)}-${value.substring(3, 5)}`;
  return `${value.substring(0, 3)}-${value.substring(3, 5)}-${value.substring(5, 9)}`;
};

export const validateTaxID = (value: string) => {
  if (value && value.replace("-", "").length < 9) {
    return "Please enter a valid Tax ID/EIN";
  }
  return null;
};

function stripToTaxId(value: string) {
  return value.replace(/[^\d]/g, "").substring(0, 9);
}

export const MaskedTaxIDTextInput = React.forwardRef(
  (
    {
      isInitiallyMasked,
      label,
      tooltipText,
      onChange,
      value,
      error,
    }: {
      isInitiallyMasked: boolean;
      label: string;
      tooltipText?: string;
      onChange: (value: string) => void;
      value: string;
      error?: string;
    },
    parentRef
  ) => {
    const [showMask, setShowMask] = React.useState(isInitiallyMasked);
    // const ref = React.useRef < HTMLInputElement > null;

    // React.useEffect(() => {
    //   if (isInitiallyMasked && !showMask) {
    //     ref.current?.focus();
    //   }
    // }, [isInitiallyMasked, showMask]);

    if (showMask) {
      return (
        <TextInput value="***-**-****" label={label} readOnly onFocus={() => setShowMask(false)} />
      );
    }
    return (
      <TaxIDTextInput
        ref={parentRef}
        label={label}
        tooltipText={tooltipText}
        onChange={onChange}
        value={value}
        error={error}
        autoComplete="off"
      />
    );
  }
);

const TaxIDTextInput = React.forwardRef(
  (
    {
      ein = false,
      value = "",
      onChange,
      label,
      name = "tax_id",
      id = "tax_id",
      tooltipText = "",
      ...otherTextInputProps
    }: {
      ein?: boolean;
      value?: string;
      onChange: (value: string) => void;
      label: string;
      name?: string;
      id?: string;
      tooltipText?: string;
    } & React.ComponentProps<typeof TextInput>,
    forwardedRef
  ) => {
    const ref = React.useRef<HTMLInputElement>(null);

    React.useImperativeHandle(forwardedRef, () => ref.current);

    const Component: React.ComponentType<React.ComponentProps<typeof TextInput>> = tooltipText
      ? TooltipTextInput
      : TextInput;
    const conditionalProps: Partial<React.ComponentProps<typeof TextInput>> = {};

    if (tooltipText) {
      conditionalProps.tooltipText = tooltipText;
    }

    const renderedValue = ein ? formatEin(value) : formatSsnOrTaxID(value);

    const [cursorPosition, setCursorPosition] = React.useState<number>();

    React.useEffect(() => {
      if (!ref.current || cursorPosition === undefined) {
        return;
      }
      const input = ref.current;
      // if the value at the cursor is a dash, go to the next character
      const index =
        renderedValue.charAt(cursorPosition) === "-" ? cursorPosition + 1 : cursorPosition;
      requestAnimationFrame(() => {
        input.setSelectionRange(index, index);
      });
    }, [ref.current, cursorPosition, renderedValue]);

    return (
      <Component
        id={id}
        name={name}
        label={label}
        ref={ref}
        value={renderedValue}
        inputMode="numeric"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const index = (event.target as HTMLInputElement)?.selectionStart;
          if (index === null) {
            // this might be through automation or something, so directly set the value.
            onChange(stripToTaxId(event.target.value));
            return;
          }
          const isBackspace =
            (event.nativeEvent as InputEvent).inputType === "deleteContentBackward";
          if (isBackspace && renderedValue.charAt(index) === "-") {
            // the character deleted was a dash, so we need to find the dash and delete the character before it
            setCursorPosition(index - 1);
            onChange(
              stripToTaxId(renderedValue.substring(0, index - 1) + renderedValue.substring(index))
            );
            return;
          }
          const isPaste = (event.nativeEvent as InputEvent).inputType === "insertFromPaste";
          if (isPaste) {
            // if this is from a paste, don't manipulate the cursor position.
            setCursorPosition(undefined);
          } else {
            setCursorPosition(index);
          }
          onChange(stripToTaxId(event.target.value));
        }}
        {...conditionalProps}
        {...otherTextInputProps}
      />
    );
  }
);

export default TaxIDTextInput;
