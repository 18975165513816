// @ts-ignore
import React, { DependencyList, useEffect, useRef } from "react";

const useTimeout = (callback: () => void, delay: number, deps: DependencyList = []) => {
  /*
  custom hook that cleans up after every render so we don't have multiple ongoing timers
  courtesy of https://felixgerschau.com/react-hooks-settimeout/
  */

  // store the latest callback if it changes
  const savedCallback = useRef(callback);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // sets up and tears down the timer if delay changes
  useEffect(() => {
    // if no delay is specified, don't start the timer
    if (delay === null) {
      return undefined;
    }

    const timerId = setTimeout(() => savedCallback.current(), delay);

    return function cleanup() {
      clearTimeout(timerId);
    };
  }, [delay, ...deps]);
};

export default useTimeout;
