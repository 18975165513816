import WireRecipient from "../../../../WireRecipient";

import type { NetworkPayload } from "../../../composites";

export const API = {
  fetchRecent: (accountId: API.AccountId): NetworkPayload => {
    return {
      url: `wires?${encodeURIComponent("account_uuid")}=${encodeURIComponent(
        accountId,
      )}`,
      options: {
        method: "GET",
      },
    };
  },

  saveWireRecipient: (
    wireRecipient: API.CreateOrUpdateWireRecipient.Request,
  ): NetworkPayload => ({
    url: "wire_recipients/",
    options: {
      method: "POST",
      payload: new WireRecipient(wireRecipient).serialize(),
    },
  }),

  listWireRecipients: (): NetworkPayload => ({
    url: "wire_recipients/",
    options: {
      method: "GET",
    },
  }),

  deleteWireRecipient: (id: API.WireRecipientId): NetworkPayload => ({
    url: `wire_recipients/${id}/`,
    options: {
      method: "DELETE",
      isJsonResponse: false,
    },
  }),
};
