import ApiHttp from "./ApiHttp";

interface SerializedMemberFriend {
  id: string;
  beneficiary_name: string;
  to_account_number: string;
  description: string;
}

interface MemberFriendProps {
  id: string;
  name: string;
  masked_account_number: string;
  description: string;
}

class MemberFriend {
  public id: string;

  public name: string;

  public masked_account_number: string;

  public description: string;

  constructor(props: MemberFriendProps) {
    this.id = props.id;
    this.name = props.name;
    this.masked_account_number = props.masked_account_number;
    this.description = props.description;
  }

  displayMaskedAccountNumber() {
    return `***${this.masked_account_number}`;
  }

  delete() {
    return ApiHttp.fetch(`m2m/${this.id}`, { method: "DELETE" });
  }

  /**
   * Fetches member friends and deserializes them.
   * @returns {Promise<MemberFriend[]>}
   */
  static async getMemberFriends() {
    const response = (await ApiHttp.fetch("m2m")) as {
      member_friends: SerializedMemberFriend[];
    } | null;

    if (!response?.member_friends) {
      return undefined;
    }

    return MemberFriend.orderAlphabeticallyByName(
      response.member_friends.map((m) => this.deserialize(m)),
    );
  }

  static orderAlphabeticallyByName(memberfriends: MemberFriend[]) {
    return memberfriends.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  /**
   * Factory method returning a new instance of MemberFriend from
   * an indigo.serialized MemberFriend
   */
  static deserialize(payload: SerializedMemberFriend) {
    return new MemberFriend({
      ...payload,
      name: payload.beneficiary_name,
      masked_account_number: payload.to_account_number,
    });
  }
}

export default MemberFriend;
