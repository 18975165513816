import { useCallback, useState } from "react";

import { useNavigate } from "react-router-dom";
import { MenuButton, Button, Dialog, Row } from "cerulean";
import { entities, modules } from "byzantine";

import PreviewCard from "../../../../../../../components/PreviewCard/PreviewCard";

import styles from "./Template.module.scss";

interface TemplateProps {
  template: API.FedwireTemplate;
  recipient: API.WireRecipient;
}

const Template = ({ template, recipient }: TemplateProps) => {
  const navigate = useNavigate();
  const { deleteTemplate, loading } =
    entities.fedwireTemplates.useDeleteTemplateRequest();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const goToEdit = useCallback(() => {
    navigate(`/wires/templates/edit/${template.id}`);
  }, [navigate, template.id]);

  const destinationAccountPreview =
    modules.wireTemplates.getDestinationAccountPreview(recipient);

  const ActionButton = (
    <MenuButton label="template action menu">
      <MenuButton.Item label="Edit" startIcon="edit-2" onSelect={goToEdit} />
      <MenuButton.Item
        label="Delete"
        startIcon="trash-2"
        onSelect={() => setIsDeleteDialogOpen(true)}
      />
    </MenuButton>
  );

  return (
    <>
      <div className={styles.templateCardContainer}>
        <PreviewCard
          header={template.name}
          subHeader={recipient.name}
          actionButton={ActionButton}
          iconName="narmi-icon-bank"
          iconContent={destinationAccountPreview}
        />
      </div>

      <Dialog
        isOpen={isDeleteDialogOpen}
        onUserDismiss={() => setIsDeleteDialogOpen(false)}
        title={`Delete ${template.name}?`}
        width={"385px"}
        footer={
          <Row alignItems="center" justifyContent="end">
            <Row.Item shrink>
              <Button
                onClick={() => setIsDeleteDialogOpen(false)}
                kind="negative"
                label="Cancel"
                disabled={loading}
              />
            </Row.Item>
            <Row.Item shrink>
              <Button
                onClick={() => deleteTemplate(template.id)}
                kind="primary"
                label="Yes, delete"
                isLoading={loading}
                disabled={loading}
              />
            </Row.Item>
          </Row>
        }
      >
        <div style={{ overflowWrap: "anywhere" }}>
          Are you sure that you want to delete {template.name}?
        </div>
      </Dialog>
    </>
  );
};

export default Template;
