import { Button, IconButton } from "@narmi/design_system";
import type MembershipType from "byzantine/src/Membership";
import { Dialog, useLoadingContext, useNotificationContext } from "cerulean";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./MembershipInfoRow.module.scss";

interface MembershipInfoRowProps {
  name: ReactNode;
  membership: MembershipType;
  removeMembership?: CallableFunction;
}
const MembershipInfoRow = ({
  name,
  membership,
  removeMembership,
}: MembershipInfoRowProps) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { setIsLoading } = useLoadingContext();
  const { sendNotification } = useNotificationContext();
  const { t } = useTranslation();

  const handleDelete = () => {
    setIsLoading(true);
    membership
      .delete()
      .then(() => {
        setDeleteModalOpen(false);
        setIsLoading(false);
        sendNotification({
          type: "success",
          text: t("Account successfully unlinked"),
        });
        removeMembership?.(membership.id);
      })
      .catch((err: string) => {
        setIsLoading(false);
        sendNotification({
          type: "negative",
          text: t(err),
        });
      });
  };

  return (
    <>
      <div className={styles["membership-info-row"]}>
        <span className={styles.left}>{name}</span>
        <span className={styles.right}>
          <div className={styles["membership-id"]}>{membership.id}</div>
          {removeMembership && (
            <IconButton
              kind="plain"
              label="Edit"
              name="trash-2"
              onClick={setDeleteModalOpen}
            />
          )}
        </span>
      </div>
      <Dialog
        isOpen={deleteModalOpen}
        footer={
          <div style={{ textAlign: "right" }}>
            <Button kind="negative" onClick={() => setDeleteModalOpen(false)}>
              {t("Cancel")}
            </Button>
            <span className="margin--left--s">
              <Button onClick={handleDelete}>{t("Yes, unlink")}</Button>
            </span>
          </div>
        }
        onUserDismiss={() => setDeleteModalOpen(false)}
        title={t("Unlink account?")}
      >
        <div>Are you sure that you want to unlink this account?</div>
      </Dialog>
    </>
  );
};

export default MembershipInfoRow;
