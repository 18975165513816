import React from "react";
import { Select } from "@narmi/design_system";

interface LangSelectProps {
  localeCodes: string[];
  localeNames: string[];
  onChange: (event: any) => void;
  selectedLocale: string;
}

const noop = () => {};

/**
 * **Fully controlled** Language selection component.
 *
 * These props should be taken from the result of the `useFluentLocalization` hook.
 * Size constraints should be managed by a parent wrapper.
 */
const LangSelect = ({
  localeCodes,
  localeNames,
  onChange = noop,
  selectedLocale = "en",
}: LangSelectProps): JSX.Element => (
  <Select label="Language" onChange={onChange} value={selectedLocale}>
    {localeCodes.map((localeCode, i) => (
      <Select.Item key={localeCode} value={localeCode}>
        {localeNames[i]}
      </Select.Item>
    ))}
  </Select>
);

export default LangSelect;
