import Account from "./Account";
import Address from "./Address";
import ApiHttp from "./ApiHttp";

export type WireRecipientProps = {
  id?: string;
  name: string;
  address: API.Address;
  routing_number: string;
  account_number: string;
  created_at?: string;
  destination_institution_name?: string;
};

export default class WireRecipient {
  public id?: string;

  public name: string;

  public address: Address;

  public routing_number: string;

  public account_number: string;

  public created_at?: string;

  public destination_institution_name?: string;

  constructor({
    id,
    name,
    address,
    routing_number,
    account_number,
    created_at,
    destination_institution_name,
  }: WireRecipientProps) {
    this.id = id;
    this.name = name;
    this.address = new Address(address);
    this.routing_number = routing_number;
    this.account_number = account_number;
    this.created_at = created_at;
    this.destination_institution_name = destination_institution_name;
  }

  get maskedAccountNumber() {
    return [
      "**",
      new Account({ number: this.account_number }).getMaskedNumber(),
    ].join("");
  }

  get bankDetails() {
    return `${this.destination_institution_name}-${this.maskedAccountNumber}`;
  }

  get displayValue() {
    return `${this.name} - ${new Account({
      number: this.account_number,
    }).getMaskedNumber()}`;
  }

  static async fetchAll() {
    const response = await ApiHttp.fetch("wire_recipients/");
    return response.results.map((r: WireRecipientProps) =>
      WireRecipient.deserialize(r),
    );
  }

  static async create(payload: WireRecipientProps) {
    const response = await ApiHttp.fetch(
      "wire_recipients/",
      { method: "POST" },
      payload,
    );
    return [WireRecipient.deserialize(response), response];
  }

  async update() {
    return ApiHttp.fetch(
      `wire_recipients/${this.id}/`,
      { method: "PUT" },
      this,
    );
  }

  static deserialize(payload: WireRecipientProps) {
    const wireRecipient = new WireRecipient(payload);
    return wireRecipient;
  }

  async delete() {
    return ApiHttp.fetch(`wire_recipients/${this.id}`, {
      method: "DELETE",
    });
  }

  serialize() {
    return {
      id: this.id,
      name: this.name,
      address: {
        street_address: this.address.street_address,
        street_address_2: this.address.street_address_2,
        city: this.address.city,
        region_code: this.address.region_code,
        postal_code: this.address.postal_code,
        country_code: this.address.country_code,
      },
      routing_number: this.routing_number,
      account_number: this.account_number,
      created_at: this.created_at,
      destination_institution_name: this.destination_institution_name,
    };
  }
}
