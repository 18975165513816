import React from "react";
import { Row } from "@narmi/design_system";

interface RowItemProps {
  rowName: string;
  rowValue: string;
}

const RowItem = ({ rowName, rowValue }: RowItemProps) => {
  return (
    <div className="row-item-container">
      <Row>
        <Row.Item>
          <span className="row-item-name">{rowName}</span>
        </Row.Item>
        <div className="row-item-value">
          <Row.Item>{rowValue}</Row.Item>
        </div>
      </Row>
    </div>
  );
};

export default RowItem;
