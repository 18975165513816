import React from "react";
import { Tooltip, TextInput } from "@narmi/design_system";

interface TooltipTextInputProps {
  label: string;
  name: string;
  tooltipText: string;
}

const TooltipTextInput = React.forwardRef<
  typeof TextInput,
  React.PropsWithChildren<TooltipTextInputProps>
>(({ label, name, tooltipText, ...otherProps }, ref) => (
  <div className="tooltip-input">
    <TextInput
      label={label}
      name={name}
      ref={ref}
      {...otherProps}
      endContent={
        <Tooltip text={tooltipText}>
          <span className="narmi-icon-info" />
        </Tooltip>
      }
    />
  </div>
));

export default TooltipTextInput;
