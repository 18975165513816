import React, { useEffect } from "react";

const setGliaVisibility = (visibility: boolean) => {
  const style = visibility ? "" : "display: none !important";
  const ctaElement = document.querySelector("#salemove");
  ctaElement?.setAttribute("style", style);
};

const useSetGliaVisibility = (visibility: boolean) => {
  useEffect(() => {
    setGliaVisibility(visibility);
    return () => {
      setGliaVisibility(true);
    };
  }, [visibility, window.location.pathname]);
};

export default useSetGliaVisibility;
