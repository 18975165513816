// sm is for the glia js sdk integration
// Should be first to avoid dependency issues
import "es6-promise/auto";
import "core-js/stable";
import "regenerator-runtime/runtime";

import "./azul.scss";

import React from "react";
import ReactDOM from "react-dom/client";
import { initReactI18next } from "react-i18next";
import i18next from "i18next";

import { withNotifications } from "cerulean";
import OrganizationUser from "byzantine/src/OrganizationUser";
import User from "byzantine/src/User";
import Account from "byzantine/src/Account";
import Membership from "byzantine/src/Membership";
import { SupportContact } from "byzantine/src/types/models/v1/Support.types";
import {
  SupportFaqCategory,
  SupportLink
} from "byzantine/src/types/SettingLocalizers.types";
import App from "byzantine/src/App";
import { preloadState } from "./src";

import AppProviders from "./components/AppProviders";
import EstatementsManager from "./components/estatements/EstatementsManager";
import TransferPageContainer from "./components/transfer/TransferPageContainer";
import AccountPageContainer from "./components/accounts/AccountPageContainer";
import ReferAMember from "./components/refer_a_member/ReferAMember";
import BeneficiariesContainer from "./components/beneficiaries/BeneficiariesContainer";
import LoginPageContainer from "./components/authentication/LoginPageContainer";
import ChangePasswordContainer from "./components/authentication/ChangePasswordContainer";
import PasswordResetPageContainer from "./components/authentication/PasswordResetPageContainer";
import MfaDeviceSelect from "./components/authentication/MfaDeviceSelect";
import MfaCodeEnter from "./components/authentication/MfaCodeEnter";
import EnrollmentPageContainer from "./components/enrollments/EnrollmentPageContainer";
import InactivityDialog from "./components/InactivityDialog";
import AutomaticSavingsPageContainer from "./components/automatic_savings/AutomaticSavingsPageContainer";

import OrganizationUsersList from "./components/organization/OrganizationUsers";
import OrganizationNewUser from "./components/organization/OrganizationNewUser";
import OrganizationEditUser from "./components/organization/OrganizationEditUser";
import DualApprovalRequestList from "./components/dual_approval/DualApprovalRequestList";
import DualApprovalNotificationBar from "./components/dual_approval/DualApprovalNotificationBar";
import SupportPageLayout from "./components/support_page/SupportPageLayout";
import MessageCenterLayout from "./components/message_center/MessageCenterLayout";

import AppAuthorize from "./components/AppAuthorize";
import Main from "./components/Main";
import InstitutionAnnouncement from "./components/InstitutionAnnouncement";
import OAuth2Authorize from "./components/OAuth2Authorize";
import SettingsNavBarContainer from "./components/settings/SettingsNavBar";

import DocumentList from "./components/documents/DocumentList";
import SsoRedirectAccountList from "./components/SsoRedirectAccountList";
import SignedUrlIframe from "./components/SignedUrlIframe";
import ServicePageContainer from "./components/service_page/ServicePageContainer";

import DisclosuresReconsentDialog from "./components/DisclosuresReconsentDialog";
import type { LegacyNafUrl, Institution } from "./types";

/* Security page */
import TopMenu from "./components/TopMenu";
import Footer from "./components/Footer";

import AddCardNafApp from "./components/transfer/loans/payment_by_card/add_a_card/AddCardNafApp";

declare global {
  interface Window {
    azulI18nCatalog: { [key: string]: string };
    sm: any;
    isFetchingFtls: boolean;
  }
}

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "en",
    nsSeparator: false,
    keySeparator: false,
    resources: {
      en: { translation: window?.azulI18nCatalog || {} },
    },
  });

const initialiseGlia = ({
  full_name: name,
  email,
  core_user_id: externalId,
}: {
  full_name: string;
  email: string;
  core_user_id: string;
}) => {
  if (typeof window?.sm === "undefined") {
    // for slower internet connections, sm can be undefined when initialiseGlia is called
    setTimeout(initialiseGlia, 1000, {
      full_name: name,
      email,
      core_user_id: externalId,
    });
    return;
  }

  window.sm
    .getApi({ version: "v1" })
    .then(
      (salemove: {
        updateInformation: ({
          name,
          email,
          externalId,
        }: {
          name: string;
          email: string;
          externalId: string;
        }) => void;
      }) =>
        salemove.updateInformation({
          name,
          email,
          externalId,
        }),
    );
};

// Microfrontend localization handling...
//
// The first useFluentLocalization hook that runs
// will read this and flip it to `true` while fetching FTLs.
//
// Hooks that run _after_ this is set to `true` will instead
// subscribe to a CustomEvent to get the loaded `ReactLocalization`
window.isFetchingFtls = false;

interface DataToPreload {
  user?: API.User;
  accounts?: API.AnyAccount[];
  limits?: any;
}

document.addEventListener("DOMContentLoaded", () => {
  const dataToPreload: DataToPreload = {};

  let institution: Institution = {
    name: "",
    internalName: "",
    type: "",
    primaryColor: "",
    s3ImagesBucket: "",
    secondary_color: "",
    tertiary_color: "",
    logo: "",
    apps: [],
  };
  if (document.getElementById("institution_long_name")) {
    institution = {
      name: JSON.parse(
        document.getElementById("institution_long_name")?.textContent || "",
      ),
      internalName: JSON.parse(
        document.getElementById("institution_internal_name")?.textContent || "",
      ),
      type: JSON.parse(
        document.getElementById("institution_type")?.textContent || "",
      ),
      primaryColor: JSON.parse(
        document.getElementById("institution_primary_color")?.textContent || "",
      ),
      s3ImagesBucket:
        JSON.parse(
          document.getElementById("s3_images_bucket")?.textContent || "",
        ) || "",
      secondary_color: JSON.parse(
        document.getElementById("institution_secondary_color")?.textContent ||
          "",
      ),
      tertiary_color: JSON.parse(
        document.getElementById("institution_tertiary_color")?.textContent ||
          "",
      ),
      logo: JSON.parse(
        document.getElementById("institution_logo_url")?.textContent || "",
      ),
      apps: JSON.parse(
        document.getElementById("institution_apps")?.textContent || "[]",
      ).map((app: string[]) => App.deserialize(app)),
    };
  }

  let features = {};
  if (document.getElementById("features")) {
    features = JSON.parse(
      document.getElementById("features")?.textContent || "",
    );
  }

  let organizationUsers: (OrganizationUser | null)[];
  if (document.getElementById("org_users")) {
    organizationUsers = JSON.parse(
      document.getElementById("org_users")?.textContent || "",
    );

    if (organizationUsers) {
      organizationUsers = organizationUsers.map((serialized) =>
        OrganizationUser.deserialize(serialized),
      );
    }
  }

  let organizationUser: OrganizationUser | null;
  if (document.getElementById("org_user")) {
    organizationUser = OrganizationUser.deserialize(
      JSON.parse(document.getElementById("org_user")?.textContent || ""),
    );
  }

  let currentOrganizationUser: OrganizationUser | null;
  if (document.getElementById("current_org_user")) {
    currentOrganizationUser = OrganizationUser.deserialize(
      JSON.parse(
        document.getElementById("current_org_user")?.textContent || "",
      ),
    );
  }

  let approvalRequests: API.DualApproval[] = [];
  if (document.getElementById("approval_requests")) {
    approvalRequests = JSON.parse(
      document.getElementById("approval_requests")?.textContent || "",
    );
  }

  let supportContacts: SupportContact[] = [];
  if (document.getElementById("support_contacts")) {
    supportContacts = JSON.parse(
      document.getElementById("support_contacts")?.textContent || "",
    );
  }

  let supportFAQs: SupportFaqCategory[] = [];
  if (document.getElementById("support_faqs")) {
    supportFAQs = JSON.parse(
      document.getElementById("support_faqs")?.textContent || "",
    );
  }

  let supportLinks: SupportLink[] = [];
  if (document.getElementById("support_links")) {
    supportLinks = JSON.parse(
      document.getElementById("support_links")?.textContent || "",
    );
  }

  let currentUserUUID = "";
  if (document.getElementById("current_user_uuid")) {
    currentUserUUID = JSON.parse(
      document.getElementById("current_user_uuid")?.textContent || "",
    );
  }

  let currentUser: User | undefined;
  if (document.getElementById("current_user")) {
    const user: API.User = JSON.parse(
      document.getElementById("current_user")?.textContent || "",
    );
    dataToPreload.user = user;
    currentUser = User.deserialize(user);
  }

  let replyDisclaimer = "";
  if (document.getElementById("reply_disclaimer")) {
    replyDisclaimer = JSON.parse(
      document.getElementById("reply_disclaimer")?.textContent || "",
    );
  }

  let replyTime = "";
  if (document.getElementById("reply_time")) {
    replyTime = JSON.parse(
      document.getElementById("reply_time")?.textContent || "",
    );
  }

  let userMessageUploads = false;
  if (document.getElementById("user_message_uploads")) {
    userMessageUploads = JSON.parse(
      document.getElementById("user_message_uploads")?.textContent || "",
    );
  }

  if (document.getElementById("glia_user")) {
    const gliaUser = JSON.parse(
      document.getElementById("glia_user")?.textContent || "",
    );
    if (gliaUser) {
      initialiseGlia(gliaUser);
    }
  }

  let accounts: Account[] = [];
  if (document.getElementById("accounts")) {
    const accountArray = JSON.parse(
      document.getElementById("accounts")?.textContent || "",
    );
    if (accountArray.map) {
      dataToPreload.accounts = accountArray;
      accounts = accountArray.map((a: API.AnyAccount) =>
        Account.deserialize(a),
      );
    }
  }

  let externalAccounts: API.ExternalAccount[] = [];
  if (document.getElementById("external_accounts")) {
    const externalAccountArray = JSON.parse(
      document.getElementById("external_accounts")?.textContent || "",
    );
    if (externalAccountArray.map) {
      externalAccounts = externalAccountArray.map((a: API.ExternalAccount) =>
        Account.deserialize(a),
      );
    }
  }

  let memberships: Membership[] = [];

  if (document.getElementById("memberships")) {
    const membershipsArray = JSON.parse(
      document.getElementById("memberships")?.textContent || "",
    );
    if (membershipsArray.map) {
      memberships = membershipsArray.map((membershipId: string) =>
        Membership.deserialize(membershipId),
      );
    }
  }

  /* before we had NAF apps, we had service and sso urls */
  let legacyNafUrls: LegacyNafUrl[] = [];
  if (document.getElementById("service_urls")) {
    legacyNafUrls = JSON.parse(
      document.getElementById("service_urls")?.textContent || "",
    );
  }
  if (document.getElementById("sso_urls")) {
    legacyNafUrls = legacyNafUrls.concat(
      JSON.parse(document.getElementById("sso_urls")?.textContent || ""),
    );
  }

  let deviceEnrolled = "";
  if (document.getElementById("device_enrolled")) {
    deviceEnrolled = JSON.parse(
      document.getElementById("device_enrolled")?.textContent || "",
    );
  }

  let institutionShortName = "";
  if (document.getElementById("institution_short_name")) {
    institutionShortName = JSON.parse(
      document.getElementById("institution_short_name")?.textContent || "",
    );
  }

  let otpDeviceChoices = "";
  if (document.getElementById("otp_device_choices")) {
    otpDeviceChoices = JSON.parse(
      document.getElementById("otp_device_choices")?.textContent || "",
    );
  }

  let deviceSelected = "";
  if (document.getElementById("device_selected")) {
    deviceSelected = JSON.parse(
      document.getElementById("device_selected")?.textContent || "",
    );
  }

  let challengeText = "";
  if (document.getElementById("challenge_text")) {
    challengeText = JSON.parse(
      document.getElementById("challenge_text")?.textContent || "",
    );
  }

  let recaptchaPublicKey = "";
  if (document.getElementById("recaptcha_public_key")) {
    recaptchaPublicKey = JSON.parse(
      document.getElementById("recaptcha_public_key")?.textContent || "",
    );
  }

  let deviceIsRemembered = false;
  if (document.getElementById("device_is_remembered")) {
    deviceIsRemembered = JSON.parse(
      document.getElementById("device_is_remembered")?.textContent || "",
    );
  }

  let announcement = "";
  if (document.getElementById("institution_announcement")) {
    announcement = JSON.parse(
      document.getElementById("institution_announcement")?.textContent || "",
    );
  }
  let storageId = "";
  if (document.getElementById("announcement_hash")) {
    storageId = JSON.parse(
      document.getElementById("announcement_hash")?.textContent || "",
    );
  }

  type ReactRootKeys =
    | "#organization-users"
    | "#organization-new-user"
    | "#organization-edit-user"
    | "#dual-approval-request-list"
    | "#dual-approval-notification-bar"
    | "#estatements-manager"
    | "#support-root"
    | "#message-center-root"
    | "#top-menu"
    | "#footer-container"
    | "#notification"
    | "#transfer-page-container"
    | "#react-dashboard"
    | "#react-institution-announcement"
    | "#app-authorize"
    | "#oauth2-authorize"
    | "#refer-a-member"
    | "#beneficiaries"
    | "#login-page-container"
    | "#change-password-container"
    | "#enrollment-page-container"
    | "#password-reset-page-container"
    | "#InactivityDialog"
    | "#mfa-device-select"
    | "#mfa-code-enter"
    | "#account-page-container"
    | "#settings-navbar"
    | "#document-list"
    | "#automatic-savings-container"
    | "#sso-redirect-signed-url-page"
    | "#iframe-signed-url-page"
    | "#service-page-container"
    | "#add-a-card"
    | "#disclosures-reconsent-dialog";

  const reactRootComponents: Record<ReactRootKeys, React.ComponentType<any>> = {
    "#organization-users": OrganizationUsersList,
    "#organization-new-user": OrganizationNewUser,
    "#organization-edit-user": OrganizationEditUser,
    "#dual-approval-request-list": DualApprovalRequestList,
    "#dual-approval-notification-bar": DualApprovalNotificationBar,
    "#estatements-manager": EstatementsManager,
    "#support-root": SupportPageLayout,
    "#message-center-root": MessageCenterLayout,
    "#top-menu": TopMenu,
    "#footer-container": Footer,
    "#notification": withNotifications(null, true),
    "#transfer-page-container": TransferPageContainer,
    "#react-dashboard": Main,
    "#react-institution-announcement": InstitutionAnnouncement,
    "#app-authorize": AppAuthorize,
    "#oauth2-authorize": OAuth2Authorize,
    "#refer-a-member": ReferAMember,
    "#beneficiaries": BeneficiariesContainer,
    "#login-page-container": LoginPageContainer,
    "#change-password-container": ChangePasswordContainer,
    "#enrollment-page-container": EnrollmentPageContainer,
    "#password-reset-page-container": PasswordResetPageContainer,
    "#InactivityDialog": InactivityDialog,
    "#mfa-device-select": MfaDeviceSelect,
    "#mfa-code-enter": MfaCodeEnter,
    "#account-page-container": AccountPageContainer,
    "#settings-navbar": SettingsNavBarContainer,
    "#document-list": DocumentList,
    "#automatic-savings-container": AutomaticSavingsPageContainer,
    "#sso-redirect-signed-url-page": SsoRedirectAccountList,
    "#iframe-signed-url-page": SignedUrlIframe,
    "#service-page-container": ServicePageContainer,
    "#add-a-card": AddCardNafApp,
    "#disclosures-reconsent-dialog": DisclosuresReconsentDialog,
  };

  Object.keys(reactRootComponents).forEach((key) => {
    const target = document.querySelector(key);
    if (target instanceof HTMLElement) {
      if (target?.dataset && "limitsJson" in target.dataset) {
        try {
          dataToPreload.limits = JSON.parse(target.dataset?.limitsJson || "");
        } catch (e) {
          console.warn("Failed to parse limitsJson");
        }
      }

      const Component = reactRootComponents[key as ReactRootKeys];

      const element = React.createElement(Component, {
        ...target.dataset,
        approvalRequests,
        supportContacts,
        supportFAQs,
        supportLinks,
        currentOrganizationUser,
        organizationUser,
        organizationUsers,
        currentUser,
        currentUserUUID,
        replyDisclaimer,
        replyTime,
        features,
        institution,
        userMessageUploads,
        accounts,
        externalAccounts,
        memberships,
        legacyNafUrls,
        deviceEnrolled,
        institutionShortName,
        otpDeviceChoices,
        deviceSelected,
        challengeText,
        recaptchaPublicKey,
        deviceIsRemembered,
        announcement,
        storageId,
      });
      const root = ReactDOM.createRoot(target);
      root.render(
        <AppProviders
          internalName={institution.internalName || ""}
          s3ImagesBucket={institution.s3ImagesBucket || ""}
          preloadedState={preloadState(dataToPreload)}
          currentUser={currentUser}
        >
          {element}
        </AppProviders>,
      );
    }
  });
});
