import React, { createContext, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { FormContext } from ".";
import useData from "./useData";

const getInputField = (inputField) => inputField.props.field;
const filterChildrenForInputFields = (children) =>
  React.Children.toArray(children).filter((child) => child.props.field !== undefined);
const mapChildrenToInputFieldNames = (children) =>
  filterChildrenForInputFields(children).map(getInputField);

const FieldContext = createContext();
const FieldContextProvider = ({
  children,
  field,
  dispatchMetadata: parentDispatchMetadata,
  dispatchData: dispatchParentData,
}) => {
  const formContext = useContext(FormContext);
  const fieldContext = useContext(FieldContext);

  const context =
    fieldContext && fieldContext.formID === formContext.formID ? fieldContext : formContext;

  const { dispatchData: dispatchMetadata } = useData({
    field,
    dispatchData: parentDispatchMetadata,
  });
  const { dispatchData } = useData({
    field,
    dispatchData: dispatchParentData,
    initialData: context.data?.[field] || {},
  });

  useEffect(() => {
    dispatchData({
      type: "update",
      update: context.data?.[field] || {},
    });
  }, [context.data?.[field]]);

  return (
    <FieldContext.Provider
      value={{
        formID: formContext.formID,
        dispatchMetadata,
        dispatchData,
        data: context.data?.[field],
        metadata: context.metadata?.[field],
      }}
    >
      {children}
    </FieldContext.Provider>
  );
};

const ContextFormField = ({
  children,
  field: wrapperField,
  style,
  validate,
  required,
  testId,
  className,
}) => {
  const field = wrapperField || mapChildrenToInputFieldNames(children)[0];
  const formContext = useContext(FormContext);
  const fieldContext = useContext(FieldContext);
  const context =
    fieldContext && fieldContext.formID === formContext.formID ? fieldContext : formContext;

  // on first render, update form metadata with validator and required.
  useEffect(() => {
    if (wrapperField) return;
    if (context === fieldContext) {
      fieldContext.dispatchMetadata({
        type: "updateField",
        field,
        update: { validate, required },
      });
    } else {
      if (!Object.prototype.hasOwnProperty.call(formContext.metadata, field)) {
        formContext.dispatchMetadata({
          type: "updateField",
          field,
          update: { validate, required },
        });
      }
    }
  }, [formContext.data, formContext.metadata]);

  // remove the field from the metadata context when the component unmounts
  useEffect(() => {
    return () => {
      context.dispatchMetadata({
        type: "deleteField",
        field,
      });
    };
  }, []);

  return (
    <FieldContextProvider
      field={wrapperField}
      dispatchMetadata={context.dispatchMetadata}
      dispatchData={context.dispatchData}
    >
      <div className={`context-form-field ${className}`} style={style} data-testid={testId}>
        {React.Children.map(children, (child) => {
          if (wrapperField) return child;
          if (!child) return child;
          if (child.props.field === undefined) return child;
          if (field !== child.props.field) return child;
          return React.cloneElement(child, {
            onChange: (event) => {
              if (child.props && child.props.onChange) {
                child.props.onChange(event);
              }
              if (event && (event instanceof Event || event.nativeEvent)) {
                if (event.target.type === "checkbox") {
                  context.dispatchData({
                    type: "updateField",
                    field,
                    update: event.target.checked,
                  });
                  return;
                }
                context.dispatchData({
                  type: "updateField",
                  field,
                  update: event.target.value,
                });
                return;
              }
              context.dispatchData({
                type: "updateField",
                field,
                update: event,
              });
            },
            value: context.data?.[field] || "",
            data: context.data?.[field] || "",
            checked: context.data?.[field] || "",
            error: context.metadata?.[field]?.error || "",
            required,
          });
        })}
      </div>
    </FieldContextProvider>
  );
};
ContextFormField.defaultProps = {
  defaultValue: "",
};
ContextFormField.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  validate: PropTypes.func,
  required: PropTypes.bool,
  className: PropTypes.string,
};

export default ContextFormField;
