import React, { KeyboardEvent, FunctionComponent } from "react";
import { Select } from "@narmi/design_system";

const ACCOUNT_TYPES = ["Checking", "Savings"];

type AccountType = "Checking" | "Savings";

interface AccountTypeSelectorProps {
  label: string;
  onChange: (value: AccountType) => void;
  value: string;
  error: string;
  accountTypes?: AccountType[];
}

const AccountTypeSelector: FunctionComponent<AccountTypeSelectorProps> = ({
  label,
  onChange,
  value,
  error,
  accountTypes = ACCOUNT_TYPES,
}) => {
  const [mappedAccountTypes] = React.useState(
    accountTypes.map((a) => ({ key: a, text: a, value: a }))
  );
  const accountTypeRefs = React.useRef(new WeakMap());

  const keyHandler = (event: KeyboardEvent<HTMLDivElement>) => {
    const val = mappedAccountTypes.find((a) => {
      return a.text[0].toLowerCase() === event.key;
    });
    if (!val) return;
    accountTypeRefs.current.get(val)?.toScroll();
  };
  return (
    <Select
      errorText={error}
      label={label}
      value={value}
      onChange={onChange}
      keyHandler={keyHandler}
    >
      {mappedAccountTypes.map((c) => (
        <Select.Item key={c.value} value={c.value}>
          <div>{c.text}</div>
        </Select.Item>
      ))}
    </Select>
  );
};

export default AccountTypeSelector;
