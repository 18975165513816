const getLastFour = (num: string) => {
  const accountAndShareId = num.split("=") || [""];
  const accountNumber = accountAndShareId[0];
  if (accountNumber.length <= 4) return num || "";
  // show suffix if it has one, else last four of number
  return accountAndShareId.length > 1
    ? `${accountNumber.substring(accountNumber.length - 2)}=${
        accountAndShareId[1]
      }`
    : accountNumber.substring(accountNumber.length - 4);
};

export const getDestinationAccountPreview = ({
  account_number,
  destination_institution_name,
}: API.WireRecipient) => {
  const lastFour = getLastFour(account_number);
  return `${destination_institution_name}-**${lastFour}`;
};
