import * as Yup from "yup";

import { createForm } from "../../forms";
import { cents } from "../../../utils";

export type ACHPaymentFormType = {
  fromAccount: string;
  amount: Cents;
  secCode: string;
};

const validationSchema = Yup.object().shape({
  fromAccount: Yup.string().required("Required"),
  amount: Yup.number()
    .integer("Please enter a number.")
    .positive("Please enter a positive amount.")
    .required("Required"),
  secCode: Yup.string().required("Required"),
});

const initialValues: ACHPaymentFormType = {
  fromAccount: "",
  amount: cents(0),
  secCode: "",
};

export const ACHPaymentForm = createForm({
  initialValues,
  validationSchema,
});
