import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  useRequestWithFeedback,
  combineRunProps,
  type LayeredOverrideRunProps,
  type OverrideRunProps,
} from "../../../composites";
import { useObjectMemo } from "../../../hooks";

import { API } from "./api";
import { actions } from "./slice";
import { selectWireRecipients } from "./selectors";

import type { BootstrapFetchRequest } from "../../bootstrap";

export const useFetchRecentWires = () => {
  const dispatch = useDispatch();
  const { send, loading } = useRequestWithFeedback<API.FetchWires.Response>();

  const fetchWires = useCallback(
    (
      accountId: API.AccountId,
      overrideRunProps: OverrideRunProps<API.FetchWires.Response> = {},
    ) => {
      send({
        action: API.fetchRecent(accountId),
        onData: (data) => {
          dispatch(actions.receive(accountId, data));
        },
        ...overrideRunProps,
      });
    },
    [dispatch, send],
  );
  return useObjectMemo({ send: fetchWires, loading });
};

export const useFetchWireRecipients = () => {
  const dispatch = useDispatch();
  const { send, loading, status } =
    useRequestWithFeedback<API.FetchWireRecipients.Response>();

  const fetchWireRecipients = useCallback(
    (
      overrideRunProps: OverrideRunProps<API.FetchWireRecipients.Response> = {},
    ) => {
      send({
        action: API.listWireRecipients(),
        onData: (data) => {
          dispatch(actions.receiveWireRecipients(data));
        },
        ...overrideRunProps,
      });
    },
    [dispatch, send],
  );

  const hasData = useSelector(selectWireRecipients).length > 0;

  return useObjectMemo({
    send: fetchWireRecipients,
    loading,
    error: status === "error",
    hasData,
  }) satisfies BootstrapFetchRequest;
};

export const useDeleteWireRecipient = () => {
  const dispatch = useDispatch();
  const { send } = useRequestWithFeedback<API.FetchWireRecipients.Response>();

  const deleteWireRecipient = useCallback(
    (
      id: API.WireRecipientId,
      overrideRunProps: OverrideRunProps<API.FetchWireRecipients.Response> = {},
    ) => {
      send({
        action: API.deleteWireRecipient(id),
        onData: () => {
          dispatch(actions.deleteWireRecipient(id));
        },
        messaging: {
          toast: {
            success: "Recipient successfully removed.",
            error: "Recipient could not be removed. Please try again.",
          },
        },
        ...overrideRunProps,
      });
    },
    [dispatch, send],
  );
  return useObjectMemo({ send: deleteWireRecipient });
};

export const useAddWireRecipientRequest = () => {
  const dispatch = useDispatch();
  const { send } = useRequestWithFeedback<
    API.CreateOrUpdateWireRecipient.Response,
    API.CreateOrUpdateWireRecipient.Error
  >();
  const addWireRecipient = useCallback(
    (
      wireRecipient: API.CreateOrUpdateWireRecipient.Request,
      overrideRunProps: LayeredOverrideRunProps<
        API.CreateOrUpdateWireRecipient.Response,
        API.CreateOrUpdateWireRecipient.Error
      > = {},
    ) => {
      send({
        action: API.saveWireRecipient(wireRecipient),
        ...combineRunProps<
          API.CreateOrUpdateWireRecipient.Response,
          API.CreateOrUpdateWireRecipient.Error
        >(
          {
            onData: (data) => {
              dispatch(actions.createWireRecipient(data));
            },
          },
          overrideRunProps,
        ),
      });
    },
    [send, dispatch],
  );

  return useObjectMemo({ send: addWireRecipient });
};
