import React from "react";
import catcat from "../utils/catcat";

export interface CollapsibleSectionProps {
  title: string;
  sectionStep: number;
  totalSteps: number;
  currentStep: number;
  children: JSX.Element | JSX.Element[];
  tagName?: string;
}

const CollapsibleSection = ({
  title,
  sectionStep,
  totalSteps,
  currentStep,
  children,
  tagName = "",
}: CollapsibleSectionProps) => {
  /*
  `sectionStep` is the step number for this section.
  `totalSteps` is the total number of steps in the whole page.
  `currentStep` is the number of the step that the user is currently on in the page.

  If the user has not reached this step, the whole section is collapsed.
  Once the user reaches the step, the whole section is displayed.
  `Step X of Y` tag is only displayed if this section is the active step.
   */
  if (currentStep < sectionStep) {
    return (
      <div className="collapsible-section-card">
        <h4 className="nds-sans fontColor--hint">{title}</h4>
      </div>
    );
  }

  const isActiveStep = currentStep === sectionStep;

  return (
    <div
      className={catcat("collapsible-section-card", {
        "active-step": isActiveStep,
      })}
    >
      <div
        className="alignChild--left--center margin--bottom--l"
        style={{ flexWrap: "wrap", gap: "var(--space-s)" }}
      >
        <h4 className="nds-sans" style={{ marginRight: "auto" }}>
          {title}
        </h4>
        {isActiveStep && (
          <div className="step-x-of-y-tag">{tagName || `Step ${sectionStep} of ${totalSteps}`}</div>
        )}
      </div>
      {children}
    </div>
  );
};

export default CollapsibleSection;
