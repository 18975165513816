import React from "react";
import PropTypes from "prop-types";
import cc from "classcat";

const ChevronLeftButton = ({ onClick }) => {
  return (
    <div
      className="narmi-icon-chevron-left clickable"
      onClick={onClick}
      onKeyUp={({ key }) => {
        if (key === "Enter") onClick();
      }}
      aria-label="Go back"
      role="button"
      tabIndex="0"
    />
  );
};
ChevronLeftButton.propTypes = {
  onClick: PropTypes.func,
};

const FormHeaderWithIcon = ({ headerText, subheader, iconComponent, onBack = null }) => {
  return (
    <div className="form-header-with-icon">
      <div
        className={cc([
          "main-header-container",
          "alignChild--left--center",
          { "title-with-chevron": onBack },
        ])}
      >
        {onBack && <ChevronLeftButton onClick={onBack} />}
        <h1>{headerText}</h1>
        {iconComponent}
      </div>
      {subheader && <div className="subheader-text">{subheader}</div>}
    </div>
  );
};
FormHeaderWithIcon.propTypes = {
  headerText: PropTypes.string,
  subheader: PropTypes.node,
  iconComponent: PropTypes.node,
  onBack: PropTypes.func,
};

export default FormHeaderWithIcon;
