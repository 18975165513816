import React, { FunctionComponent } from "react";
import { Button } from "@narmi/design_system";

interface ContinueApplicationButtonProps {
  onNext: () => void;
  label?: string;
}
const ContinueApplicationButton: FunctionComponent<ContinueApplicationButtonProps> = ({
  onNext,
  label = "Continue my application",
}) => {
  return <Button label={label} testId="continue" onClick={onNext} />;
};

export default ContinueApplicationButton;
