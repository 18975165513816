export const characterSize = [
  { fontSize: 1, width: 1 },
  { fontSize: 2, width: 1 },
  { fontSize: 3, width: 2 },
  { fontSize: 4, width: 2 },
  { fontSize: 5, width: 3 },
  { fontSize: 6, width: 4 },
  { fontSize: 7, width: 4 },
  { fontSize: 8, width: 5 },
  { fontSize: 9, width: 5 },
  { fontSize: 10, width: 6 },
  { fontSize: 11, width: 7 },
  { fontSize: 12, width: 7 },
  { fontSize: 13, width: 8 },
  { fontSize: 14, width: 8 },
  { fontSize: 15, width: 9 },
  { fontSize: 16, width: 10 },
  { fontSize: 17, width: 10 },
  { fontSize: 18, width: 11 },
  { fontSize: 19, width: 11 },
  { fontSize: 20, width: 12 },
  { fontSize: 21, width: 13 },
  { fontSize: 22, width: 13 },
  { fontSize: 23, width: 14 },
  { fontSize: 24, width: 14 },
  { fontSize: 25, width: 15 },
  { fontSize: 26, width: 16 },
  { fontSize: 27, width: 16 },
  { fontSize: 28, width: 17 },
  { fontSize: 29, width: 17 },
  { fontSize: 30, width: 18 },
  { fontSize: 31, width: 19 },
  { fontSize: 32, width: 19 },
  { fontSize: 33, width: 20 },
  { fontSize: 34, width: 20 },
  { fontSize: 35, width: 21 },
  { fontSize: 36, width: 22 },
  { fontSize: 37, width: 22 },
  { fontSize: 38, width: 23 },
  { fontSize: 39, width: 23 },
  { fontSize: 40, width: 24 },
  { fontSize: 41, width: 25 },
  { fontSize: 42, width: 25 },
  { fontSize: 43, width: 26 },
  { fontSize: 44, width: 26 },
  { fontSize: 45, width: 27 },
  { fontSize: 46, width: 28 },
  { fontSize: 47, width: 28 },
  { fontSize: 48, width: 29 },
  { fontSize: 49, width: 29 },
  { fontSize: 50, width: 30 },
  { fontSize: 51, width: 31 },
  { fontSize: 52, width: 31 },
  { fontSize: 53, width: 32 },
  { fontSize: 54, width: 32 },
  { fontSize: 55, width: 33 },
  { fontSize: 56, width: 34 },
  { fontSize: 57, width: 34 },
  { fontSize: 58, width: 35 },
  { fontSize: 59, width: 35 },
  { fontSize: 60, width: 36 },
];

export const punctuationSize = [
  { fontSize: 1, width: 0 },
  { fontSize: 2, width: 1 },
  { fontSize: 3, width: 1 },
  { fontSize: 4, width: 1 },
  { fontSize: 5, width: 1 },
  { fontSize: 6, width: 2 },
  { fontSize: 7, width: 2 },
  { fontSize: 8, width: 2 },
  { fontSize: 9, width: 2 },
  { fontSize: 10, width: 2 },
  { fontSize: 11, width: 3 },
  { fontSize: 12, width: 3 },
  { fontSize: 13, width: 3 },
  { fontSize: 14, width: 3 },
  { fontSize: 15, width: 4 },
  { fontSize: 16, width: 4 },
  { fontSize: 17, width: 4 },
  { fontSize: 18, width: 4 },
  { fontSize: 19, width: 5 },
  { fontSize: 20, width: 5 },
  { fontSize: 21, width: 5 },
  { fontSize: 22, width: 5 },
  { fontSize: 23, width: 6 },
  { fontSize: 24, width: 6 },
  { fontSize: 25, width: 6 },
  { fontSize: 26, width: 6 },
  { fontSize: 27, width: 7 },
  { fontSize: 28, width: 7 },
  { fontSize: 29, width: 7 },
  { fontSize: 30, width: 7 },
  { fontSize: 31, width: 8 },
  { fontSize: 32, width: 8 },
  { fontSize: 33, width: 8 },
  { fontSize: 34, width: 8 },
  { fontSize: 35, width: 9 },
  { fontSize: 36, width: 9 },
  { fontSize: 37, width: 9 },
  { fontSize: 38, width: 9 },
  { fontSize: 39, width: 10 },
  { fontSize: 40, width: 10 },
  { fontSize: 41, width: 10 },
  { fontSize: 42, width: 10 },
  { fontSize: 43, width: 11 },
  { fontSize: 44, width: 11 },
  { fontSize: 45, width: 11 },
  { fontSize: 46, width: 11 },
  { fontSize: 47, width: 12 },
  { fontSize: 48, width: 12 },
  { fontSize: 49, width: 12 },
  { fontSize: 50, width: 12 },
  { fontSize: 51, width: 13 },
  { fontSize: 52, width: 13 },
  { fontSize: 53, width: 13 },
  { fontSize: 54, width: 13 },
  { fontSize: 55, width: 14 },
  { fontSize: 56, width: 14 },
  { fontSize: 57, width: 14 },
  { fontSize: 58, width: 14 },
  { fontSize: 59, width: 15 },
  { fontSize: 60, width: 15 },
];
