import { useEffect } from "react";
import { useLocalization } from "@fluent/react";
import { entities } from "byzantine";
import {
  AccountNumberTextInput,
  RoutingNumberTextInput,
  Select,
} from "cerulean";
import {
  CollapsibleCard,
  ResponsiveFlex,
  Button,
  Row,
} from "@narmi/design_system";
import { SectionProps } from "./RecipientDrawer";
import * as formAdapters from "../../../../src/adapters";

const ACHDestinationSection = ({
  isOpen,
  isDisabled,
  onClose,
  onOpen,
  onSave,
}: SectionProps) => {
  const { l10n } = useLocalization();
  const accountTypes = entities.recipients.useAccountTypeOptions();
  const { form, handleSubmit } = entities.recipients.useACHDestinationForm();

  // reset the form every time user opens the drawer in case there are old values
  useEffect(() => {
    form.handleReset(form.initialValues);
  }, []);

  const handleClickSave = () => {
    // TODO: update with real recipient id
    handleSubmit("12345" as API.RecipientId, () => {
      onSave();
    });
  };

  return (
    <CollapsibleCard
      onClose={onClose}
      onOpen={onOpen}
      title="ACH"
      isDisabled={isDisabled}
      isOpen={isOpen}
      trigger="caret-end"
    >
      <ResponsiveFlex gapSize="s">
        <AccountNumberTextInput
          label={l10n.getString("labelAccountNumber", null, "Account number")}
          {...formAdapters.fieldWithOnChange(form, "accountNumber")}
        />

        <RoutingNumberTextInput
          label={l10n.getString("label-routing", null, "Routing number")}
          {...formAdapters.fieldWithOnChange(form, "routingNumber")}
        />

        <Select
          label={l10n.getString(
            "ach-payment-account-type",
            null,
            "Account type",
          )}
          {...formAdapters.select(form, "accountType")}
        >
          {accountTypes.map((option, index) => (
            <Select.Item key={`account_type_${index}`} value={option}>
              {option}
            </Select.Item>
          ))}
        </Select>
        <Row justifyContent="end">
          <Row.Item shrink>
            <Button kind="primary" onClick={handleClickSave}>
              {l10n.getString("button-save")}
            </Button>
          </Row.Item>
        </Row>
      </ResponsiveFlex>
    </CollapsibleCard>
  );
};

export default ACHDestinationSection;
