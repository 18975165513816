import { useCallback } from "react";

import { useObjectMemo } from "../../../hooks";
import {
  parseErrorResponse,
  useRequestWithFeedback,
} from "../../../composites";
import { useLibrary } from "../../../providers";
import { useAppDispatch } from "../utils";

import { API } from "./api";
import { actions } from "./slice";
import { useFedwireTemplates } from "./hooks";

import type { BootstrapFetchRequest } from "../../bootstrap";

export const useFetchTemplates = () => {
  const { send, loading, status } = useRequestWithFeedback<
    API.FetchFedwireTemplates.Response,
    API.FetchFedwireTemplates.Error
  >();

  const dispatch = useAppDispatch();
  const fetchTemplates = useCallback(() => {
    send({
      action: API.fetchTemplates(),
      onData: ({ results: templates }) => {
        dispatch(actions.upsertAll(templates));
      },
    });
  }, [dispatch, send]);

  const hasData = useFedwireTemplates().length > 0;

  return useObjectMemo({
    error: status === "error",
    send: fetchTemplates,
    loading,
    hasData,
  }) satisfies BootstrapFetchRequest;
};

export const useDeleteTemplateRequest = () => {
  const { send, loading } = useRequestWithFeedback();

  const dispatch = useAppDispatch();
  const { throwToast } = useLibrary("toasts");
  const deleteTemplate = useCallback(
    (fedwireTemplateId: API.FedwireTemplateId) => {
      return send({
        messaging: {
          toast: {
            success: "Template deleted.",
          },
        },
        action: API.deleteTemplate(fedwireTemplateId),
        onSuccess: () => {
          dispatch(actions.deleteOne(fedwireTemplateId));
        },
        onError: async (error) => {
          const { errors } = await parseErrorResponse(error);
          if (errors.length) {
            throwToast({
              kind: "error",
              message: `${errors[0].description}`,
            });
          }
        },
      });
    },
    [dispatch, send, throwToast],
  );

  return useObjectMemo({
    deleteTemplate,
    loading,
  });
};
