import React, { useCallback } from "react";
import { ContentCard, IconButton, useNotificationContext } from "cerulean";
import Feature from "byzantine/src/Feature";
import { Link } from "react-router-dom";
import { useLocalization } from "@fluent/react";
import type { InstitutionFeatures } from "byzantine/src/types";
import { useUserFeatures } from "../../../../../contexts/UserFeaturesContext";

import styles from "./CardActions.module.scss";

const DamagedWrapper = ({
  cardId,
  isDamaged,
  onUserDismiss,
  children,
}: {
  cardId: API.Card["id"];
  isDamaged: boolean;
  onUserDismiss: () => void;
  children: React.ReactElement;
}) => {
  const { l10n } = useLocalization();
  const { sendNotification } = useNotificationContext();

  const onDamagedReplacementClick = useCallback(() => {
    sendNotification({
      type: "negative",
      text: l10n.getString("card-feature-replace-damaged-error"),
    });
    onUserDismiss();
  }, []);

  return !isDamaged ? (
    <Link
      to={`/cards/replace-a-card/${cardId}/reason`}
      className={styles.replaceCardContainer}
    >
      {children}
    </Link>
  ) : (
    <div
      onClick={onDamagedReplacementClick}
      className={styles.replaceCardContainer}
      aria-label="Replace Card - Replacing a recently replaced damaged card is not allowed."
    >
      {children}
    </div>
  );
};

interface ReplaceCardProps {
  cardId: API.Card["id"];
  state: API.Card["state"];
  onUserDismiss: () => void;
}

const CardActions = ({ cardId, state, onUserDismiss }: ReplaceCardProps) => {
  const { l10n } = useLocalization();
  const features = useUserFeatures() as InstitutionFeatures;
  const isDamaged = state === "damaged";
  const isNormal = state === "normal";

  return (
    <ContentCard kind="elevated">
      <DamagedWrapper
        cardId={cardId}
        isDamaged={isDamaged}
        onUserDismiss={onUserDismiss}
      >
        <>
          <div className={styles.titleContainer}>
            <span className={styles.title}>
              {l10n.getString("card-feature-replace")}
            </span>
            <span aria-hidden="true" className={styles.iconContainer}>
              <IconButton name="arrow-right" kind="plain" textSize="l" />
            </span>
          </div>
          <p
            className={`fontColor--secondary fontSize--s ${styles.description}`}
          >
            {l10n.getString("card-feature-replace-description")}
          </p>
        </>
      </DamagedWrapper>
      {isNormal && (
        <Feature features={features} or={["card_travel_notes"]}>
          <Link
            to={`/cards/${cardId}/travel_notice`}
            className={styles.replaceCardContainer}
          >
            <div className={styles.titleContainer}>
              <span className={styles.title}>
                {l10n.getString("card-feature-travel-notes")}
              </span>
              <span aria-hidden="true" className={styles.iconContainer}>
                <IconButton name="arrow-right" kind="plain" textSize="l" />
              </span>
            </div>
            <p
              className={`fontColor--secondary fontSize--s ${styles.description}`}
            >
              {l10n.getString("card-feature-travel-notes-description")}
            </p>
          </Link>
        </Feature>
      )}
    </ContentCard>
  );
};

export default CardActions;
