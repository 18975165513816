import { createContext } from "react";

import type { Network } from "./network.types";

export interface INetwork {
  fetchApi: (
    endPoint: string,
    optionsWithoutDefaults?: Network.Options,
  ) => Promise<Network.Response>;
}

export const NetworkContext = createContext<INetwork | undefined>(undefined);

export type NetworkProps = {
  network?: INetwork;
};
