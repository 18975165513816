import { formatNumber } from "@narmi/design_system";
import SectionCard from "../SectionCard";

type Stop = {
  min_check_number: string;
  max_check_number?: string;
  amount?: number;
};

interface StopPaymentRequestProps {
  stop: Stop;
}
const StopPaymentRequest = ({ stop }: StopPaymentRequestProps) => (
  <div className="requests-card-subsection-item">
    {`Check-${stop.min_check_number}`}
    {stop.max_check_number && ` to Check-${stop.max_check_number}`}
    {!!stop?.amount && ` for ${formatNumber(stop.amount / 100)}`}
  </div>
);

interface RequestsCardProps {
  isLoading: boolean;
  stopPayments: Stop[];
}

const RequestsCard = ({ isLoading, stopPayments }: RequestsCardProps) => (
  <SectionCard isLoading={isLoading}>
    {stopPayments.length > 0 && (
      <>
        <SectionCard.Title text="Requests" />
        <div className="requests-card-subsection-title">Stop payment</div>
        {stopPayments.map((stop) => (
          <StopPaymentRequest key={JSON.stringify(stop)} stop={stop} />
        ))}
      </>
    )}
  </SectionCard>
);

export default RequestsCard;
