import { useLocalization } from "@fluent/react";

import { catcat as cc, ContentCard, Row } from "cerulean";
import filters from "byzantine/src/filters";
import TravelNoticeDeleteButton from "./TravelNoticeDeleteButton";
import styles from "./TravelNoticeCard.module.scss";

interface TravelNoticeCardProps {
  startDate: string;
  endDate: string;
  deleteTravelNote: () => void;
}

const TravelNoticeCard = ({
  startDate,
  endDate,
  deleteTravelNote,
}: TravelNoticeCardProps) => {
  const { l10n } = useLocalization();
  const formattedDate = filters.dateRange(startDate, endDate);
  return (
    <>
      <ContentCard kind="bordered" radiusSize="m">
        <div className={styles.row}>
          <Row alignItems="center">
            <Row.Item>
              <div className="fontColor--heading fontWeight--bold">
                {l10n.getString("travel-notice-form-dates-section")}
              </div>
            </Row.Item>
            <Row.Item shrink>
              <TravelNoticeDeleteButton
                formattedDate={formattedDate}
                deleteTravelNote={deleteTravelNote}
              />
            </Row.Item>
          </Row>
        </div>
        <div className="alignChild--left--center margin--top--xs">
          <div className={cc("alignChild--center--center", styles.icon)}>
            <span className="narmi-icon-calendar" />
          </div>
          <div>{formattedDate}</div>
        </div>
      </ContentCard>
    </>
  );
};

export default TravelNoticeCard;
