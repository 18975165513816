type PaginationProps = {
  page: string | number;
  pageSize: string | number;
  total: string | number;
  navigatePage: CallableFunction;
};

/* even though parseInt can be used on actual numbers, it is not typed that way */
function parseIntWrapper(stringOrInt: number | string, radix: number = 10) {
  return parseInt(`${stringOrInt}`, radix);
}

class Pagination {
  page: number;

  pageSize: number;

  total: number;

  navigatePage: CallableFunction;

  constructor(props: PaginationProps) {
    this.page = parseIntWrapper(props.page, 10) || 1;
    this.pageSize = parseIntWrapper(props.pageSize, 10);
    this.total = parseIntWrapper(props.total, 10);
    this.navigatePage = props.navigatePage;
  }

  getNumPages() {
    if (!this.pageSize) return 0;
    return Math.ceil(this.total / this.pageSize);
  }
}

export default Pagination;
