import PromotionCard from "../PromotionCard";
import MarketingCard from "../MarketingCard/MarketingCard";
import { useUserFeatures } from "../../contexts/UserFeaturesContext";
import useWelcomeOffer from "./useWelcomeOffer";

export interface MarketingUserFeatures {
  olb_enable_marketing_banners: boolean; // eslint-disable-line camelcase
}

const MarketingCardContainer = () => {
  const welcomeOffer = useWelcomeOffer();
  const features = useUserFeatures() as MarketingUserFeatures;

  if (welcomeOffer && !features.olb_enable_marketing_banners) {
    return <PromotionCard promotion={welcomeOffer} />;
  } else if (features.olb_enable_marketing_banners) {
    return <MarketingCard address="31b65103-a70b-4a35-8245-124a91fe1fe0" />;
  }

  return null;
};

export default MarketingCardContainer;
