import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextInput } from "@narmi/design_system";

export const validateCardExpiry = (value) => {
  // validate that we have a month and year
  if (/^[0-9]{2}[0-9]{2}/.test(value)) {
    return "Please enter a valid expiration date";
  }
  // validate that the month is between 01 and 12
  const month = parseInt(value.slice(0, 2));
  if (month < 1 || month > 12) {
    return "Please enter a valid expiration month";
  }

  const now = new Date();
  const expDate = new Date(value.replace("/", "/1/"));

  // a negative value in this expression indicates a month-year combo in the past
  if (expDate.getMonth() - now.getMonth() + 12 * (expDate.getFullYear() - now.getFullYear()) < 0) {
    return "Expiration date must be in the future";
  }
  return null;
};

const ExpirationDateField = ({ onChange, value, field, product, ...textInputProps }) => {
  const [displayValue, setDisplayValue] = useState("");
  const [moveCursor, setMoveCursor] = useState();

  React.useEffect(() => {
    setDisplayValue(value);
  }, [value]);

  React.useEffect(() => {
    if (moveCursor) moveCursor();
    setMoveCursor();
  }, [displayValue]);

  const handleInput = (event) => {
    // see if we're cursored to the middle of this field
    if (event.target.selectionStart !== event.target.value?.length) {
      // synthetic events are pooled, closure what we might need
      const target = event.target;
      const selectionStart = target.selectionStart;
      const selectionEnd = target.selectionEnd;
      setMoveCursor(() => () => {
        target.setSelectionRange(selectionStart, selectionEnd);
      });
    }

    // initially clean the parsedValue to just be numbers (e.g. remove the '/')
    const cleanedInput = event.target.value.replace(/\D/g, "");

    // whenever the just-digits length is 2 or more, add the '/' after the 2nd char
    let formattedInput = cleanedInput.replace(/^(..)(.?.)/, (match, p1, p2) => [p1, p2].join("/"));

    // don't allow formattedInput to exceed 5 chars (incl. '/')
    formattedInput = formattedInput.slice(0, 5);

    if (formattedInput.length === 1 && !["0", "1"].includes(formattedInput))
      formattedInput = "0" + formattedInput;

    onChange(formattedInput);
  };

  return (
    <TextInput
      onChange={handleInput}
      value={displayValue}
      inputMode="numeric"
      autoComplete="cc-exp"
      {...textInputProps}
    />
  );
};
ExpirationDateField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default ExpirationDateField;
