import {
  useEffect,
  useState,
  createContext,
  useContext,
  ReactNode,
  useMemo,
} from "react";
import User from "byzantine/src/User";
import type UserType from "byzantine/src/User";

export interface CurrentUserContextProviderProps {
  children: ReactNode;
  currentUser?: UserType;
}

interface CurrentUserContextType {
  currentUser: UserType | null;
  setCurrentUser: (user: UserType) => void;
}

const CurrentUserContext = createContext<CurrentUserContextType>({
  currentUser: null,
  setCurrentUser: () => {},
});

const getCurrentUserFromHTML = (): UserType | null => {
  let currentUser: UserType | null = null;

  if (document.getElementById("current_user")) {
    currentUser =
      User.deserialize(
        JSON.parse(document.getElementById("current_user")?.textContent || "")
      ) || null;
  }

  return currentUser;
};

export const CurrentUserContextProvider = ({
  children,
  currentUser,
}: CurrentUserContextProviderProps) => {
  const [user, setUser] = useState<UserType | null>(currentUser || null);

  useEffect(() => {
    // Hydrate user from global if it is not provided
    const updatedUser = currentUser || getCurrentUserFromHTML();

    setUser(updatedUser);
  }, [currentUser]);

  const value = useMemo(
    () => ({ currentUser: user, setCurrentUser: setUser }),
    [user, setUser]
  );

  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export const useCurrentUser = () => useContext(CurrentUserContext);

export default CurrentUserContext;
