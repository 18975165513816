// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".icon--GMgiP{margin-right:4px;font-size:var(--font-size-xs);color:var(--theme-primary);background-color:RGBA(var(--theme-rgb-primary), var(--ephemeral-opacity));height:var(--space-l);width:var(--space-l);border-radius:50%}.row--AX8eO{margin-top:-1rem;margin-bottom:-1rem}@media only screen and (min-width: 480px){.row--AX8eO{margin-top:0;margin-bottom:0}}", "",{"version":3,"sources":["webpack://./components/settings/cards/CardManagerDrawer/TravelNotice/ExistingTravelNotice/TravelNoticeCard.module.scss"],"names":[],"mappings":"AAEA,aACE,gBAAA,CACA,6BAAA,CACA,0BAAA,CACA,yEAAA,CACA,qBAAA,CACA,oBAAA,CACA,iBAAA,CAGF,YAGE,gBAAA,CACA,mBAAA,CACA,0CALF,YAMI,YAAA,CACA,eAAA,CAAA","sourcesContent":["@import \"../../../../../../../scss/mixins\";\n\n.icon {\n  margin-right: 4px;\n  font-size: var(--font-size-xs);\n  color: var(--theme-primary);\n  background-color: RGBA(var(--theme-rgb-primary), var(--ephemeral-opacity));\n  height: var(--space-l);\n  width: var(--space-l);\n  border-radius: 50%;\n}\n\n.row {\n  // at lower than 480px screen width, NDS plain buttons acquire an extra 2rem of height\n  // this ensures that the visual spacing does not change\n  margin-top: -1rem;\n  margin-bottom: -1rem;\n  @media only screen and (min-width: 480px) {\n    margin-top: 0;\n    margin-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "icon--GMgiP",
	"row": "row--AX8eO"
};
export default ___CSS_LOADER_EXPORT___;
