import { DEFAULT_STATE } from "byzantine";
import { cloneDeep, keyBy } from "lodash";

type Props = {
  user?: API.User;
  accounts?: API.AnyAccount[];
  limits?: API.Limits.ByType;
};

const preloadState = ({ user, accounts, limits = {} }: Props) => {
  const defaultState = cloneDeep(DEFAULT_STATE);
  defaultState.user.user = user || null;
  defaultState.accounts.byId = keyBy(accounts, "id");
  defaultState.limits.data = limits;
  return defaultState;
};

export default preloadState;
