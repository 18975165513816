import type AddressType from "byzantine/src/Address";

interface AddressProps {
  address: AddressType | API.Address;
}

const Address = ({ address }: AddressProps) => (
  <span>
    <div className="address-line">
      {address.street_address}
      {address.street_address_2 && `, ${address.street_address_2}`}
    </div>
    <div className="address-line">
      {address.city}, {address.region_code} {address.postal_code}
    </div>
  </span>
);

export default Address;
