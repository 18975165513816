import React, { FunctionComponent, useState, useRef, useEffect } from "react";
import { TextInput } from "@narmi/design_system";

export function phoneFormatter(value?: string | null) {
  if (value === null || value === undefined || value.length === 0) {
    return "";
  }
  const numerical = value.replace(/[^0-9]/g, "");
  const { length } = numerical;
  // formatting area code
  if (length < 4) {
    if (numerical[0] === "1" && length === 1) {
      return "";
    }
    return `(${numerical.substring(0, length)})`;
  }
  // formatting the next three digits along with area code
  if (length < 7) {
    // Checks to see if country code is included
    if (numerical[0] === "1") {
      return `(${numerical.substring(1, 4)}) ${numerical.substring(4, length)}`;
    }
    return `(${numerical.substring(0, 3)}) ${numerical.substring(3, length)}`;
  }
  // formatting the full phone number
  if (length < 12) {
    // Checks to see if country code is included
    if (numerical[0] === "1") {
      return `(${numerical.substring(1, 4)}) ${numerical.substring(
        4,
        7
      )}-${numerical.substring(7, length)}`;
    }
  }
  // Checks to see if country code is included
  if (numerical[0] === "1") {
    return `(${numerical.substring(1, 4)}) ${numerical.substring(
      4,
      7
    )}-${numerical.substring(7, 11)}`;
  }
  return `(${numerical.substring(0, 3)}) ${numerical.substring(
    3,
    6
  )}-${numerical.substring(6, 10)}`;
}

function phoneUnformatter(value?: string | null) {
  // strips all punctuation
  if (value === null || value === undefined || value.length === 0) {
    return "";
  }
  const numerical = value.replace(/[^0-9]/g, "");

  if (numerical.length === 10) {
    // if there are 10 digits and the first is not a 1
    // add country code
    return `+1${numerical}`;
  }

  if (numerical.length > 10) {
    return `+${numerical}`;
  }

  return numerical;
}

interface PhoneTextInputProps {
  label?: string;
  field?: string;
  error?: string;
  onChange: (value: string) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  disabled?: boolean;
  endContent?: React.ReactNode;
}

const PhoneTextInput: FunctionComponent<
  PhoneTextInputProps & Omit<React.ComponentProps<"input">, "onChange">
> = (props) => {
  /*
  in order for this to work with azul.Form or azul.ContextForm,
  props.field cannot be passed into TextInput.
  otherwise the parent form will directly manage TextInput's value
  */
  const {
    onChange,
    label = "Phone number",
    value,
    field,
    disabled,
    endContent,
    ...nativeElementProps
  } = props;
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);
  const [cursorNeedsUpdate, setCursorNeedsUpdate] = useState(false);
  const inputRef = useRef<HTMLInputElement>();
  const displayValue = phoneFormatter(phoneUnformatter(value));

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!inputRef.current) {
      return;
    }
    const currentCursorPosition = inputRef.current.selectionStart;
    const parsedValue = event.target.value.replace(/[^\d]/g, "").substring(0, 11);
    const isCursorInMiddleOfString = (currentCursorPosition ?? 0) < displayValue.length;
    if (
      (event.nativeEvent as InputEvent).inputType === "deleteContentBackward" &&
      parsedValue === value
    ) {
      const valueLastCharRemoved = value.substring(0, value.length - 1);
      onChange(valueLastCharRemoved);
    } else {
      onChange(phoneUnformatter(phoneFormatter(parsedValue)));
    }
    if (isCursorInMiddleOfString) {
      setCursorPosition(currentCursorPosition);
      setCursorNeedsUpdate(true);
    }
  };

  useEffect(() => {
    if (cursorNeedsUpdate && inputRef.current) {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
      setCursorNeedsUpdate(false);
    }
  }, [cursorNeedsUpdate]);

  return (
    <div>
      <TextInput
        id="phone"
        label={label}
        onChange={handleInput}
        value={displayValue}
        inputMode="numeric"
        {...nativeElementProps}
        ref={inputRef}
        disabled={disabled}
        endContent={endContent}
      />
    </div>
  );
};

export default PhoneTextInput;
