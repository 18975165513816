import React from "react";

/**
 * Component to render the logo of a financial instituition
 *
 * An `institution` prop containing the `logo` for the url and `name` for the alt text is required
 *
 * Clicking on the logo will navigate to the root page of the domain
 */
const InstitutionLogo = ({
  className = "",
  institution,
}: {
  institution: { logo: string; name: string };
  className?: string;
}) => {
  const { logo, name } = institution;
  return logo && name ? (
    <a
      className={`institution-logo ${className}`}
      style={{ backgroundImage: `url(${logo})` }}
      href="/"
    >
      {name} Logo
    </a>
  ) : null;
};

export default InstitutionLogo;
