import { createDeepEqualSelector } from "../utils";

import type { RootState } from "../types";
import type { WiresSlice } from "./slice";

export const sortWires = (a: API.Wire, b: API.Wire) => {
  if (a.created_at < b.created_at) {
    return 1;
  }
  if (a.created_at > b.created_at) {
    return -1;
  }

  return 0;
};

export const selectRecentWires = createDeepEqualSelector(
  [
    (state) => Object.values(state.wires.byId) as API.Wire[],
    (_, accountId: API.AccountId) => accountId,
  ],
  (wireValues, accountId) => {
    const filtered = wireValues.filter(
      (wire) => wire.from_account === accountId,
    );

    filtered.sort(sortWires);
    return filtered;
  },
);

export const selectWire = <S extends { wires: WiresSlice }>(
  state: S,
  wireId: API.WireId,
) => {
  const wireValues = Object.values(state.wires.byId);
  const wire = wireValues.find((w) => {
    return w.id === wireId;
  });

  return wire;
};

export const selectWireRecipient = <S extends { wires: WiresSlice }>(
  state: S,
  wireRecipientId: API.WireRecipientId,
) => {
  const wireRecipientValues = Object.values(state.wires.recipients.byId);
  return wireRecipientValues.find((w) => w.id === wireRecipientId);
};

export const selectWireRecipients = createDeepEqualSelector(
  (state: RootState) => state.wires.recipients.byId,
  (wireRecipients) => Object.values(wireRecipients),
);

export const selectOneWireRecipient = createDeepEqualSelector(
  [
    (state: RootState) => state.wires.recipients.byId,
    (_, recipientId?: API.WireRecipientId) => recipientId,
  ],
  (recipientsById, recipientId) => {
    if (!recipientId) {
      return undefined;
    }
    return recipientsById[recipientId] as API.WireRecipient;
  },
);
