export namespace Network {
  export type Headers = {
    [headerName: string]: string;
  };

  export type BaseOptions = {
    body?: string | object;
    responseType?: "text" | "base64";
    credentials?: string;
    headers?: Headers;
    method?: "DELETE" | "GET" | "POST" | "PUT";
    timeoutInterval?: number;
  };

  export type Method = Exclude<BaseOptions["method"], undefined>;

  export type UnknownPayload = Record<string, unknown>;

  export type Response<T = UnknownPayload> = {
    bodyString?: string;
    data?: string;
    headers: Headers;
    status: number;
    url: string;
    json: () => Promise<T>;
    text: () => Promise<string>;
  };

  export type RequiresPayload = "POST" | "PUT" | "PATCH";

  export type StrictOptions<P = UnknownPayload> =
    | {
        method: Extract<BaseOptions["method"], RequiresPayload>;
        payload: P;
        idempotencyKey?: UUID;
      }
    | {
        method?: Exclude<BaseOptions["method"], RequiresPayload>;
        payload?: P;
      };

  export type Options<P = UnknownPayload> = StrictOptions<P> & {
    customHeaders?: BaseOptions["headers"];
    timeoutMilliseconds?: BaseOptions["timeoutInterval"];
    token?: string;
    secret?: string;
    isJsonResponse?: boolean;
    disableAuth?: boolean;
    disableCaching?: boolean;
  };
}

export class ResponseError extends Error {
  public response: Network.Response | undefined;

  constructor(message?: string, response?: Network.Response) {
    super(message);
    this.response = response;
  }
}
