/* eslint-disable camelcase */
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocalization } from "@fluent/react";
import {
  Alert,
  BackCancelButton,
  Button,
  CollapsibleSection,
  ContextForm,
  FormHeaderWithIcon,
  RadioButtons,
  formatNumber,
} from "cerulean";
import Account from "byzantine/src/Account";
import Filters from "byzantine/src/filters";
import utils from "byzantine/src/utils";
import AccountContext from "../../../contexts/AccountContext";
import SimpleTransferFields, {
  TRANSFER_FORM_TYPES,
} from "../../SimpleTransferFields";
import LoanSelector from "../LoanSelector";
import LoanPaymentTypesDialog from "./LoanPaymentTypesDialog";

export interface LoanForm {
  to_account_id?: string;
  payment_type?: string;
  frequency?: string;
  amount?: string;
  from_account_id?: string;
  date: string;
}
interface LoanPaydownFormProps {
  data: LoanForm;
  onChange: (val: unknown) => void;
  onSubmit: (callback: (arg?: unknown) => void) => void;
  cancel: () => void;
  limits: object;
}

export enum PaymentType {
  Preset = "preset",
  Custom = "custom",
  Principal = "principal",
}

const getFormTypeForPaymentType = (paymentType?: PaymentType) => {
  switch (paymentType) {
    case PaymentType.Preset:
      return TRANSFER_FORM_TYPES.LOAN_OPTIONALITY__PRESET;
    case PaymentType.Custom:
      return TRANSFER_FORM_TYPES.LOAN_OPTIONALITY__CUSTOM;
    case PaymentType.Principal:
      return TRANSFER_FORM_TYPES.LOAN_OPTIONALITY__PRINCIPAL;
    default:
      return TRANSFER_FORM_TYPES.LOAN_OPTIONALITY__CUSTOM;
  }
};

const LoanPaydownForm = ({
  data,
  onChange,
  onSubmit,
  cancel,
  limits,
}: LoanPaydownFormProps) => {
  const { l10n } = useLocalization();
  const { accounts } = useContext(AccountContext) as { accounts: Account[] };
  const [currentStep, setCurrentStep] = useState(1);
  const loanAccounts = useMemo(
    () =>
      accounts.filter(
        (a) => a.isInternalLoan() && a.isValidTransferDestination()
      ),
    [accounts]
  );
  const paymentTypeChoice = useRef("");

  const [minimumPayment, paymentDueDate, isAmortizedLoan] = useMemo(() => {
    const loanAccount = accounts.find(
      (account) => account.id === data?.to_account_id
    );
    if (!loanAccount) {
      return ["", "", false];
    }

    let minPayment = loanAccount.loan_details?.minimum_payment;
    if (minPayment) {
      minPayment = formatNumber(minPayment);
    }

    let dueDate = loanAccount.loan_details?.next_payment_at;
    if (dueDate) {
      dueDate = Filters.shortDate(dueDate);
    }

    return [minPayment, dueDate, loanAccount.isAmortizedLoan()];
  }, [data?.to_account_id]);

  const hasAmountDue = utils.parseValueAsFloat(minimumPayment) > 0;
  const shouldDisplayWarning =
    data?.payment_type === PaymentType.Principal && hasAmountDue;

  const warning = isAmortizedLoan
    ? l10n.getString("loan-paydown-principal-only-warning-amortized")
    : l10n.getString("loan-paydown-principal-only-warning", {
      amount: minimumPayment,
      dueDate: paymentDueDate,
    });

  // toggling to preset should update amount field to minimum payment
  // toggling from preset should clear the amount field
  useEffect(() => {
    if (!data.payment_type) {
      return;
    }
    const previousPaymentType = paymentTypeChoice.current;
    paymentTypeChoice.current = data.payment_type;

    if (
      ![data.payment_type, previousPaymentType].includes(PaymentType.Preset)
    ) {
      return;
    }

    const paymentAmount =
      data.payment_type === PaymentType.Preset ? minimumPayment : undefined;
    onChange({ amount: paymentAmount });
  }, [data?.payment_type, minimumPayment]);

  const paymentTypeOptions = {
    ...(hasAmountDue
      ? {
        [l10n.getString("loan-paydown-amount-due")]: {
          value: PaymentType.Preset,
          details: l10n.getString("loan-paydown-amount-due-details", {amount: minimumPayment, date: paymentDueDate}),
        },
      }
      : {}),
    [l10n.getString("loan-paydown-custom-amount")]: PaymentType.Custom,
    [l10n.getString("loan-paydown-principal-only")]: PaymentType.Principal,
  };

  useEffect(() => {
    if (data?.payment_type && currentStep < 3) {
      setCurrentStep(3);
    } else if (data?.to_account_id && currentStep < 2) {
      setCurrentStep(2);
    }
  }, [data?.to_account_id, data?.payment_type]);

  return (
    <div className="loan-payment-page">
      <FormHeaderWithIcon headerText={l10n.getString("loan-paydown-form-page-heading")}/>
      <CollapsibleSection
        title={l10n.getString("loan-paydown-step-1-title")}
        currentStep={currentStep}
        sectionStep={1}
        totalSteps={3}
        tagName={l10n.getString("loan-paydown-step-x-of-y-tag", {sectionStep: 1, totalSteps: 3})}
      >
        <ContextForm.Field required style={{ marginBottom: "var(--space-s)" }}>
          <LoanSelector loans={loanAccounts} field="to_account_id" label={l10n.getString("loan-paydown-loan-selector-label")} />
        </ContextForm.Field>
      </CollapsibleSection>
      <CollapsibleSection
        title={l10n.getString("loan-paydown-step-2-title")}
        currentStep={currentStep}
        sectionStep={2}
        totalSteps={3}
        tagName={l10n.getString("loan-paydown-step-x-of-y-tag", {sectionStep: 2, totalSteps: 3})}
      >
        <ContextForm.Field required style={{ marginBottom: 0 }}>
          <RadioButtons
            name="payment_type"
            field="payment_type"
            options={paymentTypeOptions}
            alwaysShowDetails={true}
          />
        </ContextForm.Field>
        <LoanPaymentTypesDialog isAmortizedLoan={isAmortizedLoan} />
      </CollapsibleSection>
      <CollapsibleSection
        title={l10n.getString("loan-paydown-step-3-title")}
        currentStep={currentStep}
        sectionStep={3}
        totalSteps={3}
        tagName={l10n.getString("loan-paydown-step-x-of-y-tag", {sectionStep: 3, totalSteps: 3})}
      >
        {shouldDisplayWarning ? (
          <div className="margin--bottom--l">
            <Alert
              isActive={shouldDisplayWarning}
              isDismissable={false}
              kind={isAmortizedLoan ? "info" : "warn"}
              icon={isAmortizedLoan ? "info" : "alert-triangle"}
            >
              <span>{warning}</span>
            </Alert>
          </div>
        ) : (
          <></>
        )}
        <SimpleTransferFields
          data={data}
          limits={limits}
          formType={getFormTypeForPaymentType(
            data?.payment_type as PaymentType
          )}
        />
      </CollapsibleSection>
      <div className="alignChild--center--center form-buttons-with-spacing">
        <BackCancelButton onBack={cancel} backLabel={l10n.getString("loan-paydown-cancel-button")} />
        <ContextForm.Action onSubmit={onSubmit} dangerouslyDisableShowLoading>
          <div style={{ marginLeft: "auto" }}>
            <Button label={l10n.getString("loan-paydown-review-button")} />
          </div>
        </ContextForm.Action>
      </div>
    </div>
  );
};

export default LoanPaydownForm;
