export interface HeadersOptions {
  [key: string]: string;
}

export type HttpMethod =
  | "GET"
  | "POST"
  | "PUT"
  | "DELETE"
  | "PATCH"
  | "OPTIONS"
  | "HEAD";

type Credentials = "omit" | "same-origin" | "include";

export interface FetchOptions {
  method?: HttpMethod;
  apiUrl?: string;
  params?: Record<string, string>;
  headers?: HeadersOptions;
  token?: string;
  secret?: string;
  credentials?: Credentials;
  csrfToken?: string;
  endpointIsUrl?: boolean;
  shouldSkipSerializeAmount?: boolean;
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
  rawResponse?: boolean;
}

export interface Applicant {
  date_of_birth: string;
  is_primary?: boolean;
}

export interface Product {
  amount: Cents;
  minimum_balance: Cents;
}

export interface DeserializedProduct {
  amount: Dollars;
  minimum_balance: Dollars;
}

export interface Funding {
  amount: Cents;
}

export interface ResponseFunding {
  amount: Dollars;
}

export const isApplicationResponse = <T>(
  response: any,
): response is {
  data: T & {
    application: ResponseApplication;
  };
  headers: HeadersOptions;
} => response?.data?.application;

export const isApplicationsResponse = <T>(
  response: any,
): response is {
  data: T & {
    applications: ResponseApplication[];
  };
  headers: HeadersOptions;
} => response?.data?.applications;

export interface ResponseApplication {
  applicants?: Applicant[];
  selected_products?: DeserializedProduct[];
  funding?: ResponseFunding;
}

export const isRawResponse = <T>(
  response: any,
  isRaw: boolean,
): response is T =>
  (response?.headers?.["content-disposition"] || "").startsWith("attachment") ||
  isRaw;

export interface Application {
  applicants?: Applicant[];
  selected_products?: Product[];
  funding?: Funding;
}

export interface RequestResponse<T> {
  headers: HeadersOptions;
  data: T;
}

export interface HttpClient {
  request<T = any>(config: {
    url: string;
    method: HttpMethod;
    headers: HeadersOptions;
    data?: any;
    params?: any;
    withCredentials?: boolean;
    onUploadProgress?: (progressEvent: ProgressEvent) => void;
  }): Promise<{ data: T; headers: HeadersOptions }>;
}
