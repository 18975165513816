import { createContext } from "react";

export const PLATFORM = {
  web: "web",
  native: "native",
  unknown: "unknown",
} as const;

type Platform = ValueOf<typeof PLATFORM>;

export interface IPlatform {
  get: () => Platform;
}

export const PlatformContext = createContext<IPlatform | undefined>(undefined);

export type PlatformProps = {
  platform?: IPlatform;
};
