import { useMemo } from "react";

import { useObjectMemo, modules } from "byzantine";

export const useDetailsSelector = () => {
  const { wireReasons } = modules.wireTemplates.useData();

  const form = modules.wireTemplates.sendViaTemplateForm.useForm();

  const {
    values: { method },
  } = modules.wireTemplates.methodSelectorForm.useForm();

  return useObjectMemo({
    wireReasons,
    method,
    form,
  });
};

export const useLimitsForDisplay = (limits: API.Limits.ByType) => {
  return useMemo(() => {
    const result: Record<string, any> = {}; // amount and available amount per time period for each transaction type
    const keys = Object.keys(limits) as API.Limits.Type[];
    keys.forEach((transaction_type) => {
      if (!["wire", "organization_user_wire"].includes(transaction_type)) {
        return; // only want wire lmits here
      }
      result[transaction_type] = {};

      const limitForTransaction = limits[transaction_type];
      if (!limitForTransaction) {
        return;
      }

      const limitsForTransactionKeys = Object.keys(
        limitForTransaction
      ) as (keyof typeof limitForTransaction)[];

      limitsForTransactionKeys.forEach((key) => {
        // limits[transaction] has numbers (which represent days) and "amount_available" as keys
        if (Number(key) && limitForTransaction[key] !== undefined) {
          // only want number keys for display
          result[transaction_type][key] = limitForTransaction[key];
        }
      });
    });
    return result;
  }, [limits]);
};
