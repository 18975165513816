import { DateTime } from "luxon";

import { SearchTransactionTypeOptions } from "./Transaction";
import Filters from "./filters";

export const getStartOfDay = (date: string) =>
  /* returns timezone-aware date at start of day */
  DateTime.fromFormat(date, "M/d/yyyy")
    .toUTC()
    .setZone("local", { keepLocalTime: true })
    .startOf("day")
    .toISO();

export const getEndOfDay = (date: string) =>
  /* returns timezone-aware date at end of day */
  DateTime.fromFormat(date, "M/d/yyyy")
    .toUTC()
    .setZone("local", { keepLocalTime: true })
    .endOf("day")
    .toISO();

export type Period = "custom" | [string, string];
type PeriodOption = {
  displayName: string;
  value: Period;
};

export const presetPeriods = () => {
  /* outputs a list of options of this month, last month, the month before that, and a user-set custom date range.
     used to hydrate a dropdown field or to generate what the start and end date is for a month interval.
  */
  const currMonthEnd = DateTime.now();
  const currMonthStart = currMonthEnd.set({ day: 1 });
  const prevMonthEnd = currMonthStart.minus({ days: 1 });
  const prevMonthStart = prevMonthEnd.set({ day: 1 });
  const prevPrevMonthEnd = prevMonthStart.minus({ days: 1 });
  const prevPrevMonthStart = prevPrevMonthEnd.set({ day: 1 });

  const options: PeriodOption[] = [
    [currMonthStart, currMonthEnd],
    [prevMonthStart, prevMonthEnd],
    [prevPrevMonthStart, prevPrevMonthEnd],
  ].map(([start, end]) => ({
    displayName: start.toFormat("MMMM yyyy"),
    value: [start.toFormat("MM/dd/yyyy"), end.toFormat("MM/dd/yyyy")],
  }));

  options.push({ displayName: "Custom date range", value: "custom" });

  return options;
};

export type PossibleFilters = {
  keyword?: string;
  minAmount?: string;
  maxAmount?: string;
  period?: Period;
  min_date?: string;
  max_date?: string;
  category?: string;
  tag?: string;
  transaction_type?: string;
};

export type Filter =
  | "keyword"
  | "minAmount"
  | "maxAmount"
  | "period"
  | "min_date"
  | "max_date"
  | "category"
  | "tag"
  | "transaction_type"
  | "amountRange";

type FilterTag = {
  type: Filter;
  value: string;
};

export const convertFiltersToQuery = (filters: PossibleFilters) => {
  let query = "";

  if (filters?.keyword) {
    query = filters.keyword;
  }
  if (filters?.minAmount) {
    const cleanedMinAmount = filters.minAmount.replace(/[$,]/g, "");
    query = `${query} amount>="${cleanedMinAmount}"`;
  }
  if (filters?.maxAmount) {
    const cleanedMaxAmount = filters.maxAmount.replace(/[$,]/g, "");
    query = `${query} amount<="${cleanedMaxAmount}"`;
  }

  if (filters?.period) {
    const periods = presetPeriods();
    const currentPeriod = periods.find(
      (period) =>
        JSON.stringify(period.value) === JSON.stringify(filters.period),
    );
    if (currentPeriod) {
      if (currentPeriod.value !== "custom") {
        const [periodStart, periodEnd] = filters.period;
        query = `${query} settled>="${getStartOfDay(
          periodStart,
        )}" settled<"${getEndOfDay(periodEnd)}"`;
      } else {
        if (filters?.min_date) {
          query = `${query} settled>="${getStartOfDay(filters.min_date)}"`;
        }
        if (filters?.max_date) {
          query = `${query} settled<"${getEndOfDay(filters.max_date)}"`;
        }
      }
    }
  }

  if (filters?.category && filters?.category !== "all") {
    query = `${query} category:"${filters.category}"`;
  }

  if (filters?.tag && filters?.tag !== "all") {
    query = `${query} tag:"${filters.tag}"`;
  }

  if (filters?.transaction_type) {
    query = `${query} is:${filters.transaction_type}`;
  }

  return query;
};

export const convertFiltersToTags = (
  filters: PossibleFilters,
  formatNumber: (amount: number) => string,
) => {
  const filterTags: FilterTag[] = [];

  const normalizeNumberForTag = (numStr: string) =>
    formatNumber(Number(numStr.replace(/[$,]/g, "")));

  if (filters?.keyword) {
    filterTags.push({ type: "keyword", value: `"${filters.keyword}"` });
  }

  // if both min and max amount are set, treat as 1 filter
  if (filters?.minAmount && filters?.maxAmount) {
    const minAmount = normalizeNumberForTag(filters.minAmount);
    const maxAmount = normalizeNumberForTag(filters.maxAmount);
    filterTags.push({
      type: "amountRange",
      value: `${minAmount}-${maxAmount}`,
    });
  } else if (filters?.minAmount) {
    const minAmount = normalizeNumberForTag(filters.minAmount);
    filterTags.push({ type: "minAmount", value: `${minAmount}+` });
  } else if (filters?.maxAmount) {
    const maxAmount = normalizeNumberForTag(filters.maxAmount);
    filterTags.push({ type: "maxAmount", value: `${maxAmount}-` });
  }

  const periods = presetPeriods();
  const currentPeriod = periods.find(
    (period) =>
      JSON.stringify(period.value) === JSON.stringify(filters?.period),
  );
  if (currentPeriod) {
    if (currentPeriod.value !== "custom") {
      // pre-set date ranges behave as 1 filter (the before & after are hardcoded for the range)
      filterTags.push({
        type: "period",
        value: `${currentPeriod.displayName}`,
      });
    } else {
      // custom date ranges behave as 2 separate filters (the min_date & max_date can be separately deleted)
      if (filters?.min_date) {
        filterTags.push({
          type: "min_date",
          value: `after: ${Filters.americanDate(
            `${DateTime.fromFormat(filters.min_date, "M/d/yyyy").toJSDate()}`,
          )}`,
        });
      }
      if (filters?.max_date) {
        filterTags.push({
          type: "max_date",
          value: `before: ${Filters.americanDate(
            `${DateTime.fromFormat(filters.max_date, "M/d/yyyy").toJSDate()}`,
          )}`,
        });
      }
    }
  }

  // setting category=all is the equivalent of not setting a category
  if (filters?.category && filters?.category !== "all") {
    filterTags.push({
      type: "category",
      value: `category: ${filters.category}`,
    });
  }

  // setting tag=all is the equivalent of not setting a tag
  if (filters?.tag && filters?.tag !== "all") {
    filterTags.push({ type: "tag", value: `tag: ${filters.tag}` });
  }

  if (filters?.transaction_type) {
    filterTags.push({
      type: "transaction_type",
      value: `type: ${SearchTransactionTypeOptions.find(
        (option) => option.value === filters.transaction_type,
      )?.displayName.toLowerCase()}`,
    });
  }

  return filterTags;
};
