export const isAchRecipient = (recipient?: API.Recipient | null) =>
  Boolean(recipient && recipient.ach_destination);

export const isWireRecipient = (recipient?: API.Recipient | null) =>
  Boolean(
    recipient && recipient.fedwire_destination && !recipient.ach_destination,
  );

export const hasNoPaymentDetails = (recipient?: API.Recipient) =>
  Boolean(
    recipient && !recipient.fedwire_destination && !recipient.ach_destination,
  );
