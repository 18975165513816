import { useCallback } from "react";

import {
  type OverrideRunProps,
  useRequestWithFeedback,
} from "../../../composites";
import { useObjectMemo } from "../../../hooks";

export const useCreateACHPayment = () => {
  const { send, loading } = useRequestWithFeedback<
    API.CreateACHPayment.Response,
    API.CreateACHPayment.Error
  >();

  const createACHPayment = useCallback(
    (
      overrideRunProps: OverrideRunProps<
        API.CreateACHPayment.Response,
        API.CreateACHPayment.Error
      > = {},
    ) => {
      send({
        // TODO: update with real API, add error handling
        action: {
          url: "/",
          options: {},
        },
        ...overrideRunProps,
      });
    },
    [send],
  );

  return useObjectMemo({
    createACHPayment,
    loading,
  });
};
