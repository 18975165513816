import { useContext, useEffect, useState } from "react";
import Feature, { Features } from "byzantine/src/Feature";
import Account from "byzantine/src/Account";
import type Address from "byzantine/src/Address";
import { translateStringSetting } from "byzantine/src/l10n/SettingLocalizers";
import { useLocalization } from "@fluent/react";
import {
  Button,
  ContextForm,
  Dialog,
  TextInput,
  RadioButtons,
  useFormData,
  useNotificationContext,
} from "cerulean";
import { useUserFeatures } from "../contexts/UserFeaturesContext";
import InstitutionSettingsContext from "../contexts/InstitutionSettingsContext";
import SectionCard from "../SectionCard";
import AmountTextInput from "../form/AmountTextInput";
import AddressTitle from "./AddressTitle";
import BillPaySso from "../transfer/BillPaySso";

const StopSingleCheckFields = () => {
  const { l10n } = useLocalization();
  return (
    <>
      <ContextForm.Field required>
        <TextInput
          field="check_number"
          label={l10n.getString("label-check-number")}
        />
      </ContextForm.Field>
      <ContextForm.Field>
        <AmountTextInput
          field="amount"
          label={l10n.getString("label-amount")}
        />
      </ContextForm.Field>
    </>
  );
};

const StopCheckRangeFields = () => {
  const { l10n } = useLocalization();
  return (
    <>
      <ContextForm.Field required>
        <TextInput
          field="min_check_number"
          label={l10n.getString("label-min-check-number")}
        />
      </ContextForm.Field>
      <ContextForm.Field required>
        <TextInput
          field="max_check_number"
          label={l10n.getString("label-max-check-number")}
        />
      </ContextForm.Field>
    </>
  );
};

interface StopCheckPaymentFormProps {
  account: Account;
  closeDialog: () => void;
  refreshStopPayments: () => void;
  stopPaymentInfo: string;
}

export const StopCheckPaymentForm = ({
  account,
  closeDialog,
  refreshStopPayments,
  stopPaymentInfo,
}: StopCheckPaymentFormProps) => {
  const { l10n } = useLocalization();
  const { sendNotificationToParent } = useNotificationContext();
  const { formData, onChange, setFormData } = useFormData({});
  const features = useUserFeatures() as Features;

  useEffect(() => {
    if (Object.keys(formData).length > 1)
      setFormData({ request_type: formData?.request_type });
  }, [formData?.request_type]);

  const onSubmit = (callback: (error?: Error) => void) => {
    const payload =
      formData.request_type === "range"
        ? {
          min_check_number: formData.min_check_number,
          max_check_number: formData.max_check_number,
        }
        : {
          min_check_number: formData.check_number,
          amount: formData?.amount === "" ? undefined : formData?.amount,
        };
    account
      .stopCheckPayment(payload)
      .then(() => {
        closeDialog();
        callback();
        sendNotificationToParent({
          type: "success",
          text: l10n.getString("notification-stop-payment-requested"),
        });
        refreshStopPayments();
      })
      .catch(callback);
  };

  const StopPaymentInfo = () => (
    <>
      <div className="font-size-s-medium-grey">
        {l10n.getString("stop-payment-ineligible")}
      </div>
      {stopPaymentInfo && (
        <div className="font-size-s-medium-grey padding--top--l">
          {stopPaymentInfo}
        </div>
      )}
    </>
  );

  return (
    <ContextForm data={formData} onChange={onChange}>
      {features.stop_payment_by_range && (
        <>
          <div className="margin--bottom--s fontWeight--semibold fontColor--heading">
            {l10n.getString("stop-payment-which-checks")}
          </div>
          <ContextForm.Field
            required
            style={{ paddingBottom: "var(--space-xxs)" }}
          >
            <RadioButtons
              field="request_type"
              name="request_type"
              options={{
                [l10n.getString("label-stop-payment-single")]: "single",
                [l10n.getString("label-stop-payment-multiple")]: "range",
              }}
            />
          </ContextForm.Field>
        </>
      )}
      {(!features.stop_payment_by_range ||
        formData?.request_type === "single") && <StopSingleCheckFields />}
      {formData?.request_type === "range" && <StopCheckRangeFields />}
      {(!features.stop_payment_by_range || formData?.request_type) && (
        <StopPaymentInfo />
      )}
      <ContextForm.ActionBar>
        <ContextForm.Action
          noValidation
          onSubmit={closeDialog}
          dangerouslyDisableShowLoading
        >
          <div style={{ margin: "auto var(--space-m) auto auto" }}>
            <Button kind="negative" label={l10n.getString("button-cancel")} />
          </div>
        </ContextForm.Action>
        <ContextForm.Action onSubmit={onSubmit}>
          <Button
            kind="primary"
            label={l10n.getString("button-stop-payment")}
          />
        </ContextForm.Action>
      </ContextForm.ActionBar>
    </ContextForm>
  );
};

interface StopCheckPaymentProps {
  account: Account;
  refreshStopPayments: () => void;
  stopPaymentInfo: string;
}

const StopCheckPayment = ({
  account,
  refreshStopPayments,
  stopPaymentInfo,
}: StopCheckPaymentProps) => {
  const { l10n } = useLocalization();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const closeDialog = () => setIsDialogOpen(false);
  const openDialog = () => setIsDialogOpen(true);

  return (
    <>
      <Button
        kind="plain"
        label={l10n.getString("button-stop-check-payment")}
        onClick={openDialog}
      />
      <Dialog
        isOpen={isDialogOpen}
        onUserDismiss={closeDialog}
        title={l10n.getString("dialog-title-stop-check-payment")}
      >
        <div className="margin--top--s">
          <StopCheckPaymentForm
            account={account}
            refreshStopPayments={refreshStopPayments}
            stopPaymentInfo={stopPaymentInfo}
            closeDialog={closeDialog}
          />
        </div>
      </Dialog>
    </>
  );
};

interface CheckWithdrawalFormProps {
  account: Account;
  closeDialog: () => void;
  address?: Address;
}

const CheckWithdrawalForm = ({
  account,
  closeDialog,
  address,
}: CheckWithdrawalFormProps) => {
  const { l10n } = useLocalization();
  const { sendNotificationToParent } = useNotificationContext();
  const { formData, onChange } = useFormData({});
  const onSubmit = (callback: (error?: Error) => void) => {
    account
      .withdrawCashiersCheck({ ...formData })
      .then(() => {
        closeDialog();
        callback();
        sendNotificationToParent({
          type: "success",
          text: l10n.getString("notification-request-submitted"),
        });
      })
      .catch((error) => callback(error));
  };

  return (
    <ContextForm data={formData} onChange={onChange}>
      <ContextForm.Field required>
        <AmountTextInput
          field="amount"
          label={l10n.getString("label-amount")}
        />
      </ContextForm.Field>
      <div className="font-size-s-medium-grey">
        {l10n.getString("cashiers-check-address")}
      </div>
      <div className="font-size-s-medium-grey padding--y--xs">
        <AddressTitle noMailing={false} address={address} aligned="left" />
      </div>
      <ContextForm.ActionBar>
        <ContextForm.Action
          noValidation
          onSubmit={closeDialog}
          dangerouslyDisableShowLoading
        >
          <div style={{ margin: "auto var(--space-m) auto auto" }}>
            <Button kind="negative" label={l10n.getString("button-cancel")} />
          </div>
        </ContextForm.Action>
        <ContextForm.Action onSubmit={onSubmit}>
          <Button
            kind="primary"
            label={l10n.getString("button-request-check")}
          />
        </ContextForm.Action>
      </ContextForm.ActionBar>
    </ContextForm>
  );
};

interface CheckWithdrawalProps {
  account: Account;
  address?: Address;
}

const CheckWithdrawal = ({ account, address }: CheckWithdrawalProps) => {
  const { l10n } = useLocalization();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const closeDialog = () => setIsDialogOpen(false);
  const openDialog = () => setIsDialogOpen(true);

  return (
    <>
      <Button
        kind="plain"
        label={l10n.getString("request-cashiers-check")}
        onClick={openDialog}
      />
      <Dialog
        isOpen={isDialogOpen}
        onUserDismiss={closeDialog}
        title={l10n.getString("request-cashiers-check")}
      >
        <div className="margin--top--s">
          <CheckWithdrawalForm
            account={account}
            address={address}
            closeDialog={closeDialog}
          />
        </div>
      </Dialog>
    </>
  );
};

interface ServicesCardProps {
  account: Account;
  address?: Address;
  refreshStopPayments: () => void;
}

const ServicesCard = ({
  account,
  address,
  refreshStopPayments,
}: ServicesCardProps) => {
  const { stoppayment_info: stopPaymentInfo = "" } = useContext(
    InstitutionSettingsContext,
  );
  const userFeatures = useUserFeatures() as Features;
  const { l10n } = useLocalization();

  const features = Object.freeze({
    cards: userFeatures?.cards,
    bill_pay:
      userFeatures?.bill_pay &&
      Account.PRODUCT_GROUPS.Checking.includes(account.product_type),
    stop_payment:
      account.features.includes("stop_payment") && userFeatures?.stop_payment,
    check_order:
      account.features.includes("check_order") && userFeatures?.check_order,
    check_withdrawal: account.features.includes("check_withdrawal"),
    external_loan_management: account.features.includes(
      "external_loan_management",
    ),
  }) as Features;

  return (
    <Feature
      features={features}
      or={[
        "cards",
        "bill_pay",
        "stop_payment",
        "check_order",
        "check_withdrawal",
        "external_loan_management",
      ]}
    >
      <SectionCard>
        <SectionCard.Title text={l10n.getString("heading-account-services")} />
        <Feature features={features} or={["cards"]}>
          <Button
            as="a"
            kind="plain"
            label={l10n.getString("button-manage-cards")}
            href="/cards"
          />
        </Feature>
        <Feature features={features} or={["bill_pay"]}>
          <>
            <div className="margin--top--xs" />
            <BillPaySso label={l10n.getString("manage-bill-pay")} />
          </>
        </Feature>
        <Feature features={features} or={["stop_payment"]}>
          <div className="margin--top--xs">
            <StopCheckPayment
              account={account}
              refreshStopPayments={refreshStopPayments}
              stopPaymentInfo={translateStringSetting(
                "stoppayment-info",
                stopPaymentInfo,
                l10n,
              )}
            />
          </div>
        </Feature>
        <Feature features={features} or={["check_order"]}>
          <div className="margin--top--xs">
            <Button
              as="a"
              target="_blank"
              kind="plain"
              label={l10n.getString("order-checks-link")}
              href={`/v1/signed_urls/check_order/urls/${account.id}`}
            />
          </div>
        </Feature>
        <Feature features={features} or={["check_withdrawal"]}>
          <div className="margin--top--xs">
            <CheckWithdrawal account={account} address={address} />
          </div>
        </Feature>
        <Feature features={features} or={["external_loan_management"]}>
          <div className="margin--top--xs">
            <Button
              as="a"
              target="_blank"
              kind="plain"
              label={l10n.getString("manage-mortgage")}
              href={`/v1/signed_urls/external_loan_management/urls/${account.id}`}
            />
          </div>
        </Feature>
      </SectionCard>
    </Feature>
  );
};

export default ServicesCard;
