import { negotiateLanguages } from "@fluent/langneg";
import { FluentBundle, FluentResource } from "@fluent/bundle";
import { ReactLocalization } from "@fluent/react";
import { LANG_CHANGE } from "../browserCustomEvents";
import api from "./api";

/**
 * Determines which locales need to be loaded and returns a list
 * of `FluentBundle` instances
 *
 * @param {String} internalName
 * @param {String} productCode three letter Narmi product code
 * @param {String} apiUrl
 * @returns {Object} map of locale codes to `FluentBundle` instances
 */
export const getAllBundles = async (internalName, s3ImagesBucket, productCode, apiUrl) => {
  let manifest = {};
  let fluentResources = [];
  let localeCodes = [];
  let ftlStrings = [];
  let defaultFtl = "";
  let hasSettingsLocales = false;
  let settingsLocaleCodes = [];
  let settingsFtlStrings = [];
  let bundlesMap = {};
  let result = {
    error: undefined,
    bundles: bundlesMap,
  };

  // We should fail fast and hard if the manifest can not be fetched.
  // This early exit prevents the `ReactLocalization` (`l10n`) property
  // from receiving any bundles, which in turn throws a warning we can track.
  // When this happens, any `Localized` components or `getString` functions
  // will use the fallback text defined in JSX source.
  try {
    manifest = await api.fetchFtlManifest(internalName, s3ImagesBucket, apiUrl);
  } catch (error) {
    result.error = error;
    return result;
  }

  if (!Object.keys(manifest).includes(productCode)) {
    result.error = `Product "${productCode}" not found in manifest`;
    return result;
  }

  if (Object.keys(manifest).includes("fi")) {
    hasSettingsLocales = true;
  }

  const productLocales = manifest[productCode];

  // Only create bundles for languages that have been enabled.
  // The institution's `en.ftl` should never be treated as disabled,
  // even if we make some mistake in the manifests.
  const enabledLocales = Object.entries(productLocales).reduce(
    (allowedLocales, [localeCode, localeData]) => {
      if (localeData.state === "enabled" || localeCode === "en") {
        allowedLocales[localeCode] = localeData;
      }
      return allowedLocales;
    },
    {}
  );

  localeCodes = [...localeCodes, ...Object.keys(enabledLocales)];

  try {
    ftlStrings = await Promise.all(
      localeCodes.map((localeCode) =>
        api.fetchInstitutionFtl(
          internalName,
          s3ImagesBucket,
          enabledLocales[localeCode].latest,
          apiUrl
        )
      )
    );
  } catch (error) {
    result.error = error;
    return result;
  }

  // in addition to the locales for this specific product,
  // conditionally load `fi` product locales for settings translations.
  // set in language stack as `<langCode>-settings`
  if (hasSettingsLocales) {
    try {
      settingsLocaleCodes = Object.keys(manifest.fi).map((lang) => `${lang}-settings`);

      settingsFtlStrings = await Promise.all(
        Object.values(manifest.fi).map((localeData) =>
          api.fetchInstitutionFtl(internalName, s3ImagesBucket, localeData.latest, apiUrl)
        )
      );
    } catch (error) {
      result.error = error;
      return result;
    }
  }

  try {
    defaultFtl = await api.fetchDefaultFtl(productCode, apiUrl);
  } catch (error) {
    result.error = error;
    return result;
  }

  // add default locale code and content
  localeCodes.push("en-default");
  ftlStrings.push(defaultFtl);

  // add `fi` locale codes and content
  if (hasSettingsLocales) {
    localeCodes = localeCodes.concat(settingsLocaleCodes);
    ftlStrings = ftlStrings.concat(settingsFtlStrings);
  }

  // convert FTL strings to FluentResource instances
  fluentResources = ftlStrings.map((str) => new FluentResource(str));

  // populate map of FluentBundle instances by locale code
  fluentResources.forEach((resource, i) => {
    const localeCode = localeCodes[i];
    const bundle = new FluentBundle(localeCode);
    bundle.addResource(resource);
    bundlesMap[localeCode] = bundle;
  });

  return { ...result, bundles: bundlesMap };
};

/**
 * @param {String} selectedLocale ISO639 two letter locale code
 * @param {Object} bundlesMap FluentBundles instances by locale code
 * @param {Function} parser custom markup parser for ReactLocalization instance
 * @returns {ReactLocalization}
 */
export const getReactLocalization = (selectedLocale, bundlesMap) => {
  const availableLocales = Object.keys(bundlesMap).filter((code) => code !== "en-default");
  let requestedLocales = [];

  if (selectedLocale) {
    requestedLocales.push(selectedLocale);
  }

  if (navigator && navigator.languages) {
    requestedLocales = [...requestedLocales, navigator.languages];
  }

  // `negotiateLanguages` resolves a list of locale codes in order, based on
  // language selection and user system preferences.
  //
  // If none of the requested locale(s) are found, we fall back to the
  // institution-specific English messages.
  const negotatedLocales = negotiateLanguages(requestedLocales, availableLocales, {
    defaultLocale: "en",
  });

  //`en-default` should always be present as the final locale in the stack
  const localeStack = [...negotatedLocales, "en-default"];

  const bundles = localeStack.map((localeCode) => bundlesMap[localeCode]);

  const l10n = new ReactLocalization(bundles);
  window.dispatchEvent(
    new CustomEvent(LANG_CHANGE, {
      detail: l10n,
    })
  );
  return l10n;
};
