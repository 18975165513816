import { useState } from "react";
import { PreferenceContentCard } from "cerulean";

import ProfileSettingRow from "../ProfileSettingRow";
import PasswordEditDialog from "./PasswordEditDialog/PasswordEditDialog";

const PasswordInfoCard = () => {
  const [isPasswordEditDialogOpen, setIsPasswordEditDialogOpen] =
    useState(false);

  return (
    <>
      <PreferenceContentCard
        title="Password"
        showSubtext={false}
        showToggle={false}
        content={
          <ProfileSettingRow
            label="Password"
            value="********************"
            editOnClick={() => {
              setIsPasswordEditDialogOpen(true);
            }}
            testId="password-setting"
          />
        }
      />
      <PasswordEditDialog
        isOpen={isPasswordEditDialogOpen}
        closeDialog={() => {
          setIsPasswordEditDialogOpen(false);
        }}
      />
    </>
  );
};

export default PasswordInfoCard;
