import React, { memo, useMemo } from "react";

import { Navigate } from "react-router-dom";
import { modules } from "byzantine";

import TfaVerify from "../../../../../../components/verify/TfaVerify";

import { useSendViaTemplate } from "./SendViaTemplate.container";
import Form from "./Form";
import Review from "./Review";

import "./SendViaTemplate.module.scss";

function SendViaTemplateScreen() {
  const { uiState, submitForm, complete2FA, submitReview, resetFlow, loading } =
    useSendViaTemplate();

  const content = useMemo(() => {
    switch (uiState) {
      case "2FA": {
        return <TfaVerify goToReview={complete2FA} cancelAction={resetFlow} />;
      }
      case "review": {
        return (
          <Review
            onSubmit={submitReview}
            loading={loading}
            goBack={resetFlow}
          />
        );
      }
      default: {
        return <Form onSubmit={submitForm} />;
      }
    }
  }, [uiState, complete2FA, resetFlow, submitReview, loading, submitForm]);

  const { showSendViaTemplateUI } = modules.wireTemplates.useWirePermissions();
  if (!showSendViaTemplateUI) {
    return <Navigate to={"/"} replace />;
  }

  return (
    <div className="wire-via-template-container">
      <div className="mobile-web-extra-space">{content}</div>
    </div>
  );
}

export default memo(SendViaTemplateScreen);
