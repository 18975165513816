/* eslint-disable camelcase */

// The web wire form accepts memo lines individually, where as mobile
// accepts them all as one string and then splits them up before sending data
// To the backend. This means that we need to infer how to stitch
// the four memos together based on how many chars are in each line.
export const formatMemos = (wire: API.Wire) => {
  const { memo_1, memo_2, memo_3, memo_4 } = wire;

  const memoLines = [memo_1, memo_2, memo_3, memo_4];

  return memoLines
    .map((memo) => {
      if (memo && memo.length < 35) {
        return memo.concat(" ");
      }
      return memo;
    })
    .join("")
    .trim();
};
