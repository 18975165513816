import { Outlet } from "react-router-dom";
import { Portal } from "cerulean";
import Account from "byzantine/src/Account";

import TopMenu from "../TopMenu";
import { LegacyNafUrl, Institution } from "../../types";

interface MainLayoutProps {
  accounts: Account[];
  features: API.Features;
  institution: Institution;
  legacyNafUrls: LegacyNafUrl[];
  deviceIsRemembered: boolean;
}

const MainLayout = (props: MainLayoutProps) => (
  <>
    <Portal rootId="top-menu-react">
      <TopMenu {...props} />
    </Portal>
    <Outlet />
  </>
);

export default MainLayout;
