import type { Network } from "../../interfaces/network.types";
/**
 * Types here use to 3 generic JSON types
 * 1. Data / D    : The JSON payload in a success API response
 * 2. Error / E   : The expected JSON payload in an error API response,
 *                  bearing in mind API error handling is often inconsistent
 * 3. Payload / P : The payload JSON sent in the request
 */

export type Data = { [key: string]: unknown };
export type Error = { [key: string]: unknown };
export type Payload = { [key: string]: unknown };

export type NetworkPayload<P = Payload> = {
  url: string;
  options: Network.Options<P>;
};

export type ErrorResponse<E = Error> = {
  response?: {
    status: number;
    json: () => Promise<E>;
  };
};

export type RunProps<D = Data, E = Error, P = Payload> = {
  action: NetworkPayload<P>;
  optimistic?: {
    update: () => void;
    rollback: () => void;
  };
  onSuccess?: () => void;
  onError?: (err: ErrorResponse<E>) => void;
  onFinally?: () => void;
  onData?: (data: D) => void;
  messaging?: {
    toast?: {
      success?: string;
      error?: string;
      partial?: string;
    };
    text?: {
      success?: string;
      error?: string;
      partial?: string;
    };
  };
};

export type OverrideRunProps<D = Data, E = Error, P = Payload> = Omit<
  RunProps<D, E, P>,
  "action"
>;

export type MessagingState = {
  errorMessage: string;
  successMessage: string;
};

export const REQUEST_STATUS = {
  idle: "idle",
  loading: "loading",
  success: "success",
  error: "error",
} as const;

export type RequestStatus = ValueOf<typeof REQUEST_STATUS>;

export type RequestState<D = Data> = {
  status: RequestStatus;
  loading: boolean;
  data: D | Record<string, never>;
  error: unknown;
};

export type RequestWithFeedback<
  D = Data,
  E = Error,
  P = Payload,
> = MessagingState &
  RequestState<D> & {
    send: (props: RunProps<D, E, P>) => Promise<void> | Promise<D>;
  };

export type ErrorResult<P = Payload> = {
  error: unknown;
  action: NetworkPayload<P>;
};

export type RunManyProps<D = Data, E = Error, P = Payload> = Omit<
  RunProps<D, E, P>,
  "action" | "onData" | "onError" | "optimistic"
> & {
  actions: NetworkPayload<P>[];
  onData?: (data: Array<D | null>) => void;
  onPartial?: (errors: ErrorResult<P>[]) => void;
  onError?: () => void;
};

export type OverrideRunManyProps<D> = Omit<RunManyProps<D>, "actions">;

type CombineRunProps<D, E, P> = (
  inner?: OverrideRunProps<D, E, P>,
) => OverrideRunProps<D, E, P>;

export type LayeredOverrideRunProps<D, E = Error, P = Payload> =
  | OverrideRunProps<D, E, P>
  | CombineRunProps<D, E, P>;

export type Request<D, E> = {
  state: RequestState<D>;
  start: () => void;
  receive: (data: D) => void;
  fail: (err: ErrorResponse<E>) => void;
  finish: () => void;
};

export type UrlKeyValueTuple = Array<[string, string]>;
