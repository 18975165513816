import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ApiHttp from "byzantine/src/ApiHttp";
import { ScrollingIframe } from "cerulean";

interface SignedUrlIframeProps {
  endpoint: string;
  height?: string;
  setLoading?: (loading: boolean) => void;
  setError?: (error: any) => void;
  sendNotification?: (notification: any) => void;
  endpointIsUrl?: boolean;
}

export default function SignedUrlIframe({
  endpoint,
  height,
  setLoading = () => {},
  setError = () => {},
  sendNotification,
  endpointIsUrl,
}: SignedUrlIframeProps) {
  const [src, setSrc] = useState("");
  const [action, setAction] = useState("");
  const [signedRequest, setSignedRequest] = useState("");
  const [code, setCode] = useState<string | null>(null);

  useEffect(() => {
    const fetchSignedUrl = async () => {
      setLoading(true);

      try {
        const response = await ApiHttp.fetch(endpoint, { endpointIsUrl });

        if (response.method === "post") {
          setAction(response.url);
          setSignedRequest(response.signed_request);
          setCode(new URL(response.url).searchParams.get("code"));
        } else {
          setSrc(response.url);
          setCode("");
        }
      } catch (err) {
        setCode("");
        setError(err);

        if (sendNotification) {
          sendNotification({
            type: "negative",
            text: "Sorry, this external service is not available at this time",
          });
        }
      }

      setLoading(false);
    };

    fetchSignedUrl();
  }, []);

  if (code === null) {
    return null;
  }

  return (
    <ScrollingIframe
      title="signedUrl"
      height={height}
      src={src}
      action={action}
      signedRequest={signedRequest}
      code={code}
    />
  );
}

SignedUrlIframe.propTypes = {
  endpoint: PropTypes.string.isRequired,
  height: PropTypes.string,
  setLoading: PropTypes.func,
  setError: PropTypes.func,
  sendNotification: PropTypes.func,
  endpointIsUrl: PropTypes.bool,
};

SignedUrlIframe.defaultProps = {
  height: "600px",
};
