import { useState } from "react";
import PropTypes from "prop-types";
import type Account from "byzantine/src/Account";
import BaseBanner from "../BaseBanner";
import { useInstitutionSettings } from "../contexts/InstitutionSettingsContext";
import { useUserFeatures } from "../contexts/UserFeaturesContext";
import EditAccountNameModal from "./EditAccountNameModal";

interface EditIconProps {
  onClick: () => void;
}

const EditIcon = ({ onClick }: EditIconProps) => (
  <div className="margin--left--m margin--top--xxs fontSize--l">
    <div
      className="narmi-icon-edit clickable"
      onClick={onClick}
      onKeyUp={({ key }) => {
        if (key === "Enter") onClick();
      }}
      aria-label="Edit name"
      role="button"
      tabIndex={0}
    />
  </div>
);

interface AccountBannerProps {
  account: Account;
}
const AccountBanner = ({ account }: AccountBannerProps) => {
  const institution = useInstitutionSettings();
  const [dialogOpen, setDialogOpen] = useState(false);

  const userFeatures = useUserFeatures();

  const buttonName = account.isDeposit()
    ? account.isValidInternalOrExternalTransferSource(
      institution.ach_allows_pull || false,
    ) && "Transfer funds"
    : account.isValidInternalOrExternalTransferDestination(
      institution.ach_allows_push || false,
    ) && "Make a payment";

  return (
    <div>
      <BaseBanner
        bannerName={account.nickname || account.name}
        buttonFunction={() =>
          window.location.assign(
            `/transfer/funds/?${
              account.isCredit() ? "to_account_id" : "from_account_id"
            }=${account.id}&previous=/accounts/${account.id}`,
          )
        }
        buttonName={buttonName || ""}
        orFeatureFlagChecks={["internal_transfers", "ach"]}
        features={userFeatures}
        icon={
          <EditIcon
            onClick={() => {
              setDialogOpen(true);
            }}
          />
        }
      />
      <EditAccountNameModal
        name={account.nickname || account.name}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        accountUuid={account.id}
      />
    </div>
  );
};
AccountBanner.propTypes = {
  account: PropTypes.object,
};

export default AccountBanner;
