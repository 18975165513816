import { SearchSelector } from "cerulean";

interface RecipientSelectorProps {
  field: string;
  value: string;
  recipients: API.Recipient[];
  label: string;
  onChange: (recipientId: API.Recipient["id"]) => void;
  onAddRecipientClick: () => void;
  error?: string;
}

const RecipientSelector = ({
  value,
  recipients,
  label,
  onChange,
  onAddRecipientClick,
  error,
}: RecipientSelectorProps) => (
  <SearchSelector<API.Recipient>
    value={value}
    items={recipients}
    label={label}
    onChange={onChange}
    filter={(recipient: API.Recipient, searchTerm: string) =>
      recipient.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
    }
    getItemLabel={(recipient: API.Recipient) => recipient.name}
    getItemValue={(recipient: API.Recipient) => recipient.id}
    bottomAction={{
      label: "+ Add a new recipient",
      onClick: onAddRecipientClick,
    }}
    error={error}
  />
);

export default RecipientSelector;
