import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";

/**
 *
 * Implements a React Portal https://reactjs.org/docs/portals.html
 * in the manner that is compatible with NextJS
 *
 *
 */
const Portal = ({ children, rootId }: React.PropsWithChildren<{ rootId: string }>) => {
  const ref = useRef<Element | null>();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector(`#${rootId}`);
    setMounted(true);
  }, [children]);

  return mounted && ref.current ? ReactDOM.createPortal(children, ref.current!) : null;
};

export default Portal;
