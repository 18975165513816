import { useIsBusinessUser } from "../../entities/helpers/user.helpers";
import { useFeature } from "../features";

export const useACHPaymentsPermissions = () => {
  const { featureEnabled } = useFeature();

  const isBusinessUser = useIsBusinessUser();
  const achPaymentsEnabled = featureEnabled("ach_payments");

  return achPaymentsEnabled && isBusinessUser;
};
