/* eslint-disable import/prefer-default-export */
import en from "./data/en.json";
import es from "./data/es.json";
import fr from "./data/fr.json";
import zh from "./data/zh.json";

en satisfies Record<string, string>;
es satisfies Record<string, string>;
fr satisfies Record<string, string>;
zh satisfies Record<string, string>;

type CountryNames = typeof en;
type CountryCode = keyof CountryNames;

const SUPPORTED_LANGS = { en, es, fr, zh } satisfies Record<
  string,
  CountryNames
>;

type LocaleMap = typeof SUPPORTED_LANGS;
export type LocaleCode = keyof LocaleMap;

const isSupportedLangCode = (langCode: string): langCode is LocaleCode =>
  Object.keys(SUPPORTED_LANGS).includes(langCode);

/**
 * @param countryCode ISO 3166 two letter country code
 * @param langCode ISO two letter language code
 * @returns string - name of country in given language (or English if unsupported)
 */

export const getCountryName = (countryCode: string, langCode: string) => {
  const countryKey = countryCode.toUpperCase() as CountryCode;
  const supportedLangCode: LocaleCode = isSupportedLangCode(langCode)
    ? langCode
    : "en";
  return SUPPORTED_LANGS[supportedLangCode][countryKey];
};

export { en };
