// slice.ts
import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

import type { SavvyMoneyViewUrl } from "./types";

interface SavvyMoneyUrl {
  view: SavvyMoneyViewUrl;
  url: string;
}

export const savvyMoneyAdapter = createEntityAdapter<SavvyMoneyUrl>({
  selectId: (savvyMoneyUrl) => savvyMoneyUrl.view,
});

const initialState = savvyMoneyAdapter.getInitialState();

const savvyMoneySlice = createSlice({
  name: "savvyMoney",
  initialState,
  reducers: {
    upsertSavvyMoneyUrl: savvyMoneyAdapter.upsertOne,
  },
});

export const { actions } = savvyMoneySlice;
export default {
  savvyMoney: savvyMoneySlice.reducer,
};
