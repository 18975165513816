/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

const name = "recipients";

type RecipientState = {
  byId: Record<API.RecipientId, API.Recipient>;
};

const initialState: RecipientState = {
  byId: {},
};

// reducers that update the store's state
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setMany: (state, action: PayloadAction<Array<API.Recipient>>) => {
      state.byId = action.payload.reduceRight(
        (prev, cur) => ({ ...prev, [cur.id]: cur }),
        {},
      );
    },
    setOne: (state, action: PayloadAction<API.Recipient>) => {
      const recipient = action.payload;
      state.byId[recipient.id] = recipient;
    },
    updateOne: (state, action: PayloadAction<API.Recipient>) => {
      const updatedRecipient = action.payload;
      const existingRecipient = state.byId[updatedRecipient.id];
      if (!existingRecipient) {
        return;
      }
      state.byId[updatedRecipient.id] = {
        ...existingRecipient,
        ...updatedRecipient,
      };
    },
    deleteOne: (state, action: PayloadAction<API.RecipientId>) => {
      const recipientId = action.payload;
      delete state.byId[recipientId];
    },
  },
});

export const { actions, reducer } = slice;

export default {
  [name]: slice.reducer,
};
