import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { SearchTransactionTypeOptions } from "byzantine/src/Transaction";
import {
  convertFiltersToTags,
  presetPeriods,
} from "byzantine/src/TransactionSearchAndFilter";
import type {
  Filter,
  PossibleFilters,
} from "byzantine/src/TransactionSearchAndFilter";
import Filters from "byzantine/src/filters";
import {
  Button,
  ContextForm,
  Dialog,
  Tag,
  TextInput,
  formatNumber,
  useFormData,
} from "cerulean";
import AmountTextInput from "../form/AmountTextInput";
import DateRangeFormField from "../form/DateRangeFormField";
import DropdownField from "../form/DropdownField";

interface TransactionFilterTagSectionProps {
  searchFilters: PossibleFilters;
  setSearchFilters: (newState: object) => void;
}

export const TransactionFilterTagSection = ({
  searchFilters,
  setSearchFilters,
}: TransactionFilterTagSectionProps) => {
  /* display active filters as deleteable tags */
  const filterArray = convertFiltersToTags(searchFilters, formatNumber);
  if (filterArray.length === 0) return null;

  const deleteFilter = (filterType: Filter) => {
    setSearchFilters((currentState: PossibleFilters) => {
      const updatedState = { ...currentState };

      /* `amountRange` filter type is the combination of minAmount and maxAmount fields,
         which are treated as 1 filter if they are both set */
      if (filterType === "amountRange") {
        delete updatedState.minAmount;
        delete updatedState.maxAmount;
      } else {
        delete updatedState[filterType];
      }

      // clear `period` if it is set to `custom` and both `min_date` and `max_date` have been cleared
      if (
        updatedState?.period === "custom" &&
        !updatedState?.min_date &&
        !updatedState?.max_date
      ) {
        delete updatedState.period;
      }

      return updatedState;
    });
  };

  return (
    <div className="tags-row search">
      {filterArray.map((filter) => (
        <Tag
          key={filter.type}
          kind="dismissible"
          label={filter.value}
          onDismiss={() => deleteFilter(filter.type)}
        />
      ))}
      <Button
        kind="plain"
        label="Clear all"
        onClick={() => setSearchFilters({})}
      />
    </div>
  );
};

const MinMaxAmountField = () => (
  <div style={{ display: "flex" }}>
    <ContextForm.Field>
      <AmountTextInput
        field="minAmount"
        label="Min"
        data-test-element="min-amount-input"
      />
    </ContextForm.Field>
    <div
      className="margin--x--xs fontSize--s margin--bottom--l"
      style={{ display: "flex" }}
    >
      <div style={{ margin: "auto" }}>to</div>
    </div>
    <ContextForm.Field>
      <AmountTextInput
        field="maxAmount"
        label="Max"
        data-test-element="max-amount-input"
      />
    </ContextForm.Field>
  </div>
);

interface TransactionSearchAndFilterProps {
  searchFilters: PossibleFilters;
  setSearchFilters: (newState: PossibleFilters) => void;
  switchToAllTransactionsTab: () => void;
  searchableCategories: string[];
  searchableTags: string[];
}

interface SearchAndFilterFormProps extends TransactionSearchAndFilterProps {
  closeDialog: () => void;
}

const SearchAndFilterForm = ({
  closeDialog,
  searchFilters,
  setSearchFilters,
  switchToAllTransactionsTab,
  searchableCategories,
  searchableTags,
}: SearchAndFilterFormProps) => {
  const { formData, setFormData, onChange } = useFormData(searchFilters);

  // organize categories & tags into the form that Dropdown expects its options to be in
  const categoryOptions = searchableCategories.map((category) => ({
    displayName: Filters.capitalizeFirstLetter(category),
    value: category,
  }));
  categoryOptions.unshift({ displayName: "All categories", value: "all" });

  const tagOptions = searchableTags.map((tag) => ({
    displayName: tag,
    value: tag,
  }));
  tagOptions.unshift({ displayName: "All tags", value: "all" });

  useEffect(() => {
    if (formData?.period !== "custom") {
      setFormData((prevState: PossibleFilters) => {
        const newState = { ...prevState };
        delete newState.min_date;
        delete newState.max_date;
        return newState;
      });
    }
  }, [formData?.period]);

  const clearForm = () => {
    // updates the search filters for the parent to also be `{}` if it wasn't empty before
    setFormData({});
    if (JSON.stringify(searchFilters) !== "{}") setSearchFilters({});
  };

  const submitSearchAndFilterForm = () => {
    setSearchFilters(formData);
    /* After the user submits the search & filter form, the active tab will be switched to `All`.
       This is because `Recent` tab's transactions will not reflect the filtering.
     */
    switchToAllTransactionsTab();
    closeDialog();
  };

  return (
    <ContextForm data={formData} onChange={onChange}>
      <ContextForm.Field>
        <DropdownField
          options={presetPeriods()}
          field="period"
          label="Date range"
        />
      </ContextForm.Field>
      <DateRangeFormField hidden={formData?.period !== "custom"} />
      <div className="search-and-filter-border">
        <MinMaxAmountField />
      </div>
      <div className="search-and-filter-border">
        <ContextForm.Field>
          <TextInput
            name="keyword"
            field="keyword"
            label="Keyword"
            data-test-element="input-keyword"
          />
        </ContextForm.Field>
      </div>
      {categoryOptions.length > 1 && (
        <div className="search-and-filter-border">
          <ContextForm.Field>
            <DropdownField
              options={categoryOptions}
              field="category"
              label="Category"
            />
          </ContextForm.Field>
        </div>
      )}
      <div className="search-and-filter-border">
        <ContextForm.Field>
          <DropdownField
            options={SearchTransactionTypeOptions}
            field="transaction_type"
            label="Transaction type"
          />
        </ContextForm.Field>
      </div>
      {tagOptions.length > 1 && (
        <div className="search-and-filter-border">
          <ContextForm.Field>
            <DropdownField options={tagOptions} field="tag" label="Tag" />
          </ContextForm.Field>
        </div>
      )}
      <ContextForm.ActionBar>
        <ContextForm.Action onSubmit={clearForm} dangerouslyDisableShowLoading>
          <Button type="button" kind="plain" label="Clear all" />
        </ContextForm.Action>
        <ContextForm.Action
          onSubmit={submitSearchAndFilterForm}
          dangerouslyDisableShowLoading
        >
          <div style={{ marginLeft: "auto" }}>
            <Button
              kind="primary"
              label="Show results"
              data-test-element="button-show-results"
            />
          </div>
        </ContextForm.Action>
      </ContextForm.ActionBar>
    </ContextForm>
  );
};

const TransactionSearchAndFilter = ({
  searchFilters,
  setSearchFilters,
  switchToAllTransactionsTab,
  searchableCategories,
  searchableTags,
}: TransactionSearchAndFilterProps) => {
  /* Necessary wrapper because if the search filter dialog state was kept inside of `TransactionCard`,
     the paginated transactions list would refresh every time the dialog state changed
     (i.e. when opening or closing) */
  const filterLength = convertFiltersToTags(searchFilters, formatNumber).length;
  const [isSearchFilterOpen, setIsSearchFilterOpen] = useState(false);
  const closeDialog = () => setIsSearchFilterOpen(false);
  const openDialog = () => setIsSearchFilterOpen(true);

  return (
    <>
      <Button
        kind="plain"
        label={`Search & filter${filterLength ? ` (${filterLength})` : ""}`}
        onClick={openDialog}
        data-test-element="button-search-filter"
      />
      <Dialog
        isOpen={isSearchFilterOpen}
        onUserDismiss={closeDialog}
        title="Search & filter"
      >
        <div className="margin--top--s">
          <SearchAndFilterForm
            closeDialog={closeDialog}
            searchFilters={searchFilters}
            setSearchFilters={setSearchFilters}
            switchToAllTransactionsTab={switchToAllTransactionsTab}
            searchableTags={searchableTags}
            searchableCategories={searchableCategories}
          />
        </div>
      </Dialog>
    </>
  );
};
TransactionSearchAndFilter.propTypes = {
  setSearchFilters: PropTypes.func.isRequired,
  searchFilters: PropTypes.object.isRequired,
  switchToAllTransactionsTab: PropTypes.func.isRequired,
  searchableCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  searchableTags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TransactionSearchAndFilter;
