import {
  useCallback,
  useState,
  KeyboardEventHandler,
  useRef,
  useContext,
  forwardRef,
  ForwardedRef,
  useEffect,
} from "react";
import { useLocalization } from "@fluent/react";
import PropTypes from "prop-types";
import { TruncatedAccount } from "@narmi/design_system";
import Account from "byzantine/src/Account";
import {
  isGroupedAccountsArray,
  filterAccounts,
  groupAccounts,
  DEFAULT_ACCOUNT_MINIMUM_FOR_GROUPING,
} from "byzantine/src/AccountUtils";
import { Features, featureEquals } from "byzantine/src/Feature";
import {
  Dropdown,
  DropdownListItem,
  DropdownLinkItem,
  DropdownItemGroupHeader,
  SearchBar,
} from "cerulean";
import { useUserFeatures } from "./contexts/UserFeaturesContext";
import { useInstitutionSettings } from "./contexts/InstitutionSettingsContext";
import AddAccountModal from "./transfer/AddAccountModal";
import AccountContext from "./contexts/AccountContext";

interface AccountListItemProps {
  account: Account;
  value: string;
  isIndented: boolean;
  onChange: (accountId: string) => void;
  closeDropdown: () => void;
  isDestination: boolean;
}
type ListMap = Map<string, HTMLElement> | null;

const AccountListItem = forwardRef(
  (
    {
      account,
      value,
      isIndented,
      onChange,
      closeDropdown,
      isDestination,
    }: AccountListItemProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    /* For rendering each account in the list (e.g. Primary Savings-001 ($2.31)) */

    const accountDescription = account.getDescription(isDestination);
    const splittingIndex = accountDescription.indexOf("(");
    let name = accountDescription;
    let lastFour = "";
    if (splittingIndex !== -1) {
      lastFour = accountDescription.substring(splittingIndex);
      name = accountDescription.substring(0, splittingIndex);
    }

    return (
      <DropdownListItem
        anyItemSelected={!!value}
        isSelected={value === account.id}
        isIndented={isIndented}
        closeDropdown={closeDropdown}
        onClick={() => {
          onChange(account.id);
        }}
        ariaLabel={`Account: ${name} ${lastFour}`}
        ref={ref}
      >
        <TruncatedAccount lastFour={lastFour} name={name} />
      </DropdownListItem>
    );
  },
);

interface AddExternalAccountOptionItemProps {
  value: string;
  setModalOpen: (isOpen: boolean) => void;
  closeDropdown: () => void;
  accounts: Account[];
}

const AddExternalAccountOptionItem = forwardRef(
  (
    {
      value,
      setModalOpen,
      closeDropdown,
      accounts,
    }: AddExternalAccountOptionItemProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { l10n } = useLocalization();
    return (
      <DropdownLinkItem
        anyItemSelected={!!value}
        setModalOpen={setModalOpen}
        closeDropdown={closeDropdown}
        ref={ref}
        isIndented={accounts?.length >= DEFAULT_ACCOUNT_MINIMUM_FOR_GROUPING}
        ariaLabel={l10n.getString("add-new-external-account")}
      >
        <div style={{ textAlign: "left" }}>{`+ ${l10n.getString(
          "add-new-external-account",
        )}`}</div>
      </DropdownLinkItem>
    );
  },
);

interface AccountSelectorProps {
  field: string;
  showAddExternalAccountLink?: boolean;
  value: string;
  accounts: Account[];
  isDestination: boolean;
  label: string;
  onChange: (accountId: string) => void;
  error?: string;
}

export default function AccountSelector({
  showAddExternalAccountLink = true,
  isDestination = false,
  value,
  accounts,
  label,
  onChange,
  error,
}: AccountSelectorProps) {
  const userFeatures = useUserFeatures();
  const institutionSettings = useInstitutionSettings();
  const { accounts: allAccounts, setAccounts } = useContext(AccountContext);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [indexFocused, setIndexFocused] = useState<number>(-1);

  const listRef = useRef<ListMap>(null);
  const addAccountBtnRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const shouldGroup = accounts?.length >= DEFAULT_ACCOUNT_MINIMUM_FOR_GROUPING;

  useEffect(() => {
    if (isDropdownOpen && shouldGroup) {
      searchInputRef?.current?.focus();
    }
  }, [isDropdownOpen, searchInputRef?.current]);

  const onSearchTermUpdate = (term: string) => {
    setIndexFocused(-1);
    setSearchTerm(term);
  };

  const filteredAccounts = filterAccounts(
    accounts,
    (account) => !!account,
    searchTerm,
  );
  const groupedAccounts = shouldGroup
    ? groupAccounts(filteredAccounts)
    : filteredAccounts;

  const getListRefMap = () => {
    if (!listRef.current) {
      listRef.current = new Map<string, HTMLElement>();
    }

    return listRef.current;
  };

  const closeDropdown = () => setIsDropdownOpen(false);

  const canAddExternalAccount =
    featureEquals(
      userFeatures as Features,
      "indigo.ExternalAccount_permission",
      "*",
    ) &&
    showAddExternalAccountLink &&
    ((!isDestination && institutionSettings.ach_allows_pull) ||
      (isDestination && institutionSettings.ach_allows_push)) &&
    (userFeatures as any).ach;

  const getRefNodeByIndex = (index: number) => {
    const listRefs = getListRefMap();
    if (shouldGroup && index === -1) {
      return searchInputRef.current;
    }

    if (canAddExternalAccount && index === filteredAccounts.length) {
      return canAddExternalAccount.current;
    }

    return listRefs.get(filteredAccounts[index]?.id);
  };

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      const list = getListRefMap();
      const lowestIndex = shouldGroup ? -1 : 0;
      const highestIndex = canAddExternalAccount
        ? filteredAccounts.length
        : filteredAccounts.length - 1;

      switch (event.key) {
        case "Enter": {
          event.preventDefault();
          if (filteredAccounts[indexFocused]?.id) {
            onChange(filteredAccounts[indexFocused].id);
          }
          return;
        }
        case "ArrowDown": {
          if (!isDropdownOpen) {
            setIsDropdownOpen(true);
          }
          setIndexFocused((prevIndexFocused) => {
            event.preventDefault();
            let nextIndex;

            if (prevIndexFocused + 1 > highestIndex) {
              nextIndex = lowestIndex;
            } else {
              nextIndex = prevIndexFocused + 1;
            }
            const listItem = getRefNodeByIndex(nextIndex);
            listItem?.focus();
            return nextIndex;
          });
          return;
        }
        case "ArrowUp": {
          if (!isDropdownOpen) {
            setIsDropdownOpen(true);
          }
          event.preventDefault();
          setIndexFocused((prevIndexFocused) => {
            let nextIndex;

            if (prevIndexFocused - 1 < lowestIndex) {
              nextIndex = highestIndex;
            } else {
              nextIndex = prevIndexFocused - 1;
            }
            const listItem = getRefNodeByIndex(nextIndex);
            listItem?.focus();
            return nextIndex;
          });
          return;
        }
        case "tab": {
          const listItem = list.get(filteredAccounts[indexFocused]?.id);
          listItem?.blur();

          setIndexFocused(-1);
          break;
        }
        default:
      }
    },
    [indexFocused, setIndexFocused],
  );

  const updateAccounts = (changedAccounts: Account[]) => {
    // TODO the context should manage the update function
    const accountsUpdated = allAccounts.map((account: Account) => account);
    changedAccounts.forEach((acc) => {
      const account = new Account(acc);
      // current AccountContext skips pending external accounts
      if (account.isExternal() && !account.verified) return;
      const idx = accountsUpdated.findIndex(
        (a: Account) => a.id === account.id,
      );
      if (idx !== -1) {
        accountsUpdated[idx] = account;
      } else {
        accountsUpdated.push(account);
      }
    });
    setAccounts(accountsUpdated);
  };

  /* Dropdown that allows search query to filter account results */
  let displayValue = "";
  if (value) {
    const selectedAccount = accounts.find((account) => account.id === value);
    if (selectedAccount) {
      displayValue = selectedAccount.getDescription(isDestination);
    }
  }

  let accountlist = null;

  if (isGroupedAccountsArray(groupedAccounts)) {
    accountlist = groupedAccounts.map((groupedAccount) => (
      <AccountListItem
        key={groupedAccount.id}
        account={groupedAccount}
        value={value}
        isIndented={false}
        isDestination={isDestination}
        closeDropdown={closeDropdown}
        onChange={onChange}
        ref={(node: HTMLDivElement) => {
          const listRefMap = getListRefMap();
          if (node) {
            listRefMap?.set(groupedAccount.id, node);
          } else {
            listRefMap?.delete(groupedAccount.id);
          }
        }}
      />
    ));
  } else if (groupedAccounts instanceof Map) {
    accountlist = Array.from(
      groupedAccounts,
      ([groupName, accountsForGroup]) => (
        <>
          <DropdownItemGroupHeader group={groupName} />
          {accountsForGroup.map((account) => (
            <AccountListItem
              account={account}
              key={account.id}
              isDestination={isDestination}
              closeDropdown={closeDropdown}
              isIndented={true}
              value={value}
              onChange={onChange}
              ref={(node: HTMLDivElement) => {
                const listRefMap = getListRefMap();
                if (node) {
                  listRefMap?.set(account.id, node);
                } else {
                  listRefMap?.delete(account.id);
                }
              }}
            />
          ))}
        </>
      ),
    );
  }

  return (
    <div onKeyDown={onKeyDown}>
      <Dropdown
        triggerLabel={label}
        triggerValue={displayValue}
        isOpen={isDropdownOpen}
        setIsOpen={setIsDropdownOpen}
        error={error}
      >
        {shouldGroup && (
          <SearchBar
            searchTerm={searchTerm}
            setSearchTerm={onSearchTermUpdate}
            ref={searchInputRef}
          />
        )}
        {accountlist}
        {canAddExternalAccount && (
          <AddExternalAccountOptionItem
            accounts={accounts}
            value={value}
            closeDropdown={closeDropdown}
            setModalOpen={setModalOpen}
            ref={addAccountBtnRef}
          />
        )}
      </Dropdown>
      <AddAccountModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        updateAccounts={updateAccounts}
      />
    </div>
  );
}

AccountSelector.propTypes = {
  field: PropTypes.string,
  accounts: PropTypes.array,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  isDestination: PropTypes.bool,
  showAddExternalAccountLink: PropTypes.bool,
  error: PropTypes.string,
};
