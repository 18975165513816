import {
  selectEndToEndTest,
  selectGAFeatures,
  selectInstitution,
  selectUser,
} from "../../entities";
import { createDeepEqualSelector } from "../../entities/utils/createDeepEqualSelector";
import { featureEquals } from "../../../../Feature";

import { isFeatureEnabled } from "./isEnabled";

import type { RootState } from "../../entities/types/rootState.types";
import type { FeatureQuery, FeatureValue } from "./types";

export const selectFeatures = createDeepEqualSelector(
  [selectGAFeatures, selectInstitution, selectUser, selectEndToEndTest],
  (gaFeatures, institution, user, { featureOverrides }) => {
    // If we have user features (which we get right after the user authenticates,
    // Use them instead of the public institution features received before auth
    return {
      ...gaFeatures,
      ...(user?.features || institution?.features),
      ...featureOverrides,
    } as API.Features;
  },
);

export function selectFeatureEnabled(
  state: RootState,
  or?: FeatureQuery["or"],
  and?: FeatureQuery["and"],
  not?: FeatureQuery["not"],
) {
  const flags = selectFeatures(state);
  return isFeatureEnabled(flags, { or, and, not });
}

export function selectFeatureEquals(
  state: RootState,
  flag: string,
  setting?: FeatureValue | FeatureValue[],
) {
  const flags = selectFeatures(state);
  return featureEquals(flags, flag, setting);
}
