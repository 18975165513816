/* eslint-disable import/no-relative-packages */
import { Combobox } from "@narmi/design_system";
// @ts-ignore
import React, { ReactElement, useState } from "react";
import { INDUSTRIES, NAICS_6_DIGITS_CODES } from "./constants";

interface NaicsCodesTree {
  [category: string]: {
    [code: string]: string;
  };
}

export const getHumanReadableNaicsIndustry = (industry?: string) => {
  if (!industry) return;
  if (NAICS_6_DIGITS_CODES[industry as keyof typeof NAICS_6_DIGITS_CODES]) {
    return `${industry} - ${NAICS_6_DIGITS_CODES[industry as keyof typeof NAICS_6_DIGITS_CODES]}`;
  }
  return INDUSTRIES[industry as keyof typeof INDUSTRIES] || industry;
};

/**
 * @param industry - NAICS code given by user
 * @returns error string if industry is invalid, else empty string
 */
export const validateIndustry = (
  industry?: string,
  requiredMsg: string = "",
  naicsMsg: string = ""
): string => {
  if (!industry) return requiredMsg;
  if (industry.length !== 6 || !NAICS_6_DIGITS_CODES.hasOwnProperty(industry)) return naicsMsg;
  return "";
};

const IndustryField = ({
  value,
  updateIndustry,
  setIndustryError = () => {},
  error,
  naicsLabel = "NAICS Code",
  requiredMsg = "Required",
  naicsErrorMsg = "Please enter a valid 6-digit NAICS code",
}: {
  value: string;
  updateIndustry: CallableFunction;
  setIndustryError?: CallableFunction;
  error?: string;
  naicsLabel?: string;
  requiredMsg?: string;
  naicsErrorMsg?: string;
}) => {
  let currentCategoryPrefix = "";
  let currentCategory = "";
  const naicsCodesTree: NaicsCodesTree = {};
  Object.keys(NAICS_6_DIGITS_CODES).map((code: string) => {
    const prefix = code.substring(0, 2);
    if (prefix !== currentCategoryPrefix) {
      currentCategoryPrefix = prefix;
      currentCategory = INDUSTRIES[prefix as keyof typeof INDUSTRIES] || currentCategory;
      naicsCodesTree[currentCategory as keyof typeof naicsCodesTree] = {};
    }
    naicsCodesTree[currentCategory][code] =
      NAICS_6_DIGITS_CODES[code as keyof typeof NAICS_6_DIGITS_CODES];
    return naicsCodesTree;
  });

  const [inputValue, setInputValue] = useState(value);

  return (
    <Combobox
      label={naicsLabel}
      data-test-element="industry-input"
      errorText={error}
      inputValue={inputValue}
      onChange={(selected: string) => {
        updateIndustry(selected);
        const validationError = validateIndustry(selected, requiredMsg, naicsErrorMsg);
        // Clear any form errors if code is at any point valid
        if (!validationError) setIndustryError(validationError);
      }}
      filterItemsByInput={(items: ReactElement[], inputVal: string) =>
        items.filter((item) => {
          if (!item) return false;
          const query = (item.props.searchValue || item.props.value).toLowerCase();
          return query.includes(inputVal);
        })
      }
      onInputChange={(selected: string) => {
        setInputValue(selected);
      }}
    >
      {Object.keys(naicsCodesTree).map((category: string) => (
        <Combobox.Category label={category} key={category}>
          {Object.keys(naicsCodesTree[category]).map((code: string) => (
            <Combobox.Item
              value={code}
              searchValue={`${naicsCodesTree[category][code]}-${code}`}
              key={code}
            >
              {`${naicsCodesTree[category][code]}-${code}`}
            </Combobox.Item>
          ))}
        </Combobox.Category>
      ))}
    </Combobox>
  );
};

export default IndustryField;
