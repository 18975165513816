import { sortAccountGroups, groupAccounts } from "../../../../AccountUtils";

import type Account from "../../../../Account";
import type { selectAccountSorting } from "../selectors";

const shortGroupTitle = (title: string) =>
  title === "External accounts" ? "External" : title;

type AccountSortingList = ReturnType<typeof selectAccountSorting>;

const flattenGroups = (
  groups: ReturnType<typeof groupAccounts<Account>>,
  sorting: AccountSortingList,
) => {
  sortAccountGroups(groups, sorting); // updates inplace
  const groupsArray = Array.from(groups);

  return groupsArray.map(([title, accounts]) => {
    return {
      title: shortGroupTitle(title),
      accountIds: accounts.map((a) => a.id as API.AccountId),
      accounts,
    };
  });
};

export const createGroups = (
  accounts: Account[],
  sorting: AccountSortingList,
) => flattenGroups(groupAccounts(accounts), sorting);
